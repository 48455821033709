// ProgressBar.js
import React from "react";
import "../styles/kayanYazilarStyle.css";

const ProgressBar = ({ progress }) => {
  return (
    <div className="kayan-yazilar-progress-bar-container">
      <div
        className="kayan-yazilar-progress-bar-fill"
        style={{ width: `${progress}%` }}
      ></div>
    </div>
  );
};

export default ProgressBar;
