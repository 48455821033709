// GameHeader.js

import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faHome } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import '../styles/harfDongusu.css'; // CSS dosyanızı import etmeyi unutmayın

const GameHeader = ({ handleReturnToPreviousStage }) => {
  const navigate = useNavigate();

  return (
    <div className="harf-dongusu-header">
      <button className="harf-dongusu-button" onClick={() => navigate(-1)}>
        <FontAwesomeIcon icon={faArrowLeft} /> Geri Dön
      </button>
      <button className="harf-dongusu-button" onClick={() => navigate('/')}>
        <FontAwesomeIcon icon={faHome} /> Ana Sayfa
      </button>
      <button className="harf-dongusu-button" onClick={handleReturnToPreviousStage}>
        <FontAwesomeIcon icon={faArrowLeft} /> Önceki Sayfaya Dön
      </button>
    </div>
  );
};

export default GameHeader;
