import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom'; 
import GameHeader from './components/GameHeader';
import GameTimerDisplay from './components/GameTimerDisplay';
import ScoreDisplay from './components/ScoreDisplay';
import GameOverModal from './components/GameOverModal';
import './styles/noktaTakipEt.css';
import { API_URLS } from '../../../config/config';
import axiosInstance from '../../../utils/axiosInstance';

function GameSetup() {
  const requestRef = useRef();
  const navigate = useNavigate();
  const location = useLocation();
  const { stage, exercise } = location.state || {};
  const token = localStorage.getItem('userToken');

  const [userData, setUserData] = useState(null);
  const [userRole, setUserRole] = useState(null);
  const [totalTime, setTotalTime] = useState(15);

  const [speedFactor, setSpeedFactor] = useState(1);
  const lineSpeed = 1 * speedFactor; 
  const arcSpeed = 0.02 * speedFactor;

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const studentResponse = await axiosInstance.get(`${API_URLS.STUDENT_URL}/me`);
        if (
          studentResponse.status === 200 &&
          studentResponse.data &&
          studentResponse.data.status === 'active'
        ) {
          const studentData = studentResponse.data;
          setUserData(studentData);
          setUserRole('student');
          return;
        } else {
          setUserData(null);
          setUserRole(null);
        }
      } catch (error) {
        console.error('Öğrenci verisi alınırken hata oluştu:', error);
      }

      try {
        const teacherResponse = await fetch(`${API_URLS.TEACHER_URL}/me`, {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });
        if (teacherResponse.ok) {
          const teacherData = await teacherResponse.json();
          setUserData(teacherData);
          setUserRole('teacher');
          return;
        }
      } catch (error) {
        console.error('Öğretmen verisi alınırken hata oluştu:', error);
      }

      setUserData(null);
      setUserRole(null);
    };

    fetchUserData();
  }, [token]);

  useEffect(() => {
    if (userData && userRole) {
      if (userRole === 'student') {
        setTotalTime(userData.status === 'inactive' ? 15 : 40);
      } else if (userRole === 'teacher') {
        setTotalTime(userData.status === 'inactive' ? 15 : 40);
      }
    } else {
      setTotalTime(15);
    }
  }, [userData, userRole]);

  const circleSize = 200; 
  const cx = circleSize / 2;
  const cy = circleSize / 2;
  const radius = (circleSize / 2) - 2;

  const angleN = 3 * Math.PI / 2;
  const angleE = 0;
  const angleS = Math.PI / 2;
  const angleW = Math.PI;

  const cardinals = [
    { name: 'N', angle: angleN, x: cx, y: 0 },
    { name: 'E', angle: angleE, x: circleSize, y: cy },
    { name: 'S', angle: angleS, x: cx, y: circleSize },
    { name: 'W', angle: angleW, x: 0, y: cy }
  ];

  const [currentPosition, setCurrentPosition] = useState({ x: cx, y: 0, state: 'cardinal', cardinal: 'N' });
  const [moveType, setMoveType] = useState(null);
  const [target, setTarget] = useState(null);
  const [arcAngle, setArcAngle] = useState(angleN);

  const [score, setScore] = useState(0);
  const [gameTime, setGameTime] = useState(totalTime);
  const [initialGameTime, setInitialGameTime] = useState(totalTime);
  const [isPlaying, setIsPlaying] = useState(false); 
  const [isGameOver, setIsGameOver] = useState(false);

  useEffect(() => {
    setGameTime(totalTime);
    setInitialGameTime(totalTime);
  }, [totalTime]);

  const getRandomCardinal = (exclude = null) => {
    const filtered = exclude ? cardinals.filter(c => c.name !== exclude) : cardinals;
    return filtered[Math.floor(Math.random() * filtered.length)];
  };

  const getRandomMoveType = () => {
    return Math.random() < 0.5 ? 'line' : 'arc';
  };

  const chooseNextMove = (pos) => {
    setScore(prev => prev + 1);

    if (pos.state === 'center') {
      const nextCardinal = getRandomCardinal(); 
      setMoveType('line');
      setTarget({ type: 'cardinal', ...nextCardinal });
    } else if (pos.state === 'cardinal') {
      const nextMove = getRandomMoveType();
      if (nextMove === 'line') {
        setMoveType('line');
        setTarget({ type: 'center' });
      } else {
        const nextCardinal = getRandomCardinal(pos.cardinal);
        setMoveType('arc');
        setTarget({ type: 'cardinal', ...nextCardinal });
        setArcAngle(cardinals.find(c=>c.name===pos.cardinal).angle);
      }
    }
  };

  useEffect(() => {
    if (isPlaying && gameTime > 0) {
      const timer = setInterval(() => {
        setGameTime(prev => prev - 1);
      }, 1000);
      return () => clearInterval(timer);
    }

    if (gameTime === 0) {
      setIsPlaying(false);
      setIsGameOver(true);
    }
  }, [isPlaying, gameTime]);

  useEffect(() => {
    const animate = () => {
      setCurrentPosition(prev => {
        let { x, y, state, cardinal } = prev;

        if (moveType === 'line' && target && isPlaying) {
          if (target.type === 'center') {
            if (cardinal === 'N') {
              if (y < cy) {
                y += lineSpeed;
              } else {
                state = 'center';
                cardinal = null;
                chooseNextMove({ x, y, state });
              }
            } else if (cardinal === 'S') {
              if (y > cy) {
                y -= lineSpeed;
              } else {
                state = 'center';
                cardinal = null;
                chooseNextMove({ x, y, state });
              }
            } else if (cardinal === 'E') {
              if (x > cx) {
                x -= lineSpeed;
              } else {
                state = 'center';
                cardinal = null;
                chooseNextMove({ x, y, state });
              }
            } else if (cardinal === 'W') {
              if (x < cx) {
                x += lineSpeed;
              } else {
                state = 'center';
                cardinal = null;
                chooseNextMove({ x, y, state });
              }
            }

          } else if (target.type === 'cardinal') {
            const targetX = target.x;
            const targetY = target.y;
            if (target.name === 'N') {
              if (y > targetY) {
                y -= lineSpeed;
              } else {
                state = 'cardinal';
                cardinal = 'N';
                chooseNextMove({ x, y, state, cardinal });
              }
            } else if (target.name === 'S') {
              if (y < targetY) {
                y += lineSpeed;
              } else {
                state = 'cardinal';
                cardinal = 'S';
                chooseNextMove({ x, y, state, cardinal });
              }
            } else if (target.name === 'E') {
              if (x < targetX) {
                x += lineSpeed;
              } else {
                state = 'cardinal';
                cardinal = 'E';
                chooseNextMove({ x, y, state, cardinal });
              }
            } else if (target.name === 'W') {
              if (x > targetX) {
                x -= lineSpeed;
              } else {
                state = 'cardinal';
                cardinal = 'W';
                chooseNextMove({ x, y, state, cardinal });
              }
            }
          }
        } else if (moveType === 'arc' && target && isPlaying) {
          const startAngle = cardinals.find(c=>c.name===cardinal).angle;
          const endAngle = target.angle;

          let currentAngle = arcAngle;
          let desiredAngle = endAngle;

          let finalEnd = desiredAngle;
          if (finalEnd <= startAngle) {
            finalEnd += 2 * Math.PI; 
          }

          if (currentAngle < finalEnd) {
            currentAngle += arcSpeed;
          } else {
            currentAngle = endAngle;
            state = 'cardinal';
            cardinal = target.name;
            chooseNextMove({ x, y, state, cardinal });
          }

          setArcAngle(currentAngle);
          x = cx + Math.cos(currentAngle) * radius;
          y = cy + Math.sin(currentAngle) * radius;
        }

        return { x, y, state, cardinal };
      });

      if (isPlaying && gameTime > 0) {
        requestRef.current = requestAnimationFrame(animate);
      }
    };

    if (isPlaying && gameTime > 0) {
      requestRef.current = requestAnimationFrame(animate);
    }

    return () => cancelAnimationFrame(requestRef.current);
  }, [moveType, target, arcAngle, isPlaying, gameTime, lineSpeed, arcSpeed]);

  const handleGoHome = () => {
    navigate('/');
  };

  const handleGoBack = () => {
    navigate(-1);
  };

  // Önceki Aşama butonu için fonksiyon
  const handleReturnToPreviousStage = () => {
    if (stage) {
      navigate(
        userRole === 'student' ? '/ogrenci-dashboard' : '/ogretmen-dashboard',
        {
          state: {
            selectedMenu: 'ExerciseList',
            selectedStage: stage,
          },
        }
      );
    } else {
      alert('Aşama bilgisi bulunmamaktadır.');
    }
  };

  const showScore = userData && userData.status !== 'inactive';

  const handleRestart = () => {
    setGameTime(totalTime);
    setInitialGameTime(totalTime);
    setScore(0);
    setIsPlaying(false);
    setIsGameOver(false);
    setMoveType(null);
    setTarget(null);
    setArcAngle(angleN);
    setCurrentPosition({ x: cx, y: 0, state: 'cardinal', cardinal: 'N' });
  };

  const handleCloseModal = () => {
    setIsGameOver(false);
  };

  const handleStart = () => {
    if (!isPlaying) {
      setIsPlaying(true);
      if (!moveType || !target) {
        chooseNextMove({ x: cx, y: 0, state: 'cardinal', cardinal: 'N' });
      }
    }
  };

  const handleStop = () => {
    setIsPlaying(false);
  };

  // Zigzag tarzı hız kontrolü
  const handleSpeedIncrease = () => {
    setSpeedFactor(prev => Math.min(prev + 0.1, 3));
  };

  const handleSpeedDecrease = () => {
    setSpeedFactor(prev => Math.max(prev - 0.1, 0.5));
  };

  return (
    <div className="nesneyi-takip-et-container">
      <GameHeader 
        handleGoHome={handleGoHome}
        handleGoBack={handleGoBack}
        handleReturnToPreviousStage={handleReturnToPreviousStage}
      />

      <div className="nesneyi-takip-et-status" style={{marginTop:20}}>
        <GameTimerDisplay gameTime={gameTime} initialGameTime={initialGameTime} />
        {showScore && <ScoreDisplay score={score} />}
      </div>

      <div className="responsive-circle-container">
        <svg 
          viewBox={`0 0 ${circleSize} ${circleSize}`} 
          style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}
        >
          <circle 
            cx={cx} 
            cy={cy} 
            r={radius} 
            stroke="white" 
            fill="transparent" 
            strokeWidth="2" 
          />
          <line
            x1={0}
            y1={cy}
            x2={circleSize}
            y2={cy}
            stroke="white"
            strokeWidth="2"
          />
          <line
            x1={cx}
            y1={0}
            x2={cx}
            y2={circleSize}
            stroke="white"
            strokeWidth="2"
          />
          <circle cx={currentPosition.x} cy={currentPosition.y} r={8} fill="yellow" />
        </svg>
      </div>

      <div className="nesneyi-takip-et-control-row">
        <div className="nesneyi-takip-et-game-controls">
          {!isPlaying && !isGameOver && (
            <button onClick={handleStart}>Başlat</button>
          )}
          {isPlaying && !isGameOver && (
            <button onClick={handleStop}>Durdur</button>
          )}
          <button onClick={handleRestart}>Yeniden Başlat</button>
        </div>

        {/* ZigzagTracker stilinde hız kontrolü */}
        <div className="speed-control-container">
          <div className="speed-label">Hız Ayarı</div>
          <div className="speed-display">
            <button className="speed-button" onClick={handleSpeedDecrease}>-</button>
            <div className="speed-value">{speedFactor.toFixed(1)}</div>
            <button className="speed-button" onClick={handleSpeedIncrease}>+</button>
          </div>
          <div className="speed-instructions">
            Hızı 0.5 ile 3 arasında ayarlayabilirsiniz.
          </div>
        </div>
      </div>

      {isGameOver && (
        <GameOverModal
          score={score}
          onRestart={handleRestart}
          userData={userData}
          onClose={handleCloseModal}
        />
      )}
    </div>
  );
}

export default GameSetup;
