import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  IconButton
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';

/**
 * AddTestDialog bileşeni
 * - isOpen: Dialog açık mı kapalı mı
 * - testSorusu, testSiklari, dogruCevap, testAciklamasi, secilenKitapId: state değerleri
 * - books: kitap listesi
 * - handleSikEkle, handleSikSil: şık ekleme ve silme fonksiyonları
 * - handleTestFormKapat, handleTestFormSubmit: dialog kapatma ve form gönderme fonksiyonları
 * - setTestSorusu, setTestSiklari, setDogruCevap, setTestAciklamasi, setSecilenKitapId: state güncelleyici fonksiyonlar
 */
const AddTestDialog = ({
  isOpen,
  testSorusu,
  testSiklari,
  dogruCevap,
  testAciklamasi,
  secilenKitapId,
  books,
  handleSikEkle,
  handleSikSil,
  handleTestFormKapat,
  handleTestFormSubmit,
  setTestSorusu,
  setTestSiklari,
  setDogruCevap,
  setTestAciklamasi,
  setSecilenKitapId,
}) => {
  return (
    <Dialog open={isOpen} onClose={handleTestFormKapat} maxWidth="md" fullWidth>
      <DialogTitle>Yeni Test Ekle</DialogTitle>
      <DialogContent>
        <form onSubmit={handleTestFormSubmit}>
          <TextField
            fullWidth
            label="Soru"
            value={testSorusu}
            onChange={(e) => setTestSorusu(e.target.value)}
            margin="normal"
            required
          />

          {testSiklari.map((sik, index) => (
            <div key={index} style={{ display: 'flex', alignItems: 'center' }}>
              <TextField
                fullWidth
                label={`Şık ${index + 1}`}
                value={sik}
                onChange={(e) => {
                  const yeniSiklar = [...testSiklari];
                  yeniSiklar[index] = e.target.value;
                  setTestSiklari(yeniSiklar);
                }}
                margin="normal"
                required
              />
              <IconButton onClick={() => handleSikSil(index)}>
                <DeleteIcon />
              </IconButton>
            </div>
          ))}

          <Button
            onClick={handleSikEkle}
            color="primary"
            variant="contained"
            style={{ marginTop: '10px' }}
            disabled={testSiklari.length >= 5}
          >
            Şık Ekle
          </Button>

          <FormControl fullWidth margin="normal" required>
            <InputLabel>Doğru Cevap</InputLabel>
            <Select
              value={dogruCevap}
              onChange={(e) => setDogruCevap(e.target.value)}
              displayEmpty
            >
              <MenuItem value="" disabled>
                Doğru cevabı seçin
              </MenuItem>
              {testSiklari.map((option, index) => (
                <MenuItem key={index} value={index}>
                  {String.fromCharCode(65 + index)}. {option || `Şık ${index + 1}`}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl fullWidth margin="normal" required>
            <InputLabel>Kitap Seçin</InputLabel>
            <Select
              value={secilenKitapId}
              onChange={(e) => setSecilenKitapId(e.target.value)}
              displayEmpty
            >
              <MenuItem value="" disabled>
                Kitap seçin
              </MenuItem>
              {Array.isArray(books) &&
                books.map((book) => (
                  <MenuItem key={book._id} value={book._id}>
                    {book.title}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>

          <TextField
            fullWidth
            label="Açıklama"
            value={testAciklamasi}
            onChange={(e) => setTestAciklamasi(e.target.value)}
            margin="normal"
            multiline
            rows={4}
          />
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleTestFormKapat}>İptal</Button>
        <Button onClick={handleTestFormSubmit} color="primary" variant="contained" type="submit">
          Kaydet
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddTestDialog;
