// src/components/blog/HomeBlogSection.js
import React, { useEffect, useState } from 'react';
import { Card, CardContent, Typography, Grid, Box, Container, CardMedia, Button } from '@mui/material';
import { API_URLS } from '../../../config/config';
import ImageNotSupportedIcon from '@mui/icons-material/ImageNotSupported';
import { useNavigate } from 'react-router-dom';
import slugify from 'slugify';

function HomeBlogSection() {
  const [blogs, setBlogs] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    fetch(`${API_URLS.HOME_URL}/blog`)
      .then(response => response.json())
      .then(data => {
        if (data.success && Array.isArray(data.data)) {
          const topThree = data.data.slice(0, 3);
          setBlogs(topThree);
        }
      })
      .catch(error => {
        console.error('Bloglar alınırken hata:', error);
      });
  }, []);

  const getCardStylesByIndex = (index) => {
    const gradients = [
      'linear-gradient(135deg, #FFC371 0%, #FF5F6D 100%)',
      'linear-gradient(135deg, #0ED2F7 0%, #B2FEFA 100%)',
      'linear-gradient(135deg, #F9D423 0%, #FF4E50 100%)'
    ];
    return gradients[index % gradients.length];
  };

  const handleBlogClick = (blogTitle) => {
    const slug = slugify(blogTitle, { lower: true, strict: true });
    navigate(`/blog/${slug}`);
  };

  return (
    <Box 
      sx={{ 
        py: 8, 
        backgroundColor: '#f0f2f5'
      }}
    >
      <Container maxWidth="lg">
        <Typography 
          variant="h4" 
          align="center" 
          gutterBottom 
          sx={{ 
            fontWeight: 'bold', 
            mb: 4,
            color: '#333',
            textShadow: '1px 1px 2px rgba(0,0,0,0.3)',
            fontFamily: 'Playfair Display, serif',
            letterSpacing: '1px'
          }}
        >
          Son Blog Yazıları
        </Typography>
        <Grid container spacing={4}>
          {blogs.map((blog, index) => {
            const imageUrl = blog.image ? `${API_URLS.BLOG_RESİM_URL}/${blog.image.replace(/\\/g, '/')}` : null;
            const plainContent = blog.content.replace(/<[^>]+>/g, '');
            const cardBackground = getCardStylesByIndex(index);

            return (
              <Grid item xs={12} sm={6} md={4} key={blog._id}>
                <Card
                  elevation={6}
                  sx={{
                    borderRadius: 3,
                    background: cardBackground,
                    color: '#fff',
                    position: 'relative',
                    overflow: 'hidden',
                    transition: 'transform 0.3s, box-shadow 0.3s',
                    display: 'flex',
                    flexDirection: 'column',
                    '&:hover': {
                      transform: 'scale(1.05)',
                      boxShadow: '-3px 8px 23px -2px rgba(0,0,0,0.75)',
                    },
                  }}
                >
                  <Box
                    sx={{
                      position: 'absolute',
                      top: 0,
                      left: 0,
                      width: '100%',
                      height: '10px',
                      backgroundColor: 'rgba(255,255,255,0.3)',
                      zIndex: 1,
                    }}
                  ></Box>

                  {imageUrl ? (
                    <CardMedia
                      component="img"
                      height="200"
                      image={imageUrl}
                      alt={blog.title}
                      sx={{
                        objectFit: 'cover',
                        transition: 'opacity 0.3s',
                        zIndex: 2,
                        position: 'relative',
                        '&:hover': {
                          opacity: 0.97,
                        }
                      }}
                    />
                  ) : (
                    <Box
                      sx={{
                        height: 200,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        backgroundColor: 'rgba(255,255,255,0.2)',
                        position: 'relative',
                        zIndex: 2
                      }}
                    >
                      <ImageNotSupportedIcon sx={{ fontSize: 48, color: '#fff', textShadow: '1px 1px 2px rgba(0,0,0,0.3)' }} />
                    </Box>
                  )}

                  <CardContent
                    sx={{
                      position: 'relative',
                      zIndex: 2,
                      textAlign: 'center',
                      backgroundColor: 'rgba(0,0,0,0.1)', 
                      backdropFilter: 'blur(4px)',
                      display: 'flex',
                      flexDirection: 'column',
                      flexGrow: 1,
                      pt: 2,
                      pb: 2
                    }}
                  >
                    <Typography 
                      variant="h6"
                      sx={{ 
                        fontWeight: '900',
                        mb: 1, 
                        color: '#fff',
                        fontFamily: 'Playfair Display, serif',
                        fontSize: '1.4rem',
                        textOverflow: 'ellipsis',
                        overflow: 'hidden',
                        whiteSpace: 'nowrap',
                        textShadow: '1px 1px 2px rgba(0,0,0,0.3)'
                      }}
                      title={blog.title}
                    >
                      {blog.title}
                    </Typography>
                    <Typography 
                      variant="body2" 
                      sx={{ 
                        color: '#fff', 
                        fontFamily: 'Montserrat, sans-serif',
                        fontWeight: '600',
                        fontSize: '1.1rem',
                        overflow: 'hidden',
                        display: '-webkit-box',
                        WebkitBoxOrient: 'vertical',
                        WebkitLineClamp: 3,
                        textShadow: '1px 1px 2px rgba(0,0,0,0.3)',
                        mb: 2
                      }}
                    >
                      {plainContent.length > 100 ? plainContent.slice(0,100) + '...' : plainContent}
                    </Typography>
                    <Box sx={{ mt: 'auto', textAlign: 'center' }}>
                      <Button 
                        variant="contained" 
                        onClick={() => handleBlogClick(blog.title)}
                        sx={{
                          textTransform: 'none',
                          fontWeight: '600',
                          fontFamily: 'Lato, sans-serif',
                          borderRadius: 2,
                          background: 'linear-gradient(135deg, #FF5F6D 0%, #FFC371 100%)',
                          color: '#fff',
                          boxShadow: '0 4px 10px rgba(0,0,0,0.15)',
                          '&:hover': {
                            background: 'linear-gradient(135deg, #FF5F6D 10%, #FFC371 90%)',
                            boxShadow: '0 4px 14px rgba(0,0,0,0.3)',
                          },
                          width: '100%',
                        }}
                      >
                        Devamını Oku
                      </Button>
                    </Box>
                  </CardContent>
                </Card>
              </Grid>
            );
          })}
          {blogs.length === 0 && (
            <Grid item xs={12}>
              <Typography 
                align="center" 
                variant="body1" 
                sx={{ 
                  width: '100%', 
                  mt: 4, 
                  color: '#333', 
                  fontStyle: 'italic', 
                  opacity: 0.9,
                  fontFamily: 'Montserrat, sans-serif',
                  textShadow: '1px 1px 2px rgba(0,0,0,0.3)',
                  fontSize: '1.2rem',
                  fontWeight: '600'
                }}
              >
                Henüz blog yazısı bulunmuyor.
              </Typography>
            </Grid>
          )}
        </Grid>
      </Container>
    </Box>
  );
}

export default HomeBlogSection;
