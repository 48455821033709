import React from 'react';
import { Grid, TextField, FormControl, InputLabel, Select, MenuItem, Button } from '@mui/material';

const OgretmenKayıtFormu = ({
  ad,
  setAd,
  soyad,
  setSoyad,
  okulAdi,
  setOkulAdi,
  secilenSehirId,
  setSecilenSehirId,
  sehirListesi,
  secilenIlceId,
  setSecilenIlceId,
  secilenSehirinIlceleri,
  telefon,
  setTelefon,
  telefonHata,
  email,
  setEmail,
  sifre,
  setSifre,
  durum,
  setDurum,
  handleEkle
}) => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={6} md={4}>
        <TextField
          fullWidth
          label="Adı"
          value={ad}
          onChange={(e) => setAd(e.target.value)}
          margin="normal"
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <TextField
          fullWidth
          label="Soyadı"
          value={soyad}
          onChange={(e) => setSoyad(e.target.value)}
          margin="normal"
        />
      </Grid>

      {/* Okul Adı (Opsiyonel) */}
      <Grid item xs={12} sm={6} md={4}>
        <TextField
          fullWidth
          label="Okul Adı (Zorunlu)"
          value={okulAdi}
          onChange={(e) => setOkulAdi(e.target.value)}
          margin="normal"
        />
      </Grid>

      <Grid item xs={12} sm={6} md={4}>
        <FormControl fullWidth margin="normal">
          <InputLabel id="sehir-select-label">Şehir</InputLabel>
          <Select
            labelId="sehir-select-label"
            value={secilenSehirId}
            onChange={setSecilenSehirId}
            displayEmpty
            renderValue={(selected) => {
              if (selected === "") {
                return <em>Şehir Seçiniz</em>;
              }
              const secilenSehir = sehirListesi.find(
                (sehir) => sehir._id === selected
              );
              return secilenSehir
                ? secilenSehir.sehir_adi
                : <em>Şehir Seçiniz</em>;
            }}
          >
            <MenuItem disabled value="">
              <em>Şehir Seçiniz</em>
            </MenuItem>
            {sehirListesi.map((sehir) => (
              <MenuItem key={sehir._id} value={sehir._id}>
                {sehir.sehir_adi}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>

      {secilenSehirId && (
        <Grid item xs={12} sm={6} md={4}>
          <FormControl fullWidth margin="normal">
            <InputLabel id="ilce-select-label">İlçe</InputLabel>
            <Select
              labelId="ilce-select-label"
              value={secilenIlceId}
              onChange={(e) => setSecilenIlceId(e.target.value)}
              displayEmpty
              renderValue={(selected) => {
                if (!selected) {
                  return <em>İlçe Seçiniz</em>;
                }
                const secilenIlce = secilenSehirinIlceleri.find(
                  (ilce) => ilce._id === selected
                );
                return secilenIlce
                  ? secilenIlce.ilce_adi
                  : <em>İlçe Seçiniz</em>;
              }}
            >
              <MenuItem disabled value="">
                <em>İlçe Seçiniz</em>
              </MenuItem>
              {secilenSehirinIlceleri.map((ilce) => (
                <MenuItem key={ilce._id} value={ilce._id}>
                  {ilce.ilce_adi}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      )}

      <Grid item xs={12} sm={6} md={4}>
        <TextField
          fullWidth
          label="Telefon"
          value={telefon}
          onChange={(e) => setTelefon(e.target.value)}
          error={telefonHata}
          helperText={
            telefonHata
              ? "Telefon numarası 05XX XXX XXXX formatında olmalıdır."
              : ""
          }
          margin="normal"
        />
      </Grid>

      <Grid item xs={12} sm={6} md={4}>
        <FormControl fullWidth margin="normal">
          <InputLabel id="status-select-label">Durum</InputLabel>
          <Select
            labelId="status-select-label"
            value={durum}
            onChange={(e) => setDurum(e.target.value)}
          >
            <MenuItem value="active">Aktif</MenuItem>
            <MenuItem value="inactive">Pasif</MenuItem>
          </Select>
        </FormControl>
      </Grid>

      <Grid item xs={12} sm={6} md={4}>
        <TextField
          fullWidth
          label="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          margin="normal"
        />
      </Grid>

      <Grid item xs={12} sm={6} md={4}>
        <TextField
          fullWidth
          label="Şifre"
          value={sifre}
          onChange={(e) => setSifre(e.target.value)}
          margin="normal"
          type="password"
        />
      </Grid>

      <Grid item xs={12} sm={6} md={4} alignSelf="center">
        <Button
          onClick={handleEkle}
          variant="contained"
          color="primary"
          fullWidth
        >
          Ekle
        </Button>
      </Grid>
    </Grid>
  );
};

export default OgretmenKayıtFormu;
