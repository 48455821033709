import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  IconButton
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';

/**
 * EditTestDialog bileşeni
 * - isOpen: Dialog açık mı kapalı mı
 * - editedTest: düzenlenecek test objesi
 * - handleClose: dialog kapatma fonksiyonu
 * - handleTestChange: textfield/ select değerlerini değiştirme fonksiyonu
 * - handleUpdate: "Kaydet" (Güncelle) butonuna basıldığında tetiklenen fonksiyon
 */
const EditTestDialog = ({
  isOpen,
  editedTest,
  handleClose,
  handleTestChange,
  handleUpdate,
}) => {
  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      maxWidth="md"
      fullWidth
    >
      <DialogTitle>Test Düzenle</DialogTitle>
      <DialogContent>
        {/* Soru */}
        <TextField
          fullWidth
          label="Soru"
          name="question"
          value={editedTest.question}
          onChange={handleTestChange}
          margin="normal"
        />

        {/* Şıklar */}
        {editedTest.options.map((option, index) => (
          <div key={index} style={{ display: 'flex', alignItems: 'center' }}>
            <TextField
              fullWidth
              label={`Şık ${index + 1}`}
              value={option}
              onChange={(e) => {
                const newOptions = [...editedTest.options];
                newOptions[index] = e.target.value;
                handleTestChange({
                  target: { name: 'options', value: newOptions },
                });
              }}
              margin="normal"
            />
            <IconButton
              onClick={() => {
                const newOptions = [...editedTest.options];
                newOptions.splice(index, 1);
                handleTestChange({
                  target: { name: 'options', value: newOptions },
                });
              }}
            >
              <DeleteIcon />
            </IconButton>
          </div>
        ))}
        {/* Şık Ekle */}
        <Button
          onClick={() => {
            if (editedTest.options.length < 5) {
              const newOptions = [...editedTest.options, ''];
              handleTestChange({
                target: { name: 'options', value: newOptions },
              });
            }
          }}
          color="primary"
          variant="contained"
          style={{ marginTop: '10px' }}
          disabled={editedTest.options.length >= 5}
        >
          Şık Ekle
        </Button>

        {/* Doğru Cevap */}
        <FormControl fullWidth margin="normal">
          <InputLabel>Doğru Cevap</InputLabel>
          <Select
            name="correctAnswer"
            value={editedTest.correctAnswer.toString()}
            onChange={handleTestChange}
            displayEmpty
          >
            <MenuItem value="" disabled>
              Doğru cevabı seçin
            </MenuItem>
            {editedTest.options.map((option, index) => (
              <MenuItem key={index} value={index.toString()}>
                {String.fromCharCode(65 + index)}. {option}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        {/* Açıklama */}
        <TextField
          fullWidth
          label="Açıklama"
          name="explanation"
          value={editedTest.explanation}
          onChange={handleTestChange}
          margin="normal"
          multiline
          rows={4}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>İptal</Button>
        <Button onClick={handleUpdate} color="primary" variant="contained">
          Güncelle
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditTestDialog;
