import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Grid, Card, CardActionArea, CardContent, CardMedia, Button, Typography, CardActions } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Header from '../components/Header';
import Footer from '../components/Footer';
import '../../assets/Auth.css';

const theme = createTheme({
  components: {
    MuiCard: {
      styleOverrides: {
        root: {
          borderRadius: '16px',
          boxShadow: '0 8px 16px 0 rgba(0,0,0,0.2)', 
          transition: '0.3s',
          '&:hover': {
            boxShadow: '0 16px 32px 0 rgba(0,0,0,0.3)',
            transform: 'scale(1.05)',
          },
          '.MuiCardMedia-root': {
            height: 140,
            transition: 'transform 0.15s ease-in-out',
            '&:hover': {
              transform: 'scale(1.03)',
            },
          },
          '.MuiCardContent-root': {
            backgroundColor: 'rgba(255, 255, 255, 0.8)',
          },
          '.MuiButton-root': {
            backgroundColor: '#d32f2f',
            color: '#fff',
            '&:hover': {
              backgroundColor: '#9a0007',
            },
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          padding: '8px 16px',
        },
      },
    },
  },
});

const loginOptions = [
  {
    title: 'Bayi Giriş',
    imageUrl: '/images/corporate-login.webp',
    path: '/kurumsal-giris',
  },
  {
    title: 'Kurum Girişi',
    imageUrl: '/images/corporate-login.webp',
    path: '/okul-giris',
  },
  {
    title: 'Öğretmen Giriş',
    imageUrl: '/images/teacher-login.webp',
    path: '/ogretmen-giris',
  },
  {
    title: 'Öğrenci Giriş',
    imageUrl: '/images/student-login.webp',
    path: '/ogrenci-giris',
  },
  // Eğer daha fazla kart eklerseniz, scrollbar etkisini göreceksiniz.
];

function MainLogin() {
  const navigate = useNavigate(); 

  const handleLoginClick = (path) => {
    navigate(path); 
  };

  return (
    <ThemeProvider theme={theme}>
      <Header />
      <div className='bg-main'>
        {/* Box kullanarak maxHeight ve scrollbar ekliyoruz */}
        <Box 
          sx={{ 
            mt: 8, 
            mb: 10, 
            mx: 'auto',
            maxWidth: 'lg', // Geniş alan
            maxHeight: '70vh', // Yüksekliği kısıtla
            overflowY: 'auto', 
            p: 1
          }}
        >
          <Grid container spacing={2} alignItems="stretch">
            {loginOptions.map((option, index) => (
              <Grid item xs={12} sm={6} md={3} key={index}>
                <Card
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    height: '100%',
                  }}
                >
                  <CardActionArea 
                    onClick={() => handleLoginClick(option.path)} 
                    sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column', justifyContent: 'flex-start' }}
                  >
                    <CardMedia
                      component="img"
                      image={option.imageUrl} 
                      alt={option.title}
                    />
                    <CardContent sx={{ flexGrow: 1 }}>
                      <Typography gutterBottom variant="h5" component="div" textAlign='center'>
                        {option.title}
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                  <CardActions sx={{ justifyContent: 'center', padding: '16px' }}>
                    <Button size="large" color="primary" variant="contained" fullWidth onClick={() => handleLoginClick(option.path)}>
                      Giriş Yap
                    </Button>
                  </CardActions>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Box>
      </div>
      <Footer />
    </ThemeProvider>
  );
}

export default MainLogin;
