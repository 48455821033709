import React, { useEffect, useState } from 'react';
import { API_URLS } from '../config/config';

function SitemapViewer() {
  const [urlList, setUrlList] = useState([]); // Parse sonucu tutacağımız array

  useEffect(() => {
    fetch(`/sitemap.xml`) 
      .then((response) => response.text())
      .then((data) => {
        // 1) DOMParser ile XML string'ini parse ediyoruz
        const parser = new DOMParser();
        const xmlDoc = parser.parseFromString(data, 'application/xml');

        // 2) <url> etiketlerini yakalıyoruz
        const urlNodes = xmlDoc.getElementsByTagName('url');

        // 3) Her bir <url> içerisindeki <loc>, <lastmod>, <changefreq>, <priority> değerlerini alıyoruz
        const parsedData = [];
        for (let i = 0; i < urlNodes.length; i++) {
          const urlElement = urlNodes[i];

          // Verileri çek (yoksa boş string döndür)
          const loc = urlElement.getElementsByTagName('loc')[0]?.textContent || '';
          const lastmod = urlElement.getElementsByTagName('lastmod')[0]?.textContent || '';
          const changefreq = urlElement.getElementsByTagName('changefreq')[0]?.textContent || '';
          const priority = urlElement.getElementsByTagName('priority')[0]?.textContent || '';

          parsedData.push({ loc, lastmod, changefreq, priority });
        }

        // 4) State'e at
        setUrlList(parsedData);
      })
      .catch((err) => console.error('Sitemap fetch hatası:', err));
  }, []);

  // Ekrana basma
  return (
    <div style={{ padding: '20px' }}>
      <h2>Sitemap İçeriği</h2>

      {urlList.length === 0 ? (
        <p>Yükleniyor veya boş...</p>
      ) : (
        <table border="1" cellPadding="8" style={{ borderCollapse: 'collapse' }}>
          <thead>
            <tr>
              <th>loc</th>
              <th>lastmod</th>
              <th>changefreq</th>
              <th>priority</th>
            </tr>
          </thead>
          <tbody>
            {urlList.map((item, index) => (
              <tr key={index}>
                <td>{item.loc}</td>
                <td>{item.lastmod}</td>
                <td>{item.changefreq}</td>
                <td>{item.priority}</td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
}

export default SitemapViewer;
