import React, { useState } from 'react';
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  Button
} from '@mui/material';
import { API_URLS } from '../../../../config/config';

const OkulEkleFormu = ({
  sehirListesi,
  setOkulListesi,
  okulListesi,
  userData,
  setDialogSeverity,
  setDialogContent,
  setEklemeBasariliDialogOpen,
}) => {
  const [secilenSehirId, setSecilenSehirId] = useState('');
  const [secilenIlceId, setSecilenIlceId] = useState('');
  const [okulAdi, setOkulAdi] = useState('');
  const [telefon, setTelefon] = useState('');
  const [telefonHata, setTelefonHata] = useState(false);
  const [secilenKurumTurleri, setSecilenKurumTurleri] = useState([]);
  const [email, setEmail] = useState('');
  const [sifre, setSifre] = useState('');

  // Telefon formatını kontrol
  const handleTelefonChange = (event) => {
    const telefonRegex = /^0(\d{3}) (\d{3}) (\d{4})$/;
    let { value } = event.target;
    const numbers = value.replace(/\D/g, '');
    // 0XXX XXX XXXX formatına uygun şekilde boşluk ekle
    value =
      numbers.substring(0, 4) +
      (numbers.length > 4 ? ' ' : '') +
      numbers.substring(4, 7) +
      (numbers.length > 7 ? ' ' : '') +
      numbers.substring(7, 11);

    setTelefonHata(!telefonRegex.test(value) && value !== '');
    setTelefon(value);
  };

  // İlçe listesi seçili şehre göre
  const secilenSehirinIlceleri =
    sehirListesi.find((sehir) => sehir._id === secilenSehirId)?.ilceler || [];

  // Kurum Türleri
  const kurumTurleri = [
    { id: 'ilkokul', adi: 'İlkokul' },
    { id: 'ortaokul', adi: 'Ortaokul' },
    { id: 'lise', adi: 'Lise' },
    { id: 'kurs', adi: 'Kurs' },
  ];

  // Ekle butonuna basınca
  const handleEkle = async () => {
    let eksikAlanlar = [];

    if (!okulAdi) eksikAlanlar.push('Okul Adı');
    if (!email) eksikAlanlar.push('Email');
    if (!sifre) eksikAlanlar.push('Şifre');
    if (!telefon) eksikAlanlar.push('Telefon');
    if (!secilenSehirId) eksikAlanlar.push('Şehir');
    if (!secilenIlceId) eksikAlanlar.push('İlçe');
    if (secilenKurumTurleri.length === 0) eksikAlanlar.push('Kurum Türleri');

    if (eksikAlanlar.length > 0) {
      setDialogSeverity('error');
      setDialogContent(`Lütfen ${eksikAlanlar.join(', ')} alanlarını doldurunuz.`);
      setEklemeBasariliDialogOpen(true);
      return;
    }

    if (telefonHata) {
      setDialogSeverity('error');
      setDialogContent('Lütfen geçerli bir telefon numarası giriniz.');
      setEklemeBasariliDialogOpen(true);
      return;
    }

    try {
      const response = await fetch(API_URLS.KURUMSAL_URL + 'schools', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          okul_adi: okulAdi,
          email: email,
          password: sifre,
          telefon: telefon,
          sehir: secilenSehirId,
          ilce: secilenIlceId,
          kurum_turu: secilenKurumTurleri,
          kurumId: userData?._id,
        }),
      });

      const responseData = await response.json();

      if (response.ok) {
        // OkulListesi'ni güncelle
        setOkulListesi([...okulListesi, responseData]);

        setDialogSeverity('success');
        setDialogContent('Yeni okul başarıyla eklendi.');
        setEklemeBasariliDialogOpen(true);

        // Formu sıfırla
        setOkulAdi('');
        setEmail('');
        setSifre('');
        setTelefon('');
        setSecilenSehirId('');
        setSecilenIlceId('');
        setSecilenKurumTurleri([]);
      } else {
        throw new Error(responseData.message || 'Bir hata oluştu');
      }
    } catch (err) {
      setDialogSeverity('error');
      setDialogContent(err.message);
      setEklemeBasariliDialogOpen(true);
    }
  };

  return (
    <>
      <FormControl fullWidth margin="normal">
        <InputLabel id="sehir-select-label">Şehir</InputLabel>
        <Select
          labelId="sehir-select-label"
          value={secilenSehirId}
          onChange={(e) => setSecilenSehirId(e.target.value)}
          displayEmpty
          renderValue={(selected) => {
            if (selected === '') {
              return <em>Şehir Seçiniz</em>;
            }
            const secilenSehir = sehirListesi.find((sehir) => sehir._id === selected);
            return secilenSehir ? secilenSehir.sehir_adi : <em>Şehir Seçiniz</em>;
          }}
        >
          <MenuItem disabled value="">
            <em>Şehir Seçiniz</em>
          </MenuItem>
          {sehirListesi.map((sehir) => (
            <MenuItem key={sehir._id} value={sehir._id}>
              {sehir.sehir_adi}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      {secilenSehirId && (
        <FormControl fullWidth margin="normal">
          <InputLabel id="ilce-select-label">İlçe</InputLabel>
          <Select
            labelId="ilce-select-label"
            id="ilce-select"
            value={secilenIlceId}
            onChange={(e) => setSecilenIlceId(e.target.value)}
            displayEmpty
            renderValue={(selected) => {
              if (!selected) {
                return <em>İlçe Seçiniz</em>;
              }
              const secilenIlce = secilenSehirinIlceleri.find((ilce) => ilce._id === selected);
              return secilenIlce ? secilenIlce.ilce_adi : <em>İlçe Seçiniz</em>;
            }}
          >
            <MenuItem disabled value="">
              <em>İlçe Seçiniz</em>
            </MenuItem>
            {secilenSehirinIlceleri.map((ilce) => (
              <MenuItem key={ilce._id} value={ilce._id}>
                {ilce.ilce_adi}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}

      <TextField
        fullWidth
        label="Okul Adı"
        value={okulAdi}
        onChange={(e) => setOkulAdi(e.target.value)}
        margin="normal"
      />

      <TextField
        fullWidth
        label="Telefon"
        value={telefon}
        onChange={handleTelefonChange}
        error={telefonHata}
        helperText={telefonHata ? 'Telefon numarası 0XXX XXX XXXX formatında olmalıdır.' : ''}
        margin="normal"
      />

      <FormControl fullWidth margin="normal">
        <InputLabel id="kurum-turu-label">Kurum Türü</InputLabel>
        <Select
          labelId="kurum-turu-label"
          id="kurum-turu-select"
          multiple
          value={secilenKurumTurleri}
          onChange={(event) => setSecilenKurumTurleri(event.target.value)}
          renderValue={(selected) => selected.join(', ')}
        >
          {kurumTurleri.map((tur) => (
            <MenuItem key={tur.id} value={tur.adi}>
              {tur.adi}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <TextField
        fullWidth
        label="Email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        margin="normal"
      />

      <TextField
        fullWidth
        label="Şifre"
        value={sifre}
        onChange={(e) => setSifre(e.target.value)}
        margin="normal"
        type="password"
      />

      <Button onClick={handleEkle} variant="contained" color="primary">
        Ekle
      </Button>
    </>
  );
};

export default OkulEkleFormu;
