// SchoolTable.jsx
import React, { useState } from 'react';
import {Typography, TextField, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Paper, Button, ButtonGroup, IconButton, Tooltip, FormControl, InputLabel,
  Select, MenuItem, OutlinedInput, Checkbox, ListItemText, Grid, Menu, TablePagination, Chip, Collapse, Box,} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import SchoolIcon from '@mui/icons-material/School';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import VisibilityIcon from '@mui/icons-material/Visibility'; 
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import * as XLSX from 'xlsx';
import { API_URLS } from '../../../../../config/config';
import MenuItemMUI from '@mui/material/MenuItem'; // Menüyle çakışmaması için alias
import ResultDialog from '../../../../../pages/components/Auth/Dialog';
import OkulRaporuModal from '../components/OkulRaporuModal';


function SchoolRow({
  kurum,
  index,
  page,
  rowsPerPage,
  handleEdit,
  handleDelete,
  handleStatusChange,
  handleAdjustBalance,
  handleOpenOkulAtamaDialog,
  handleOpenRapor,        // ← Rapor butonuna tıklayınca çağrılacak fonksiyon
  newEndDates,
  setNewEndDates,
  handleUpdateEndDate,
}) {
  const [open, setOpen] = useState(false);

  const {
    _id,
    adminAdi,
    kurumAdi,
    okul_adi,
    password_open,
    email,
    telefon,
    sehirAdi,
    ilceAdi,
    okul_turu,
    latestSubscription,
    bakiye3Ay,
    bakiye6Ay,
    bakiye12Ay,
    status,
    aktifEdenAdi,
    createdAt,
  } = kurum;

  const ekleyen =
    adminAdi && adminAdi !== 'Bulunamadı' && adminAdi !== 'null'
      ? adminAdi
      : kurumAdi && kurumAdi !== 'Bulunamadı' && kurumAdi !== 'null'
      ? kurumAdi
      : '';

  const abonelikBaslangic = latestSubscription
    ? new Date(latestSubscription.baslangicTarihi).toLocaleDateString()
    : 'Yok';
  const abonelikBitis = latestSubscription
    ? new Date(latestSubscription.bitisTarihi).toLocaleDateString()
    : 'Yok';
  const kayitTarihi = createdAt ? new Date(createdAt).toLocaleDateString() : 'Yok';

  const toplamBakiye = (bakiye3Ay || 0) + (bakiye6Ay || 0) + (bakiye12Ay || 0);

  return (
    <>
      {/* Ana satır */}
      <TableRow>
        <TableCell>
          <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>

        <TableCell>{page * rowsPerPage + index + 1}</TableCell> {/* Sıra No */}
        <TableCell>{ekleyen}</TableCell>                        {/* Ekleyen */}
        <TableCell>{okul_adi}</TableCell>                       {/* Okul Adı */}
        <TableCell>{password_open}</TableCell>                  {/* Şifre (Açık) */}
        <TableCell>{email}</TableCell>                          {/* Email */}
        <TableCell>{telefon}</TableCell>                        {/* Telefon */}

        <TableCell>
          <Chip
            label={status === 'active' ? 'Aktif' : 'Pasif'}
            color={status === 'active' ? 'success' : 'error'}
            onClick={() => handleStatusChange(_id, status)}
            sx={{
              cursor: 'pointer',
              '&:hover': {
                backgroundColor: 'lightgray',
              },
            }}
          />
        </TableCell>

        <TableCell align="right" sx={{ minWidth: 210, display: 'flex', justifyContent: 'flex-end' }}>
          <ButtonGroup variant="contained">
            {/* Bakiye Ayarla */}
            <Tooltip title="Bakiye Ayarla" arrow>
              <IconButton
                onClick={() => handleAdjustBalance(kurum)}
                color="success"
                size="medium"
                sx={{
                  backgroundColor: '#4caf50',
                  color: '#fff',
                  '&:hover': {
                    backgroundColor: '#388e3c',
                    transform: 'scale(1.1)',
                    transition: 'transform 0.2s',
                  },
                  borderRadius: '8px',
                  marginRight: '8px',
                }}
              >
                <AttachMoneyIcon fontSize="medium" />
              </IconButton>
            </Tooltip>

            {/* Düzenle */}
            <Tooltip title="Düzenle" arrow>
              <IconButton
                onClick={() => handleEdit(_id)}
                color="primary"
                size="medium"
                sx={{
                  backgroundColor: '#1976d2',
                  color: '#fff',
                  '&:hover': {
                    backgroundColor: '#1565c0',
                    transform: 'scale(1.1)',
                    transition: 'transform 0.2s',
                  },
                  borderRadius: '8px',
                  marginRight: '8px',
                }}
              >
                <EditIcon fontSize="medium" />
              </IconButton>
            </Tooltip>

            {/* Sil */}
            <Tooltip title="Sil" arrow>
              <IconButton
                onClick={() => handleDelete(_id)}
                color="secondary"
                size="medium"
                sx={{
                  backgroundColor: '#f44336',
                  color: '#fff',
                  '&:hover': {
                    backgroundColor: '#d32f2f',
                    transform: 'scale(1.1)',
                    transition: 'transform 0.2s',
                  },
                  borderRadius: '8px',
                  marginRight: '8px',
                }}
              >
                <DeleteIcon fontSize="medium" />
              </IconButton>
            </Tooltip>

            {/* Kuruma Ata (opsiyonel) */}
            <Tooltip title="Kuruma Ata" arrow>
              <IconButton
                onClick={() => handleOpenOkulAtamaDialog(_id)}
                color="info"
                size="medium"
                sx={{
                  backgroundColor: '#0288d1',
                  color: '#fff',
                  '&:hover': {
                    backgroundColor: '#0277bd',
                    transform: 'scale(1.1)',
                    transition: 'transform 0.2s',
                  },
                  borderRadius: '8px',
                  marginRight: '8px',
                }}
              >
                <SchoolIcon fontSize="medium" />
              </IconButton>
            </Tooltip>

            {/* Rapor */}
            <Tooltip title="Rapor Görüntüle" arrow>
              <IconButton
                onClick={() => handleOpenRapor(_id)} // ← Rapor açma fonksiyonu
                color="inherit"
                size="medium"
                sx={{
                  backgroundColor: '#673ab7',
                  color: '#fff',
                  '&:hover': {
                    backgroundColor: '#5e35b1',
                    transform: 'scale(1.1)',
                    transition: 'transform 0.2s',
                  },
                  borderRadius: '8px',
                }}
              >
                <VisibilityIcon fontSize="medium" />
              </IconButton>
            </Tooltip>
          </ButtonGroup>
        </TableCell>
      </TableRow>

      {/* Expand edilebilir alt satır */}
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={10}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <Typography variant="subtitle1" gutterBottom>
                Detaylar
              </Typography>
              <Table size="small" aria-label="detail">
                <TableHead>
                  <TableRow>
                    <TableCell>Şehir</TableCell>
                    <TableCell>İlçe</TableCell>
                    <TableCell>Okul Türü</TableCell>
                    <TableCell>Abonelik Başlangıç</TableCell>
                    <TableCell>Abonelik Bitiş</TableCell>
                    <TableCell>Yeni Bitiş Tarihi</TableCell>
                    <TableCell>Bakiye 3 Ay</TableCell>
                    <TableCell>Bakiye 6 Ay</TableCell>
                    <TableCell>Bakiye 12 Ay</TableCell>
                    <TableCell>Bakiye (Toplam)</TableCell>
                    <TableCell>Aktif Eden</TableCell>
                    <TableCell>Kayıt Tarihi</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell>{sehirAdi || ''}</TableCell>
                    <TableCell>{ilceAdi || ''}</TableCell>
                    <TableCell>{okul_turu ? okul_turu.join(', ') : ''}</TableCell>
                    <TableCell>{abonelikBaslangic}</TableCell>
                    <TableCell>{abonelikBitis}</TableCell>
                    {/* Yeni Bitiş Tarihi + Güncelleme */}
                    <TableCell>
                      <DatePicker
                        selected={newEndDates[_id] || null}
                        onChange={(date) => setNewEndDates({ ...newEndDates, [_id]: date })}
                        dateFormat="dd/MM/yyyy"
                        placeholderText="Yeni Bitiş Tarihi"
                        customInput={
                          <TextField variant="outlined" size="small" label="Yeni Bitiş Tarihi" />
                        }
                      />
                      <Button
                        variant="contained"
                        color="primary"
                        fullWidth
                        sx={{ marginTop: 1 }}
                        onClick={() => handleUpdateEndDate(_id, newEndDates[_id])}
                      >
                        Tarihi Güncelle
                      </Button>
                    </TableCell>
                    <TableCell>{bakiye3Ay || 0}</TableCell>
                    <TableCell>{bakiye6Ay || 0}</TableCell>
                    <TableCell>{bakiye12Ay || 0}</TableCell>
                    <TableCell>{toplamBakiye}</TableCell>
                    <TableCell>{aktifEdenAdi || 'Yok'}</TableCell>
                    <TableCell>{kayitTarihi}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}

// ─────────────────────────────────────────────────────────────────────────
// 2) Ana Bileşen: SchoolTable
// ─────────────────────────────────────────────────────────────────────────
const SchoolTable = ({
  filteredOkulListesi,
  searchQuery,
  setSearchQuery,
  handleEdit,
  handleDelete,
  handleStatusChange,
  handleAdjustBalance,
  filterStatus,
  setFilterStatus,
  filterSehir,
  setFilterSehir,
  filterKurumTuru,
  setFilterKurumTuru,
  sehirListesi,
  okulTurleri,
  filterEkleyen,
  setFilterEkleyen,
  handleOpenOkulAtamaDialog, // opsiyonel
}) => {
  // Sayfalama
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);

  // Abonelik bitiş tarihi güncelleme için
  const [newEndDates, setNewEndDates] = useState({});

  // İşlem sonucu diyaloğu
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogContent, setDialogContent] = useState('');
  const [dialogSeverity, setDialogSeverity] = useState('info');

  // Export menüsü anchor
  const [anchorEl, setAnchorEl] = useState(null);

  // YENİ: Rapor modal state
  const [raporModalOpen, setRaporModalOpen] = useState(false);
  const [selectedSchoolId, setSelectedSchoolId] = useState('');  
  const [selectedSchoolName, setSelectedSchoolName] = useState('');

  // ─────────────────────────────────────────────────────────────────────────
  // Rapor Modal aç/kapat


  const handleOpenRapor = (id, name) => {
    setSelectedSchoolId(id);
    setSelectedSchoolName(name);
    setRaporModalOpen(true);
  };
  
  const handleCloseRapor = () => {
    setRaporModalOpen(false);
    setSelectedSchoolId('');
  };

  // ─────────────────────────────────────────────────────────────────────────
  // Export menüsü
  const handleExportClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleExportClose = () => {
    setAnchorEl(null);
  };

  // CSV, TXT, XLSX export fonksiyonları
  const exportToCSV = () => {
    if (filteredOkulListesi.length === 0) return;
    const headers = [
      'Sıra No',
      'Ekleyen',
      'Okul Adı',
      'Şifre',
      'Email',
      'Telefon',
      'Şehir',
      'İlçe',
      'Okul Türü',
      'Abonelik Başlangıç',
      'Abonelik Bitiş',
      'Bakiye (Toplam)',
      'Bakiye 3 Ay',
      'Bakiye 6 Ay',
      'Bakiye 12 Ay',
      'Durum',
      'Kayıt Tarihi',
    ];

    const rows = filteredOkulListesi.map((kurum, index) => {
      const toplamBakiye =
        (kurum.bakiye3Ay || 0) + (kurum.bakiye6Ay || 0) + (kurum.bakiye12Ay || 0);
      const ekleyen =
        kurum.adminAdi && kurum.adminAdi !== 'Bulunamadı' && kurum.adminAdi !== 'null'
          ? kurum.adminAdi
          : kurum.kurumAdi && kurum.kurumAdi !== 'Bulunamadı' && kurum.kurumAdi !== 'null'
          ? kurum.kurumAdi
          : '';
      const abonelikBaslangic = kurum.latestSubscription
        ? new Date(kurum.latestSubscription.baslangicTarihi).toLocaleDateString()
        : 'Yok';
      const abonelikBitis = kurum.latestSubscription
        ? new Date(kurum.latestSubscription.bitisTarihi).toLocaleDateString()
        : 'Yok';
      const kayitTarihi = kurum.createdAt
        ? new Date(kurum.createdAt).toLocaleDateString()
        : 'Yok';

      return [
        index + 1,
        ekleyen,
        kurum.okul_adi,
        kurum.password_open || '',
        kurum.email,
        kurum.telefon,
        kurum.sehirAdi || '',
        kurum.ilceAdi || '',
        kurum.okul_turu ? kurum.okul_turu.join(', ') : '',
        abonelikBaslangic,
        abonelikBitis,
        toplamBakiye,
        kurum.bakiye3Ay || 0,
        kurum.bakiye6Ay || 0,
        kurum.bakiye12Ay || 0,
        kurum.status === 'active' ? 'Aktif' : 'Pasif',
        kayitTarihi,
      ];
    });

    const csvContent =
      'data:text/csv;charset=utf-8,' +
      [headers.join(','), ...rows.map((e) => e.join(','))].join('\n');
    const encodedUri = encodeURI(csvContent);
    const link = document.createElement('a');
    link.href = encodedUri;
    link.download = 'okul_listesi.csv';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    handleExportClose();
  };

  const exportToTXT = () => {
    if (filteredOkulListesi.length === 0) return;
    let txtContent = '';
    txtContent +=
      'Sıra No | Ekleyen | Okul Adı | Şifre | Email | Telefon | Şehir | İlçe | Okul Türü | Abonelik Başlangıç | Abonelik Bitiş | Bakiye(Toplam) | Bakiye 3 Ay | Bakiye 6 Ay | Bakiye 12 Ay | Durum | Kayıt Tarihi\n';

    filteredOkulListesi.forEach((kurum, index) => {
      const toplamBakiye =
        (kurum.bakiye3Ay || 0) + (kurum.bakiye6Ay || 0) + (kurum.bakiye12Ay || 0);
      const ekleyen =
        kurum.adminAdi && kurum.adminAdi !== 'Bulunamadı' && kurum.adminAdi !== 'null'
          ? kurum.adminAdi
          : kurum.kurumAdi && kurum.kurumAdi !== 'Bulunamadı' && kurum.kurumAdi !== 'null'
          ? kurum.kurumAdi
          : '';
      const abonelikBaslangic = kurum.latestSubscription
        ? new Date(kurum.latestSubscription.baslangicTarihi).toLocaleDateString()
        : 'Yok';
      const abonelikBitis = kurum.latestSubscription
        ? new Date(kurum.latestSubscription.bitisTarihi).toLocaleDateString()
        : 'Yok';
      const kayitTarihi = kurum.createdAt
        ? new Date(kurum.createdAt).toLocaleDateString()
        : 'Yok';

      txtContent += `${index + 1} | ${ekleyen} | ${kurum.okul_adi} | ${
        kurum.password_open || ''
      } | ${kurum.email} | ${kurum.telefon} | ${kurum.sehirAdi || ''} | ${
        kurum.ilceAdi || ''
      } | ${kurum.okul_turu ? kurum.okul_turu.join(', ') : ''} | ${abonelikBaslangic} | ${abonelikBitis} | ${toplamBakiye} | ${
        kurum.bakiye3Ay || 0
      } | ${kurum.bakiye6Ay || 0} | ${kurum.bakiye12Ay || 0} | ${
        kurum.status === 'active' ? 'Aktif' : 'Pasif'
      } | ${kayitTarihi}\n`;
    });

    const blob = new Blob([txtContent], { type: 'text/plain;charset=utf-8' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = 'okul_listesi.txt';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    handleExportClose();
  };

  const exportToXLSX = () => {
    if (filteredOkulListesi.length === 0) return;
    const headers = [
      'Sıra No',
      'Ekleyen',
      'Okul Adı',
      'Şifre',
      'Email',
      'Telefon',
      'Şehir',
      'İlçe',
      'Okul Türü',
      'Abonelik Başlangıç',
      'Abonelik Bitiş',
      'Bakiye (Toplam)',
      'Bakiye 3 Ay',
      'Bakiye 6 Ay',
      'Bakiye 12 Ay',
      'Durum',
      'Kayıt Tarihi',
    ];
    const wsData = [];
    wsData.push(headers);

    filteredOkulListesi.forEach((kurum, index) => {
      const toplamBakiye =
        (kurum.bakiye3Ay || 0) + (kurum.bakiye6Ay || 0) + (kurum.bakiye12Ay || 0);
      const ekleyen =
        kurum.adminAdi && kurum.adminAdi !== 'Bulunamadı' && kurum.adminAdi !== 'null'
          ? kurum.adminAdi
          : kurum.kurumAdi && kurum.kurumAdi !== 'Bulunamadı' && kurum.kurumAdi !== 'null'
          ? kurum.kurumAdi
          : '';
      const abonelikBaslangic = kurum.latestSubscription
        ? new Date(kurum.latestSubscription.baslangicTarihi).toLocaleDateString()
        : 'Yok';
      const abonelikBitis = kurum.latestSubscription
        ? new Date(kurum.latestSubscription.bitisTarihi).toLocaleDateString()
        : 'Yok';
      const kayitTarihi = kurum.createdAt
        ? new Date(kurum.createdAt).toLocaleDateString()
        : 'Yok';

      const row = [
        index + 1,
        ekleyen,
        kurum.okul_adi,
        kurum.password_open || '',
        kurum.email,
        kurum.telefon,
        kurum.sehirAdi || '',
        kurum.ilceAdi || '',
        kurum.okul_turu ? kurum.okul_turu.join(', ') : '',
        abonelikBaslangic,
        abonelikBitis,
        toplamBakiye,
        kurum.bakiye3Ay || 0,
        kurum.bakiye6Ay || 0,
        kurum.bakiye12Ay || 0,
        kurum.status === 'active' ? 'Aktif' : 'Pasif',
        kayitTarihi,
      ];
      wsData.push(row);
    });

    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.aoa_to_sheet(wsData);
    XLSX.utils.book_append_sheet(wb, ws, 'Okul Listesi');
    const wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const blob = new Blob([wbout], { type: 'application/octet-stream' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = 'okul_listesi.xlsx';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    handleExportClose();
  };

  // Abonelik bitiş tarihini güncelle
  const handleUpdateEndDate = async (kurumId, selectedDate) => {
    if (!selectedDate) {
      setDialogSeverity('error');
      setDialogContent('Lütfen yeni bitiş tarihini seçin.');
      setDialogOpen(true);
      return;
    }

    try {
      const response = await fetch(API_URLS.ADMIN_URL + 'payments/updateSubscriptionEndDate', {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('userToken')}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          userId: kurumId,
          userType: 'School',
          newEndDate: selectedDate.toISOString(),
        }),
      });

      const data = await response.json();

      if (response.ok) {
        setDialogSeverity('success');
        setDialogContent('Abonelik bitiş tarihi başarıyla güncellendi.');
        setDialogOpen(true);

        // Güncellemeden sonra seçimi sıfırlayalım
        setNewEndDates((prevDates) => ({
          ...prevDates,
          [kurumId]: null,
        }));
      } else {
        setDialogSeverity('error');
        setDialogContent(data.message || 'Abonelik bitiş tarihi güncellenemedi.');
        setDialogOpen(true);
      }
    } catch (error) {
      setDialogSeverity('error');
      setDialogContent('Abonelik bitiş tarihi güncellenirken bir hata oluştu.');
      setDialogOpen(true);
    }
  };

  // Sayfa değiştirme
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  // Sayfa başına kayıt değiştirme
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // Dinamik “rowsPerPageOptions”
  const totalCount = filteredOkulListesi.length;
  const rowsPerPageOptions = [];
  for (let i = 25; i <= totalCount; i += 25) {
    rowsPerPageOptions.push(i);
  }
  if (rowsPerPageOptions.length === 0) {
    rowsPerPageOptions.push(25);
  }

  // Ekleyen değerleri (adminAdi veya kurumAdi) filtresi için
  const ekleyenListesi = Array.from(
    new Set(
      filteredOkulListesi.map((kurum) => {
        if (
          kurum.adminAdi &&
          kurum.adminAdi !== 'Bulunamadı' &&
          kurum.adminAdi !== 'null'
        ) {
          return kurum.adminAdi;
        } else if (
          kurum.kurumAdi &&
          kurum.kurumAdi !== 'Bulunamadı' &&
          kurum.kurumAdi !== 'null'
        ) {
          return kurum.kurumAdi;
        }
        return '';
      })
    )
  )
    .filter((item) => item !== '')
    .sort();

  // Paginated data
  const paginatedData = filteredOkulListesi.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );

  return (
    <>
      <Typography variant="h5" component="h3" marginBottom={2}>
        Kayıtlı Okullar Listesi
      </Typography>

      {/* Arama */}
      <TextField
        fullWidth
        label="Ara..."
        value={searchQuery}
        onChange={(e) => setSearchQuery(e.target.value)}
        margin="normal"
      />

      {/* Filtreler */}
      <Grid container spacing={2} sx={{ mb: 2 }}>
        {/* Durum Filtresi */}
        <Grid item xs={12} sm={3}>
          <FormControl fullWidth variant="outlined">
            <InputLabel id="status-filter-label">Durum</InputLabel>
            <Select
              labelId="status-filter-label"
              id="status-filter"
              value={filterStatus}
              onChange={(e) => setFilterStatus(e.target.value)}
              label="Durum"
            >
              <MenuItem value="">
                <em>Tüm Durumlar</em>
              </MenuItem>
              <MenuItem value="active">Aktif</MenuItem>
              <MenuItem value="inactive">Pasif</MenuItem>
            </Select>
          </FormControl>
        </Grid>

        {/* Şehir Filtresi */}
        <Grid item xs={12} sm={3}>
          <FormControl fullWidth variant="outlined">
            <InputLabel id="sehir-filter-label">Şehir</InputLabel>
            <Select
              labelId="sehir-filter-label"
              id="sehir-filter"
              value={filterSehir}
              onChange={(e) => setFilterSehir(e.target.value)}
              label="Şehir"
            >
              <MenuItem value="">
                <em>Tümü</em>
              </MenuItem>
              {sehirListesi.map((sehir) => (
                <MenuItem key={sehir._id} value={sehir._id}>
                  {sehir.sehir_adi}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        {/* Okul Türü Filtresi */}
        <Grid item xs={12} sm={3}>
          <FormControl fullWidth variant="outlined">
            <InputLabel id="kurum-turu-filter-label">Okul Türü</InputLabel>
            <Select
              labelId="kurum-turu-filter-label"
              id="kurum-turu-filter"
              multiple
              value={filterKurumTuru}
              onChange={(e) => setFilterKurumTuru(e.target.value)}
              input={<OutlinedInput label="Okul Türü" />}
              renderValue={(selected) =>
                selected
                  .map((value) => okulTurleri.find((kt) => kt.id === value)?.adi)
                  .filter(Boolean)
                  .join(', ')
              }
            >
              {okulTurleri.map((turu) => (
                <MenuItem key={turu.id} value={turu.id}>
                  <Checkbox checked={filterKurumTuru.indexOf(turu.id) > -1} />
                  <ListItemText primary={turu.adi} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        {/* Ekleyen Filtresi */}
        <Grid item xs={12} sm={3}>
          <FormControl fullWidth variant="outlined">
            <InputLabel id="ekleyen-filter-label">Ekleyen</InputLabel>
            <Select
              labelId="ekleyen-filter-label"
              id="ekleyen-filter"
              value={filterEkleyen}
              onChange={(e) => setFilterEkleyen(e.target.value)}
              label="Ekleyen"
            >
              <MenuItem value="">
                <em>Tümü</em>
              </MenuItem>
              {ekleyenListesi.map((ekleyen) => (
                <MenuItem key={ekleyen} value={ekleyen}>
                  {ekleyen}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      </Grid>

      {/* Filtreleri Temizle & Dışa Aktar */}
      <Grid container spacing={2} sx={{ mb: 2 }}>
        <Grid item>
          <Button
            variant="outlined"
            color="secondary"
            onClick={() => {
              setFilterStatus('');
              setFilterSehir('');
              setFilterKurumTuru([]);
              setFilterEkleyen('');
            }}
            sx={{ mr: 2 }}
          >
            Filtreleri Temizle
          </Button>

          <Button variant="outlined" onClick={handleExportClick}>
            Dışa Aktar
          </Button>
          <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleExportClose}>
            <MenuItemMUI onClick={exportToCSV}>CSV Olarak İndir</MenuItemMUI>
            <MenuItemMUI onClick={exportToTXT}>TXT Olarak İndir</MenuItemMUI>
            <MenuItemMUI onClick={exportToXLSX}>Excel (XLSX) Olarak İndir</MenuItemMUI>
          </Menu>
        </Grid>
      </Grid>

      {/* Üst taraftaki sayfalama */}
      <TablePagination
        rowsPerPageOptions={rowsPerPageOptions}
        component="div"
        count={filteredOkulListesi.length}
        rowsPerPage={rowsPerPage}
        page={page}
        labelRowsPerPage="Sayfa başına kayıt:"
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        sx={{
          '& .MuiTablePagination-toolbar': {
            display: 'flex',
            justifyContent: 'flex-start',
          },
          '& .MuiTablePagination-spacer': {
            flex: '0',
          },
          '& .MuiTablePagination-actions': {
            marginLeft: 0,
          },
        }}
      />

      {/* Tablo */}
      <TableContainer component={Paper}>
        <Table aria-label="okul listesi">
          <TableHead>
            <TableRow sx={{ backgroundColor: 'primary.main' }}>
              <TableCell />
              <TableCell>
                <Typography variant="subtitle1" sx={{ color: 'white', fontWeight: 'bold' }}>
                  Sıra No
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="subtitle1" sx={{ color: 'white', fontWeight: 'bold' }}>
                  Ekleyen
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="subtitle1" sx={{ color: 'white', fontWeight: 'bold' }}>
                  Okul Adı
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="subtitle1" sx={{ color: 'white', fontWeight: 'bold' }}>
                  Şifre
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="subtitle1" sx={{ color: 'white', fontWeight: 'bold' }}>
                  Email
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="subtitle1" sx={{ color: 'white', fontWeight: 'bold' }}>
                  Telefon
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="subtitle1" sx={{ color: 'white', fontWeight: 'bold' }}>
                  Durum
                </Typography>
              </TableCell>
              <TableCell align="right">
                <Typography variant="subtitle1" sx={{ color: 'white', fontWeight: 'bold' }}>
                  İşlem
                </Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {paginatedData.map((kurum, index) => (
              <SchoolRow
                key={kurum._id}
                kurum={kurum}
                index={index}
                page={page}
                rowsPerPage={rowsPerPage}
                handleEdit={handleEdit}
                handleDelete={handleDelete}
                handleStatusChange={handleStatusChange}
                handleAdjustBalance={handleAdjustBalance}
                handleOpenOkulAtamaDialog={handleOpenOkulAtamaDialog}
                handleOpenRapor={handleOpenRapor}           // ← Rapor açma fonk.
                newEndDates={newEndDates}
                setNewEndDates={setNewEndDates}
                handleUpdateEndDate={handleUpdateEndDate}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Alt taraftaki sayfalama */}
      <TablePagination
        rowsPerPageOptions={rowsPerPageOptions}
        component="div"
        count={filteredOkulListesi.length}
        rowsPerPage={rowsPerPage}
        page={page}
        labelRowsPerPage="Sayfa başına kayıt:"
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        sx={{
          '& .MuiTablePagination-toolbar': {
            display: 'flex',
            justifyContent: 'flex-start',
          },
          '& .MuiTablePagination-spacer': {
            flex: '0',
          },
          '& .MuiTablePagination-actions': {
            marginLeft: 0,
          },
        }}
      />

      {/* İşlem sonucu diyaloğu */}
      <ResultDialog
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        title={dialogSeverity === 'error' ? 'Hata Oluştu' : 'İşlem Başarılı'}
        message={dialogContent}
        severity={dialogSeverity}
      />

      {/* YENİ: Okul Raporu Modal */}
      <OkulRaporuModal
        open={raporModalOpen}
        onClose={() => setRaporModalOpen(false)}
        okulId={selectedSchoolId}
        okulAdi={selectedSchoolName}  // opsiyonel
      />
    </>
  );
};

export default SchoolTable;
