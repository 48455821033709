// Okullar.js
import React, { useState, useEffect } from 'react';
import { Paper, Grid } from '@mui/material';
import { API_URLS } from '../../../config/config';
import ResultDialog from '../../../pages/components/Auth/Dialog';
import useFetchUserData from '../../../hooks/admin/useFetchUserData';

// Yeni oluşturulan bileşenleri import ediyoruz
import SchoolForm from './okul/components/SchoolForm';
import SchoolTable from './okul/components/SchoolTable';
import EditSchoolDialog from './okul/components/EditSchoolDialog';
import BalanceAdjustmentDialog from './okul/components/BalanceAdjustmentDialog';

// -- YENİ: OkulAtamaDialog import --
import OkulAtamaDialog from './okul/components/OkulAtamaDialog';

const Okullar = () => {
  // State tanımları
  const [okulListesi, setOkulListesi] = useState([]);
  const [sehirListesi, setSehirListesi] = useState([]);
  const [secilenSehirId, setSecilenSehirId] = useState('');
  const [secilenIlceId, setSecilenIlceId] = useState('');
  const [shouldFetch, setShouldFetch] = useState(true);
  const [userData, setUserData] = useState(null);
  const [okulAdi, setOkulAdi] = useState('');
  const [telefon, setTelefon] = useState('');
  const [telefonHata, setTelefonHata] = useState(false);
  const [secilenKurumTurleri, setSecilenKurumTurleri] = useState([]);
  const [email, setEmail] = useState('');
  const [sifre, setSifre] = useState('');
  const [durum, setDurum] = useState('inactive');

  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogContent, setDialogContent] = useState('');
  const [dialogSeverity, setDialogSeverity] = useState('info');

  const [eklemeBasariliDialogOpen, setEklemeBasariliDialogOpen] = useState(false);
  const [silmeBasariliDialogOpen, setSilmeBasariliDialogOpen] = useState(false);

  const [duzenlemeDialogAcik, setDuzenlemeDialogAcik] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [balanceDialogOpen, setBalanceDialogOpen] = useState(false);
  const [selectedKurum, setSelectedKurum] = useState(null);
  const [balanceAmount, setBalanceAmount] = useState(0);
  const [balanceReason, setBalanceReason] = useState('');
  const [selectedPackageType, setSelectedPackageType] = useState('');
  const [currentPackageBalance, setCurrentPackageBalance] = useState(0);

  // Yeni filtreleme state değişkenleri
  const [filterStatus, setFilterStatus] = useState('');
  const [filterSehir, setFilterSehir] = useState('');
  const [filterKurumTuru, setFilterKurumTuru] = useState([]);

  // YENİ: Ekleyen filtresi
  const [filterEkleyen, setFilterEkleyen] = useState('');

  // Okul türleri listesi
  const okulTurleri = [
    { id: 'ilkokul', adi: 'İlkokul' },
    { id: 'ortaokul', adi: 'Ortaokul' },
    { id: 'lise', adi: 'Lise' },
    { id: 'universite', adi: 'Üniversite' },
  ];

  useFetchUserData(setUserData);

  // -- YENİ: Kurum listesi ve Okul Atama Dialogu state'leri --
  const [kurumListesi, setKurumListesi] = useState([]);  
  const [okulAtamaDialogOpen, setOkulAtamaDialogOpen] = useState(false);
  const [selectedSchoolIdForAssignment, setSelectedSchoolIdForAssignment] = useState('');

  // Filtrelenmiş Okul Listesi
  const filteredOkulListesi = okulListesi
    // 1) Arama sorgusuna göre filtreleme
    .filter(
      (kurum) =>
        (kurum.okul_adi && kurum.okul_adi.toLowerCase().includes(searchQuery.toLowerCase())) ||
        (kurum.email && kurum.email.toLowerCase().includes(searchQuery.toLowerCase()))
    )
    // 2) Durum filtrelemesi
    .filter((kurum) => (filterStatus ? kurum.status === filterStatus : true))
    // 3) Şehir filtrelemesi
    .filter((kurum) => (filterSehir ? kurum.sehir === filterSehir : true))
    // 4) Kurum Türü filtrelemesi
    .filter((kurum) =>
      filterKurumTuru.length > 0
        ? kurum.kurum_turu.some((turu) => filterKurumTuru.includes(turu))
        : true
    )
    // 5) YENİ: Ekleyen filtrelemesi
    .filter((kurum) => {
      const ekleyen =
        kurum.adminAdi && kurum.adminAdi !== 'Bulunamadı' && kurum.adminAdi !== 'null'
          ? kurum.adminAdi
          : kurum.kurumAdi && kurum.kurumAdi !== 'Bulunamadı' && kurum.kurumAdi !== 'null'
          ? kurum.kurumAdi
          : '';

      if (!filterEkleyen) return true;
      return ekleyen === filterEkleyen;
    });

  const [duzenlenenKurum, setDuzenlenenKurum] = useState({
    okulAdi: '',
    email: '',
    telefon: '',
    sehirId: '',
    ilceId: '',
    kurumTuru: [],
    ilceler: [],
    status: '',
    password: '',
  });

  const fetchIlceler = async (sehirId, seciliIlceId) => {
    try {
      const response = await fetch(`${API_URLS.ADMIN_URL}sehirler/ilceler/${sehirId}`);
      if (!response.ok) throw new Error('İlçeler yüklenirken bir hata oluştu.');

      const data = await response.json();
      const ilceler = data.ilceler ? data.ilceler : [];

      setDuzenlenenKurum((prev) => ({
        ...prev,
        ilceler: ilceler,
        ilceId: seciliIlceId && ilceler.some((ilce) => ilce._id === seciliIlceId) ? seciliIlceId : prev.ilceId,
      }));
    } catch (error) {
      console.error('İlçeleri yüklerken bir hata oluştu:', error);
    }
  };

  useEffect(() => {
    const fetchSehirler = async () => {
      if (!shouldFetch) return;

      try {
        const response = await fetch(API_URLS.ADMIN_URL + 'sehirler');
        if (!response.ok) {
          throw new Error('Veri çekme işlemi başarısız');
        }

        const sehirler = await response.json();
        setSehirListesi(sehirler);
      } catch (err) {
        console.error('Şehirler yüklenirken bir hata oluştu:', err.message);
      } finally {
        setShouldFetch(false);
      }
    };

    const fetchSchools = async () => {
      try {
        const response = await fetch(API_URLS.ADMIN_URL + 'schools');
        if (!response.ok) throw new Error('Okullar yüklenirken bir hata oluştu.');
        const data = await response.json();

        const enhancedData = data.map((kurum) => {
          const sehir = sehirListesi.find((s) => s._id === kurum.sehir);
          const ilce = sehir?.ilceler.find((i) => i._id === kurum.ilce);

          const adminAdi = kurum.adminAdi && kurum.adminAdi !== 'null' ? kurum.adminAdi : '';
          const kurumAdi = kurum.kurumAdi && kurum.kurumAdi !== 'null' ? kurum.kurumAdi : '';

          return {
            ...kurum,
            sehirAdi: sehir ? sehir.sehir_adi : 'Bulunamadı',
            ilceAdi: ilce ? ilce.ilce_adi : 'Bulunamadı',
            status: kurum.status || 'inactive',
            bakiye: kurum.bakiye || 0,
            latestSubscription: kurum.latestSubscription || null,
            adminAdi,
            kurumAdi,
          };
        });

        setOkulListesi(enhancedData);
      } catch (error) {
        console.error(error.message);
      }
    };

    // Örnek kurum listesini de fetch edebilirsiniz:
    const fetchKurumListesi = async () => {
      try {
        // Örnek istek: "GET /api/kurumlar"
        // API_URLS.ADMIN_URL + 'kurumlar' gibi bir endpoint
        const resp = await fetch(API_URLS.ADMIN_URL + 'kurumlar');
        if (!resp.ok) throw new Error('Kurum listesi çekilemedi');
        const resData = await resp.json();
        setKurumListesi(resData);
      } catch (error) {
        console.error('Kurum listesi alınırken hata:', error);
      }
    };

    fetchSchools();
    fetchSehirler();
    fetchKurumListesi();
  }, [shouldFetch, sehirListesi]);

  const handleSehirChange = (event) => {
    setSecilenSehirId(event.target.value);
    setSecilenIlceId('');
  };

  const handleIlceChange = (event) => {
    setSecilenIlceId(event.target.value);
  };

  const handleTelefonChange = (event) => {
    const telefonRegex = /^0(\d{3}) (\d{3}) (\d{4})$/;
    let { value } = event.target;

    const numbers = value.replace(/\D/g, '');
    value =
      numbers.substring(0, 4) +
      (numbers.length > 4 ? ' ' : '') +
      numbers.substring(4, 7) +
      (numbers.length > 7 ? ' ' : '') +
      numbers.substring(7, 11);
    setTelefonHata(!telefonRegex.test(value) && value !== '');
    setTelefon(value);
  };

  const handleEkle = async () => {
    let eksikAlanlar = [];

    if (!okulAdi) eksikAlanlar.push('Okul Adı');
    if (!email) eksikAlanlar.push('Email');
    if (!sifre) eksikAlanlar.push('Şifre');
    if (!telefon) eksikAlanlar.push('Telefon');
    if (!secilenSehirId) eksikAlanlar.push('Şehir');
    if (!secilenIlceId) eksikAlanlar.push('İlçe');
    if (secilenKurumTurleri.length === 0) eksikAlanlar.push('Kurum Türleri');

    if (eksikAlanlar.length > 0) {
      setDialogSeverity('error');
      setDialogContent(`Lütfen ${eksikAlanlar.join(', ')} alanlarını doldurunuz.`);
      setEklemeBasariliDialogOpen(true);
      return;
    }

    if (telefonHata) {
      setDialogSeverity('error');
      setDialogContent('Lütfen geçerli bir telefon numarası giriniz.');
      setEklemeBasariliDialogOpen(true);
      return;
    }

    try {
      const response = await fetch(API_URLS.ADMIN_URL + 'schools', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          okul_adi: okulAdi,
          email: email,
          password: sifre,
          password_open: sifre,
          telefon: telefon,
          sehir: secilenSehirId,
          ilce: secilenIlceId,
          kurum_turu: secilenKurumTurleri,
          adminId: userData?._id,
          status: durum,
        }),
      });

      const responseData = await response.json();

      if (response.ok) {
        const yeniOkul = {
          ...responseData,
          sehirAdi:
            sehirListesi.find((sehir) => sehir._id === secilenSehirId)?.sehir_adi || '',
          ilceAdi:
            sehirListesi
              .find((sehir) => sehir._id === secilenSehirId)
              ?.ilceler.find((ilce) => ilce._id === secilenIlceId)?.ilce_adi || '',
        };

        setOkulListesi([...okulListesi, yeniOkul]);

        setDialogSeverity('success');
        setDialogContent('Yeni okul başarıyla eklendi.');
        setEklemeBasariliDialogOpen(true);

        setOkulAdi('');
        setEmail('');
        setSifre('');
        setTelefon('');
        setSecilenSehirId('');
        setSecilenIlceId('');
        setSecilenKurumTurleri([]);
        setDurum('inactive');
      } else {
        throw new Error(responseData.message || 'Bir hata oluştu');
      }
    } catch (err) {
      setDialogSeverity('error');
      setDialogContent(err.message);
      setEklemeBasariliDialogOpen(true);
    }
  };

  const handleSehirChangeDuzenle = (event) => {
    const yeniSehirId = event.target.value;
    setDuzenlenenKurum((prev) => ({ ...prev, sehirId: yeniSehirId }));
    const secilenSehir = sehirListesi.find((sehir) => sehir._id === yeniSehirId);
    const ilceler = secilenSehir ? secilenSehir.ilceler : [];
    setDuzenlenenKurum((prev) => ({ ...prev, ilceId: '', ilceler: ilceler }));
  };

  const handleIlceChangeDuzenle = (event) => {
    const yeniIlceId = event.target.value;
    setDuzenlenenKurum((prev) => ({ ...prev, ilceId: yeniIlceId }));
  };

  const handleEdit = (kurumId) => {
    const seciliKurum = okulListesi.find((kurum) => kurum._id === kurumId);

    if (seciliKurum) {
      const sehirId = seciliKurum.sehir;

      setDuzenlenenKurum((prev) => ({
        ...prev,
        _id: seciliKurum._id,
        okulAdi: seciliKurum.okul_adi,
        email: seciliKurum.email,
        telefon: seciliKurum.telefon,
        sehirId: sehirId,
        ilceId: seciliKurum.ilce,
        kurumTuru: seciliKurum.kurum_turu,
        status: seciliKurum.status,
        password: '',
      }));
      fetchIlceler(sehirId);

      setDuzenlemeDialogAcik(true);
    } else {
      console.error('Seçilen kurum bulunamadı.');
    }
  };

  const handleGuncelle = async () => {
    let eksikAlanlar = [];

    if (!duzenlenenKurum.okulAdi) eksikAlanlar.push('Okul Adı');
    if (!duzenlenenKurum.email) eksikAlanlar.push('Email');
    if (!duzenlenenKurum.telefon) eksikAlanlar.push('Telefon');
    if (!duzenlenenKurum.sehirId) eksikAlanlar.push('Şehir');
    if (!duzenlenenKurum.ilceId) eksikAlanlar.push('İlçe');
    if (duzenlenenKurum.kurumTuru.length === 0) eksikAlanlar.push('Kurum Türleri');

    if (eksikAlanlar.length > 0) {
      setDialogSeverity('error');
      setDialogContent(`Lütfen ${eksikAlanlar.join(', ')} alanlarını doldurunuz.`);
      setDialogOpen(true);
      return;
    }

    try {
      const existingKurum = okulListesi.find((k) => k._id === duzenlenenKurum._id);

      const updateData = {
        okul_adi: duzenlenenKurum.okulAdi,
        email: duzenlenenKurum.email,
        telefon: duzenlenenKurum.telefon,
        sehir: duzenlenenKurum.sehirId,
        ilce: duzenlenenKurum.ilceId,
        kurum_turu: duzenlenenKurum.kurumTuru,
        status: duzenlenenKurum.status,
      };

      if (duzenlenenKurum.password.trim() !== '') {
        updateData.password = duzenlenenKurum.password;
        updateData.password_open = duzenlenenKurum.password;
      }

      const response = await fetch(`${API_URLS.ADMIN_URL}schools/${duzenlenenKurum._id}`, {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(updateData),
      });

      const data = await response.json();
      if (response.ok) {
        const guncellenmisKurum = {
          ...existingKurum,
          ...duzenlenenKurum,
          sehirAdi:
            sehirListesi.find((sehir) => sehir._id === duzenlenenKurum.sehirId)?.sehir_adi || '',
          ilceAdi:
            sehirListesi
              .find((sehir) => sehir._id === duzenlenenKurum.sehirId)
              ?.ilceler.find((ilce) => ilce._id === duzenlenenKurum.ilceId)?.ilce_adi || '',
        };

        setOkulListesi((prevList) =>
          prevList.map((kurum) => (kurum._id === duzenlenenKurum._id ? guncellenmisKurum : kurum))
        );

        setDialogSeverity('success');
        setDialogContent('Okul başarıyla güncellendi.');
        setDialogOpen(true);
        setDuzenlemeDialogAcik(false);
      } else {
        throw new Error(data.message || 'Bir hata oluştu');
      }
    } catch (err) {
      setDialogSeverity('error');
      setDialogContent(err.message);
      setDialogOpen(true);
    }
  };

  const handleDelete = async (kurumId) => {
    const onay = window.confirm('Bu okulu silmek istediğinize emin misiniz?');
    if (!onay) return;

    try {
      const response = await fetch(API_URLS.ADMIN_URL + `schools/${kurumId}`, {
        method: 'DELETE',
      });

      const data = await response.json();

      if (!response.ok) {
        setDialogSeverity('error');
        setDialogContent(data.message || 'Okul silinirken bir hata oluştu.');
        setSilmeBasariliDialogOpen(true);
      } else {
        setOkulListesi((prevList) => prevList.filter((kurum) => kurum._id !== kurumId));

        setDialogSeverity('success');
        setDialogContent(`${data.message}`);
        setSilmeBasariliDialogOpen(true);
      }
    } catch (err) {
      setDialogSeverity('error');
      setDialogContent('Hata: ' + err.message);
      setSilmeBasariliDialogOpen(true);
    }
  };

  const handleStatusChange = async (kurumId, currentStatus) => {
    const newStatus = currentStatus === 'active' ? 'inactive' : 'active';

    try {
      const response = await fetch(`${API_URLS.ADMIN_URL}schools/${kurumId}/status`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('userToken')}`,
        },
        body: JSON.stringify({ status: newStatus }),
      });

      if (!response.ok) throw new Error('Durum güncellenirken bir hata oluştu.');

      setOkulListesi((prevList) =>
        prevList.map((kurum) => (kurum._id === kurumId ? { ...kurum, status: newStatus } : kurum))
      );

      setDialogSeverity('success');
      setDialogContent('Durum başarıyla güncellendi.');
      setDialogOpen(true);
    } catch (error) {
      setDialogSeverity('error');
      setDialogContent(error.message);
      setDialogOpen(true);
    }
  };

  const handleAdjustBalance = (kurum) => {
    setSelectedKurum(kurum);
    setBalanceDialogOpen(true);
    setSelectedPackageType('');
    setBalanceAmount(0);
    setBalanceReason('');
    setCurrentPackageBalance(0);
  };

  const handleBalanceSubmit = async () => {
    if (!balanceReason) {
      setDialogSeverity('error');
      setDialogContent('Lütfen bir neden giriniz.');
      setDialogOpen(true);
      return;
    }
    if (selectedPackageType === '') {
      setDialogSeverity('error');
      setDialogContent('Lütfen bir paket seçiniz.');
      setDialogOpen(true);
      return;
    }

    const newPackageBalance = balanceAmount;
    const oldPackageBalance = currentPackageBalance;
    const difference = newPackageBalance - oldPackageBalance;

    try {
      const response = await fetch(`${API_URLS.ADMIN_URL}bakiye/balance-adjustment`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('userToken')}`,
        },
        body: JSON.stringify({
          userType: 'School',
          userId: selectedKurum._id,
          packageType: selectedPackageType,
          newBalance: newPackageBalance,
          reason: balanceReason,
        }),
      });

      const data = await response.json();

      if (response.ok) {
        setOkulListesi((prevList) =>
          prevList.map((kurum) => {
            if (kurum._id === selectedKurum._id) {
              const updatedKurum = { ...kurum };
              if (selectedPackageType == 3) {
                updatedKurum.bakiye3Ay = newPackageBalance;
              } else if (selectedPackageType == 6) {
                updatedKurum.bakiye6Ay = newPackageBalance;
              } else if (selectedPackageType == 12) {
                updatedKurum.bakiye12Ay = newPackageBalance;
              }
              updatedKurum.bakiye += difference;
              return updatedKurum;
            } else {
              return kurum;
            }
          })
        );

        setDialogSeverity('success');
        setDialogContent('Bakiye başarıyla güncellendi.');
        setDialogOpen(true);
        setBalanceDialogOpen(false);
        setBalanceAmount(0);
        setBalanceReason('');
        setSelectedPackageType('');
      } else {
        throw new Error(data.message || 'Bakiye güncellenirken bir hata oluştu.');
      }
    } catch (error) {
      setDialogSeverity('error');
      setDialogContent(error.message);
      setDialogOpen(true);
    }
  };

  // -- YENİ: Okul Atama işlemini açma/kapama & atama fonksiyonları --
  const handleOpenOkulAtamaDialog = (schoolId) => {
    setSelectedSchoolIdForAssignment(schoolId);
    setOkulAtamaDialogOpen(true);
  };

  const handleCloseOkulAtamaDialog = () => {
    setOkulAtamaDialogOpen(false);
    setSelectedSchoolIdForAssignment('');
  };

  const handleAssign = async (schoolId, kurumId) => {
    try {
      const resp = await fetch(`${API_URLS.ADMIN_URL}schools/${schoolId}/assign`, {
        method: 'POST',
        headers: { 
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('userToken')}`,
        },
        body: JSON.stringify({ kurumId }),
      });

      const data = await resp.json();

      if (!resp.ok) {
        throw new Error(data.message || 'Okul atama işlemi başarısız.');
      }

      // Başarılı işlem sonrası diyaloğu kapat
      setOkulAtamaDialogOpen(false);

      // Okulları veya tabloyu yeniden fetchlemek isterseniz:
      setShouldFetch(true);

      setDialogSeverity('success');
      setDialogContent('Okul, kuruma başarıyla atandı.');
      setDialogOpen(true);
    } catch (error) {
      setDialogSeverity('error');
      setDialogContent(error.message);
      setDialogOpen(true);
    }
  };

  return (
    <Paper style={{ padding: 20 }}>
      <Grid container spacing={3}>
        {/* Okul Kayıt Formu */}
        <SchoolForm
          sehirListesi={sehirListesi}
          secilenSehirId={secilenSehirId}
          secilenIlceId={secilenIlceId}
          secilenSehirinIlceleri={
            sehirListesi.find((sehir) => sehir._id === secilenSehirId)?.ilceler || []
          }
          handleSehirChange={handleSehirChange}
          handleIlceChange={handleIlceChange}
          okulAdi={okulAdi}
          setOkulAdi={setOkulAdi}
          telefon={telefon}
          handleTelefonChange={handleTelefonChange}
          telefonHata={telefonHata}
          secilenKurumTurleri={secilenKurumTurleri}
          setSecilenKurumTurleri={setSecilenKurumTurleri}
          email={email}
          setEmail={setEmail}
          sifre={sifre}
          setSifre={setSifre}
          durum={durum}
          setDurum={setDurum}
          handleEkle={handleEkle}
        />

        {/* Kayıtlı Okullar Listesi */}
        <Grid item xs={12} md={12}>
          <SchoolTable
            filteredOkulListesi={filteredOkulListesi}
            searchQuery={searchQuery}
            setSearchQuery={setSearchQuery}
            handleEdit={handleEdit}
            handleDelete={handleDelete}
            handleStatusChange={handleStatusChange}
            handleAdjustBalance={handleAdjustBalance}
            filterStatus={filterStatus}
            setFilterStatus={setFilterStatus}
            filterSehir={filterSehir}
            setFilterSehir={setFilterSehir}
            filterKurumTuru={filterKurumTuru}
            setFilterKurumTuru={setFilterKurumTuru}
            sehirListesi={sehirListesi}
            okulTurleri={okulTurleri}
            filterEkleyen={filterEkleyen}
            setFilterEkleyen={setFilterEkleyen}
            // YENİ: Okul Atama Dialogunu açan fonksiyon
            handleOpenOkulAtamaDialog={handleOpenOkulAtamaDialog}
          />
        </Grid>

        {/* Düzenleme Dialogu */}
        <EditSchoolDialog
          duzenlemeDialogAcik={duzenlemeDialogAcik}
          setDuzenlemeDialogAcik={setDuzenlemeDialogAcik}
          duzenlenenKurum={duzenlenenKurum}
          setDuzenlenenKurum={setDuzenlenenKurum}
          sehirListesi={sehirListesi}
          handleSehirChangeDuzenle={handleSehirChangeDuzenle}
          handleIlceChangeDuzenle={handleIlceChangeDuzenle}
          handleGuncelle={handleGuncelle}
        />

        {/* Bakiye Ayarlama Dialogu */}
        <BalanceAdjustmentDialog
          balanceDialogOpen={balanceDialogOpen}
          setBalanceDialogOpen={setBalanceDialogOpen}
          selectedKurum={selectedKurum}
          selectedPackageType={selectedPackageType}
          setSelectedPackageType={setSelectedPackageType}
          currentPackageBalance={currentPackageBalance}
          setCurrentPackageBalance={setCurrentPackageBalance}
          balanceAmount={balanceAmount}
          setBalanceAmount={setBalanceAmount}
          balanceReason={balanceReason}
          setBalanceReason={setBalanceReason}
          handleBalanceSubmit={handleBalanceSubmit}
        />

        {/* Sonuç Dialogları */}
        <ResultDialog
          open={eklemeBasariliDialogOpen}
          onClose={() => setEklemeBasariliDialogOpen(false)}
          title={dialogSeverity === 'error' ? 'Hata Oluştu' : 'İşlem Başarılı'}
          message={dialogContent}
          severity={dialogSeverity}
        />

        <ResultDialog
          open={silmeBasariliDialogOpen}
          onClose={() => setSilmeBasariliDialogOpen(false)}
          title={dialogSeverity === 'error' ? 'Hata Oluştu' : 'Silme İşlemi Başarılı'}
          message={dialogContent}
          severity={dialogSeverity}
        />

        <ResultDialog
          open={dialogOpen}
          onClose={() => setDialogOpen(false)}
          title={dialogSeverity === 'error' ? 'Hata Oluştu' : 'İşlem Sonucu'}
          message={dialogContent}
          severity={dialogSeverity}
        />
      </Grid>

      {/* YENİ: OkulAtamaDialog */}
      <OkulAtamaDialog
        open={okulAtamaDialogOpen}
        onClose={handleCloseOkulAtamaDialog}
        selectedSchoolId={selectedSchoolIdForAssignment}
        handleAssign={handleAssign}
      />
    </Paper>
  );
};

export default Okullar;
