// src/pages/okul/components/OkulListesi.jsx

import React from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Button,
  Stack
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';

const OkulListesi = ({
  filteredOkulListesi,
  handleEdit,
  handleDelete,
  handleOpenTransferDialog,
  // YENİ:
  handleOpenRapor
}) => {
  return (
    <TableContainer component={Paper}>
      <Table aria-label="okul listesi">
        <TableHead>
          <TableRow>
            <TableCell>Okul Adı</TableCell>
            <TableCell align="right">Email</TableCell>
            <TableCell align="right">Şifre</TableCell>
            <TableCell align="right">Telefon</TableCell>
            <TableCell align="right">Şehir</TableCell>
            <TableCell align="right">İlçe</TableCell>
            <TableCell align="right">Okul Türü</TableCell>
            <TableCell align="right">Bakiye (3 Ay)</TableCell>
            <TableCell align="right">Bakiye (6 Ay)</TableCell>
            <TableCell align="right">Bakiye (12 Ay)</TableCell>
            <TableCell align="right">Bakiye (Toplam)</TableCell>
            <TableCell align="right">İşlem</TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {filteredOkulListesi.map((kurum, index) => {
            const toplamBakiye =
              (kurum.bakiye3Ay || 0) +
              (kurum.bakiye6Ay || 0) +
              (kurum.bakiye12Ay || 0);

            return (
              <TableRow key={index}>
                <TableCell component="th" scope="row">
                  {kurum.okul_adi}
                </TableCell>
                <TableCell align="right">{kurum.email}</TableCell>
                <TableCell align="right">{kurum.password_open}</TableCell>
                <TableCell align="right">{kurum.telefon}</TableCell>
                <TableCell align="right">{kurum.sehirAdi || ''}</TableCell>
                <TableCell align="right">{kurum.ilceAdi || ''}</TableCell>
                <TableCell align="right">
                  {kurum.kurum_turu ? kurum.kurum_turu.join(', ') : ''}
                </TableCell>

                {/* Bakiye sütunları */}
                <TableCell align="right">{kurum.bakiye3Ay || 0}</TableCell>
                <TableCell align="right">{kurum.bakiye6Ay || 0}</TableCell>
                <TableCell align="right">{kurum.bakiye12Ay || 0}</TableCell>
                <TableCell align="right">{toplamBakiye}</TableCell>

                <TableCell align="right">
                  <Stack direction="row" spacing={1}>
                    <IconButton
                      onClick={() => handleEdit(kurum._id)}
                      variant="outlined"
                      color="primary"
                      size="small"
                    >
                      <EditIcon />
                    </IconButton>
                    <IconButton
                      onClick={() => handleDelete(kurum._id)}
                      variant="contained"
                      color="secondary"
                      size="small"
                    >
                      <DeleteIcon />
                    </IconButton>

                    <Button
                      variant="contained"
                      color="success"
                      onClick={() => handleOpenTransferDialog(kurum._id)}
                      size="small"
                    >
                      Bakiye Transferi
                    </Button>

                    {/* YENİ: Rapor Butonu */}
                    <Button
                      variant="contained"
                      color="info"
                      onClick={() => handleOpenRapor(kurum._id, kurum.okul_adi)}
                      size="small"
                    >
                      Rapor
                    </Button>
                  </Stack>
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default OkulListesi;
