import React, { useState, useEffect } from 'react';
import {
  Box,
  Container,
  Card,
  CardContent,
  Typography,
  Stack,
  CircularProgress,
  Divider,
  Paper,
  Button,
} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import HomeIcon from '@mui/icons-material/Home';
import axios from 'axios';
import { API_URLS } from '../../config/config';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { Doughnut } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  PointElement,
} from 'chart.js';
import axiosInstance from '../../utils/axiosInstance';

ChartJS.register(ArcElement, Tooltip, Legend, CategoryScale, LinearScale, PointElement);

// --- STYLED COMPONENTS ---
const StyledButton = styled(Button)(
  ({ theme, isCorrect, isSelected, isAnswered }) => ({
    backgroundColor: isAnswered
      ? isCorrect
        ? '#66bb6a'
        : isSelected
        ? '#ef5350'
        : theme.palette.grey[400]
      : theme.palette.primary.main,
    color: '#fff !important',

    borderRadius: '12px',
    padding: '12px',
    fontWeight: 'bold',
    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)',
    '&:hover': {
      backgroundColor: isAnswered
        ? isCorrect
          ? '#81c784'
          : isSelected
          ? '#e57373'
          : theme.palette.grey[500]
        : theme.palette.primary.dark,
      color: '#fff !important',
    },
  })
);

const NavButton = styled(Button)(({ theme }) => ({
  minWidth: 'auto',
  padding: '6px 12px',
  borderRadius: '8px',
  fontSize: '0.875rem',
  textTransform: 'none',
  boxShadow: 'none',
  '&:hover': {
    boxShadow: 'none',
  },
}));

const SummaryCard = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(4),
  marginTop: theme.spacing(4),
  borderRadius: '16px',
  textAlign: 'center',
  backgroundColor: '#fafafa',
  boxShadow: '0px 6px 24px rgba(0, 0, 0, 0.15)',
  border: `1px solid ${theme.palette.divider}`,
}));

const ChartContainer = styled('div')(({ theme }) => ({
  margin: theme.spacing(3, 0),
  textAlign: 'center',
}));

const StyledCard = styled(Card)(({ theme }) => ({
  padding: theme.spacing(4),
  borderRadius: '16px',
  boxShadow: '0px 6px 20px rgba(0, 0, 0, 0.1)',
  backgroundColor: '#ffffff',
  border: `1px solid ${theme.palette.divider}`,
}));

// -- Burada sayfayı tam saran bir Box komponenti oluşturuyoruz --
// -- Arka planı yumuşak bir pastel renge ayarlıyoruz --
const PageWrapper = styled(Box)(({ theme }) => ({
  minHeight: '100vh',
  backgroundColor: '#cce7ed', // Göz yormayan pastel bir renk
  paddingTop: theme.spacing(4),
  paddingBottom: theme.spacing(4),
}));

const TestSayfasi = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();

  const {
    fromStage,
    stageNumber,
    wordCount,
    totalTime,
    readingSpeed,
    bookId: stateBookId,
    userRole: locationUserRole,
  } = location.state || {};

  const { bookId: paramBookId } = params;
  const bookId = stateBookId || paramBookId;

  const [currentTestIndex, setCurrentTestIndex] = useState(0);
  const [tests, setTests] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);
  const [isAnswered, setIsAnswered] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isFinished, setIsFinished] = useState(false);
  const [correctAnswers, setCorrectAnswers] = useState(0);
  const [incorrectAnswers, setIncorrectAnswers] = useState(0);
  const [answeredQuestions, setAnsweredQuestions] = useState(0);
  const [showBookTitle, setShowBookTitle] = useState(true);

  // Burada kullanıcı ve aktiflik bilgilerini tutmak için gerekli state'ler
  const [userData, setUserData] = useState(null);
  const [userRole, setUserRole] = useState(null);
  const [userActive, setUserActive] = useState(false);

  const token = localStorage.getItem('userToken');

  // KayanYazilarOyunu'ndan benzer şekilde kullanıcı verisini çekip aktifliğini kontrol ediyoruz.
  useEffect(() => {
    const fetchUserData = async () => {
      try {
        // 1) Önce öğrenci mi diye bak
        const studentResponse = await axiosInstance.get(`${API_URLS.STUDENT_URL}/me`);
        if (
          studentResponse.status === 200 &&
          studentResponse.data &&
          studentResponse.data.status === 'active'
        ) {
          const studentData = studentResponse.data;
          setUserData(studentData);
          setUserRole('student');
          setUserActive(true);
          return;
        } else {
          setUserData(null);
          setUserRole(null);
          setUserActive(false);
        }
      } catch (error) {
        console.error('Öğrenci verisi alınırken hata oluştu:', error);
      }

      // 2) Öğrenci yok veya aktif değilse öğretmen verisine bak
      try {
        const teacherResponse = await fetch(`${API_URLS.TEACHER_URL}/me`, {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });
        if (teacherResponse.ok) {
          const teacherData = await teacherResponse.json();
          // Öğretmenin aktiflik alanı yoksa, sistemde öğretmen olduğu sürece userActive=true kabul edebilirsiniz
          setUserData(teacherData);
          setUserRole('teacher');
          setUserActive(true);
          return;
        }
      } catch (error) {
        console.error('Öğretmen verisi alınırken hata oluştu:', error);
      }

      // 3) Hem öğretmen hem öğrenci girişli durum yoksa (veya aktif değilse)
      setUserData(null);
      setUserRole(null);
      setUserActive(false);
    };

    fetchUserData();
  }, [token]);

  // Testleri getiriyoruz
  useEffect(() => {
    if (!bookId) {
      setError('Kitap bilgisi bulunamadı.');
      setIsLoading(false);
      return;
    }
    const fetchTests = async () => {
      try {
        const response = await axios.get(`${API_URLS.TESTLER_URL}book/${bookId}/tests`);
        setTests(response.data);
        setIsLoading(false);
      } catch (err) {
        setError('Testler yüklenirken bir hata oluştu.');
        setIsLoading(false);
      }
    };

    fetchTests();
  }, [bookId]);

  // Test bittiğinde, eğer aşama bilgisi varsa sonuçları kaydet
  useEffect(() => {
    if (isFinished && fromStage && stageNumber) {
      const saveTestResults = async () => {
        const testResults = {
          wordCount,
          totalTime,
          readingSpeed,
          bookId,
          correctAnswers,
          incorrectAnswers,
          totalQuestions: tests.length,
        };

        try {
          // Eğer kullanıcı rolü Student ise
          if (userRole === 'student') {
            await axiosInstance.post(
              `${API_URLS.STUDENT_URL}akillikurs/progress/update-stage-test-results`,
              {
                stageNumber,
                testResults,
              }
            );
          } else if (userRole === 'teacher') {
            // Öğretmen: localStorage token ile Authorization header ekle
            const teacherToken = localStorage.getItem('userToken');
            await axios.post(
              `${API_URLS.TEACHER_URL}akillikurs/progress/update-stage-test-results`,
              {
                stageNumber,
                testResults,
              },
              {
                headers: {
                  Authorization: `Bearer ${teacherToken}`,
                  'Content-Type': 'application/json',
                },
              }
            );
          }
        } catch (error) {
          console.error(
            'Test sonuçları kaydedilemedi:',
            error.response ? error.response.data : error.message
          );
        }
      };
      saveTestResults();
    }
  }, [
    isFinished,
    fromStage,
    stageNumber,
    wordCount,
    totalTime,
    readingSpeed,
    bookId,
    correctAnswers,
    incorrectAnswers,
    tests.length,
    userRole,
  ]);

  // Seçilen şık
  const handleOptionSelect = (index) => {
    // Eğer kullanıcı aktif değilse, bu fonksiyon çalışmasın (hiç cevaplayamasın)
    if (!userActive) return;

    setSelectedOption(index);
    setIsAnswered(true);
    setAnsweredQuestions((prev) => prev + 1);

    if (index === tests[currentTestIndex]?.correctAnswer) {
      setCorrectAnswers((prev) => prev + 1);
    } else {
      setIncorrectAnswers((prev) => prev + 1);
    }
  };

  // Sonraki soru
  const handleNext = () => {
    // Eğer kullanıcı aktif değilse, diğer sorulara geçiş yapamasın
    if (!userActive) return;

    if (currentTestIndex < tests.length - 1) {
      setCurrentTestIndex(currentTestIndex + 1);
      setSelectedOption(null);
      setIsAnswered(false);
    } else {
      setIsFinished(true);
    }
  };

  // Bir önceki aşamaya geri dön
  const handleReturnToPreviousStage = () => {
    if (fromStage && stageNumber) {
      navigate(
        userRole === 'student' ? '/ogrenci-dashboard' : '/ogretmen-dashboard',
        {
          state: {
            selectedMenu: 'ExerciseList',
            selectedStage: stageNumber,
          },
        }
      );
    } else {
      alert('Aşama bilgisi bulunmamaktadır.');
    }
  };

  if (isLoading) {
    return (
      <PageWrapper>
        <Container maxWidth="sm" sx={{ mt: 4, textAlign: 'center' }}>
          <CircularProgress />
        </Container>
      </PageWrapper>
    );
  }

  if (error) {
    return (
      <PageWrapper>
        <Container maxWidth="sm" sx={{ mt: 4, textAlign: 'center' }}>
          <Typography variant="h6" color="error">
            {error}
          </Typography>
        </Container>
      </PageWrapper>
    );
  }

  // Testler bittiğinde sonuç ekranı
  if (isFinished) {
    const totalQuestions = tests.length;
    const unansweredQuestions = totalQuestions - answeredQuestions;
    const data = {
      labels: ['Doğru', 'Yanlış', 'Cevaplanmamış'],
      datasets: [
        {
          data: [correctAnswers, incorrectAnswers, unansweredQuestions],
          backgroundColor: ['#66bb6a', '#ef5350', '#9e9e9e'],
          hoverBackgroundColor: ['#81c784', '#e57373', '#bdbdbd'],
        },
      ],
    };

    return (
      <PageWrapper>
        <Container maxWidth="sm" sx={{ mt: 4, textAlign: 'center' }}>
          <StyledCard>
            <CardContent>
              <ChartContainer>
                <Doughnut data={data} />
              </ChartContainer>
              <Typography variant="h5" sx={{ mb: 2 }}>
                Test Sonuçları
              </Typography>
              <Stack direction="row" justifyContent="space-between">
                <Typography variant="body1" sx={{ color: '#66bb6a', fontWeight: 'bold' }}>
                  Doğru: {correctAnswers}
                </Typography>
                <Typography variant="body1" sx={{ color: '#ef5350', fontWeight: 'bold' }}>
                  Yanlış: {incorrectAnswers}
                </Typography>
              </Stack>
              <Divider sx={{ my: 2 }} />
              <Typography variant="body1">Cevaplanan Sorular: {answeredQuestions}</Typography>
              <Typography variant="body1">
                Cevaplanmamış Sorular: {unansweredQuestions}
              </Typography>
            </CardContent>

            <Stack direction="row" spacing={1} justifyContent="center" sx={{ mb: 2 }}>
              <NavButton variant="contained" color="primary" onClick={() => navigate(-1)}>
                Geri Dön
              </NavButton>
              <NavButton variant="outlined" color="secondary" onClick={() => navigate('/')}>
                Anasayfaya Dön
              </NavButton>
              <NavButton variant="contained" color="secondary" onClick={handleReturnToPreviousStage}>
                Bir Önceki Aşamaya Dön
              </NavButton>
            </Stack>
          </StyledCard>
          <SummaryCard>
            <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
              Toplam Sorular: {totalQuestions}
            </Typography>
          </SummaryCard>
        </Container>
      </PageWrapper>
    );
  }

  // Önce kitap içeriğini gösteriyoruz
  if (showBookTitle && tests.length > 0) {
    return (
      <PageWrapper>
        <Container maxWidth="md" sx={{ mt: 6, mb: 6 }}>
          <StyledCard>
            <CardContent>
              <Stack direction="row" spacing={1} justifyContent="center" sx={{ mb: 4 }}>
                <NavButton
                  variant="contained"
                  color="primary"
                  onClick={() => navigate(-1)}
                  startIcon={<ArrowBackIcon />}
                >
                  Geri Dön
                </NavButton>
                <NavButton
                  variant="outlined"
                  color="secondary"
                  onClick={() => navigate('/')}
                  startIcon={<HomeIcon />}
                >
                  Anasayfaya Dön
                </NavButton>
                <NavButton variant="contained" color="secondary" onClick={handleReturnToPreviousStage}>
                  Bir Önceki Aşamaya Dön
                </NavButton>
              </Stack>

              <Typography
                variant="h3"
                component="h2"
                gutterBottom
                sx={{ fontWeight: 500, textAlign: 'center' }}
              >
                {tests[0]?.bookTitle}
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  mt: 2,
                  maxWidth: '800px',
                  margin: '0 auto',
                  fontSize: '18px',
                  lineHeight: 1.8,
                  letterSpacing: '0.5px',
                }}
                dangerouslySetInnerHTML={{ __html: tests[0]?.bookContent }}
              />

              <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => setShowBookTitle(false)}
                  sx={{ borderRadius: '10px' }}
                >
                  Hadi Sorulara Geçelim
                </Button>
              </Box>
            </CardContent>
          </StyledCard>
        </Container>
      </PageWrapper>
    );
  }

  // Kullanıcı aktif değilse: sadece ilk soruyu göstersin, cevap butonları devre dışı olsun,
  // "Sonraki" butonu da pasif veya hiç gösterilmesin.
  if (!userActive && tests.length > 0) {
    const firstQuestion = tests[0];
    return (
      <PageWrapper>
        <Container maxWidth="sm" sx={{ mt: 4 }}>
          <StyledCard>
            <CardContent>
              <Typography variant="h5" gutterBottom>
                {firstQuestion.question}
              </Typography>
              <Stack spacing={2}>
                {firstQuestion.options.map((option, index) => (
                  <StyledButton
                    key={index}
                    disabled
                    // Kullanıcı cevap seçemesin
                    isCorrect={false}
                    isSelected={false}
                    isAnswered={false}
                  >
                    {option}
                  </StyledButton>
                ))}
              </Stack>
            </CardContent>
          </StyledCard>
          <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{ mt: 3 }}>
            <NavButton variant="outlined" disabled>
              Önceki
            </NavButton>
            <NavButton variant="contained" disabled>
              Sonraki
            </NavButton>
          </Stack>
          <Typography
            variant="body1"
            color="error"
            sx={{
              mt: 2,
              textAlign: 'center',
              fontStyle: 'italic',
              fontWeight: 'bold', 
              fontSize: '1.5rem',  
            }}
          >
            Bu testi çözebilmek için giriş yapmanız ve hesabınızın aktif olması gerekir.
          </Typography>
        </Container>
      </PageWrapper>
    );
  }

  // Kullanıcı aktifse normal test akışı
  return (
    <PageWrapper>
      <Container maxWidth="sm" sx={{ mt: 4 }}>
        <StyledCard>
          <CardContent>
            <Typography
              variant="h5"
              gutterBottom
              dangerouslySetInnerHTML={{ __html: tests[currentTestIndex]?.question }}
            />
            <Stack spacing={2}>
              {tests[currentTestIndex]?.options.map((option, index) => (
                <StyledButton
                  key={index}
                  onClick={() => handleOptionSelect(index)}
                  disabled={isAnswered}
                  isCorrect={index === tests[currentTestIndex]?.correctAnswer}
                  isSelected={index === selectedOption}
                  isAnswered={isAnswered}
                >
                  {option}
                </StyledButton>
              ))}
            </Stack>
          </CardContent>
        </StyledCard>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          sx={{ mt: 3 }}
        >
          <NavButton
            variant="outlined"
            disabled={currentTestIndex === 0}
            onClick={() => {
              if (currentTestIndex > 0) {
                setCurrentTestIndex(currentTestIndex - 1);
                setSelectedOption(null);
                setIsAnswered(false);
              }
            }}
          >
            Önceki
          </NavButton>
          <NavButton variant="contained" onClick={handleNext}>
            {currentTestIndex < tests.length - 1 ? 'Sonraki' : 'Bitir'}
          </NavButton>
        </Stack>
      </Container>
    </PageWrapper>
  );
};

export default TestSayfasi;
