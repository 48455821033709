import React from 'react';
import { Table, TableHead, TableRow, TableCell, TableBody, IconButton } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

/**
 * TestsTable
 * - tests: testlerin listesi
 * - books: kitap listesi (eğer tablo içinde ihtiyaç varsa)
 * - handleEditTest: seçilen testi düzenleme
 * - handleDeleteTest: seçilen testi silme
 */
const TestsTable = ({ tests, books, handleEditTest, handleDeleteTest }) => {
  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>Kitap Adı</TableCell>
          <TableCell>Soru</TableCell>
          <TableCell>Şıklar</TableCell>
          <TableCell>Doğru Cevap</TableCell>
          <TableCell>İşlem</TableCell>
        </TableRow>
      </TableHead>

      <TableBody>
        {tests.map((test) => {
          // Eğer test.bookId varsa, ilgili kitabı books içinden bulup adını çekebilirsiniz.
          // Burada test.bookTitle doğrudan geliyor varsayıyoruz.
          const bookTitle = test.bookTitle || 'Bilinmiyor';

          // Doğru cevap index (number) olarak saklanıyorsa:
          // test.correctAnswer bir sayı ve test.options dizisi varsa:
          const correctIndex = test.correctAnswer;
          const correctAnswerText =
            test.options && correctIndex >= 0 && correctIndex < test.options.length
              ? `${String.fromCharCode(65 + correctIndex)}. ${test.options[correctIndex]}`
              : 'Geçersiz';

          return (
            <TableRow key={test._id}>
              <TableCell>{bookTitle}</TableCell>
              <TableCell>{test.question}</TableCell>
              <TableCell>
                {test.options &&
                  test.options.map((option, index) => (
                    <div key={index}>
                      {String.fromCharCode(65 + index)}. {option}
                    </div>
                  ))}
              </TableCell>
              <TableCell>{correctAnswerText}</TableCell>
              <TableCell>
                <IconButton onClick={() => handleEditTest(test._id)}>
                  <EditIcon />
                </IconButton>
                <IconButton onClick={() => handleDeleteTest(test._id)}>
                  <DeleteIcon />
                </IconButton>
              </TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  );
};

export default TestsTable;
