import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlay } from '@fortawesome/free-solid-svg-icons';
import '../styles/cumleleriOkuStyle.css';

const Instructions = ({ startGame, title, description }) => {
  return (
    <div className="tachisto-intro-container">
      <div className="tachisto-card">
        <div className="tachisto-card-body">
          <h1 className="tachisto-title">{title}</h1>
          <p className="tachisto-description">{description}</p>
          <h3 className="tachisto-instructions-title">Nasıl Oynanır?</h3>
          <ul className="tachisto-instructions-list">
            <li><strong>1. Zorluk Seviyesi Seçin:</strong> Kolay, Orta veya Zor</li>
            <li><strong>2. Çalışmayı Başlatın:</strong> "Başlat" butonuna tıklayın.</li>
            <li><strong>3. Cümleleri İzleyin:</strong> Alt kısımdan cümleler gelecektir.</li>
          </ul>
          <button className="tachisto-button" onClick={startGame}>
            <FontAwesomeIcon icon={faPlay} /> BAŞLAT
          </button>
        </div>
      </div>
    </div>
  );
};

export default Instructions;
