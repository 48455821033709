import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem
} from '@mui/material';

const OkulDuzenleDialog = ({
  open,
  setOpen,
  duzenlenenKurum,
  setDuzenlenenKurum,
  sehirListesi,
  fetchIlceler,
  handleGuncelle,
}) => {
  // Kurum türleri
  const kurumTurleri = [
    { id: 'ilkokul', adi: 'İlkokul' },
    { id: 'ortaokul', adi: 'Ortaokul' },
    { id: 'lise', adi: 'Lise' },
    { id: 'kurs', adi: 'Kurs' },
  ];

  const handleSehirChangeDuzenle = (event) => {
    const yeniSehirId = event.target.value;
    setDuzenlenenKurum((prev) => ({ ...prev, sehirId: yeniSehirId }));
    const secilenSehir = sehirListesi.find((sehir) => sehir._id === yeniSehirId);
    const ilceler = secilenSehir ? secilenSehir.ilceler : [];
    setDuzenlenenKurum((prev) => ({
      ...prev,
      ilceId: '',
      ilceler: ilceler,
    }));

    // Dialog içinden ilçeleri yeniden çekmek istiyorsanız:
    fetchIlceler(yeniSehirId, '');
  };

  const handleIlceChangeDuzenle = (event) => {
    const yeniIlceId = event.target.value;
    setDuzenlenenKurum((prev) => ({ ...prev, ilceId: yeniIlceId }));
  };

  return (
    <Dialog open={open} onClose={() => setOpen(false)}>
      <DialogTitle>Kurum Düzenle</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          id="okulAdi"
          label="Okul Adı"
          type="text"
          fullWidth
          variant="outlined"
          value={duzenlenenKurum.okulAdi}
          onChange={(e) =>
            setDuzenlenenKurum({ ...duzenlenenKurum, okulAdi: e.target.value })
          }
        />

        <TextField
          margin="dense"
          id="email"
          label="Email"
          type="email"
          fullWidth
          variant="outlined"
          value={duzenlenenKurum.email}
          onChange={(e) =>
            setDuzenlenenKurum({ ...duzenlenenKurum, email: e.target.value })
          }
        />

        <TextField
          margin="dense"
          id="telefon"
          label="Telefon"
          type="tel"
          fullWidth
          variant="outlined"
          value={duzenlenenKurum.telefon}
          onChange={(e) =>
            setDuzenlenenKurum({ ...duzenlenenKurum, telefon: e.target.value })
          }
        />

        <FormControl fullWidth margin="dense">
          <InputLabel id="sehir-select-label-duzenle">Şehir</InputLabel>
          <Select
            labelId="sehir-select-label-duzenle"
            id="sehir-select-duzenle"
            value={duzenlenenKurum.sehirId || ''}
            onChange={handleSehirChangeDuzenle}
          >
            <MenuItem value="">
              <em>Şehir Seçiniz</em>
            </MenuItem>
            {sehirListesi.map((sehir) => (
              <MenuItem key={sehir._id} value={sehir._id}>
                {sehir.sehir_adi}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        {duzenlenenKurum.sehirId && (
          <FormControl fullWidth margin="dense">
            <InputLabel id="ilce-select-label-duzenle">İlçe</InputLabel>
            <Select
              labelId="ilce-select-label-duzenle"
              id="ilce-select-duzenle"
              value={duzenlenenKurum.ilceId || ''}
              onChange={handleIlceChangeDuzenle}
              displayEmpty
              renderValue={(selected) => {
                if (selected) {
                  const secilenIlce = duzenlenenKurum.ilceler.find(
                    (ilce) => ilce._id === selected
                  );
                  return secilenIlce ? secilenIlce.ilce_adi : 'İlçe bulunamadı';
                }
                return <em>İlçe Seçiniz</em>;
              }}
            >
              {duzenlenenKurum.ilceler?.map((ilce) => (
                <MenuItem key={ilce._id} value={ilce._id}>
                  {ilce.ilce_adi}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}

        <FormControl fullWidth margin="dense">
          <InputLabel id="kurum-turu-label">Kurum Türü</InputLabel>
          <Select
            labelId="kurum-turu-label"
            id="kurum-turu-select"
            multiple
            value={duzenlenenKurum.kurumTuru || []}
            onChange={(event) =>
              setDuzenlenenKurum((prev) => ({
                ...prev,
                kurumTuru: event.target.value,
              }))
            }
            renderValue={(selected) => selected.join(', ')}
          >
            {kurumTurleri.map((tur) => (
              <MenuItem key={tur.id} value={tur.adi}>
                {tur.adi}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </DialogContent>

      <DialogActions>
        <Button onClick={() => setOpen(false)}>İptal</Button>
        <Button onClick={handleGuncelle}>Güncelle</Button>
      </DialogActions>
    </Dialog>
  );
};

export default OkulDuzenleDialog;
