import React, { useState, useMemo, useEffect } from 'react';
import {
  Card,
  CardContent,
  Typography,
  Grid,
  Button,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Paper,
  Avatar,
  CircularProgress,
} from '@mui/material';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { styled } from '@mui/system';
import {
  Person as PersonIcon,
  Business as BusinessIcon,
  School as SchoolIcon,
} from '@mui/icons-material';
import ResultDialog from '../../../pages/components/Auth/Dialog';
import { API_URLS } from '../../../config/config';

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  marginBottom: theme.spacing(3),
  backgroundColor: '#fff',
  borderRadius: theme.shape.borderRadius,
  boxShadow: theme.shadows[2],
}));

const FilterContainer = styled('div')(({ theme }) => ({
  marginBottom: theme.spacing(4),
  padding: theme.spacing(2),
  backgroundColor: '#f5f5f5',
  borderRadius: theme.shape.borderRadius,
}));

function PaymentReports() {
  const [payments, setPayments] = useState([]);
  const [loading, setLoading] = useState(false);

  // Filtre State'leri
  const [startDateFilter, setStartDateFilter] = useState(null);
  const [endDateFilter, setEndDateFilter] = useState(null);
  const [userTypeFilter, setUserTypeFilter] = useState('');
  const [statusFilter, setStatusFilter] = useState('');
  const [nameFilter, setNameFilter] = useState('');

  // Şehir ve İlçe State'leri
  const [sehirListesi, setSehirListesi] = useState([]);
  const [secilenSehirId, setSecilenSehirId] = useState('');
  const [secilenIlceId, setSecilenIlceId] = useState('');
  const [ilceler, setIlceler] = useState([]);

  // Dialog State'leri
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogContent, setDialogContent] = useState('');
  const [dialogSeverity, setDialogSeverity] = useState('info');

  // Yeni eklenen sehir ve ilce filtreleri için useEffect
  useEffect(() => {
    fetchSehirler();
  }, []);

  // Şehirleri Çekme Fonksiyonu
  const fetchSehirler = async () => {
    try {
      const response = await fetch(API_URLS.ADMIN_URL + 'sehirler', {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('userToken')}`,
          'Content-Type': 'application/json',
        },
      });
      if (!response.ok) throw new Error('Şehirler yüklenirken bir hata oluştu.');
      const sehirler = await response.json();
      setSehirListesi(sehirler);
    } catch (err) {
      console.error('Şehirler yüklenirken bir hata oluştu:', err.message);
    }
  };

  // İlçeleri Çekme Fonksiyonu
  const fetchIlceler = async (sehirId) => {
    try {
      const response = await fetch(
        `${API_URLS.ADMIN_URL}sehirler/ilceler/${sehirId}`,
        {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${localStorage.getItem('userToken')}`,
            'Content-Type': 'application/json',
          },
        }
      );
      if (!response.ok) throw new Error('İlçeler yüklenirken bir hata oluştu.');
      const data = await response.json();
      const ilceler = data.ilceler ? data.ilceler : [];
      setIlceler(ilceler);
    } catch (error) {
      console.error('İlçeleri yüklerken bir hata oluştu:', error);
    }
  };

  // Şehir Değiştiğinde İlçeleri Güncelleme
  const handleSehirChange = (event) => {
    const sehirId = event.target.value;
    setSecilenSehirId(sehirId);
    setSecilenIlceId('');
    if (sehirId) {
      fetchIlceler(sehirId);
    } else {
      setIlceler([]);
    }
  };

  // İlçe Değiştiğinde
  const handleIlceChange = (event) => {
    setSecilenIlceId(event.target.value);
  };

  // Ödeme Raporlarını Çekme Fonksiyonu
  const fetchPayments = async () => {
    setLoading(true);
    try {
      const response = await fetch(
        API_URLS.ADMIN_URL + 'payments/payment-time-filtreli',
        {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${localStorage.getItem('userToken')}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            startDate: startDateFilter
              ? startDateFilter.toISOString()
              : null,
            endDate: endDateFilter
              ? endDateFilter.toISOString()
              : null,
            userType: userTypeFilter,
            status: statusFilter,
            sehirId: secilenSehirId,
            ilceId: secilenIlceId,
          }),
        }
      );
      const data = await response.json();
      if (response.ok) {
        setPayments(data);
      } else {
        console.error('Ödeme raporu alınamadı:', data.message);
        setDialogSeverity('error');
        setDialogContent(data.message || 'Ödeme raporu alınamadı.');
        setDialogOpen(true);
      }
    } catch (error) {
      console.error('Ödeme raporu alınırken bir hata oluştu:', error);
      setDialogSeverity('error');
      setDialogContent('Ödeme raporu alınırken bir hata oluştu.');
      setDialogOpen(true);
    } finally {
      setLoading(false);
    }
  };

  // Kullanıcı adı/getirme fonksiyonları
  const getUserName = (payment) => {
    const user = payment?.userId;
    if (!user) return 'Bilinmiyor';
    if (payment.userType === 'Ogretmen' || payment.userType === 'Ogrenci') {
      return `${user.ad} ${user.soyad}`;
    } else if (payment.userType === 'Kurumsal') {
      return user.kurum_adi;
    } else if (payment.userType === 'School') {
      return user.okul_adi;
    } else {
      return 'Bilinmiyor';
    }
  };

  const getUserTypeLabel = (userType) => {
    switch (userType) {
      case 'Ogretmen':
        return 'Öğretmen';
      case 'Ogrenci':
        return 'Öğrenci';
      case 'Kurumsal':
        return 'Kurumsal';
      case 'School':
        return 'Okul';
      default:
        return 'Bilinmiyor';
    }
  };

  const getStatusLabel = (status) => {
    switch (status) {
      case 'pending':
        return 'Beklemede';
      case 'successful':
        return 'Başarılı';
      case 'failed':
        return 'Başarısız';
      default:
        return 'Bilinmiyor';
    }
  };

  const getUserIcon = (userType) => {
    if (userType === 'Ogretmen' || userType === 'Ogrenci') {
      return <PersonIcon />;
    } else if (userType === 'Kurumsal') {
      return <BusinessIcon />;
    } else if (userType === 'School') {
      return <SchoolIcon />;
    } else {
      return <PersonIcon />;
    }
  };

  const getUserBalance = (payment) => {
    const user = payment?.userId;
    return user && user.bakiye !== undefined
      ? `${user.bakiye} TL`
      : 'Bilinmiyor';
  };

  const getUserCity = (payment) => {
    const user = payment?.userId;
    return user && user.sehirAdi
      ? user.sehirAdi
      : 'Bilinmiyor';
  };

  const getUserDistrict = (payment) => {
    const user = payment?.userId;
    return user && user.ilceAdi
      ? user.ilceAdi
      : 'Bilinmiyor';
  };

  const getSubscriptionEndDate = (payment) => {
    const user = payment?.userId;
    if (!user) return 'Bilinmiyor';

    let abonelik = null;

    if (user.abonelikler && user.abonelikler.length > 0) {
      abonelik = user.abonelikler[user.abonelikler.length - 1];
    } else if (user.abonelik) {
      abonelik = user.abonelik;
    }

    if (abonelik && abonelik.bitisTarihi) {
      const bitisTarihi = new Date(abonelik.bitisTarihi);
      return bitisTarihi.toLocaleDateString('tr-TR');
    }

    return 'Bilinmiyor';
  };

  // Filtreleme Mantığı
  const filteredPayments = useMemo(() => {
    return payments.filter((payment) => {
      // toLowerCase çağrısı öncesinde, eğer değer null/undefined ise
      // boş stringle değiştirelim.
      const userName = (getUserName(payment) || '').toLowerCase();
      const statusLabel = (getStatusLabel(payment?.status) || '').toLowerCase();
      const sehir = (getUserCity(payment) || '').toLowerCase();
      const ilce = (getUserDistrict(payment) || '').toLowerCase();
      const searchValue = (nameFilter || '').toLowerCase();

      return (
        // Arama filtresi: Kullanıcı adı veya durum etiketi
        (userName.includes(searchValue) ||
          statusLabel.includes(searchValue)) &&
        // Kullanıcı Türü filtresi
        (userTypeFilter ? payment.userType === userTypeFilter : true) &&
        // Durum filtresi
        (statusFilter ? payment.status === statusFilter : true) &&
        // Şehir filtresi
        (secilenSehirId
          ? payment.userId &&
            payment.userId.sehir &&
            payment.userId.sehir._id === secilenSehirId
          : true) &&
        // İlçe filtresi
        (secilenIlceId
          ? payment.userId &&
            payment.userId.ilce &&
            payment.userId.ilce._id === secilenIlceId
          : true) &&
        // Tarih filtresi
        (startDateFilter
          ? new Date(payment.odemeTarihi) >= startDateFilter
          : true) &&
        (endDateFilter
          ? new Date(payment.odemeTarihi) <= endDateFilter
          : true)
      );
    });
  }, [
    payments,
    nameFilter,
    userTypeFilter,
    statusFilter,
    secilenSehirId,
    secilenIlceId,
    startDateFilter,
    endDateFilter,
  ]);

  return (
    <Card>
      <CardContent>
        <Typography variant="h5" gutterBottom>
          Ödeme Raporları
        </Typography>

        {/* Filtreleme Alanı */}
        <FilterContainer>
          <Grid container spacing={2} alignItems="center">
            {/* Kullanıcı Türü Filtre */}
            <Grid item xs={12} sm={3}>
              <FormControl fullWidth variant="outlined" size="small">
                <InputLabel>Kullanıcı Türü</InputLabel>
                <Select
                  value={userTypeFilter}
                  onChange={(e) => setUserTypeFilter(e.target.value)}
                  label="Kullanıcı Türü"
                >
                  <MenuItem value="">Tümü</MenuItem>
                  <MenuItem value="Ogretmen">Öğretmen</MenuItem>
                  <MenuItem value="Kurumsal">Kurumsal</MenuItem>
                  <MenuItem value="School">Okul</MenuItem>
                  <MenuItem value="Ogrenci">Öğrenci</MenuItem>
                </Select>
              </FormControl>
            </Grid>

            {/* Başlangıç Tarihi Filtre */}
            <Grid item xs={12} sm={3}>
              <DatePicker
                selected={startDateFilter}
                onChange={(date) => setStartDateFilter(date)}
                dateFormat="dd/MM/yyyy"
                placeholderText="Başlangıç Tarihi"
                customInput={
                  <TextField
                    fullWidth
                    variant="outlined"
                    size="small"
                    label="Başlangıç Tarihi"
                  />
                }
              />
            </Grid>

            {/* Bitiş Tarihi Filtre */}
            <Grid item xs={12} sm={3}>
              <DatePicker
                selected={endDateFilter}
                onChange={(date) => setEndDateFilter(date)}
                dateFormat="dd/MM/yyyy"
                placeholderText="Bitiş Tarihi"
                customInput={
                  <TextField
                    fullWidth
                    variant="outlined"
                    size="small"
                    label="Bitiş Tarihi"
                  />
                }
              />
            </Grid>

            {/* Durum Filtre */}
            <Grid item xs={12} sm={3}>
              <FormControl fullWidth variant="outlined" size="small">
                <InputLabel>Durum</InputLabel>
                <Select
                  value={statusFilter}
                  onChange={(e) => setStatusFilter(e.target.value)}
                  label="Durum"
                >
                  <MenuItem value="">Tümü</MenuItem>
                  <MenuItem value="pending">Beklemede</MenuItem>
                  <MenuItem value="successful">Başarılı</MenuItem>
                  <MenuItem value="failed">Başarısız</MenuItem>
                </Select>
              </FormControl>
            </Grid>

            {/* Şehir Filtre */}
            <Grid item xs={12} sm={3}>
              <FormControl fullWidth variant="outlined" size="small">
                <InputLabel>Şehir</InputLabel>
                <Select
                  labelId="sehir-select-label"
                  value={secilenSehirId}
                  onChange={handleSehirChange}
                  label="Şehir"
                >
                  <MenuItem value="">
                    <em>Tümü</em>
                  </MenuItem>
                  {sehirListesi.map((sehir) => (
                    <MenuItem key={sehir._id} value={sehir._id}>
                      {sehir.sehir_adi}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            {/* İlçe Filtre */}
            {secilenSehirId && (
              <Grid item xs={12} sm={3}>
                <FormControl fullWidth variant="outlined" size="small">
                  <InputLabel>İlçe</InputLabel>
                  <Select
                    labelId="ilce-select-label"
                    value={secilenIlceId}
                    onChange={handleIlceChange}
                    label="İlçe"
                  >
                    <MenuItem value="">
                      <em>Tümü</em>
                    </MenuItem>
                    {ilceler.map((ilce) => (
                      <MenuItem key={ilce._id} value={ilce._id}>
                        {ilce.ilce_adi}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            )}

            {/* Raporu Getir Butonu */}
            <Grid item xs={12} sm={3}>
              <Button
                variant="contained"
                color="primary"
                onClick={fetchPayments}
                fullWidth
              >
                Raporu Getir
              </Button>
            </Grid>
          </Grid>
        </FilterContainer>

        {/* Arama Alanı (Liste Üzerine) */}
        <Grid container spacing={2} alignItems="center" sx={{ mb: 3 }}>
          <Grid item xs={12}>
            <TextField
              fullWidth
              variant="outlined"
              size="small"
              label="Kullanıcı Adına veya Duruma Göre Ara"
              value={nameFilter}
              onChange={(e) => setNameFilter(e.target.value)}
            />
          </Grid>
        </Grid>

        {/* Loading göstergesi */}
        {loading ? (
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            style={{ minHeight: '200px' }}
          >
            <CircularProgress />
          </Grid>
        ) : filteredPayments.length > 0 ? (
          filteredPayments.map((payment) => (
            <StyledPaper key={payment._id}>
              <Grid container spacing={2}>
                <Grid
                  item
                  xs={12}
                  sm={1}
                  container
                  justifyContent="center"
                  alignItems="center"
                >
                  <Avatar sx={{ bgcolor: '#3f51b5' }}>
                    {getUserIcon(payment.userType)}
                  </Avatar>
                </Grid>
                <Grid item xs={12} sm={11}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6} md={3}>
                      <Typography variant="subtitle1" gutterBottom>
                        <strong>Kullanıcı:</strong> {getUserName(payment)}
                      </Typography>
                      <Typography variant="body2">
                        <strong>Email:</strong>{' '}
                        {payment.userId ? payment.userId.email : 'Bilinmiyor'}
                      </Typography>
                      <Typography variant="body2">
                        <strong>Bakiye:</strong> {getUserBalance(payment)}
                      </Typography>
                      <Typography variant="body2">
                        <strong>Şehir:</strong> {getUserCity(payment)}
                      </Typography>
                      <Typography variant="body2">
                        <strong>İlçe:</strong> {getUserDistrict(payment)}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                      <Typography variant="subtitle1" gutterBottom>
                        <strong>Paket Bilgisi</strong>
                      </Typography>
                      <Typography variant="body2">
                        <strong>Süre:</strong> {payment.paketSuresi} Ay
                      </Typography>
                      {payment.adet && payment.adet > 0 && (
                        <Typography variant="body2">
                          <strong>Adet:</strong> {payment.adet}
                        </Typography>
                      )}
                      <Typography variant="body2">
                        <strong>Toplam Tutar:</strong> {payment.toplamTutar} TL
                      </Typography>
                      <Typography variant="body2">
                        <strong>Abonelik Bitiş:</strong>{' '}
                        {getSubscriptionEndDate(payment)}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                      <Typography variant="subtitle1" gutterBottom>
                        <strong>Ödeme Detayları</strong>
                      </Typography>
                      <Typography variant="body2">
                        <strong>Satın Alma:</strong>{' '}
                        {new Date(payment.odemeTarihi).toLocaleDateString(
                          'tr-TR'
                        )}
                      </Typography>
                      <Typography variant="body2">
                        <strong>Kullanıcı Tipi:</strong>{' '}
                        {getUserTypeLabel(payment.userType)}
                      </Typography>
                      <Typography variant="body2">
                        <strong>Paket Tipi:</strong> {payment.packageType}
                      </Typography>
                      <Typography variant="body2">
                        <strong>Durum:</strong> {getStatusLabel(payment.status)}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                      <Typography variant="subtitle1" gutterBottom>
                        <strong>İyzico Bilgileri</strong>
                      </Typography>
                      <Typography variant="body2">
                        <strong>Ödeme ID:</strong>{' '}
                        {payment.iyzicoPaymentId || 'Bilinmiyor'}
                      </Typography>
                      <Typography variant="body2">
                        <strong>Durum:</strong>{' '}
                        {payment.iyzicoStatus || 'Bilinmiyor'}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </StyledPaper>
          ))
        ) : (
          <Typography variant="body1">
            Belirtilen kriterlere uygun ödeme bulunamadı.
          </Typography>
        )}
      </CardContent>

      <ResultDialog
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        title={dialogSeverity === 'error' ? 'Hata Oluştu' : 'İşlem Başarılı'}
        message={dialogContent}
        severity={dialogSeverity}
      />
    </Card>
  );
}

export default PaymentReports;
