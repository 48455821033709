// GameHeader.js

import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faHome } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import '../styles/nesneyiTakipEt.css';

const GameHeader = ({ handleReturnToPreviousStage }) => {
  const navigate = useNavigate();

  return (
    <div className="nesneyi-takip-et-header text-center">
      <button className="nesneyi-takip-et-button" onClick={() => navigate(-1)}>
        <FontAwesomeIcon icon={faArrowLeft} /> Geri Dön
      </button>
      <button className="nesneyi-takip-et-button" onClick={() => navigate('/')}>
        <FontAwesomeIcon icon={faHome} /> Ana Sayfa
      </button>
      <button className="nesneyi-takip-et-button" onClick={handleReturnToPreviousStage}>
        <FontAwesomeIcon icon={faArrowLeft} /> Önceki Sayfaya Dön
      </button>
    </div>
  );
};

export default GameHeader;
