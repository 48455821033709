// Kurumlar.js
import React, { useState, useEffect } from 'react';
import { Paper, Grid, Button } from '@mui/material';
import { API_URLS } from '../../../config/config';
import useFetchUserData from '../../../hooks/admin/useFetchUserData';

// Bileşenleri içe aktar
import KurumFormu from './bayi/componets/KurumFormu';
import KurumListesi from './bayi/componets/KurumListesi';
import DuzenlemeFormuDialog from './bayi/componets/DuzenlemeFormuDialog';
import BakiyeDialog from './bayi/componets/BakiyeDialog';
import ResultDialog from '../../../pages/components/Auth/Dialog';

const Kurumlar = () => {
  // State tanımları
  const [kurumListesi, setKurumListesi] = useState([]);
  const [sehirListesi, setSehirListesi] = useState([]);
  const [secilenSehirId, setSecilenSehirId] = useState('');
  const [secilenIlceId, setSecilenIlceId] = useState('');
  const [shouldFetch, setShouldFetch] = useState(true);
  const [userData, setUserData] = useState(null);
  const [kurumAdi, setKurumAdi] = useState('');
  const [telefon, setTelefon] = useState('');
  const [telefonHata, setTelefonHata] = useState(false);
  const [secilenKurumTurleri, setSecilenKurumTurleri] = useState([]);
  const [email, setEmail] = useState('');
  const [sifre, setSifre] = useState('');
  const [durum, setDurum] = useState('inactive');

  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogContent, setDialogContent] = useState('');
  const [dialogSeverity, setDialogSeverity] = useState('info');

  const [eklemeBasariliDialogOpen, setEklemeBasariliDialogOpen] = useState(false);
  const [silmeBasariliDialogOpen, setSilmeBasariliDialogOpen] = useState(false);

  const [duzenlemeDialogAcik, setDuzenlemeDialogAcik] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [balanceDialogOpen, setBalanceDialogOpen] = useState(false);
  const [selectedKurum, setSelectedKurum] = useState(null);
  const [balanceAmount, setBalanceAmount] = useState(0);
  const [balanceReason, setBalanceReason] = useState('');
  const [selectedPackageType, setSelectedPackageType] = useState('');
  const [currentPackageBalance, setCurrentPackageBalance] = useState(0);

  const [duzenlenenKurum, setDuzenlenenKurum] = useState({
    kurumAdi: '',
    email: '',
    telefon: '',
    sehirId: '',
    ilceId: '',
    kurumTuru: [],
    ilceler: [],
    status: '',
    password: '',
  });

  // Yeni filtreleme state değişkenleri
  const [filterStatus, setFilterStatus] = useState('');
  const [filterSehir, setFilterSehir] = useState('');
  const [filterKurumTuru, setFilterKurumTuru] = useState([]);

  useFetchUserData(setUserData);

  // Yardımcı Fonksiyonlar ve useEffect'ler

  const fetchIlceler = async (sehirId, seciliIlceId) => {
    try {
      const response = await fetch(`${API_URLS.ADMIN_URL}sehirler/ilceler/${sehirId}`);
      if (!response.ok) throw new Error('İlçeler yüklenirken bir hata oluştu.');

      const data = await response.json();
      const ilceler = data.ilceler ? data.ilceler : [];

      setDuzenlenenKurum((prev) => ({
        ...prev,
        ilceler: ilceler,
        ilceId: seciliIlceId && ilceler.some((ilce) => ilce._id === seciliIlceId) ? seciliIlceId : prev.ilceId,
      }));
    } catch (error) {
      console.error('İlçeleri yüklerken bir hata oluştu:', error);
    }
  };

  useEffect(() => {
    const fetchSehirler = async () => {
      if (!shouldFetch) return;

      try {
        const response = await fetch(API_URLS.ADMIN_URL + 'sehirler');
        if (!response.ok) {
          throw new Error('Veri çekme işlemi başarısız');
        }

        const sehirler = await response.json();

        setSehirListesi(sehirler);
      } catch (err) {
        console.error('Şehirler yüklenirken bir hata oluştu:', err.message);
      } finally {
        setShouldFetch(false);
      }
    };

    const fetchKurumlar = async () => {
      try {
        const response = await fetch(API_URLS.ADMIN_URL + 'kurumsal');
        if (!response.ok) throw new Error('Kurumlar yüklenirken bir hata oluştu.');
        const data = await response.json();

        const enhancedData = data.map((kurum) => {
          const sehir = sehirListesi.find((s) => s._id === kurum.sehir);
          const ilce = sehir?.ilceler.find((i) => i._id === kurum.ilce);

          return {
            ...kurum,
            sehirAdi: sehir ? sehir.sehir_adi : 'Bulunamadı',
            ilceAdi: ilce ? ilce.ilce_adi : 'Bulunamadı',
            status: kurum.status || 'inactive',
            bakiye: kurum.bakiye || 0,
            latestSubscription: kurum.latestSubscription || null,
          };
        });

        setKurumListesi(enhancedData);
      } catch (error) {
        console.error(error.message);
      }
    };

    fetchKurumlar();
    fetchSehirler();
  }, [shouldFetch, sehirListesi]);

  const handleSehirChange = (event) => {
    setSecilenSehirId(event.target.value);
  };

  const handleIlceChange = (event) => {
    setSecilenIlceId(event.target.value);
  };

  const handleTelefonChange = (event) => {
    const telefonRegex = /^0(\d{3}) (\d{3}) (\d{4})$/;
    let { value } = event.target;

    const numbers = value.replace(/\D/g, '');
    value =
      numbers.substring(0, 4) +
      (numbers.length > 4 ? ' ' : '') +
      numbers.substring(4, 7) +
      (numbers.length > 7 ? ' ' : '') +
      numbers.substring(7, 11);
    setTelefonHata(!telefonRegex.test(value) && value !== '');
    setTelefon(value);
  };

  const kurumTurleri = [
    { id: 'ilkokul', adi: 'İlkokul' },
    { id: 'ortaokul', adi: 'Ortaokul' },
    { id: 'lise', adi: 'Lise' },
    { id: 'kurs', adi: 'Kurs' },
  ];

  const secilenSehirinIlceleri = sehirListesi.find((sehir) => sehir._id === secilenSehirId)?.ilceler || [];

  const handleEkle = async () => {
    let eksikAlanlar = [];

    if (!kurumAdi) eksikAlanlar.push('Kurum Adı');
    if (!email) eksikAlanlar.push('Email');
    if (!sifre) eksikAlanlar.push('Şifre');
    if (!telefon) eksikAlanlar.push('Telefon');
    if (!secilenSehirId) eksikAlanlar.push('Şehir');
    if (!secilenIlceId) eksikAlanlar.push('İlçe');
    if (secilenKurumTurleri.length === 0) eksikAlanlar.push('Kurum Türleri');

    if (eksikAlanlar.length > 0) {
      setDialogSeverity('error');
      setDialogContent(`Lütfen ${eksikAlanlar.join(', ')} alanlarını doldurunuz.`);
      setEklemeBasariliDialogOpen(true);
      return;
    }

    if (telefonHata) {
      setDialogSeverity('error');
      setDialogContent('Lütfen geçerli bir telefon numarası giriniz.');
      setEklemeBasariliDialogOpen(true);
      return;
    }

    try {
      const response = await fetch(API_URLS.ADMIN_URL + 'kurumsal', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          kurum_adi: kurumAdi,
          email: email,
          password: sifre,
          password_open: sifre,
          telefon: telefon,
          sehir: secilenSehirId,
          ilce: secilenIlceId,
          kurum_turu: secilenKurumTurleri,
          adminId: userData?._id,
          status: durum,
        }),
      });

      const responseData = await response.json();

      if (response.ok) {
        const yeniKurum = {
          ...responseData,
          sehirAdi: sehirListesi.find((sehir) => sehir._id === secilenSehirId)?.sehir_adi || '',
          ilceAdi: secilenSehirinIlceleri.find((ilce) => ilce._id === secilenIlceId)?.ilce_adi || '',
        };

        setKurumListesi([...kurumListesi, yeniKurum]);

        setDialogSeverity('success');
        setDialogContent('Yeni kurum başarıyla eklendi.');
        setEklemeBasariliDialogOpen(true);

        setKurumAdi('');
        setEmail('');
        setSifre('');
        setTelefon('');
        setSecilenSehirId('');
        setSecilenIlceId('');
        setSecilenKurumTurleri([]);
        setDurum('inactive');
      } else {
        throw new Error(responseData.message || 'Bir hata oluştu');
      }
    } catch (err) {
      setDialogSeverity('error');
      setDialogContent(err.message);
      setEklemeBasariliDialogOpen(true);
    }
  };

  const handleSehirChangeDuzenle = (event) => {
    const yeniSehirId = event.target.value;
    setDuzenlenenKurum((prev) => ({ ...prev, sehirId: yeniSehirId }));
    const secilenSehir = sehirListesi.find((sehir) => sehir._id === yeniSehirId);
    const ilceler = secilenSehir ? secilenSehir.ilceler : [];
    setDuzenlenenKurum((prev) => ({ ...prev, ilceId: '', ilceler: ilceler }));
  };

  const handleIlceChangeDuzenle = (event) => {
    const yeniIlceId = event.target.value;
    setDuzenlenenKurum((prev) => ({ ...prev, ilceId: yeniIlceId }));
  };

  const handleEdit = (kurumId) => {
    const seciliKurum = kurumListesi.find((kurum) => kurum._id === kurumId);

    if (seciliKurum) {
      const sehirId = seciliKurum.sehir;

      setDuzenlenenKurum((prev) => ({
        ...prev,
        _id: seciliKurum._id,
        kurumAdi: seciliKurum.kurum_adi,
        email: seciliKurum.email,
        telefon: seciliKurum.telefon,
        sehirId: sehirId,
        ilceId: seciliKurum.ilce,
        kurumTuru: seciliKurum.kurum_turu,
        status: seciliKurum.status,
        password: '',
      }));
      fetchIlceler(sehirId);

      setDuzenlemeDialogAcik(true);
    } else {
      console.error('Seçilen kurum bulunamadı.');
    }
  };

  const handleGuncelle = async () => {
    let eksikAlanlar = [];

    if (!duzenlenenKurum.kurumAdi) eksikAlanlar.push('Kurum Adı');
    if (!duzenlenenKurum.email) eksikAlanlar.push('Email');
    if (!duzenlenenKurum.telefon) eksikAlanlar.push('Telefon');
    if (!duzenlenenKurum.sehirId) eksikAlanlar.push('Şehir');
    if (!duzenlenenKurum.ilceId) eksikAlanlar.push('İlçe');
    if (duzenlenenKurum.kurumTuru.length === 0) eksikAlanlar.push('Kurum Türleri');

    if (eksikAlanlar.length > 0) {
      setDialogSeverity('error');
      setDialogContent(`Lütfen ${eksikAlanlar.join(', ')} alanlarını doldurunuz.`);
      setDialogOpen(true);
      return;
    }

    try {
      const existingKurum = kurumListesi.find((k) => k._id === duzenlenenKurum._id);

      const updateData = {
        kurum_adi: duzenlenenKurum.kurumAdi,
        email: duzenlenenKurum.email,
        telefon: duzenlenenKurum.telefon,
        sehir: duzenlenenKurum.sehirId,
        ilce: duzenlenenKurum.ilceId,
        kurum_turu: duzenlenenKurum.kurumTuru,
        status: duzenlenenKurum.status,
      };

      if (duzenlenenKurum.password.trim() !== '') {
        updateData.password = duzenlenenKurum.password;
        updateData.password_open = duzenlenenKurum.password;
      }

      const response = await fetch(`${API_URLS.ADMIN_URL}kurumsal/${duzenlenenKurum._id}`, {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(updateData),
      });

      const data = await response.json();
      if (response.ok) {
        const guncellenmisKurum = {
          ...existingKurum,
          ...duzenlenenKurum,
          sehirAdi: sehirListesi.find((sehir) => sehir._id === duzenlenenKurum.sehirId)?.sehir_adi || '',
          ilceAdi:
            sehirListesi
              .find((sehir) => sehir._id === duzenlenenKurum.sehirId)
              ?.ilceler.find((ilce) => ilce._id === duzenlenenKurum.ilceId)?.ilce_adi || '',
        };

        setKurumListesi((prevList) =>
          prevList.map((kurum) => (kurum._id === duzenlenenKurum._id ? guncellenmisKurum : kurum))
        );

        setDialogSeverity('success');
        setDialogContent('Kurum başarıyla güncellendi.');
        setDialogOpen(true);
        setDuzenlemeDialogAcik(false);
      } else {
        throw new Error(data.message || 'Bir hata oluştu');
      }
    } catch (err) {
      setDialogSeverity('error');
      setDialogContent(err.message);
      setDialogOpen(true);
    }
  };

  const handleDelete = async (kurumId) => {
    const onay = window.confirm('Bu kurumu silmek istediğinize emin misiniz?');
    if (!onay) return;

    try {
      const response = await fetch(API_URLS.ADMIN_URL + `kurumsal/${kurumId}`, {
        method: 'DELETE',
      });

      const data = await response.json();

      if (!response.ok) {
        setDialogSeverity('error');
        setDialogContent(data.message || 'Kurum silinirken bir hata oluştu.');
        setSilmeBasariliDialogOpen(true);
      } else {
        setKurumListesi((prevList) => prevList.filter((kurum) => kurum._id !== kurumId));
        setDialogSeverity('success');
        setDialogContent(`${data.message}`);
        setSilmeBasariliDialogOpen(true);
      }
    } catch (err) {
      setDialogSeverity('error');
      setDialogContent('Hata: ' + err.message);
      setSilmeBasariliDialogOpen(true);
    }
  };

  const handleStatusChange = async (kurumId, currentStatus) => {
    const newStatus = currentStatus === 'active' ? 'inactive' : 'active';

    try {
      const response = await fetch(`${API_URLS.ADMIN_URL}kurumsal/${kurumId}/status`, {
        method: 'PUT',
        headers: { 
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('userToken')}`

         },
        body: JSON.stringify({ status: newStatus }),
      });

      if (!response.ok) throw new Error('Durum güncellenirken bir hata oluştu.');

      setKurumListesi((prevList) =>
        prevList.map((kurum) => (kurum._id === kurumId ? { ...kurum, status: newStatus } : kurum))
      );

      setDialogSeverity('success');
      setDialogContent('Durum başarıyla güncellendi.');
      setDialogOpen(true);
    } catch (error) {
      setDialogSeverity('error');
      setDialogContent(error.message);
      setDialogOpen(true);
    }
  };

  const handleAdjustBalance = (kurum) => {
    setSelectedKurum(kurum);
    setBalanceDialogOpen(true);
    setSelectedPackageType('');
    setBalanceAmount(0);
    setBalanceReason('');
    setCurrentPackageBalance(0);
  };

  const handleBalanceSubmit = async () => {
    if (!balanceReason) {
      setDialogSeverity('error');
      setDialogContent('Lütfen bir neden giriniz.');
      setDialogOpen(true);
      return;
    }
    if (selectedPackageType === '') {
      setDialogSeverity('error');
      setDialogContent('Lütfen bir paket seçiniz.');
      setDialogOpen(true);
      return;
    }

    const newPackageBalance = balanceAmount;
    const oldPackageBalance = currentPackageBalance;
    const difference = newPackageBalance - oldPackageBalance;

    try {
      const response = await fetch(`${API_URLS.ADMIN_URL}bakiye/balance-adjustment`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('userToken')}`,
        },
        body: JSON.stringify({
          userType: 'Institution',
          userId: selectedKurum._id,
          packageType: selectedPackageType,
          newBalance: newPackageBalance,
          reason: balanceReason,
        }),
      });

      const data = await response.json();

      if (response.ok) {
        setKurumListesi((prevList) =>
          prevList.map((kurum) => {
            if (kurum._id === selectedKurum._id) {
              const updatedKurum = { ...kurum };
              if (selectedPackageType == 3) {
                updatedKurum.bakiye3Ay = newPackageBalance;
              } else if (selectedPackageType == 6) {
                updatedKurum.bakiye6Ay = newPackageBalance;
              } else if (selectedPackageType == 12) {
                updatedKurum.bakiye12Ay = newPackageBalance;
              }
              updatedKurum.bakiye += difference;
              return updatedKurum;
            } else {
              return kurum;
            }
          })
        );

        setDialogSeverity('success');
        setDialogContent('Bakiye başarıyla güncellendi.');
        setDialogOpen(true);
        setBalanceDialogOpen(false);
        setBalanceAmount(0);
        setBalanceReason('');
        setSelectedPackageType('');
      } else {
        throw new Error(data.message || 'Bakiye güncellenirken bir hata oluştu.');
      }
    } catch (error) {
      setDialogSeverity('error');
      setDialogContent(error.message);
      setDialogOpen(true);
    }
  };

  // Filtrelenmiş Kurum Listesi
  const filteredKurumListesi = kurumListesi
    .filter((kurum) =>
      (kurum.kurum_adi && kurum.kurum_adi.toLowerCase().includes(searchQuery.toLowerCase())) ||
      (kurum.email && kurum.email.toLowerCase().includes(searchQuery.toLowerCase()))
    )
    .filter((kurum) => (filterStatus ? kurum.status === filterStatus : true))
    .filter((kurum) => (filterSehir ? kurum.sehir === filterSehir : true))
    .filter((kurum) =>
      filterKurumTuru.length > 0
        ? kurum.kurum_turu.some((turu) => filterKurumTuru.includes(turu))
        : true
    );

  return (
    <Paper style={{ padding: 20 }}>
      <Grid container spacing={3}>
        {/* Kurum Formu */}
        <KurumFormu
          sehirListesi={sehirListesi}
          secilenSehirId={secilenSehirId}
          handleSehirChange={handleSehirChange}
          secilenIlceId={secilenIlceId}
          handleIlceChange={handleIlceChange}
          secilenSehirinIlceleri={secilenSehirinIlceleri}
          kurumAdi={kurumAdi}
          setKurumAdi={setKurumAdi}
          telefon={telefon}
          handleTelefonChange={handleTelefonChange}
          telefonHata={telefonHata}
          secilenKurumTurleri={secilenKurumTurleri}
          setSecilenKurumTurleri={setSecilenKurumTurleri}
          durum={durum}
          setDurum={setDurum}
          email={email}
          setEmail={setEmail}
          sifre={sifre}
          setSifre={setSifre}
          handleEkle={handleEkle}
          kurumTurleri={kurumTurleri}
        />

        {/* Kurum Listesi */}
        <Grid item xs={12}>
          <KurumListesi
            filteredKurumListesi={filteredKurumListesi}
            searchQuery={searchQuery}
            setSearchQuery={setSearchQuery}
            handleStatusChange={handleStatusChange}
            handleAdjustBalance={handleAdjustBalance}
            handleEdit={handleEdit}
            handleDelete={handleDelete}
            filterStatus={filterStatus}
            setFilterStatus={setFilterStatus}
            filterSehir={filterSehir}
            setFilterSehir={setFilterSehir}
            filterKurumTuru={filterKurumTuru}
            setFilterKurumTuru={setFilterKurumTuru}
            sehirListesi={sehirListesi}
            kurumTurleri={kurumTurleri}
          />
        </Grid>
      </Grid>

      {/* Dialog Bileşenleri */}
      <DuzenlemeFormuDialog
        duzenlemeDialogAcik={duzenlemeDialogAcik}
        setDuzenlemeDialogAcik={setDuzenlemeDialogAcik}
        duzenlenenKurum={duzenlenenKurum}
        setDuzenlenenKurum={setDuzenlenenKurum}
        sehirListesi={sehirListesi}
        handleSehirChangeDuzenle={handleSehirChangeDuzenle}
        handleIlceChangeDuzenle={handleIlceChangeDuzenle}
        handleGuncelle={handleGuncelle}
        kurumTurleri={kurumTurleri}
      />

      <BakiyeDialog
        balanceDialogOpen={balanceDialogOpen}
        setBalanceDialogOpen={setBalanceDialogOpen}
        selectedKurum={selectedKurum}
        selectedPackageType={selectedPackageType}
        setSelectedPackageType={setSelectedPackageType}
        currentPackageBalance={currentPackageBalance}
        setCurrentPackageBalance={setCurrentPackageBalance}
        balanceAmount={balanceAmount}
        setBalanceAmount={setBalanceAmount}
        balanceReason={balanceReason}
        setBalanceReason={setBalanceReason}
        handleBalanceSubmit={handleBalanceSubmit}
      />

      {/* Sonuç Dialogları */}
      <ResultDialog
        open={eklemeBasariliDialogOpen}
        onClose={() => setEklemeBasariliDialogOpen(false)}
        title={dialogSeverity === 'error' ? 'Hata Oluştu' : 'İşlem Başarılı'}
        message={dialogContent}
        severity={dialogSeverity}
      />

      <ResultDialog
        open={silmeBasariliDialogOpen}
        onClose={() => setSilmeBasariliDialogOpen(false)}
        title={dialogSeverity === 'error' ? 'Hata Oluştu' : 'Silme İşlemi Başarılı'}
        message={dialogContent}
        severity={dialogSeverity}
      />

      <ResultDialog
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        title={dialogSeverity === 'error' ? 'Hata Oluştu' : 'İşlem Sonucu'}
        message={dialogContent}
        severity={dialogSeverity}
      />
    </Paper>
  );
};

export default Kurumlar;
