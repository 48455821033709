// StageList.js
import React, { useState, useEffect } from 'react';
import axios from '../../../utils/axiosInstance'; 
import { 
  Grid, 
  Card, 
  CardActionArea, 
  CardContent, 
  Typography, 
  Container, 
  Box, 
  Chip, 
  Avatar, 
  List, 
  ListItem, 
  ListItemIcon, 
  Divider, 
  Modal, 
  Backdrop, 
  Fade, 
  Button, 
  Dialog, 
  DialogActions, 
  DialogContent, 
  DialogContentText,
  DialogTitle, 
  Snackbar, 
  Alert
} from '@mui/material';
import {  
  CheckCircle, 
  Lock, 
  School, 
  Speed, 
  Assignment, 
  Timer, 
  TrendingUp, 
  Info as InfoIcon, 
  Delete as DeleteIcon
} from '@mui/icons-material';
import { styled } from '@mui/system';
import { useNavigate } from 'react-router-dom';
import useFetchStudentDataForHome from '../../../hooks/ogrenci/useFetchStudentDataForHome';
import { API_URLS } from '../../../config/config';

const StyledCard = styled(Card)(({ theme }) => ({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  borderRadius: '20px',
  transition: 'transform 0.3s, box-shadow 0.3s',
  position: 'relative',
  backgroundImage: 'linear-gradient(135deg, #FF9A9E 0%, #FAD0C4 100%)',
  color: '#FFFFFF',
  '&:hover': {
    transform: 'translateY(-8px)',
    boxShadow: theme.shadows[10],
  },
}));

const StyledContainer = styled(Container)(({ theme }) => ({
  paddingTop: theme.spacing(6),
  paddingBottom: theme.spacing(6),
  minHeight: '100vh',
  color: '#333333',
}));

const StageList = ({ onStageSelect }) => {
  const navigate = useNavigate();
  const [studentData, setStudentData] = useState(null);
  const [progressData, setProgressData] = useState(null);
  const [initialTestResult, setInitialTestResult] = useState(null);

  const [showWelcomeModal, setShowWelcomeModal] = useState(false); 

  useFetchStudentDataForHome(setStudentData);  

  const [openDeleteProgressDialog, setOpenDeleteProgressDialog] = useState(false);
  const [openDeleteTestDialog, setOpenDeleteTestDialog] = useState(false);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });

  useEffect(() => {
    const fetchInitialTest = async () => {
      try {
        const response = await axios.get(`${API_URLS.STUDENT_URL}akillikurs/test/initial-test`);
        setInitialTestResult(response.data.testResult);
        return true;
      } catch (error) {
        if (error.response && error.response.status === 404) {
          // Hız testi yoksa modalı göster
          setShowWelcomeModal(true);
        } else {
          console.error('Başlangıç testi alınırken hata oluştu:', error);
        }
        return false;
      }
    };

    const fetchProgress = async () => {
      try {
        const response = await axios.get(`${API_URLS.STUDENT_URL}akillikurs/progress`);
        setProgressData(response.data.progress);
      } catch (error) {
        console.error('İlerleme verisi alınırken hata oluştu:', error);
      }
    };

    const fetchData = async () => {
      // Token kontrolü yapmaya gerek yok, axiosInstance cookie üzerinden oturum bilgilerini gönderir.
      const initialTestSuccess = await fetchInitialTest();
      if (initialTestSuccess) {
        await fetchProgress();
      }

      const hasVisited = localStorage.getItem('hasVisitedStageList');
      if (!hasVisited) {
        setShowWelcomeModal(true);
        localStorage.setItem('hasVisitedStageList', 'true');
      }
    };

    fetchData();
  }, [navigate]);

  const calculateTargets = () => {
    if (!initialTestResult || !studentData) return [];

    const initialSpeed = initialTestResult.readingSpeed;
    const sinif = parseInt(studentData.sinif, 10);

    let multiplier;
    if (sinif >= 1 && sinif <= 4) {
      multiplier = 2.85;
    } else if (sinif >= 5 && sinif <= 8) {
      multiplier = 3.5;
    } else if (sinif >= 9 && sinif <= 12) {
      multiplier = 4.4;
    } else {
      multiplier = 5.6;
    }

    const result1 = initialSpeed * multiplier;
    const result2 = result1 - initialSpeed;
    const incrementPerStage = result2 / 21;
    const targets = [];

    for (let i = 1; i <= 21; i++) {
      const targetSpeed = initialSpeed + incrementPerStage * i;
      targets.push(Math.round(targetSpeed));
    }

    return targets;
  };

  const targets = calculateTargets();

  let firstCompletedReadingSpeed = null;
  if (progressData && progressData.stages) {
    for (let stageProgress of progressData.stages) {
      if (stageProgress.stageCompleted && stageProgress.testResult) {
        firstCompletedReadingSpeed = stageProgress.testResult.readingSpeed;
        break;
      }
    }
  }

  const stages = Array.from({ length: 21 }, (_, index) => {
    const stageNumber = index + 1;
    let unlocked = false;
    let completed = false;
    let testResult = null;
    let newTargetSpeed = null;

    if (progressData) {
      const stageProgress = progressData.stages.find(
        (stage) => stage.stageNumber === stageNumber
      );

      if (stageProgress) {
        completed = stageProgress.stageCompleted;
        testResult = stageProgress.testResult || null;
      }

      if (stageNumber === 1) {
        unlocked = true; 
      } else {
        const previousStage = progressData.stages.find(
          (stage) => stage.stageNumber === stageNumber - 1
        );
        const previousStageCompleted = previousStage ? previousStage.stageCompleted : false;
        unlocked = previousStageCompleted; 
      }
    } else {
      unlocked = stageNumber === 1;
    }

    let currentSpeed = null;
    if (completed && testResult) {
      currentSpeed = testResult.readingSpeed;
    } else if (firstCompletedReadingSpeed) {
      currentSpeed = firstCompletedReadingSpeed;
    } else if (initialTestResult) {
      currentSpeed = initialTestResult.readingSpeed;
    }

    if (currentSpeed !== null) {
      const result1 = currentSpeed * 3.5;
      const result2 = result1 - currentSpeed;
      const incrementPerStage = result2 / 21;
      newTargetSpeed = Math.round(currentSpeed + incrementPerStage * stageNumber);
    }

    return {
      number: stageNumber,
      title: `Aşama ${stageNumber}`,
      unlocked,
      completed,
      targetSpeed: targets[index] || null,
      testResult,
      newTargetSpeed,
    };
  });

  const handleStageClick = (stage) => {
    if (onStageSelect) {
      onStageSelect(stage);
    } else {
      navigate(`/exercises/${stage.number}`, { state: { stage } });
    }
  };

  const formatTime = (totalSeconds) => {
    const minutes = Math.floor(totalSeconds / 60);
    const seconds = totalSeconds % 60;
    return `${minutes} dakika ${seconds} saniye`;
  };

  const handleOpenDeleteProgressDialog = () => {
    setOpenDeleteProgressDialog(true);
  };

  const handleCloseDeleteProgressDialog = () => {
    setOpenDeleteProgressDialog(false);
  };

  const handleConfirmDeleteProgress = async () => {
    try {
      await axios.delete(`${API_URLS.STUDENT_URL}akillikurs/progress`);
      setSnackbar({ open: true, message: 'İlerleme başarıyla silindi.', severity: 'success' });
      setProgressData(null);
      setOpenDeleteProgressDialog(false);
    } catch (error) {
      console.error('İlerleme silinirken hata oluştu:', error);
      setSnackbar({ open: true, message: 'İlerleme silinirken bir hata oluştu.', severity: 'error' });
      setOpenDeleteProgressDialog(false);
    }
  };

  const handleOpenDeleteTestDialog = () => {
    setOpenDeleteTestDialog(true);
  };

  const handleCloseDeleteTestDialog = () => {
    setOpenDeleteTestDialog(false);
  };

  const handleConfirmDeleteTest = async () => {
    try {
      await axios.delete(`${API_URLS.STUDENT_URL}akillikurs/test/initial-test`);
      setSnackbar({ open: true, message: 'Test sonucu başarıyla silindi.', severity: 'success' });
      setInitialTestResult(null);
      setOpenDeleteTestDialog(false);
    } catch (error) {
      console.error('Test sonucu silinirken hata oluştu:', error);
      setSnackbar({ open: true, message: 'Test sonucu silinirken bir hata oluştu.', severity: 'error' });
      setOpenDeleteTestDialog(false);
    }
  };

  const handleCloseSnackbar = () => {
    setSnackbar({ ...snackbar, open: false });
  };

  const handleCloseWelcomeModal = () => {
    setShowWelcomeModal(false);
    if (!initialTestResult) {
      navigate('/seviye-tespit-sinav', { state: { initialTest: true } });
    }
  };
  
  return (
    <StyledContainer maxWidth="lg">
      <Box sx={{ textAlign: 'center', mb: 6 }}>
        <Typography
          variant="h3"
          component="h1"
          gutterBottom
          sx={{ fontFamily: `'Comic Neue', cursive`, color: '#2c3e50' }}
        >
          21 Günlük Eğitim Aşamaları
        </Typography>
        <Typography variant="subtitle1" sx={{ mb: 2, color: '#4a4a4a' }}>
          Her bir aşama için aşağıdaki kartlardan seçim yapabilirsiniz.
        </Typography>

        {/* Seviye test sonucunu burada gösterelim */}
        {initialTestResult && (
          <Typography variant="h5" sx={{ mb: 2, color: '#2c3e50' }}>
            Başlangıç Okuma Hızınız:{' '}
            <strong>{initialTestResult.readingSpeed} kelime/dakika</strong>
          </Typography>
        )}

        {(!studentData?.ogretmenId || studentData.ogretmenId.length === 0) && (
          <Box sx={{ mt: 4, display: 'flex', justifyContent: 'center' }}>
            <Card
              sx={{
                backgroundImage: 'linear-gradient(135deg, #FF9A9E 0%, #FAD0C4 100%)',
                boxShadow: 4,
                borderRadius: 3,
                p: 3,
                maxWidth: 600,
                width: '100%',
              }}
            >
              <Typography variant="h6" sx={{ mb: 3, color: '#333' }}>
                İlerleme Verilerinizi Yönetebilirsiniz:
              </Typography>
              <Box sx={{ display: 'flex', justifyContent: 'center', gap: 3, flexWrap: 'wrap' }}>
                <Button
                  variant="contained"
                  color="error"
                  onClick={handleOpenDeleteProgressDialog}
                  startIcon={<DeleteIcon />}
                  sx={{
                    fontWeight: 'bold',
                    textTransform: 'none',
                    px: 4,
                    py: 1.5,
                    fontSize: '1rem',
                    borderRadius: 2.5,
                    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
                    transition: 'transform 0.3s, box-shadow 0.3s',
                    '&:hover': {
                      transform: 'scale(1.05)',
                      boxShadow: '0 8px 16px rgba(0, 0, 0, 0.3)',
                    },
                  }}
                >
                  Tüm Aşamaları Sil
                </Button>
                <Button
                  variant="contained"
                  color="warning"
                  onClick={handleOpenDeleteTestDialog}
                  disabled={!initialTestResult}
                  startIcon={<DeleteIcon />}
                  sx={{
                    fontWeight: 'bold',
                    textTransform: 'none',
                    px: 4,
                    py: 1.5,
                    fontSize: '1rem',
                    borderRadius: 2.5,
                    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
                    transition: 'transform 0.3s, box-shadow 0.3s',
                    '&:hover': {
                      transform: 'scale(1.05)',
                      boxShadow: '0 8px 16px rgba(0, 0, 0, 0.3)',
                    },
                  }}
                >
                  İlk Okuma Hızı Testini Sil
                </Button>
              </Box>
            </Card>
          </Box>
        )}
      </Box>

      <Grid container spacing={4}>
        {stages.map((stage) => (
          <Grid item xs={12} sm={6} md={4} key={stage.number}>
            <StyledCard
              sx={{
                opacity: stage.unlocked ? 1 : 0.6,
                pointerEvents: stage.unlocked ? 'auto' : 'none',
                backgroundImage: stage.completed
                  ? 'linear-gradient(135deg, #FFC371 0%, #FF5F6D 100%)' // Tamamlandı
                  : stage.unlocked
                  ? 'linear-gradient(135deg, #8BC6EC 0%, #9599E2 100%)' // Açık
                  : 'linear-gradient(135deg, #B06AB3 0%, #4568DC 100%)', // Kilitli
              }}
            >
              {stage.unlocked ? (
                <CardActionArea
                  onClick={() => handleStageClick(stage)}
                  sx={{ flexGrow: 1 }}
                >
                  <CardContent>
                    {/* Başlık ve İkon */}
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        mb: 3,
                      }}
                    >
                      <Avatar
                        sx={{
                          bgcolor: 'transparent',
                          mt: 1,
                        }}
                      >
                        {stage.completed ? (
                          <CheckCircle sx={{ color: '#198754', fontSize: 40 }} />
                        ) : (
                          <School sx={{ color: '#FFFFFF', fontSize: 40 }} />
                        )}
                      </Avatar>
                      <Typography
                        variant="h5"
                        component="div"
                        sx={{
                          fontFamily: `'Comic Neue', cursive`,
                          color: '#FFFFFF',
                          mt: 1,
                          textAlign: 'center',
                        }}
                      >
                        {stage.title}
                      </Typography>
                    </Box>
                    {/* Bilgi Bölümleri */}
                    <Box sx={{ mb: 2 }}>
                      <List>
                        {/* Başlangıçtaki Hedef Okuma Hızı */}
                        <ListItem
                          sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            paddingLeft: 0,
                            paddingRight: 0,
                          }}
                        >
                          <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <ListItemIcon sx={{ minWidth: '40px', color: '#FFFFFF' }}>
                              <Speed />
                            </ListItemIcon>
                            <Typography
                              variant="body1"
                              sx={{
                                color: '#FFFFFF',
                                fontWeight: 'bold',
                                textAlign: 'center',
                              }}
                            >
                              Başlangıçtaki hedef okuma hızınız:
                            </Typography>
                          </Box>
                          <Divider
                            sx={{
                              width: '100%',
                              my: 1,
                              backgroundColor: 'rgba(255, 255, 255, 0.3)',
                            }}
                          />
                          <Chip
                            label={`${stage.targetSpeed} kelime/dakika`}
                            color="primary"
                            sx={{
                              backgroundColor: 'rgba(255, 255, 255, 0.3)',
                              color: '#FFFFFF',
                              alignSelf: 'center',
                            }}
                          />
                        </ListItem>
                        {/* Test Sonuçları */}
                        {stage.testResult && (
                          <>
                            {/* Okuduğunuz Kelime Sayısı */}
                            <ListItem
                              sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                paddingLeft: 0,
                                paddingRight: 0,
                              }}
                            >
                              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                <ListItemIcon sx={{ minWidth: '40px', color: '#FFFFFF' }}>
                                  <Assignment />
                                </ListItemIcon>
                                <Typography
                                  variant="body1"
                                  sx={{
                                    color: '#FFFFFF',
                                    fontWeight: 'bold',
                                    textAlign: 'center',
                                  }}
                                >
                                  Okuduğunuz kelime sayısı:
                                </Typography>
                              </Box>
                              <Divider
                                sx={{
                                  width: '100%',
                                  my: 1,
                                  backgroundColor: 'rgba(255, 255, 255, 0.3)',
                                }}
                              />
                              <Chip
                                label={stage.testResult.wordCount}
                                color="success"
                                sx={{
                                  backgroundColor: 'rgba(255, 255, 255, 0.3)',
                                  color: '#FFFFFF',
                                  alignSelf: 'center',
                                }}
                              />
                            </ListItem>

                            {/* Süre */}
                            <ListItem
                              sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                paddingLeft: 0,
                                paddingRight: 0,
                              }}
                            >
                              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                <ListItemIcon sx={{ minWidth: '40px', color: '#FFFFFF' }}>
                                  <Timer />
                                </ListItemIcon>
                                <Typography
                                  variant="body1"
                                  sx={{
                                    color: '#FFFFFF',
                                    fontWeight: 'bold',
                                    textAlign: 'center',
                                  }}
                                >
                                  Süre:
                                </Typography>
                              </Box>
                              <Divider
                                sx={{
                                  width: '100%',
                                  my: 1,
                                  backgroundColor: 'rgba(255, 255, 255, 0.3)',
                                }}
                              />
                              <Chip
                                label={formatTime(stage.testResult.totalTime)}
                                color="info"
                                sx={{
                                  backgroundColor: 'rgba(255, 255, 255, 0.3)',
                                  color: '#FFFFFF',
                                  alignSelf: 'center',
                                }}
                              />
                            </ListItem>

                            {/* Okuma Hızı */}
                            <ListItem
                              sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                paddingLeft: 0,
                                paddingRight: 0,
                              }}
                            >
                              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                <ListItemIcon sx={{ minWidth: '40px', color: '#FFFFFF' }}>
                                  <Speed />
                                </ListItemIcon>
                                <Typography
                                  variant="body1"
                                  sx={{
                                    color: '#FFFFFF',
                                    fontWeight: 'bold',
                                    textAlign: 'center',
                                  }}
                                >
                                  Okuma hızınız:
                                </Typography>
                              </Box>
                              <Divider
                                sx={{
                                  width: '100%',
                                  my: 1,
                                  backgroundColor: 'rgba(255, 255, 255, 0.3)',
                                }}
                              />
                              <Chip
                                label={`${stage.testResult.readingSpeed} kelime/dakika`}
                                color="warning"
                                sx={{
                                  backgroundColor: 'rgba(255, 255, 255, 0.3)',
                                  color: '#FFFFFF',
                                  alignSelf: 'center',
                                }}
                              />
                            </ListItem>
                          </>
                        )}

                        {/* Yeni Aşamadaki Hedef Okuma Hızı */}
                        {stage.newTargetSpeed && (
                          <ListItem
                            sx={{
                              display: 'flex',
                              flexDirection: 'column',
                              alignItems: 'center',
                              paddingLeft: 0,
                              paddingRight: 0,
                            }}
                          >
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                              <ListItemIcon sx={{ minWidth: '40px', color: '#FFFFFF' }}>
                                <TrendingUp />
                              </ListItemIcon>
                              <Typography
                                variant="body1"
                                sx={{
                                  color: '#FFFFFF',
                                  fontWeight: 'bold',
                                  textAlign: 'center',
                                }}
                              >
                                Yeni aşamadaki okuma hızı hedefiniz bu olmalı:
                              </Typography>
                            </Box>
                            <Divider
                              sx={{
                                width: '100%',
                                my: 1,
                                backgroundColor: 'rgba(255, 255, 255, 0.3)',
                              }}
                            />
                            <Chip
                              label={`${stage.newTargetSpeed} kelime/dakika`}
                              color="secondary"
                              sx={{
                                backgroundColor: 'rgba(255, 255, 255, 0.3)',
                                color: '#FFFFFF',
                                alignSelf: 'center',
                              }}
                            />
                          </ListItem>
                        )}
                      </List>
                    </Box>
                  </CardContent>
                  {/* Tamamlandı Chip'i */}
                  {stage.completed && (
                    <Box sx={{ p: 2, width: '100%', display: 'flex', justifyContent: 'center' }}>
                      <Chip
                        label="Tamamlandı"
                        icon={<CheckCircle />}
                        sx={{
                          fontWeight: 'bold',
                          color: '#FFFFFF',
                          backgroundImage: 'linear-gradient(135deg, #4caf50 0%, #81c784 100%)',
                          width: '100%',
                          maxWidth: '400px',
                          fontSize: '1.2rem',
                          height: '48px',
                          padding: '0 16px',
                          borderRadius: '8px',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          textTransform: 'none',
                          boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                          transition: 'transform 0.3s, box-shadow 0.3s',
                          '&:hover': {
                            transform: 'scale(1.05)',
                            boxShadow: '0 6px 12px rgba(0, 0, 0, 0.2)',
                          },
                        }}
                      />
                    </Box>
                  )}
                </CardActionArea>
              ) : (
                <CardContent sx={{ flexGrow: 1 }}>
                  {/* Başlık ve Kilit İkonu */}
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      mb: 3,
                    }}
                  >
                    <Typography
                      variant="h4"
                      component="div"
                      sx={{
                        fontFamily: `'Comic Neue', cursive`,
                        color: '#FFFFFF',
                        fontWeight: 'bold',
                      }}
                    >
                      {stage.number}
                    </Typography>
                    <Avatar
                      sx={{
                        bgcolor: 'transparent',
                        mt: 1,
                      }}
                    >
                      <Lock sx={{ color: '#FFFFFF', fontSize: 40 }} />
                    </Avatar>
                    <Typography
                      variant="h5"
                      component="div"
                      sx={{
                        fontFamily: `'Comic Neue', cursive`,
                        color: '#FFFFFF',
                        mt: 1,
                        textAlign: 'center',
                      }}
                    >
                      {stage.title}
                    </Typography>
                  </Box>

                  {/* Bilgi Bölümleri */}
                  <Box sx={{ mb: 2 }}>
                    <Grid container spacing={1}>
                      <Grid item xs={12}>
                        <ListItem
                          sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            paddingLeft: 0,
                            paddingRight: 0,
                          }}
                        >
                          <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <ListItemIcon sx={{ minWidth: '40px', color: '#FFFFFF' }}>
                              <Speed />
                            </ListItemIcon>
                            <Typography
                              variant="body1"
                              sx={{
                                color: '#FFFFFF',
                                fontWeight: 'bold',
                                textAlign: 'center',
                              }}
                            >
                              Bu aşamadaki hedef okuma hızınız:
                            </Typography>
                          </Box>
                          <Divider
                            sx={{
                              width: '100%',
                              my: 1,
                              backgroundColor: 'rgba(255, 255, 255, 0.3)',
                            }}
                          />
                          <Chip
                            label={`${stage.targetSpeed} kelime/dakika`}
                            color="primary"
                            sx={{
                              backgroundColor: 'rgba(255, 255, 255, 0.3)',
                              color: '#FFFFFF',
                              alignSelf: 'center',
                            }}
                          />
                        </ListItem>
                      </Grid>

                      <Grid item xs={12}>
                        <ListItem
                          sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            paddingLeft: 0,
                            paddingRight: 0,
                          }}
                        >
                          <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <ListItemIcon sx={{ minWidth: '40px', color: '#FFFFFF' }}>
                              <TrendingUp />
                            </ListItemIcon>
                            <Typography
                              variant="body1"
                              sx={{
                                color: '#FFFFFF',
                                fontWeight: 'bold',
                                textAlign: 'center',
                              }}
                            >
                              Yeni aşamadaki okuma hızı hedefiniz bu olmalı:
                            </Typography>
                          </Box>
                          <Divider
                            sx={{
                              width: '100%',
                              my: 1,
                              backgroundColor: 'rgba(255, 255, 255, 0.3)',
                            }}
                          />
                          <Chip
                            label={`${stage.newTargetSpeed} kelime/dakika`}
                            color="secondary"
                            sx={{
                              backgroundColor: 'rgba(255, 255, 255, 0.3)',
                              color: '#FFFFFF',
                              alignSelf: 'center',
                            }}
                          />
                        </ListItem>
                      </Grid>

                      <Grid item xs={12}>
                        <Typography variant="body1" sx={{ color: '#FFFFFF', textAlign: 'center' }}>
                          Bu aşama henüz kilitli. Devam etmek için önceki aşamayı tamamlayın.
                        </Typography>
                      </Grid>
                    </Grid>
                  </Box>
                </CardContent>
              )}
            </StyledCard>
          </Grid>
        ))}
      </Grid>

      {/* Tüm aşamalar tamamlanmışsa sertifika butonunu göster */}
      {progressData && progressData.stages && progressData.stages.length === 21 && 
       progressData.stages.every(s => s.stageCompleted) && (
        <Box sx={{ textAlign: 'center', mt: 4 }}>
          <Button 
            variant="contained" 
            color="success"
            onClick={() => navigate('/ogrenci-sertifika')}
            sx={{ 
              fontWeight: 'bold', 
              fontSize: '1rem', 
              px: 4, 
              py: 1.5 
            }}
          >
            Sertifikayı Görüntüle
          </Button>
        </Box>
      )}

      {/* İlerlemeyi Silme Onay Dialog'ı */}
      <Dialog
        open={openDeleteProgressDialog}
        onClose={handleCloseDeleteProgressDialog}
        aria-labelledby="delete-progress-dialog-title"
        aria-describedby="delete-progress-dialog-description"
      >
        <DialogTitle id="delete-progress-dialog-title">Tüm Aşamaları Sil</DialogTitle>
        <DialogContent>
          <DialogContentText id="delete-progress-dialog-description">
            Tüm ilerleme verilerinizi silmek istediğinizden emin misiniz? Bu işlem geri alınamaz.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDeleteProgressDialog}>İptal</Button>
          <Button onClick={handleConfirmDeleteProgress} color="error">Sil</Button>
        </DialogActions>
      </Dialog>

      {/* İlk Test Sonucunu Silme Onay Dialog'ı */}
      <Dialog
        open={openDeleteTestDialog}
        onClose={handleCloseDeleteTestDialog}
        aria-labelledby="delete-test-dialog-title"
        aria-describedby="delete-test-dialog-description"
      >
        <DialogTitle id="delete-test-dialog-title">İlk Okuma Hızı Testini Sil</DialogTitle>
        <DialogContent>
          <DialogContentText id="delete-test-dialog-description">
            İlk okuma hızı test sonucunuzu silmek istediğinizden emin misiniz? Bu işlem geri alınamaz.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDeleteTestDialog}>İptal</Button>
          <Button onClick={handleConfirmDeleteTest} color="warning">Sil</Button>
        </DialogActions>
      </Dialog>

      {/* Bildirim (Snackbar) */}
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert onClose={handleCloseSnackbar} severity={snackbar.severity} sx={{ width: '100%' }}>
          {snackbar.message}
        </Alert>
      </Snackbar>

      {/* Hoş Geldiniz Modal'ı */}
      <Modal
        open={showWelcomeModal}
        onClose={handleCloseWelcomeModal}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={showWelcomeModal}>
          <Box
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: { xs: '80%', md: '50%' },
              bgcolor: 'rgba(30, 30, 30, 0.95)',
              borderRadius: 4,
              boxShadow: 24,
              p: 4,
              color: '#FFFFFF',
              border: '2px solid #4caf50',
              backdropFilter: 'blur(5px)',
            }}
          >
            {/* Başlık ve İkon */}
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
              <InfoIcon sx={{ mr: 1, fontSize: '2rem', color: '#4caf50' }} />
              <Typography variant="h4" sx={{ fontWeight: 'bold' }}>
                Hoş Geldiniz!
              </Typography>
            </Box>

            {/* Eğer hız testi yapılmadıysa uyarı mesajı ekleyin */}
            {!initialTestResult && (
              <Typography variant="body1" sx={{ mb: 2, fontSize: '1.1rem' }}>
                Öncelikle hız testinizi yapmalısınız.
              </Typography>
            )}

            {/* Talimatlar */}
            <Typography variant="body1" sx={{ mb: 1, fontSize: '1.1rem' }}>
              - <strong>Hızlı okuma testi</strong> yapınız.
            </Typography>
            <Typography variant="body1" sx={{ mb: 1, fontSize: '1.1rem' }}>
              - <strong>Akıllı kurs</strong> işlemlerine devam ediniz.
            </Typography>
            <Typography variant="body1" sx={{ mb: 1, fontSize: '1.1rem' }}>
              - Akıllı kurs otomatik olarak aşama aşama hedeflerinizi belirleyecektir.
            </Typography>
            <Typography variant="body1" sx={{ mb: 1, fontSize: '1.1rem' }}>
              - <strong>Raporlar</strong> bölümünden ilerlemelerinizi detaylı görebilirsiniz.
            </Typography>
            <Typography variant="body1" sx={{ mb: 1, fontSize: '1.1rem' }}>
              - 21 günün sonunda <strong>sertifikanız</strong> düzenlenecektir.
            </Typography>

            {/* Tamam Butonu */}
            <Box sx={{ mt: 4, textAlign: 'center' }}>
              <Button
                variant="contained"
                color="success"
                onClick={handleCloseWelcomeModal}
                sx={{
                  px: 4,
                  py: 1.5,
                  fontSize: '1rem',
                  borderRadius: 2,
                  textTransform: 'none',
                  boxShadow: '0 4px 10px rgba(0, 0, 0, 0.2)',
                  transition: 'background-color 0.3s, transform 0.3s',
                  '&:hover': {
                    backgroundColor: '#388e3c',
                    transform: 'scale(1.05)',
                    boxShadow: '0 6px 14px rgba(0, 0, 0, 0.3)',
                  },
                }}
              >
                Tamam
              </Button>
            </Box>
          </Box>
        </Fade>
      </Modal>
    </StyledContainer>
  );
};

export default StageList;
