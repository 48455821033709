// Ogrenci.js
import React, { useState, useEffect } from 'react';
import { Paper, Grid, Typography } from '@mui/material';
import { API_URLS } from '../../../config/config';
import ResultDialog from '../../../pages/components/Auth/Dialog';
import useFetchSehirler from './hooks/useFetchSehirler';

// Yeni oluşturulan bileşenleri import ediyoruz
import StudentForm from './components/StudentForm';
import StudentTable from './components/StudentTable';   // Collapsible versiyonla değiştireceğiz
import EditStudentDialog from './components/EditStudentDialog';
import ConfirmDeleteDialog from './components/ConfirmDeleteDialog';

const Ogrenci = () => {
  // State’ler
  const [ogrenciListesi, setOgrenciListesi] = useState([]);
  const [sehirListesi, setSehirListesi] = useState([]);
  const [secilenSehirId, setSecilenSehirId] = useState('');
  const [secilenIlceId, setSecilenIlceId] = useState('');
  const [shouldFetch, setShouldFetch] = useState(true);

  // Form alanları
  const [ad, setAd] = useState('');
  const [soyad, setSoyad] = useState('');
  const [okulAdi, setOkulAdi] = useState('');
  const [telefon, setTelefon] = useState('');
  const [telefonHata, setTelefonHata] = useState(false);
  const [email, setEmail] = useState('');
  const [sifre, setSifre] = useState('');
  const [sinif, setSinif] = useState('');
  const [yas, setYas] = useState('');
  const [durum, setDurum] = useState('inactive');

  // Dialog
  const [dialogContent, setDialogContent] = useState('');
  const [dialogSeverity, setDialogSeverity] = useState('info');
  const [dialogOpen, setDialogOpen] = useState(false);
  const [resultDialogOpen, setResultDialogOpen] = useState(false);
  const [resultDialogMessage, setResultDialogMessage] = useState('');
  const [resultDialogSeverity, setResultDialogSeverity] = useState('info');
  const [eklemeBasariliDialogOpen, setEklemeBasariliDialogOpen] = useState(false);

  // Düzenleme
  const [duzenlemeDialogAcik, setDuzenlemeDialogAcik] = useState(false);
  const [duzenlenenOgrenci, setDuzenlenenOgrenci] = useState({
    ad: '',
    soyad: '',
    okulAdi: '',
    email: '',
    telefon: '',
    sehirId: '',
    ilceId: '',
    ilceler: [],
    sifre: '',
  });

  // Silme onay
  const [confirmDeleteDialogOpen, setConfirmDeleteDialogOpen] = useState(false);
  const [studentToDelete, setStudentToDelete] = useState(null);

  // Listeyi tekrar çekmek için
  const [shouldFetchList, setShouldFetchList] = useState(false);

  // Arama ve filtreler
  const [searchTerm, setSearchTerm] = useState('');
  const [statusFilter, setStatusFilter] = useState('');
  const [cityFilter, setCityFilter] = useState('');
  const [ilceFilter, setIlceFilter] = useState('');
  const [ekleyenFilter, setEkleyenFilter] = useState('');

  // ATAMA İÇİN ek listeler
  const [okulListesi, setOkulListesi] = useState([]);
  const [kurumListesi, setKurumListesi] = useState([]);
  const [ogretmenListesi, setOgretmenListesi] = useState([]);

  // Şehirleri çekmek için custom hook
  useFetchSehirler(setSehirListesi, shouldFetch, setShouldFetch);

  // Şehir/ilçe seçicileri
  const handleSehirChange = (e) => {
    setSecilenSehirId(e.target.value);
    setSecilenIlceId('');
  };
  const handleIlceChange = (e) => {
    setSecilenIlceId(e.target.value);
  };

  // Telefon maskeleme
  const handleTelefonChange = (e) => {
    const telefonRegex = /^0(\d{3}) (\d{3}) (\d{4})$/;
    let { value } = e.target;
    const numbers = value.replace(/\D/g, '');

    value =
      numbers.substring(0, 4) +
      (numbers.length > 4 ? ' ' : '') +
      numbers.substring(4, 7) +
      (numbers.length > 7 ? ' ' : '') +
      numbers.substring(7, 11);

    setTelefonHata(!telefonRegex.test(value) && value !== '');
    setTelefon(value);
  };

  // Düzenleme formu - şehir
  const handleSehirChangeDuzenle = (event) => {
    const yeniSehirId = event.target.value;
    setDuzenlenenOgrenci((prev) => ({ ...prev, sehirId: yeniSehirId }));
    const secilenSehir = sehirListesi.find((s) => s._id === yeniSehirId);
    const ilceler = secilenSehir ? secilenSehir.ilceler : [];
    setDuzenlenenOgrenci((prev) => ({ ...prev, ilceId: '', ilceler }));
  };

  // Düzenleme formu - ilçe
  const handleIlceChangeDuzenle = (event) => {
    const yeniIlceId = event.target.value;
    setDuzenlenenOgrenci((prev) => ({ ...prev, ilceId: yeniIlceId }));
  };

  // Yeni kayıt formu için seçili şehrin ilçeleri
  const secilenSehirinIlceleri =
    sehirListesi.find((s) => s._id === secilenSehirId)?.ilceler || [];

  // Öğrenci listesini çek
  useEffect(() => {
    const fetchOgrenciListesi = async () => {
      try {
        const response = await fetch(`${API_URLS.ADMIN_URL}student`);
        if (!response.ok) {
          throw new Error('Öğrenci listesi yüklenirken bir hata oluştu.');
        }
        const data = await response.json();

        // Şehir/İlçe adı ekle
        const enhancedData = data.map((ogrenci) => {
          const sehir = sehirListesi.find((s) => s._id === ogrenci.sehir);
          const ilce = sehir?.ilceler?.find((i) => i._id === ogrenci.ilce);
          return {
            ...ogrenci,
            sehirAdi: sehir ? sehir.sehir_adi : 'Bulunamadı',
            ilceAdi: ilce ? ilce.ilce_adi : 'Bulunamadı',
            sinif: ogrenci.sinif?.toString() || '',
            yas: ogrenci.yas?.toString() || '',
            status: ogrenci.status || 'inactive',
            abonelik: ogrenci.abonelik || {},
            ekleyenAdi: ogrenci.ekleyenAdi || '',
          };
        });

        setOgrenciListesi(enhancedData);
      } catch (error) {
        console.error(error);
      } finally {
        setShouldFetchList(false);
      }
    };

    fetchOgrenciListesi();
  }, [shouldFetchList, sehirListesi]);

  // Okul / Kurum / Öğretmen listelerini çek (Atama için)
  useEffect(() => {
    // Okul
    fetch(`${API_URLS.ADMIN_URL}schools`)
      .then((res) => res.json())
      .then((data) => setOkulListesi(data))
      .catch((err) => console.error(err));

    // Kurum
    fetch(`${API_URLS.ADMIN_URL}kurumsal`)
      .then((res) => res.json())
      .then((data) => setKurumListesi(data))
      .catch((err) => console.error(err));

    // Öğretmen
    fetch(`${API_URLS.ADMIN_URL}teachers`)
      .then((res) => res.json())
      .then((data) => setOgretmenListesi(data))
      .catch((err) => console.error(err));
  }, []);

  // Dialog kapat
  const handleDialogClose = () => {
    setDialogOpen(false);
    setResultDialogOpen(false);
  };

  // Yeni öğrenci ekle
  const handleEkle = async () => {
    let eksikAlanlar = [];
    if (!ad) eksikAlanlar.push('Adı');
    if (!soyad) eksikAlanlar.push('Soyadı');
    if (!email) eksikAlanlar.push('Email');
    if (!sifre) eksikAlanlar.push('Şifre');
    if (!telefon) eksikAlanlar.push('Telefon');
    if (!secilenSehirId) eksikAlanlar.push('Şehir');
    if (!secilenIlceId) eksikAlanlar.push('İlçe');
    if (!sinif) eksikAlanlar.push('Sınıf');
    if (!yas) eksikAlanlar.push('Yaş');

    if (eksikAlanlar.length > 0) {
      setDialogSeverity('error');
      setDialogContent(`Lütfen ${eksikAlanlar.join(', ')} alanlarını doldurunuz.`);
      setEklemeBasariliDialogOpen(true);
      return;
    }

    if (telefonHata) {
      setDialogSeverity('error');
      setDialogContent('Lütfen geçerli bir telefon numarası giriniz.');
      setEklemeBasariliDialogOpen(true);
      return;
    }

    try {
      const response = await fetch(API_URLS.ADMIN_URL + 'student', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          ad,
          soyad,
          email,
          password: sifre,
          password_open: sifre,
          okul_adi: okulAdi,
          telefon,
          sehir: secilenSehirId,
          ilce: secilenIlceId,
          sinif,
          yas,
          status: durum,
        }),
      });

      const responseData = await response.json();
      if (response.ok) {
        setOgrenciListesi((prev) => [...prev, responseData]);

        setDialogSeverity('success');
        setDialogContent('Yeni Öğrenci başarıyla eklendi.');
        setEklemeBasariliDialogOpen(true);

        // Form sıfırla
        setAd('');
        setSoyad('');
        setOkulAdi('');
        setEmail('');
        setSifre('');
        setTelefon('');
        setSecilenSehirId('');
        setSecilenIlceId('');
        setSinif('');
        setYas('');
      } else {
        throw new Error(responseData.message || 'Bir hata oluştu');
      }
    } catch (err) {
      setDialogSeverity('error');
      setDialogContent(err.message);
      setEklemeBasariliDialogOpen(true);
    }
  };

  // Düzenleme dialogu aç
  const handleEdit = (ogrenciId) => {
    const seciliOgrenci = ogrenciListesi.find((ogr) => ogr._id === ogrenciId);
    if (!seciliOgrenci) return console.error('Seçilen öğrenci bulunamadı.');

    const sehirId = seciliOgrenci.sehir;
    setDuzenlenenOgrenci((prev) => ({
      ...prev,
      _id: seciliOgrenci._id,
      ad: seciliOgrenci.ad,
      soyad: seciliOgrenci.soyad,
      okulAdi: seciliOgrenci.okul_adi,
      email: seciliOgrenci.email,
      telefon: seciliOgrenci.telefon,
      sehirId,
      ilceId: seciliOgrenci.ilce,
      sinif: seciliOgrenci.sinif,
      yas: seciliOgrenci.yas,
      sifre: '',
    }));
    fetchIlceler(sehirId);
    setDuzenlemeDialogAcik(true);
  };

  // İlçeleri çek (düzenleme)
  const fetchIlceler = async (sehirId, seciliIlceId) => {
    try {
      const response = await fetch(`${API_URLS.ADMIN_URL}sehirler/ilceler/${sehirId}`);
      if (!response.ok) throw new Error('İlçeler yüklenirken bir hata oluştu.');
      const data = await response.json();

      const ilceler = data.ilceler || [];
      setDuzenlenenOgrenci((prev) => ({
        ...prev,
        ilceler,
        ilceId:
          seciliIlceId && ilceler.some((ilce) => ilce._id === seciliIlceId)
            ? seciliIlceId
            : prev.ilceId,
      }));
    } catch (error) {
      console.error('İlçeleri yüklerken bir hata oluştu:', error);
    }
  };

  // Öğrenci güncelle
  const handleGuncelle = async () => {
    const { _id, ad, soyad, okulAdi, email, telefon, sehirId, ilceId, sinif, yas, sifre } =
      duzenlenenOgrenci;

    let eksikAlanlar = [];
    if (!ad) eksikAlanlar.push('Adı');
    if (!soyad) eksikAlanlar.push('Soyadı');
    if (!email) eksikAlanlar.push('Email');
    if (!telefon) eksikAlanlar.push('Telefon');
    if (!sehirId) eksikAlanlar.push('Şehir');
    if (!ilceId) eksikAlanlar.push('İlçe');

    if (eksikAlanlar.length > 0) {
      setDialogSeverity('error');
      setDialogContent(`Lütfen ${eksikAlanlar.join(', ')} alanlarını doldurunuz.`);
      setDialogOpen(true);
      return;
    }

    try {
      const body = {
        ad,
        soyad,
        okul_adi: okulAdi,
        email,
        telefon,
        sehir: sehirId,
        ilce: ilceId,
        sinif,
        yas,
      };
      if (sifre) {
        body.password = sifre;
        body.password_open = sifre;
      }

      const response = await fetch(`${API_URLS.ADMIN_URL}student/${_id}`, {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(body),
      });
      const data = await response.json();
      if (!response.ok) throw new Error(data.message || 'Bir hata oluştu');

      setOgrenciListesi((prev) =>
        prev.map((ogr) => (ogr._id === _id ? { ...ogr, ...data.ogrenci } : ogr))
      );
      setShouldFetchList(true);

      setDialogSeverity('success');
      setDialogContent('Öğrenci başarıyla güncellendi.');
      setDialogOpen(true);
      setDuzenlemeDialogAcik(false);
    } catch (err) {
      setDialogSeverity('error');
      setDialogContent(err.message);
      setDialogOpen(true);
    }
  };

  // Durum (aktif/pasif)
  const handleStatusChange = async (ogrenciId, currentStatus) => {
    const newStatus = currentStatus === 'active' ? 'inactive' : 'active';
    try {
      const response = await fetch(`${API_URLS.ADMIN_URL}student/${ogrenciId}/status`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('userToken')}`,
        },
        body: JSON.stringify({ status: newStatus }),
      });
      if (!response.ok) throw new Error('Durum güncellenirken bir hata oluştu.');

      const updatedStudent = await response.json();
      setOgrenciListesi((prevList) =>
        prevList.map((o) => (o._id === ogrenciId ? { ...o, status: updatedStudent.status } : o))
      );
      setShouldFetchList(true);

      setResultDialogMessage('Durum başarıyla güncellendi.');
      setResultDialogSeverity('success');
      setResultDialogOpen(true);
    } catch (error) {
      setResultDialogMessage(error.message);
      setResultDialogSeverity('error');
      setResultDialogOpen(true);
    }
  };

  // Silme onay
  const handleOpenDeleteDialog = (ogrenciId) => {
    setStudentToDelete(ogrenciId);
    setConfirmDeleteDialogOpen(true);
  };

  // Sil
  const handleDeleteOgrenci = async (ogrenciId) => {
    try {
      const response = await fetch(`${API_URLS.ADMIN_URL}student/${ogrenciId}`, {
        method: 'DELETE',
        headers: { 'Content-Type': 'application/json' },
      });
      if (!response.ok) throw new Error('Öğrenci silinirken bir hata oluştu.');

      setOgrenciListesi((prev) => prev.filter((ogr) => ogr._id !== ogrenciId));

      setResultDialogMessage('Öğrenci başarıyla silindi.');
      setResultDialogSeverity('success');
      setResultDialogOpen(true);
    } catch (error) {
      setResultDialogMessage(error.message);
      setResultDialogSeverity('error');
      setResultDialogOpen(true);
    }
  };

  // Filtrelenmiş liste
  const filteredOgrenciListesi = ogrenciListesi.filter((ogr) => {
    const ad = (ogr.ad || '').toLowerCase();
    const soyad = (ogr.soyad || '').toLowerCase();
    const tel = (ogr.telefon || '').toLowerCase();
    const mail = (ogr.email || '').toLowerCase();
    const okAdi = (ogr.okul_adi || '').toLowerCase();
    const sin = (ogr.sinif || '').toLowerCase();
    const y = (ogr.yas || '').toLowerCase();
    const sehirAdi = (ogr.sehirAdi || '').toLowerCase();
    const ilceAdi = (ogr.ilceAdi || '').toLowerCase();
    const ekleyen = (ogr.ekleyenAdi || '').toLowerCase();

    const search = searchTerm.toLowerCase();
    const matchesSearch =
      ad.includes(search) ||
      soyad.includes(search) ||
      tel.includes(search) ||
      mail.includes(search) ||
      okAdi.includes(search) ||
      sin.includes(search) ||
      y.includes(search) ||
      sehirAdi.includes(search) ||
      ilceAdi.includes(search) ||
      ekleyen.includes(search);

    const matchesStatus = statusFilter ? ogr.status === statusFilter : true;
    const matchesCity = cityFilter ? ogr.sehir === cityFilter : true;
    const matchesIlce = ilceFilter ? ogr.ilce === ilceFilter : true;
    const matchesEkleyen = ekleyenFilter
      ? ekleyen === ekleyenFilter.toLowerCase()
      : true;

    return (
      matchesSearch &&
      matchesStatus &&
      matchesCity &&
      matchesIlce &&
      matchesEkleyen
    );
  });

  return (
    <Paper style={{ padding: 20, margin: '20px auto', maxWidth: '95%' }}>
      <Grid container spacing={4}>
        {/* Öğrenci Kayıt Formu */}
        <Grid item xs={12}>
          <Typography variant="h5" component="h3" gutterBottom>
            Öğrenci Kayıt Formu
          </Typography>
          <StudentForm
            ad={ad}
            setAd={setAd}
            soyad={soyad}
            setSoyad={setSoyad}
            okulAdi={okulAdi}
            setOkulAdi={setOkulAdi}
            sinif={sinif}
            setSinif={setSinif}
            yas={yas}
            setYas={setYas}
            sehirListesi={sehirListesi}
            secilenSehirId={secilenSehirId}
            handleSehirChange={handleSehirChange}
            secilenIlceId={secilenIlceId}
            handleIlceChange={handleIlceChange}
            secilenSehirinIlceleri={secilenSehirinIlceleri}
            telefon={telefon}
            handleTelefonChange={handleTelefonChange}
            telefonHata={telefonHata}
            durum={durum}
            setDurum={setDurum}
            email={email}
            setEmail={setEmail}
            sifre={sifre}
            setSifre={setSifre}
            handleEkle={handleEkle}
          />
        </Grid>

        {/* Filtreleme ve Öğrenci Listesi */}
        <Grid item xs={12}>
          <Typography variant="h5" component="h3" gutterBottom>
            Öğrenci Listesi
          </Typography>
          <StudentTable
            filteredOgrenciListesi={filteredOgrenciListesi}
            searchTerm={searchTerm}
            setSearchTerm={setSearchTerm}
            statusFilter={statusFilter}
            setStatusFilter={setStatusFilter}
            cityFilter={cityFilter}
            setCityFilter={setCityFilter}
            ilceFilter={ilceFilter}
            setIlceFilter={setIlceFilter}
            ekleyenFilter={ekleyenFilter}
            setEkleyenFilter={setEkleyenFilter}
            sehirListesi={sehirListesi}
            handleEdit={handleEdit}
            handleOpenDeleteDialog={handleOpenDeleteDialog}
            handleStatusChange={handleStatusChange}

            // ATAMA listeleri
            okulListesi={okulListesi}
            kurumListesi={kurumListesi}
            ogretmenListesi={ogretmenListesi}
            setShouldFetchList={setShouldFetchList}
          />
        </Grid>
      </Grid>

      {/* Dialoglar */}
      <>
        {/* Yeni ekleme sonucu */}
        <ResultDialog
          open={eklemeBasariliDialogOpen}
          onClose={() => setEklemeBasariliDialogOpen(false)}
          title={dialogSeverity === 'error' ? 'Hata Oluştu' : 'İşlem Başarılı'}
          message={dialogContent}
          severity={dialogSeverity}
        />

        {/* Genel sonuç */}
        <ResultDialog
          open={resultDialogOpen}
          onClose={handleDialogClose}
          title={'İşlem Sonucu'}
          message={resultDialogMessage}
          severity={resultDialogSeverity}
        />

        {/* Düzenleme Dialog */}
        <EditStudentDialog
          duzenlemeDialogAcik={duzenlemeDialogAcik}
          setDuzenlemeDialogAcik={setDuzenlemeDialogAcik}
          duzenlenenOgrenci={duzenlenenOgrenci}
          setDuzenlenenOgrenci={setDuzenlenenOgrenci}
          sehirListesi={sehirListesi}
          handleSehirChangeDuzenle={handleSehirChangeDuzenle}
          handleIlceChangeDuzenle={handleIlceChangeDuzenle}
          handleGuncelle={handleGuncelle}
        />

        {/* Silme Onay Dialog */}
        <ConfirmDeleteDialog
          confirmDeleteDialogOpen={confirmDeleteDialogOpen}
          setConfirmDeleteDialogOpen={setConfirmDeleteDialogOpen}
          handleDeleteOgrenci={handleDeleteOgrenci}
          studentToDelete={studentToDelete}
          setStudentToDelete={setStudentToDelete}
        />
      </>
    </Paper>
  );
};

export default Ogrenci;
