import React, { useState } from 'react';
import { Typography, TextField, FormControl, InputLabel, Select, MenuItem, OutlinedInput, Checkbox, ListItemText, Grid, Button, Menu, MenuItem as MenuItemMUI, TablePagination,
Paper, Table, TableBody, TableHead, TableRow, TableCell, IconButton, Tooltip, Chip, Collapse,} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import InfoIcon from '@mui/icons-material/Info';
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import * as XLSX from 'xlsx';
import ResultDialog from '../../../../../pages/components/Auth/Dialog';
import KurumRaporuModal from './KurumRaporuModal';


function KurumCollapsibleRow({
  index,
  kurum,
  page,
  rowsPerPage,
  handleStatusChange,
  handleAdjustBalance,
  handleEdit,
  handleDelete,
  handleOpenRapor, // Raporu açma fonksiyonunu prop olarak alıyoruz
}) {
  const [open, setOpen] = useState(false);


  const toplamBakiye =
    (kurum.bakiye3Ay || 0) + (kurum.bakiye6Ay || 0) + (kurum.bakiye12Ay || 0);

  const ekleyen =
    kurum.adminAdi && kurum.adminAdi !== 'Bulunamadı' && kurum.adminAdi !== 'null'
      ? kurum.adminAdi
      : kurum.kurumAdi &&
        kurum.kurumAdi !== 'Bulunamadı' &&
        kurum.kurumAdi !== 'null'
      ? kurum.kurumAdi
      : 'Belirtilmemiş';

  const abonelikBaslangic = kurum.latestSubscription
    ? new Date(kurum.latestSubscription.baslangicTarihi).toLocaleDateString()
    : 'Yok';
  const abonelikBitis = kurum.latestSubscription
    ? new Date(kurum.latestSubscription.bitisTarihi).toLocaleDateString()
    : 'Yok';
  const kayitTarihi = kurum.createdAt
    ? new Date(kurum.createdAt).toLocaleDateString()
    : 'Yok';

  return (
    <>
      {/* Ana satır */}
      <TableRow hover tabIndex={-1}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
          </IconButton>
        </TableCell>

        <TableCell>{page * rowsPerPage + index + 1}</TableCell>
        <TableCell
          sx={{
            maxWidth: 150,
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
        >
          {kurum.kurum_adi || 'Yok'}
        </TableCell>

        <TableCell
          sx={{
            maxWidth: 150,
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
        >
          {kurum.password_open || 'Yok'}
        </TableCell>
        <TableCell
          sx={{
            maxWidth: 150,
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
        >
          {ekleyen}
        </TableCell>
        <TableCell>
          <Chip
            label={kurum.status === 'active' ? 'Aktif' : 'Pasif'}
            color={kurum.status === 'active' ? 'success' : 'error'}
            onClick={() => handleStatusChange(kurum._id, kurum.status)}
            sx={{
              cursor: 'pointer',
              '&:hover': { backgroundColor: 'lightgray' },
            }}
          />
        </TableCell>

        <TableCell align="center">
          {/* Bakiye Ayarla */}
          <Tooltip title="Bakiye Ayarla" arrow>
            <IconButton
              onClick={() => handleAdjustBalance(kurum)}
              color="success"
              size="small"
              sx={{ mr: 1 }}
            >
              <AttachMoneyIcon fontSize="small" />
            </IconButton>
          </Tooltip>

          {/* Düzenle */}
          <Tooltip title="Düzenle" arrow>
            <IconButton
              onClick={() => handleEdit(kurum._id)}
              color="primary"
              size="small"
              sx={{ mr: 1 }}
            >
              <EditIcon fontSize="small" />
            </IconButton>
          </Tooltip>

          {/* Sil */}
          <Tooltip title="Sil" arrow>
            <IconButton
              onClick={() => handleDelete(kurum._id)}
              color="error"
              size="small"
              sx={{ mr: 1 }}
            >
              <DeleteIcon fontSize="small" />
            </IconButton>
          </Tooltip>

          {/* RAPOR GÖRÜNTÜLE  */}       
        <Tooltip title="Raporları Gör" arrow>
          <IconButton
            onClick={() => handleOpenRapor(kurum._id, kurum.kurum_adi)}
            color="info"
            size="small"
            sx={{ mr: 1 }}
          >
            <InfoIcon fontSize="small" />
          </IconButton>
        </Tooltip>

        </TableCell>
      </TableRow>

      {/* Detay (Collapsible) satır */}
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Paper sx={{ margin: 1, padding: 2, backgroundColor: '#f9f9f9' }}>
              <Typography variant="h6" gutterBottom component="div">
                Kurum Detayları
              </Typography>
              <Typography variant="body1">
                <strong>Telefon:</strong> {kurum.telefon || 'Yok'}
              </Typography>
              <Typography variant="body1">
                <strong>Email:</strong> {kurum.email || 'Yok'}
              </Typography>
              <Typography variant="body1">
                <strong>Şehir/İlçe:</strong> {kurum.sehirAdi || 'Yok'} /{' '}
                {kurum.ilceAdi || 'Yok'}
              </Typography>
              <Typography variant="body1">
                <strong>Kurum Türü:</strong>{' '}
                {kurum.kurum_turu ? kurum.kurum_turu.join(', ') : 'Yok'}
              </Typography>
              <Typography variant="body1">
                <strong>Bakiye (3 Ay / 6 Ay / 12 Ay):</strong>{' '}
                {kurum.bakiye3Ay || 0} / {kurum.bakiye6Ay || 0} /{' '}
                {kurum.bakiye12Ay || 0}
              </Typography>
              <Typography variant="body1">
                <strong>Bakiye (Toplam):</strong> {toplamBakiye}
              </Typography>
              <Typography variant="body1">
                <strong>Abonelik Tarihleri:</strong> {abonelikBaslangic} -{' '}
                {abonelikBitis}
              </Typography>
              <Typography variant="body1">
                <strong>Kayıt Tarihi:</strong> {kayitTarihi}
              </Typography>
              <Typography variant="body1">
                <strong>Şifre (Açık):</strong> {kurum.password_open || 'Yok'}
              </Typography>
            </Paper>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}

// ----------------------------------------------------------
// 2) Ana bileşen: tablo + filtre + sayfalama + dışa aktarma
// ----------------------------------------------------------
const KurumListesi = ({
  filteredKurumListesi,
  searchQuery,
  setSearchQuery,
  handleStatusChange,
  handleAdjustBalance,
  handleEdit,
  handleDelete,
  filterStatus,
  setFilterStatus,
  filterSehir,
  setFilterSehir,
  filterKurumTuru,
  setFilterKurumTuru,
  sehirListesi,
  kurumTurleri,
}) => {
  // Export menüsü anchor
  const [anchorEl, setAnchorEl] = useState(null);

  // Sayfalama
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);

  // Dialog (işlem sonucu) state'leri
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogContent, setDialogContent] = useState('');
  const [dialogSeverity, setDialogSeverity] = useState('info');

  // **Rapor Modal State**
  const [raporModalOpen, setRaporModalOpen] = useState(false);
  const [selectedKurumId, setSelectedKurumId] = useState('');

  // Dışa Aktar Menüsü Aç/Kapat
  const handleExportClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleExportClose = () => {
    setAnchorEl(null);
  };

  // CSV İndir
  const exportToCSV = () => {
    if (filteredKurumListesi.length === 0) return;
    const headers = [
      'Sıra No',
      'Ekleyen',
      'Kurum Adı',
      'Şifre',
      'Email',
      'Telefon',
      'Şehir',
      'İlçe',
      'Kurum Türü',
      'Bakiye (Toplam)',
      'Bakiye 3 Ay',
      'Bakiye 6 Ay',
      'Bakiye 12 Ay',
      'Abonelik Başlangıç',
      'Abonelik Bitiş',
      'Durum',
      'Kayıt Tarihi',
    ];

    const rows = filteredKurumListesi.map((kurum, index) => {
      const toplamBakiye =
        (kurum.bakiye3Ay || 0) + (kurum.bakiye6Ay || 0) + (kurum.bakiye12Ay || 0);

      const ekleyen =
        kurum.adminAdi && kurum.adminAdi !== 'Bulunamadı' && kurum.adminAdi !== 'null'
          ? kurum.adminAdi
          : kurum.kurumAdi &&
            kurum.kurumAdi !== 'Bulunamadı' &&
            kurum.kurumAdi !== 'null'
          ? kurum.kurumAdi
          : '';

      const abonelikBaslangic = kurum.latestSubscription
        ? new Date(kurum.latestSubscription.baslangicTarihi).toLocaleDateString()
        : 'Yok';
      const abonelikBitis = kurum.latestSubscription
        ? new Date(kurum.latestSubscription.bitisTarihi).toLocaleDateString()
        : 'Yok';
      const kayitTarihi = kurum.createdAt
        ? new Date(kurum.createdAt).toLocaleDateString()
        : 'Yok';

      return [
        index + 1,
        ekleyen,
        kurum.kurum_adi,
        kurum.password_open || '',
        kurum.email,
        kurum.telefon,
        kurum.sehirAdi || '',
        kurum.ilceAdi || '',
        kurum.kurum_turu ? kurum.kurum_turu.join(', ') : '',
        toplamBakiye,
        kurum.bakiye3Ay || 0,
        kurum.bakiye6Ay || 0,
        kurum.bakiye12Ay || 0,
        abonelikBaslangic,
        abonelikBitis,
        kurum.status === 'active' ? 'Aktif' : 'Pasif',
        kayitTarihi,
      ];
    });

    const csvContent =
      'data:text/csv;charset=utf-8,' +
      [headers.join(','), ...rows.map((r) => r.join(','))].join('\n');

    const encodedUri = encodeURI(csvContent);
    const link = document.createElement('a');
    link.href = encodedUri;
    link.download = 'kurum_listesi.csv';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    handleExportClose();
  };

  // TXT İndir
  const exportToTXT = () => {
    if (filteredKurumListesi.length === 0) return;
    let txtContent = '';
    txtContent +=
      'Sıra No | Ekleyen | Kurum Adı | Şifre | Email | Telefon | Şehir | İlçe | Kurum Türü | Bakiye(Toplam) | Bakiye 3 Ay | Bakiye 6 Ay | Bakiye 12 Ay | Abonelik Başlangıç | Abonelik Bitiş | Durum | Kayıt Tarihi\n';

    filteredKurumListesi.forEach((kurum, index) => {
      const toplamBakiye =
        (kurum.bakiye3Ay || 0) + (kurum.bakiye6Ay || 0) + (kurum.bakiye12Ay || 0);

      const ekleyen =
        kurum.adminAdi && kurum.adminAdi !== 'Bulunamadı' && kurum.adminAdi !== 'null'
          ? kurum.adminAdi
          : kurum.kurumAdi &&
            kurum.kurumAdi !== 'Bulunamadı' &&
            kurum.kurumAdi !== 'null'
          ? kurum.kurumAdi
          : '';

      const abonelikBaslangic = kurum.latestSubscription
        ? new Date(kurum.latestSubscription.baslangicTarihi).toLocaleDateString()
        : 'Yok';
      const abonelikBitis = kurum.latestSubscription
        ? new Date(kurum.latestSubscription.bitisTarihi).toLocaleDateString()
        : 'Yok';
      const kayitTarihi = kurum.createdAt
        ? new Date(kurum.createdAt).toLocaleDateString()
        : 'Yok';

      txtContent += `${index + 1} | ${ekleyen} | ${kurum.kurum_adi} | ${
        kurum.password_open || ''
      } | ${kurum.email} | ${kurum.telefon} | ${kurum.sehirAdi || ''} | ${
        kurum.ilceAdi || ''
      } | ${
        kurum.kurum_turu ? kurum.kurum_turu.join(', ') : ''
      } | ${toplamBakiye} | ${kurum.bakiye3Ay || 0} | ${
        kurum.bakiye6Ay || 0
      } | ${kurum.bakiye12Ay || 0} | ${abonelikBaslangic} | ${abonelikBitis} | ${
        kurum.status === 'active' ? 'Aktif' : 'Pasif'
      } | ${kayitTarihi}\n`;
    });

    const blob = new Blob([txtContent], { type: 'text/plain;charset=utf-8' });
    const url = URL.createObjectURL(blob);

    const link = document.createElement('a');
    link.href = url;
    link.download = 'kurum_listesi.txt';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    handleExportClose();
  };

  // XLSX İndir
  const exportToXLSX = () => {
    if (filteredKurumListesi.length === 0) return;
    const headers = [
      'Sıra No',
      'Ekleyen',
      'Kurum Adı',
      'Şifre',
      'Email',
      'Telefon',
      'Şehir',
      'İlçe',
      'Kurum Türü',
      'Bakiye (Toplam)',
      'Bakiye 3 Ay',
      'Bakiye 6 Ay',
      'Bakiye 12 Ay',
      'Abonelik Başlangıç',
      'Abonelik Bitiş',
      'Durum',
      'Kayıt Tarihi',
    ];
    const wsData = [];
    wsData.push(headers);

    filteredKurumListesi.forEach((kurum, index) => {
      const toplamBakiye =
        (kurum.bakiye3Ay || 0) + (kurum.bakiye6Ay || 0) + (kurum.bakiye12Ay || 0);

      const ekleyen =
        kurum.adminAdi && kurum.adminAdi !== 'Bulunamadı' && kurum.adminAdi !== 'null'
          ? kurum.adminAdi
          : kurum.kurumAdi &&
            kurum.kurumAdi !== 'Bulunamadı' &&
            kurum.kurumAdi !== 'null'
          ? kurum.kurumAdi
          : '';

      const abonelikBaslangic = kurum.latestSubscription
        ? new Date(kurum.latestSubscription.baslangicTarihi).toLocaleDateString()
        : 'Yok';
      const abonelikBitis = kurum.latestSubscription
        ? new Date(kurum.latestSubscription.bitisTarihi).toLocaleDateString()
        : 'Yok';
      const kayitTarihi = kurum.createdAt
        ? new Date(kurum.createdAt).toLocaleDateString()
        : 'Yok';

      const row = [
        index + 1,
        ekleyen,
        kurum.kurum_adi,
        kurum.password_open || '',
        kurum.email,
        kurum.telefon,
        kurum.sehirAdi || '',
        kurum.ilceAdi || '',
        kurum.kurum_turu ? kurum.kurum_turu.join(', ') : '',
        toplamBakiye,
        kurum.bakiye3Ay || 0,
        kurum.bakiye6Ay || 0,
        kurum.bakiye12Ay || 0,
        abonelikBaslangic,
        abonelikBitis,
        kurum.status === 'active' ? 'Aktif' : 'Pasif',
        kayitTarihi,
      ];
      wsData.push(row);
    });

    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.aoa_to_sheet(wsData);
    XLSX.utils.book_append_sheet(wb, ws, 'Kurum Listesi');

    const wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const blob = new Blob([wbout], { type: 'application/octet-stream' });

    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = 'kurum_listesi.xlsx';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    handleExportClose();
  };

  // Sayfalama fonksiyonları
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // Dinamik "sayfa başına kayıt" seçenekleri
  const totalCount = filteredKurumListesi.length;
  const rowsPerPageOptions = [];
  for (let i = 25; i <= totalCount; i += 25) {
    rowsPerPageOptions.push(i);
  }
  if (rowsPerPageOptions.length === 0) {
    rowsPerPageOptions.push(25);
  }

 

const [selectedKurumAdi, setSelectedKurumAdi] = useState('');
  // Paginated data
  const paginatedData = filteredKurumListesi.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );

  // Rapor Modal’ı aç/kapat
  const handleOpenRapor = (id, adi) => {
    setSelectedKurumId(id);
    setSelectedKurumAdi(adi);
    setRaporModalOpen(true);
  };

const handleCloseRapor = () => {
  setRaporModalOpen(false);
  setSelectedKurumId('');
  setSelectedKurumAdi('');
};

  return (
    <>
      <Typography variant="h5" component="h3" marginBottom={2}>
        Kayıtlı Kurumlar Listesi
      </Typography>

      {/* Arama Alanı */}
      <TextField
        fullWidth
        label="Ara..."
        value={searchQuery}
        onChange={(e) => setSearchQuery(e.target.value)}
        margin="normal"
      />

      {/* Filtreleme Bileşenleri */}
      <Grid container spacing={2} sx={{ mb: 2 }}>
        {/* Durum Filtresi */}
        <Grid item xs={12} sm={4}>
          <FormControl fullWidth variant="outlined">
            <InputLabel id="status-filter-label">Durum</InputLabel>
            <Select
              labelId="status-filter-label"
              id="status-filter"
              value={filterStatus}
              onChange={(e) => setFilterStatus(e.target.value)}
              label="Durum"
            >
              <MenuItem value="">
                <em>Tümü</em>
              </MenuItem>
              <MenuItem value="active">Aktif</MenuItem>
              <MenuItem value="inactive">Pasif</MenuItem>
            </Select>
          </FormControl>
        </Grid>

        {/* Şehir Filtresi */}
        <Grid item xs={12} sm={4}>
          <FormControl fullWidth variant="outlined">
            <InputLabel id="sehir-filter-label">Şehir</InputLabel>
            <Select
              labelId="sehir-filter-label"
              id="sehir-filter"
              value={filterSehir}
              onChange={(e) => setFilterSehir(e.target.value)}
              label="Şehir"
            >
              <MenuItem value="">
                <em>Tümü</em>
              </MenuItem>
              {sehirListesi.map((sehir) => (
                <MenuItem key={sehir._id} value={sehir._id}>
                  {sehir.sehir_adi}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        {/* Kurum Türü Filtresi */}
        <Grid item xs={12} sm={4}>
          <FormControl fullWidth variant="outlined">
            <InputLabel id="kurum-turu-filter-label">Kurum Türü</InputLabel>
            <Select
              labelId="kurum-turu-filter-label"
              id="kurum-turu-filter"
              multiple
              value={filterKurumTuru}
              onChange={(e) => setFilterKurumTuru(e.target.value)}
              input={<OutlinedInput label="Kurum Türü" />}
              renderValue={(selected) =>
                selected
                  .map((value) => kurumTurleri.find((kt) => kt.id === value)?.adi)
                  .join(', ')
              }
            >
              <MenuItem value="">
                <em>Tümü</em>
              </MenuItem>
              {kurumTurleri.map((turu) => (
                <MenuItem key={turu.id} value={turu.id}>
                  <Checkbox checked={filterKurumTuru.indexOf(turu.id) > -1} />
                  <ListItemText primary={turu.adi} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      </Grid>

      {/* Filtreleri Temizle & Dışa Aktar */}
      <Grid container spacing={2} sx={{ mb: 2 }}>
        <Grid item>
          <Button
            variant="outlined"
            color="secondary"
            onClick={() => {
              setFilterStatus('');
              setFilterSehir('');
              setFilterKurumTuru([]);
            }}
            sx={{ mr: 2 }}
          >
            Filtreleri Temizle
          </Button>

          {/* Dışa Aktar Menüsü */}
          <Button variant="outlined" onClick={handleExportClick}>
            Dışa Aktar
          </Button>
          <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleExportClose}>
            <MenuItemMUI onClick={exportToCSV}>CSV Olarak İndir</MenuItemMUI>
            <MenuItemMUI onClick={exportToTXT}>TXT Olarak İndir</MenuItemMUI>
            <MenuItemMUI onClick={exportToXLSX}>Excel (XLSX) Olarak İndir</MenuItemMUI>
          </Menu>
        </Grid>
      </Grid>

      {/* ÜST TARAFTAKİ SAYFALAMA */}
      <TablePagination
        rowsPerPageOptions={rowsPerPageOptions}
        component="div"
        count={filteredKurumListesi.length}
        rowsPerPage={rowsPerPage}
        page={page}
        labelRowsPerPage="Sayfa başına kayıt:"
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        sx={{
          '& .MuiTablePagination-toolbar': {
            display: 'flex',
            justifyContent: 'flex-start',
          },
          '& .MuiTablePagination-spacer': {
            flex: '0',
          },
          '& .MuiTablePagination-actions': {
            marginLeft: 0,
          },
        }}
      />

      {/* Tablo: CollapsibleRows */}
      <Paper>
        <Table size="small">
          <TableHead sx={{ backgroundColor: 'primary.main' }}>
            <TableRow>
              <TableCell />
              <TableCell sx={{ color: '#fff', fontWeight: 'bold' }}>Sıra No</TableCell>
              <TableCell sx={{ color: '#fff', fontWeight: 'bold' }}>Kurum Adı</TableCell>
              <TableCell sx={{ color: '#fff', fontWeight: 'bold' }}>Şifre</TableCell>
              <TableCell sx={{ color: '#fff', fontWeight: 'bold' }}>Ekleyen</TableCell>
              <TableCell sx={{ color: '#fff', fontWeight: 'bold' }}>Durum</TableCell>
              <TableCell sx={{ color: '#fff', fontWeight: 'bold' }} align="center">
                İşlem
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {paginatedData.map((kurum, index) => (
              <KurumCollapsibleRow
                key={kurum._id}
                index={index}
                kurum={kurum}
                page={page}
                rowsPerPage={rowsPerPage}
                handleStatusChange={handleStatusChange}
                handleAdjustBalance={handleAdjustBalance}
                handleEdit={handleEdit}
                handleDelete={handleDelete}
                handleOpenRapor={handleOpenRapor} 
              />
            ))}
          </TableBody>
        </Table>
      </Paper>

      {/* ALT TARAFTAKİ SAYFALAMA */}
      <TablePagination
        rowsPerPageOptions={rowsPerPageOptions}
        component="div"
        count={filteredKurumListesi.length}
        rowsPerPage={rowsPerPage}
        page={page}
        labelRowsPerPage="Sayfa başına kayıt:"
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        sx={{
          '& .MuiTablePagination-toolbar': {
            display: 'flex',
            justifyContent: 'flex-start',
          },
          '& .MuiTablePagination-spacer': {
            flex: '0',
          },
          '& .MuiTablePagination-actions': {
            marginLeft: 0,
          },
          mt: 2,
        }}
      />

      {/* Opsiyonel: İşlem sonucu diyaloğu (ResultDialog) */}
      <ResultDialog
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        title={dialogSeverity === 'error' ? 'Hata Oluştu' : 'İşlem Başarılı'}
        message={dialogContent}
        severity={dialogSeverity}
      />

      {/* Rapor Modal Bileşeni */}
      <KurumRaporuModal
        open={raporModalOpen}
        onClose={handleCloseRapor}
        kurumId={selectedKurumId}
        kurumAdi={selectedKurumAdi}  // yeni prop
      />
    </>
  );
};

export default KurumListesi;
