// SifreGuncelle.js
import React, { useState } from 'react';
import {Button, Typography, Paper, Card, CardContent, Grid, TextField, InputAdornment, Checkbox, FormControlLabel,} from '@mui/material';
import LockIcon from '@mui/icons-material/Lock';
import KeyIcon from '@mui/icons-material/VpnKey';
import 'bootstrap/dist/css/bootstrap.min.css';
import { API_URLS } from '../../../config/config';
import useFetchUserData from '../../../hooks/ogrenci/useFetchUserData';
import axios from '../../../utils/axiosInstance';
import ResultDialog from '../../../pages/components/Auth/Dialog';

const SifreGuncelle = () => {
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogContent, setDialogContent] = useState('');
  const [dialogSeverity, setDialogSeverity] = useState('error');
  const [loading, setLoading] = useState(false);
  const [userData, setUserData] = useState(null);

  // Kullanıcı bilgisi
  useFetchUserData(setUserData);

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    // Yeni şifre ve onay şifresi eşleşiyor mu?
    if (newPassword !== confirmPassword) {
      setDialogSeverity('error');
      setDialogContent('Yeni şifre ve onay şifresi eşleşmiyor!');
      setDialogOpen(true);
      setLoading(false);
      return;
    }

    try {
      // Artık fetch yerine axiosInstance'ı kullanıyoruz.
      // Token, interceptor üzerinden (cookie) otomatik gönderilecek.
      const response = await axios.put(
        `${API_URLS.STUDENT_URL}ayarlar/update-password`,
        {
          oldPassword: currentPassword,
          newPassword: newPassword,
          password_open: newPassword,
        }
      );

      setLoading(false);

      // Backend bir hata döndürdüyse
      if (response.status !== 200) {
        throw new Error(
          response.data?.message || 'Şifre güncellenirken bir hata oluştu.'
        );
      }

      setDialogSeverity('success');
      setDialogContent('Şifre başarıyla güncellendi!');
      setDialogOpen(true);

      // Formu temizle
      setCurrentPassword('');
      setNewPassword('');
      setConfirmPassword('');
      setShowPassword(false);
    } catch (error) {
      setLoading(false);
      setDialogSeverity('error');
      setDialogContent(
        error.response?.data?.message || error.message || 'Bir hata oluştu.'
      );
      setDialogOpen(true);
    }
  };

  return (
    <Paper style={{ padding: 20 }}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Card className="bg-warning text-dark mb-3">
            <div
              className="card-header text-center font-weight-bold"
              style={{ fontSize: '1.25rem', letterSpacing: '0.05rem' }}
            >
              ŞİFRE GÜNCELLEME
            </div>
            <hr style={{ margin: '0', borderColor: 'dark' }} />
            <CardContent>
              <Typography
                variant="h6"
                component="h2"
                style={{ display: 'flex', alignItems: 'center' }}
              >
                <LockIcon style={{ marginRight: '8px', fontSize: '1.5rem' }} />
                Şifrenizi Değiştirin
              </Typography>
              <form onSubmit={handleSubmit}>
                <TextField
                  fullWidth
                  label="Mevcut Şifre"
                  type={showPassword ? 'text' : 'password'}
                  margin="normal"
                  variant="outlined"
                  value={currentPassword}
                  onChange={(e) => setCurrentPassword(e.target.value)}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <LockIcon style={{ fontSize: '1.2rem' }} />
                      </InputAdornment>
                    ),
                  }}
                />
                <TextField
                  fullWidth
                  label="Yeni Şifre"
                  type={showPassword ? 'text' : 'password'}
                  margin="normal"
                  variant="outlined"
                  value={newPassword}
                  onChange={(e) => setNewPassword(e.target.value)}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <KeyIcon style={{ fontSize: '1.2rem' }} />
                      </InputAdornment>
                    ),
                  }}
                />
                <TextField
                  fullWidth
                  label="Yeni Şifreyi Onayla"
                  type={showPassword ? 'text' : 'password'}
                  margin="normal"
                  variant="outlined"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <KeyIcon style={{ fontSize: '1.2rem' }} />
                      </InputAdornment>
                    ),
                  }}
                />
                <FormControlLabel
                  control={<Checkbox checked={showPassword} onChange={toggleShowPassword} />}
                  label="Şifreyi Göster"
                  style={{ marginTop: '8px' }}
                />
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  fullWidth
                  style={{ marginTop: '16px' }}
                  disabled={loading}
                >
                  {loading ? 'GÜNCELLENİYOR...' : 'GÜNCELLE'}
                </Button>
              </form>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <ResultDialog
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        title={dialogSeverity === 'error' ? 'Güncelleme Başarısız' : 'Güncelleme Başarılı'}
        message={dialogContent}
        severity={dialogSeverity}
      />
    </Paper>
  );
};

export default SifreGuncelle;
