import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlay, faPause, faRedo } from '@fortawesome/free-solid-svg-icons';
import '../styles/cumleleriOkuStyle.css';

const GameControls = ({
  difficulty,
  handleDifficultyChange,
  isPlaying,
  handlePlayPause,
  handleReset,
  speed,
  handleSpeedChange,
  getSpeedInSeconds
}) => {
  return (
    <div className="cumle-game-controls">
      <select
        className="cumle-select"
        value={difficulty}
        onChange={handleDifficultyChange}
      >
        <option value="easy">Kolay</option>
        <option value="medium">Orta</option>
        <option value="hard">Zor</option>
      </select>

      <button onClick={handlePlayPause} className="cumle-button">
        <FontAwesomeIcon icon={isPlaying ? faPause : faPlay} /> {isPlaying ? 'Duraklat' : 'Başlat'}
      </button>
      <button onClick={handleReset} className="cumle-button">
        <FontAwesomeIcon icon={faRedo} /> Yeniden Başlat
      </button>

      <div className="cumle-speed-control-container">
        <div className="speed-label">Hız</div>
        <div className="speed-display">
          <div className="speed-value">{getSpeedInSeconds(speed)}sn</div>
        </div>
        <input
          type="range"
          id="speedRange"
          value={speed / 1000}
          onChange={handleSpeedChange}
          min="0.1"
          max="2"
          step="0.1"
          className="cumle-speed-range"
        />
      </div>
    </div>
  );
};

export default GameControls;
