import { useState, useEffect, useRef } from 'react';

export const useGameLogic = (paragraph, userData, difficulty, highlightSpeed) => {
  const initialTime = !userData || userData.status === 'inactive' ? 15 : 60;

  let maxWordsPerChunk = 1;
  if (difficulty === 'medium') maxWordsPerChunk = 2;
  if (difficulty === 'hard') maxWordsPerChunk = 3;

  // highlightSpeed state'i artık prop'tan geliyor. 
  // highlightInterval = highlightSpeed
  const [highlightInterval, setHighlightInterval] = useState(highlightSpeed);

  const [isPlaying, setIsPlaying] = useState(false);
  const [timeRemaining, setTimeRemaining] = useState(initialTime);
  const [message, setMessage] = useState('');
  const [lineWords, setLineWords] = useState([]);
  const [currentLine, setCurrentLine] = useState(0);
  const [highlightedLine, setHighlightedLine] = useState(-1);
  const [highlightedWordIndexInLine, setHighlightedWordIndexInLine] = useState(-1);

  const timerRef = useRef(null);
  const highlightIntervalRef = useRef(null);

  const [currentWordIndexInLine, setCurrentWordIndexInLine] = useState(0);
  const [highlightedWordCount, setHighlightedWordCount] = useState(0);

  // WPM hesaplamak için
  const [elapsedTime, setElapsedTime] = useState(0);
  const [startTime, setStartTime] = useState(null);

  useEffect(() => {
    handleReset();
    if (paragraph) {
      const parsedLines = extractLineWords(paragraph);
      setLineWords(parsedLines);
      if (parsedLines.length > 0) {
        setCurrentLine(0);
        setCurrentWordIndexInLine(0);
      } else {
        setCurrentLine(0);
        setCurrentWordIndexInLine(0);
      }
    } else {
      setLineWords([]);
      setCurrentLine(0);
      setCurrentWordIndexInLine(0);
    }
  }, [paragraph]);

  // highlightSpeed değiştiğinde highlightInterval'i de güncelle
  useEffect(() => {
    setHighlightInterval(highlightSpeed);
  }, [highlightSpeed]);

  useEffect(() => {
    if (isPlaying && timeRemaining > 0) {
      if (!startTime) setStartTime(Date.now());
      timerRef.current = setTimeout(() => {
        setTimeRemaining((prev) => prev - 1);
        if (startTime) {
          setElapsedTime((Date.now() - startTime) / 1000);
        }
      }, 1000);
    } else if (timeRemaining === 0) {
      setIsPlaying(false);
      setMessage('Süre doldu! Egzersiz sona erdi.');
      clearInterval(highlightIntervalRef.current);
    }

    return () => clearTimeout(timerRef.current);
  }, [isPlaying, timeRemaining, startTime]);

  useEffect(() => {
    if (isPlaying && lineWords.length > 0) {
      clearInterval(highlightIntervalRef.current);
      highlightIntervalRef.current = setInterval(() => {
        highlightNextWord();
      }, highlightInterval);
    } else {
      clearInterval(highlightIntervalRef.current);
    }
    return () => clearInterval(highlightIntervalRef.current);
  }, [isPlaying, lineWords, currentLine, currentWordIndexInLine, highlightInterval]);

  const highlightNextWord = () => {
    if (currentLine < lineWords.length) {
      const currentLineWords = lineWords[currentLine];
      const chunkSize = Math.min(maxWordsPerChunk, currentLineWords.length - currentWordIndexInLine);

      if (chunkSize <= 0) {
        goToNextLine();
        return;
      }

      const chosenWordIndex = currentWordIndexInLine;
      setHighlightedLine(currentLine);
      setHighlightedWordIndexInLine(chosenWordIndex);
      setHighlightedWordCount(prev => prev + chunkSize);
      setCurrentWordIndexInLine(currentWordIndexInLine + chunkSize);

    } else {
      setHighlightedLine(-1);
      setHighlightedWordIndexInLine(-1);
    }
  };

  const goToNextLine = () => {
    const nextLine = currentLine + 1;
    if (nextLine < lineWords.length) {
      setCurrentLine(nextLine);
      setHighlightedLine(-1);
      setHighlightedWordIndexInLine(-1);
      setCurrentWordIndexInLine(0);
      // Artık burada interval kısaltma yapılmıyor, kullanıcı hızına bağlı.
    } else {
      setHighlightedLine(-1);
      setHighlightedWordIndexInLine(-1);
    }
  };

  const highlightNextWordImmediately = () => {
    if (lineWords.length > 0 && currentLine < lineWords.length) {
      highlightNextWord();
    }
  };

  const handlePlayPause = () => {
    if (isPlaying) {
      setMessage('Egzersiz duraklatıldı.');
    } else {
      setMessage('');
      if (!startTime) setStartTime(Date.now());
    }
    setIsPlaying(!isPlaying);
  };

  const handleReset = () => {
    setIsPlaying(false);
    setTimeRemaining(initialTime);
    setMessage('');
    setHighlightedLine(-1);
    setHighlightedWordIndexInLine(-1);
    setCurrentLine(0);
    setCurrentWordIndexInLine(0);
    setHighlightedWordCount(0);
    setStartTime(null);
    setElapsedTime(0);
    setHighlightInterval(highlightSpeed); 
    clearInterval(highlightIntervalRef.current);
  };

  const totalTimeInMin = elapsedTime / 60;
  const wpmEstimate = totalTimeInMin > 0 ? Math.round(highlightedWordCount / totalTimeInMin) : 0;

  return {
    isPlaying,
    timeRemaining,
    message,
    handlePlayPause,
    handleReset,
    highlightedLine,
    highlightedWordIndexInLine,
    lineWords,
    highlightNextWordImmediately,
    highlightedWordCount,
    wpmEstimate
  };
};

const extractLineWords = (html) => {
  const tempDiv = document.createElement('div');
  tempDiv.innerHTML = html;
  const textContent = tempDiv.textContent || tempDiv.innerText || '';

  const lines = textContent.split('\n').map(l => l.trim()).filter(l => l.length > 0);
  
  const lineWords = lines.map(line => {
    const words = line.split(/\s+/).filter(w => w.trim().length > 0);
    return words.map(word =>
      word       
        .replace(/[^\p{L}\p{N}_]+/gu, '')
    );
  });

  return lineWords;
};
