// useLetterGridGame.js

import { useState, useEffect } from 'react';

const alphabet = 'ABCÇDEFGĞHIİJKLMNOÖPRSŞTUÜVYZ';

export const useLetterGridGame = (userData, onGameOver, initialDifficulty = 'easy') => {
  const [letters, setLetters] = useState([]);
  const [targetLetters, setTargetLetters] = useState([]);
  const [message, setMessage] = useState('');
  const [score, setScore] = useState(0);
  const [isPlaying, setIsPlaying] = useState(false);
  const [clickedLetters, setClickedLetters] = useState([]);
  const [difficulty, setDifficulty] = useState(initialDifficulty);
  const [gridSize, setGridSize] = useState(36);

  const [correctSelections, setCorrectSelections] = useState(0);
  const [incorrectSelections, setIncorrectSelections] = useState(0);

  const [targetLetterCounts, setTargetLetterCounts] = useState({});
  const [foundLetterCounts, setFoundLetterCounts] = useState({});

  const initialTime = !userData || userData.status === 'inactive' ? 15 : 60;
  const [timeRemaining, setTimeRemaining] = useState(initialTime);

  useEffect(() => {
    setTimeRemaining(initialTime);
  }, [initialTime]);

  useEffect(() => {
    if (isPlaying) {
      initializeGame();
    }
  }, [difficulty]);

  useEffect(() => {
    let timer;
    if (isPlaying && timeRemaining > 0) {
      timer = setTimeout(() => setTimeRemaining(timeRemaining - 1), 1000);
    } else if (isPlaying && timeRemaining === 0) {
      setIsPlaying(false);
      setMessage('Süre doldu!');
      setTimeout(() => {
        if (onGameOver)
          onGameOver(score, correctSelections, incorrectSelections);
      }, 0);
    }
    return () => clearTimeout(timer);
  }, [isPlaying, timeRemaining]);

  const initializeGame = () => {
    let size, targetCount, targetLetterFrequency;
    switch (difficulty) {
      case 'easy':
        size = 36;
        targetCount = 1;
        targetLetterFrequency = 0.2;
        break;
      case 'medium':
        size = 64;
        targetCount = 2;
        targetLetterFrequency = 0.15;
        break;
      case 'hard':
        size = 100;
        targetCount = 3;
        targetLetterFrequency = 0.1;
        break;
      default:
        size = 36;
        targetCount = 1;
        targetLetterFrequency = 0.2;
    }
    setGridSize(size);

    const targets = [];
    for (let i = 0; i < targetCount; i++) {
      let randomLetter;
      do {
        randomLetter = alphabet[Math.floor(Math.random() * alphabet.length)];
      } while (targets.includes(randomLetter));
      targets.push(randomLetter);
    }
    setTargetLetters(targets);

    const newLetters = [];
    for (let i = 0; i < size; i++) {
      let randomLetter;
      if (Math.random() < targetLetterFrequency) {
        randomLetter = targets[Math.floor(Math.random() * targetCount)];
      } else {
        randomLetter = alphabet[Math.floor(Math.random() * alphabet.length)];
      }
      newLetters.push(randomLetter);
    }

    const counts = {};
    targets.forEach((letter) => {
      counts[letter] = newLetters.filter((l) => l === letter).length;
    });

    setLetters(newLetters);
    setClickedLetters([]);
    setMessage('');
    setTargetLetterCounts(counts);
    setFoundLetterCounts({});
    setCorrectSelections(0);
    setIncorrectSelections(0);
  };

  const handlePlayPause = () => {
    if (!isPlaying) {
      setMessage('');
      initializeGame();
    }
    setIsPlaying(!isPlaying);
  };

  const handleReset = () => {
    setIsPlaying(false);
    setScore(0);
    setTimeRemaining(initialTime);
    setCorrectSelections(0);
    setIncorrectSelections(0);
    initializeGame();
  };

  const handleLetterClick = (letter, index) => {
    if (!isPlaying || clickedLetters.includes(index)) return;

    setClickedLetters((prevClicked) => [...prevClicked, index]);

    if (targetLetters.includes(letter)) {
      setFoundLetterCounts((prevCounts) => {
        const newCounts = { ...prevCounts };
        newCounts[letter] = (newCounts[letter] || 0) + 1;
        return newCounts;
      });

      setScore((prevScore) => prevScore + 1);
      setCorrectSelections((prevCorrect) => prevCorrect + 1);
      setMessage('Doğru!');

      const newFoundLetterCounts = {
        ...foundLetterCounts,
        [letter]: (foundLetterCounts[letter] || 0) + 1,
      };

      const allFound = targetLetters.every(
        (target) =>
          newFoundLetterCounts[target] >= targetLetterCounts[target]
      );

      if (allFound) {
        if (timeRemaining > 0) {
          setMessage('Tüm harfleri buldunuz! Yeni harflere geçiliyor...');
          setTimeout(() => {
            initializeGame();
          }, 1000);
        } else {
          setIsPlaying(false);
          setTimeout(() => {
            if (onGameOver)
              onGameOver(score, correctSelections, incorrectSelections);
          }, 0);
        }
      }
    } else {
      setScore((prevScore) => (prevScore > 0 ? prevScore - 1 : 0));
      setIncorrectSelections((prevIncorrect) => prevIncorrect + 1);
      setMessage('Yanlış!');
    }
  };

  const handleDifficultyChange = (e) => {
    setDifficulty(e.target.value);
    handleReset();
  };

  return {
    letters,
    targetLetters,
    message,
    timeRemaining,
    score,
    isPlaying,
    clickedLetters,
    difficulty,
    gridSize,
    correctSelections,
    incorrectSelections,
    handlePlayPause,
    handleReset,
    handleLetterClick,
    handleDifficultyChange,
  };
};
