// src/components/ogretmen/rapor/OgretmenRaporuModal.jsx
import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  AppBar,
  Toolbar,
  IconButton,
  Tabs,
  Tab,
  Box,
  Typography,
  CircularProgress,
  Paper,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  Card,
  CardContent,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { API_URLS } from '../../../../config/config';

/**
 * Sekme paneli (TabPanel) yardımcı bileşeni
 */
function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      {...other}
      style={{ paddingTop: 16 }}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

/**
 * OgretmenRaporuModal
 *
 * @param {boolean} open  - Modal’ın açık/kapalı durumu
 * @param {Function} onClose - Modal’ı kapatma fonksiyonu
 * @param {string} teacherId  - İlgili öğretmenin _id değeri
 * @param {string} [teacherName] - (Opsiyonel) Öğretmenin Ad-Soyad bilgisi
 */
const OgretmenRaporuModal = ({ open, onClose, teacherId, teacherName = '' }) => {
  const [tabValue, setTabValue] = useState(0);

  // Veriler
  const [ogrenciler, setOgrenciler] = useState([]);
  const [books, setBooks] = useState([]);

  // Loading state
  const [loadingOgrenciler, setLoadingOgrenciler] = useState(false);
  const [loadingBooks, setLoadingBooks] = useState(false);

  // Error state
  const [errorOgrenciler, setErrorOgrenciler] = useState('');
  const [errorBooks, setErrorBooks] = useState('');

  // Modal açıldığında ya da teacherId değiştiğinde verileri çek
  useEffect(() => {
    if (open && teacherId) {
      fetchOgrenciler();
      fetchBooks();
    }
    // eslint-disable-next-line
  }, [open, teacherId]);

  // ─────────────────────────────────────────────────────────────────────────
  // FONKSİYON: Öğrencileri getir
  // ─────────────────────────────────────────────────────────────────────────
  const fetchOgrenciler = async () => {
    setLoadingOgrenciler(true);
    setErrorOgrenciler('');
    try {
      const token = localStorage.getItem('userToken');
      const res = await fetch(
        `${API_URLS.ADMIN_URL}teachers/rapor/${teacherId}/ogrenciler`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      if (!res.ok) throw new Error('Öğrenciler getirilemedi.');
      const data = await res.json();
      setOgrenciler(data);
    } catch (err) {
      setErrorOgrenciler(err.message);
    } finally {
      setLoadingOgrenciler(false);
    }
  };

  // ─────────────────────────────────────────────────────────────────────────
  // FONKSİYON: Kitapları getir
  // ─────────────────────────────────────────────────────────────────────────
  const fetchBooks = async () => {
    setLoadingBooks(true);
    setErrorBooks('');
    try {
      const token = localStorage.getItem('userToken');
      const res = await fetch(
        `${API_URLS.ADMIN_URL}teachers/rapor/${teacherId}/books`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      if (!res.ok) throw new Error('Kitaplar getirilemedi.');
      const data = await res.json();
      setBooks(data);
    } catch (err) {
      setErrorBooks(err.message);
    } finally {
      setLoadingBooks(false);
    }
  };

  // Sekme değiştirme
  const handleChangeTab = (event, newValue) => {
    setTabValue(newValue);
  };

  // Modal kapatma
  const handleClose = () => {
    onClose();
  };

  // Kartlarda göstereceğimiz sayılar
  const ogrenciSayisi = ogrenciler.length; 
  const kitapSayisi = books.length;  

  return (
    <Dialog
      fullWidth
      maxWidth="lg"
      open={open}
      onClose={handleClose}
      sx={{
        '& .MuiPaper-root': {
          borderRadius: 4, // Modal köşelerini yuvarla
          backgroundColor: '#f7f7f7', // Arka plan rengi
        },
      }}
    >
      {/* Üst başlık alanı (AppBar) */}
      <AppBar sx={{ position: 'relative', backgroundColor: '#1976d2' }}>
        <Toolbar>
          <DialogTitle sx={{ flex: 1, color: '#fff', fontWeight: 'bold' }}>
            {/* İsteğe bağlı: Ad-Soyad varsa göster, yoksa ID */}
            Öğretmen Raporu – {teacherName || teacherId}
          </DialogTitle>
          <IconButton edge="end" color="inherit" onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </AppBar>

      {/* İçerik */}
      <DialogContent dividers>
        {/* Kartların olduğu alan */}
        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 2, mb: 2 }}>
          {/* Öğrenci Kartı */}
          <Card sx={{ flex: '1 1 200px', boxShadow: 3, borderRadius: 2 }}>
            <CardContent>
              <Typography variant="h6" color="primary" gutterBottom>
                Öğrenci Sayısı
              </Typography>
              <Typography variant="h4">{ogrenciSayisi}</Typography>
            </CardContent>
          </Card>

          {/* Kitap Kartı */}
          <Card sx={{ flex: '1 1 200px', boxShadow: 3, borderRadius: 2 }}>
            <CardContent>
              <Typography variant="h6" color="primary" gutterBottom>
                Kitap Sayısı
              </Typography>
              <Typography variant="h4">{kitapSayisi}</Typography>
            </CardContent>
          </Card>
        </Box>

        {/* Sekmeler */}
        <Tabs
          value={tabValue}
          onChange={handleChangeTab}
          textColor="primary"
          indicatorColor="primary"
          variant="scrollable"
          sx={{ backgroundColor: '#fff', borderRadius: 1 }}
        >
          <Tab label="Öğrenciler" />
          <Tab label="Kitaplar" />
        </Tabs>

        {/* Tab içerikleri */}
        {/* 1) Öğrenciler Tab */}
        <TabPanel value={tabValue} index={0}>
          {loadingOgrenciler && <CircularProgress />}
          {!loadingOgrenciler && errorOgrenciler && (
            <Typography color="error">{errorOgrenciler}</Typography>
          )}
          {!loadingOgrenciler && !errorOgrenciler && (
            <Paper sx={{ mt: 2 }}>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>Ad</TableCell>
                    <TableCell>Soyad</TableCell>
                    <TableCell>Email</TableCell>
                    <TableCell>Kayıt Tarihi</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {ogrenciler.map((ogr) => (
                    <TableRow key={ogr._id}>
                      <TableCell>{ogr.ad || 'Ad Yok'}</TableCell>
                      <TableCell>{ogr.soyad || 'Soyad Yok'}</TableCell>
                      <TableCell>{ogr.email || 'Email Yok'}</TableCell>
                      <TableCell>
                        {ogr.createdAt
                          ? new Date(ogr.createdAt).toLocaleString()
                          : 'Tarih Yok'}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Paper>
          )}
        </TabPanel>

        {/* 2) Kitaplar Tab */}
        <TabPanel value={tabValue} index={1}>
          {loadingBooks && <CircularProgress />}
          {!loadingBooks && errorBooks && (
            <Typography color="error">{errorBooks}</Typography>
          )}
          {!loadingBooks && !errorBooks && (
            <Paper sx={{ mt: 2 }}>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>Kitap Adı</TableCell>
                    <TableCell>Zorluk</TableCell>
                    <TableCell>Kayıt Tarihi</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {books.map((book) => (
                    <TableRow key={book._id}>
                      <TableCell>{book.title || 'Kitap Adı Yok'}</TableCell>
                      <TableCell>{book.difficulty || 'Zorluk Yok'}</TableCell>
                      <TableCell>
                        {book.createdAt
                          ? new Date(book.createdAt).toLocaleString()
                          : 'Tarih Yok'}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Paper>
          )}
        </TabPanel>
      </DialogContent>
    </Dialog>
  );
};

export default OgretmenRaporuModal;
