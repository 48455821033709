import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  AppBar,
  Tabs,
  Tab,
  Box,
  Typography,
  CircularProgress,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  Paper,
  Toolbar,
  IconButton,
  Card,
  CardContent,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { API_URLS } from '../../../../../config/config';

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      {...other}
      style={{ paddingTop: 16 }}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

const KurumRaporuModal = ({ open, onClose, kurumId, kurumAdi  }) => {
  const [tabValue, setTabValue] = useState(0);

  // Okullar, Öğretmenler, Öğrenciler, Books state
  const [okullar, setOkullar] = useState([]);
  const [ogretmenler, setOgretmenler] = useState([]);
  const [ogrenciler, setOgrenciler] = useState([]);
  const [books, setBooks] = useState([]);

  // Loading state
  const [loadingOkullar, setLoadingOkullar] = useState(false);
  const [loadingOgretmenler, setLoadingOgretmenler] = useState(false);
  const [loadingOgrenciler, setLoadingOgrenciler] = useState(false);
  const [loadingBooks, setLoadingBooks] = useState(false);

  // Hata state
  const [errorOkullar, setErrorOkullar] = useState('');
  const [errorOgretmenler, setErrorOgretmenler] = useState('');
  const [errorOgrenciler, setErrorOgrenciler] = useState('');
  const [errorBooks, setErrorBooks] = useState('');

  // Modal açıldığında ya da kurumId değiştiğinde verileri çek
  useEffect(() => {
    if (open && kurumId) {
      fetchOkullar();
      fetchOgretmenler();
      fetchOgrenciler();
      fetchBooks();
    }
    // eslint-disable-next-line
  }, [open, kurumId]);

  const fetchOkullar = async () => {
    setLoadingOkullar(true);
    setErrorOkullar('');
    try {
      const token = localStorage.getItem('userToken');
      const res = await fetch(
        `${API_URLS.ADMIN_URL}kurum/rapor/bayi/${kurumId}/okullar`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (!res.ok) throw new Error('Okullar getirilemedi.');
      const data = await res.json();
      setOkullar(data);
    } catch (err) {
      setErrorOkullar(err.message);
    } finally {
      setLoadingOkullar(false);
    }
  };

  const fetchOgretmenler = async () => {
    setLoadingOgretmenler(true);
    setErrorOgretmenler('');
    try {
      const token = localStorage.getItem('userToken');
      const res = await fetch(
        `${API_URLS.ADMIN_URL}kurum/rapor/bayi/${kurumId}/ogretmenler`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (!res.ok) throw new Error('Öğretmenler getirilemedi.');
      const data = await res.json();
      setOgretmenler(data);
    } catch (err) {
      setErrorOgretmenler(err.message);
    } finally {
      setLoadingOgretmenler(false);
    }
  };

  const fetchOgrenciler = async () => {
    setLoadingOgrenciler(true);
    setErrorOgrenciler('');
    try {
      const token = localStorage.getItem('userToken');
      const res = await fetch(
        `${API_URLS.ADMIN_URL}kurum/rapor/bayi/${kurumId}/ogrenciler`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (!res.ok) throw new Error('Öğrenciler getirilemedi.');
      const data = await res.json();
      setOgrenciler(data);
    } catch (err) {
      setErrorOgrenciler(err.message);
    } finally {
      setLoadingOgrenciler(false);
    }
  };

  const fetchBooks = async () => {
    setLoadingBooks(true);
    setErrorBooks('');
    try {
      const token = localStorage.getItem('userToken');
      const res = await fetch(
        `${API_URLS.ADMIN_URL}kurum/rapor/bayi/${kurumId}/books`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (!res.ok) throw new Error('Kitaplar getirilemedi.');
      const data = await res.json();
      setBooks(data);
    } catch (err) {
      setErrorBooks(err.message);
    } finally {
      setLoadingBooks(false);
    }
  };

  const handleChangeTab = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleClose = () => {
    onClose();
  };

  return (
    <Dialog
      fullWidth
      maxWidth="lg"
      open={open}
      onClose={handleClose}
      sx={{
        '& .MuiPaper-root': {
          borderRadius: 4, // Modal köşelerini yuvarla
          backgroundColor: '#f7f7f7', // Arkaplan rengi
        },
      }}
    >
      {/* Üst başlık alanı */}
      <AppBar
        sx={{
          position: 'relative',
          backgroundColor: '#1976d2', // AppBar rengi
        }}
      >
        <Toolbar>
          <DialogTitle
            sx={{
              flex: 1,
              color: '#fff',
              fontWeight: 'bold',
            }}
          >
             Kurum Raporu – {kurumAdi || kurumId}
          </DialogTitle>
          <IconButton edge="end" color="inherit" onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </AppBar>

      {/* İçerik */}
      <DialogContent dividers>
        {/* Kartların olduğu alan */}
        <Box
          sx={{
            display: 'flex',
            flexWrap: 'wrap',
            gap: 2,
            mb: 2,
          }}
        >
          {/* Okullar Kartı */}
          <Card
            sx={{
              flex: '1 1 200px',
              boxShadow: 3,
              borderRadius: 2,
            }}
          >
            <CardContent>
              <Typography variant="h6" color="primary" gutterBottom>
                Okul Sayısı
              </Typography>
              <Typography variant="h4">{okullar.length}</Typography>
            </CardContent>
          </Card>

          {/* Öğretmenler Kartı */}
          <Card
            sx={{
              flex: '1 1 200px',
              boxShadow: 3,
              borderRadius: 2,
            }}
          >
            <CardContent>
              <Typography variant="h6" color="primary" gutterBottom>
                Öğretmen Sayısı
              </Typography>
              <Typography variant="h4">{ogretmenler.length}</Typography>
            </CardContent>
          </Card>

          {/* Öğrenciler Kartı */}
          <Card
            sx={{
              flex: '1 1 200px',
              boxShadow: 3,
              borderRadius: 2,
            }}
          >
            <CardContent>
              <Typography variant="h6" color="primary" gutterBottom>
                Öğrenci Sayısı
              </Typography>
              <Typography variant="h4">{ogrenciler.length}</Typography>
            </CardContent>
          </Card>

          {/* Books Kartı */}
          <Card
            sx={{
              flex: '1 1 200px',
              boxShadow: 3,
              borderRadius: 2,
            }}
          >
            <CardContent>
              <Typography variant="h6" color="primary" gutterBottom>
                Kitap Sayısı
              </Typography>
              <Typography variant="h4">{books.length}</Typography>
            </CardContent>
          </Card>
        </Box>

        {/* Sekmeler */}
        <Tabs
          value={tabValue}
          onChange={handleChangeTab}
          textColor="primary"
          indicatorColor="primary"
          variant="scrollable"
          sx={{ backgroundColor: '#fff', borderRadius: 1 }}
        >
          <Tab label="Okullar" />
          <Tab label="Öğretmenler" />
          <Tab label="Öğrenciler" />
          <Tab label="Books" />
        </Tabs>

        {/* Tab içerikleri */}
        <TabPanel value={tabValue} index={0}>
          {loadingOkullar && <CircularProgress />}
          {!loadingOkullar && errorOkullar && (
            <Typography color="error">{errorOkullar}</Typography>
          )}
          {!loadingOkullar && !errorOkullar && (
            <Paper sx={{ mt: 2 }}>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>Okul Adı</TableCell>
                    <TableCell>Kayıt Tarihi</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {okullar.map((okul) => (
                    <TableRow key={okul._id}>
                      <TableCell>
                        {okul.okul_adi || 'Okul Adı Yok'}
                      </TableCell>
                      <TableCell>
                        {okul.createdAt
                          ? new Date(okul.createdAt).toLocaleString()
                          : 'Tarih Yok'}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Paper>
          )}
        </TabPanel>

        <TabPanel value={tabValue} index={1}>
          {loadingOgretmenler && <CircularProgress />}
          {!loadingOgretmenler && errorOgretmenler && (
            <Typography color="error">{errorOgretmenler}</Typography>
          )}
          {!loadingOgretmenler && !errorOgretmenler && (
            <Paper sx={{ mt: 2 }}>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>Ad</TableCell>
                    <TableCell>Soyad</TableCell>
                    <TableCell>Kayıt Tarihi</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {ogretmenler.map((ogrt) => (
                    <TableRow key={ogrt._id}>
                      <TableCell>{ogrt.ad || 'Ad Yok'}</TableCell>
                      <TableCell>{ogrt.soyad || 'Soyad Yok'}</TableCell>
                      <TableCell>
                        {ogrt.createdAt
                          ? new Date(ogrt.createdAt).toLocaleString()
                          : 'Tarih Yok'}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Paper>
          )}
        </TabPanel>

        <TabPanel value={tabValue} index={2}>
          {loadingOgrenciler && <CircularProgress />}
          {!loadingOgrenciler && errorOgrenciler && (
            <Typography color="error">{errorOgrenciler}</Typography>
          )}
          {!loadingOgrenciler && !errorOgrenciler && (
            <Paper sx={{ mt: 2 }}>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>Ad</TableCell>
                    <TableCell>Soyad</TableCell>
                    <TableCell>Sınıf</TableCell>
                    <TableCell>Kayıt Tarihi</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {ogrenciler.map((ogr) => (
                    <TableRow key={ogr._id}>
                      <TableCell>{ogr.ad || 'Ad Yok'}</TableCell>
                      <TableCell>{ogr.soyad || 'Soyad Yok'}</TableCell>
                      <TableCell>{ogr.sinif || 'Sınıf Yok'}</TableCell>
                      <TableCell>
                        {ogr.createdAt
                          ? new Date(ogr.createdAt).toLocaleString()
                          : 'Tarih Yok'}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Paper>
          )}
        </TabPanel>

        <TabPanel value={tabValue} index={3}>
          {loadingBooks && <CircularProgress />}
          {!loadingBooks && errorBooks && (
            <Typography color="error">{errorBooks}</Typography>
          )}
          {!loadingBooks && !errorBooks && (
            <Paper sx={{ mt: 2 }}>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>Kitap Adı</TableCell>
                    <TableCell>Kayıt Tarihi</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {books.map((book) => (
                    <TableRow key={book._id}>
                      <TableCell>{book.title || 'Kitap Adı Yok'}</TableCell>
                      <TableCell>
                        {book.createdAt
                          ? new Date(book.createdAt).toLocaleString()
                          : 'Tarih Yok'}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Paper>
          )}
        </TabPanel>
      </DialogContent>
    </Dialog>
  );
};

export default KurumRaporuModal;
