import React from 'react';
import '../styles/SayfaTarama.css';

const GameExplanation = () => {
  return (
    <div className="kayu-game-explanation">
      <h3 className="kayu-instructions-title">Talimatlar</h3>
      <ul className="kayu-instructions-list">
        <li><strong>1. Zorluk ve Paragraf Seçin:</strong> Zorluk seviyesini ve istediğiniz paragrafı seçin.</li>
        <li><strong>2. Çalışmayı Başlatın:</strong> "Başla" butonuna tıklayarak çalışmayı başlatın.</li>
        <li><strong>3. Vurgulanmış Kelimeyi İnceleyin:</strong> Paragrafta rastgele seçilen kelimeleri takip edin.</li>
        <li><strong>4. Süre Dolduğunda:</strong> Çalışma sona erer ve skorunuz görüntülenir.</li>
      </ul>
    </div>
  );
};

export default GameExplanation;
