import React, { useState, useEffect } from 'react';
import { 
  Card, CardContent, TextField, Button, Typography, Container, CircularProgress, 
  FormControl, InputLabel, Select, MenuItem, Grid, useMediaQuery
} from '@mui/material';
import Header from '../../../home/components/Header';
import Footer from '../../../home/components/Footer';
import ResultDialog from '../../components/Auth/Dialog';
import { API_URLS } from '../../../config/config';
import { useNavigate } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';

function OgrenciRegisterForm() {
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobileView = useMediaQuery(theme.breakpoints.down('sm'));

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [emailHata, setEmailHata] = useState(false);
  const [password, setPassword] = useState('');
  const [telefon, setTelefon] = useState('');
  const [telefonHata, setTelefonHata] = useState(false);
  const [okulAdi, setOkulAdi] = useState(''); 
  const [sinif, setSinif] = useState('');
  const [sinifHata, setSinifHata] = useState(false);
  const [sehirListesi, setSehirListesi] = useState([]);
  const [secilenSehirId, setSecilenSehirId] = useState('');
  const [secilenIlceId, setSecilenIlceId] = useState("");
  const [shouldFetch, setShouldFetch] = useState(true);
  const [loading, setLoading] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogContent, setDialogContent] = useState('');
  const [dialogSeverity, setDialogSeverity] = useState('error'); 

  useEffect(() => {
    const fetchSehirler = async () => { 
      if (!shouldFetch) return;
      try {
        const response = await fetch(API_URLS.ADMIN_URL + "sehirler");
        if (!response.ok) {
          throw new Error('Şehir verisi çekilemedi.');
        }
        const sehirler = await response.json();    
        setSehirListesi(sehirler);
      } catch (err) {
        console.error("Şehirler yüklenirken bir hata oluştu:", err.message);
      } finally {
        setShouldFetch(false);
      }
    };

    if (shouldFetch) {
      fetchSehirler();
    }
  }, [shouldFetch]); 

  const handleEmailChange = (event) => {
    const { value } = event.target;
    setEmail(value);
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    setEmailHata(!emailRegex.test(value));
  };

  const handleTelefonChange = (event) => {
    const telefonRegex = /^0(\d{3}) (\d{3}) (\d{4})$/;
    let { value } = event.target;
    const numbers = value.replace(/\D/g, '');  
    value = numbers.substring(0, 4) + 
            (numbers.length > 4 ? ' ' : '') + 
            numbers.substring(4, 7) + 
            (numbers.length > 7 ? ' ' : '') + 
            numbers.substring(7, 11); 
    setTelefonHata(!telefonRegex.test(value) && value !== '');
    setTelefon(value);
  }; 

  const handleSehirChange = (event) => {
    setSecilenSehirId(event.target.value);
    setSecilenIlceId('');
  };  

  const handleIlceChange = (event) => {
    setSecilenIlceId(event.target.value);
  };

  const secilenSehirinIlceleri = sehirListesi.find(sehir => sehir._id === secilenSehirId)?.ilceler || [];

  const handleSinifChange = (event) => {
    const { value } = event.target;
    if (/^\d*$/.test(value)) {
      setSinif(value);
      setSinifHata(false);
    } else {
      setSinifHata(true);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (emailHata) {
      setDialogSeverity('error');
      setDialogContent('Lütfen geçerli bir email adresi giriniz.');
      setDialogOpen(true);
      return;
    }

    if (sinif.trim() === '' || isNaN(sinif) || parseInt(sinif) <= 0) {
      setSinifHata(true);
      setDialogSeverity('error');
      setDialogContent('Lütfen geçerli bir sınıf numarası giriniz.');
      setDialogOpen(true);
      return;
    }

    setLoading(true); 

    try {   
      const response = await fetch(API_URLS.STUDENT_URL, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          ad: firstName,
          soyad: lastName,
          email: email,
          password: password,
          password_open: password,
          okul_adi: okulAdi || undefined,
          telefon: telefon,
          sehir: secilenSehirId,
          ilce: secilenIlceId,  
          sinif: parseInt(sinif),
        }),
      });

      const data = await response.json();
      setLoading(false); 
      if (!response.ok) {   
        setDialogSeverity('error');
        setDialogContent(data.message || 'Kayıt işlemi başarısız.');
        setDialogOpen(true); 
      } else {
        // Kayıt başarılı, şimdi otomatik giriş yapalım
        const loginResponse = await fetch(API_URLS.STUDENT_URL + 'login', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ email, password }),
        });

        const loginData = await loginResponse.json();

        if (loginResponse.ok) {
          localStorage.setItem('userToken', loginData.token);
          setDialogSeverity('success');
          setDialogContent('Kayıt ve giriş başarılı! Yönlendiriliyorsunuz...');
          setDialogOpen(true); 
        } else {
          // Otomatik giriş başarısız olsa da kullanıcı kayıt oldu
          setDialogSeverity('warning');
          setDialogContent('Kayıt başarılı ancak otomatik giriş yapılamadı. Lütfen tekrar giriş yapınız.');
          setDialogOpen(true); 
        }
      }
    } catch (err) {
      setLoading(false);      
      setDialogContent('Kayıt sırasında bir hata oluştu: ' + err.message);
      setDialogOpen(true); 
    }
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
    if (dialogSeverity === 'success') {
      navigate('/ogrenci-dashboard');
    } else if (dialogSeverity === 'warning') {
      navigate('/ogrenci-giris');
    }
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
      {/* Büyük ekranlarda Header */}
      {!isMobileView && <Header />}
      <div className="bg-main" style={{ flex: '1 0 auto' }}>
        <Container maxWidth="sm" sx={{ pt: 3, pb: 3 }}>
          <Card sx={{
            backgroundColor: 'rgba(255, 255, 255, 0.85)', 
            borderRadius: '8px',
            boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
            width: '100%',
            p: isMobileView ? 2 : 3
          
          }}>
            <CardContent>
              {/* Mobilde Geri ve Anasayfaya Git butonları */}
              {isMobileView && (
                <div style={{ width: '100%', marginBottom: '1rem', display: 'flex', justifyContent: 'space-between' }}>
                  <Button variant="contained" color="secondary" onClick={() => navigate(-1)}>
                    Geri
                  </Button>
                  <Button variant="contained" color="secondary" onClick={() => navigate('/')}>
                    Anasayfaya Git
                  </Button>
                </div>
              )}

              <Typography
                variant="h5"
                component="div"
                sx={{
                  marginBottom: 2,
                  color: '#333',
                  fontWeight: 'bold', 
                  letterSpacing: '0.05rem', 
                  fontSize: '1.5rem', 
                  textAlign: 'center'
                }}
              >
                Öğrenci - Kayıt Formu
              </Typography>

              <form onSubmit={handleSubmit} style={{ width: '100%' }}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      label="Ad"
                      variant="outlined"
                      value={firstName}
                      onChange={(e) => setFirstName(e.target.value)}
                      required
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      label="Soyad"
                      variant="outlined"
                      value={lastName}
                      onChange={(e) => setLastName(e.target.value)}
                      required
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      label="Okul Adı *"
                      variant="outlined"
                      value={okulAdi}
                      onChange={(e) => setOkulAdi(e.target.value)}
                      required
                    />
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <TextField 
                      variant="outlined" 
                      fullWidth 
                      label="Sınıf"
                      type="number"
                      value={sinif} 
                      onChange={handleSinifChange} 
                      error={sinifHata}
                      helperText={sinifHata ? 'Lütfen geçerli bir sınıf numarası giriniz.' : ''}     
                      required
                    />
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                      <InputLabel id="sehir-select-label">Şehir</InputLabel>
                      <Select
                        labelId="sehir-select-label"
                        value={secilenSehirId}
                        onChange={handleSehirChange}
                        displayEmpty
                        required
                        renderValue={(selected) => {
                          if (selected === "") {
                            return <em>Şehir Seçiniz</em>;
                          }             
                          const secilenSehir = sehirListesi.find(sehir => sehir._id === selected);
                          return secilenSehir ? secilenSehir.sehir_adi : <em>Şehir Seçiniz</em>;
                        }}
                      >
                        <MenuItem disabled value="">
                          <em>Şehir Seçiniz</em>
                        </MenuItem>
                        {sehirListesi.map((sehir) => (
                          <MenuItem key={sehir._id} value={sehir._id}>
                            {sehir.sehir_adi}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>

                  {secilenSehirId && (
                    <Grid item xs={12} sm={6}>
                      <FormControl fullWidth>
                        <InputLabel id="ilce-select-label">İlçe</InputLabel>
                        <Select
                          labelId="ilce-select-label"
                          value={secilenIlceId}
                          onChange={handleIlceChange}
                          displayEmpty
                          required
                          renderValue={(selected) => {
                            if (!selected) {
                              return <em>İlçe Seçiniz</em>;
                            }
                            const secilenIlce = secilenSehirinIlceleri.find(ilce => ilce._id === selected);
                            return secilenIlce ? secilenIlce.ilce_adi : <em>İlçe Seçiniz</em>;
                          }}
                        >
                          <MenuItem disabled value="">
                            <em>İlçe Seçiniz</em>
                          </MenuItem>
                          {secilenSehirinIlceleri.map((ilce) => (
                            <MenuItem key={ilce._id} value={ilce._id}>
                              {ilce.ilce_adi}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                  )}

                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      label="Telefon"
                      value={telefon}
                      onChange={handleTelefonChange}
                      error={telefonHata}
                      helperText={telefonHata ? 'Telefon numarası 05XX XXX XXXX formatında olmalıdır.' : ''}
                      required
                    />
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      label="E-posta"
                      type="email"
                      variant="outlined"
                      value={email}
                      onChange={handleEmailChange}
                      error={emailHata}
                      helperText={emailHata ? 'Lütfen geçerli bir email adresi giriniz.' : ''}
                      required
                    />
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      label="Şifre"
                      type="password"
                      variant="outlined"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      required
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <Button 
                      type="submit" 
                      variant="contained" 
                      color="primary" 
                      fullWidth 
                      sx={{ mt: 2, py: 1.5 }}
                    > 
                      Kayıt Ol
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </CardContent>
          </Card>
        </Container>
      </div>

      {loading && (
        <div style={{
          position: 'fixed',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          zIndex: 9999,
        }}>
          <CircularProgress />
        </div>
      )}
      <ResultDialog
        open={dialogOpen}
        onClose={handleDialogClose}
        title={
          dialogSeverity === 'error' 
            ? "Bir Hata Oluştu!" 
            : dialogSeverity === 'success' 
            ? "Başarılı!" 
            : "Kayıt Tamamlandı!"
        }
        message={dialogContent}
        severity={dialogSeverity}
      />
      {/* Büyük ekranlarda Footer */}
      {!isMobileView && <Footer />}
    </div>
  );
}

export default OgrenciRegisterForm;
