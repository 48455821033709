import React, { useState, useEffect } from 'react';
import parse, { domToReact } from 'html-react-parser';
import { useNavigate } from 'react-router-dom';
import GameHeader from './components/GameHeader';
import ProgressBar from './components/ProgressBar';
import GameOverModal from './components/GameOverModal';
import GameTimer from './components/GameTimer';
import { useSilinenYaziOyunu } from './hooks/useSilinenYaziOyunu';
import './styles/kayanYazilarStyle.css'; // Kayan Yazılar css

const SilinenYaziOyunu = () => {
  const navigate = useNavigate();
  const token = localStorage.getItem('userToken');

  // Custom Hook verileri
  const {
    userData,
    userRole,
    paragraphs,
    selectedParagraphIndex,
    difficulty,
    speed,
    theme,
    timeLeft,
    gizlenenKelimeSayisi,
    calisiyorMu,
    oyunBitti: oyunGercektenBitti, // <- Hook'tan dönen ham oyunBitti
    progress,
    handleDifficultyChange,
    handleParagraphChange,
    handleThemeChange,
    handleSpeedChange,
    handlePlayPause: hookPlayPause,
    handleReset,
    setSelectedParagraphIndex,
    kelimeler,
  } = useSilinenYaziOyunu(token);

  // -------------------------------------------------
  // 1) Oyun gerçekten başladı mı?
  // -------------------------------------------------
  const [oyunBasladi, setOyunBasladi] = useState(false);

  // -------------------------------------------------
  // 2) Oyun Bitti mi? (Sadece oyun başladıysa aktif)
  // -------------------------------------------------
  const oyunBitti = oyunBasladi && oyunGercektenBitti;

  // -------------------------------------------------
  // 3) Modal (Game Over) açık mı?
  // -------------------------------------------------
  const [showGameOver, setShowGameOver] = useState(false);

  // -------------------------------------------------
  // 4) Modal açma / kapama kontrolü
  // -------------------------------------------------
  useEffect(() => {
    // Oyun başlamadıysa modal açılmasın
    if (!oyunBasladi) return;

    // Oyun bittiyse ve modal kapalıysa => aç
    if (oyunBitti && !showGameOver) {
      setShowGameOver(true);
    }
    // Oyun bitmemişse ve modal açıksa => kapat
    else if (!oyunBitti && showGameOver) {
      setShowGameOver(false);
    }
  }, [oyunBitti, showGameOver, oyunBasladi]);

  // Modal kapatılınca
  const handleCloseModal = () => {
    setShowGameOver(false);
    setOyunBasladi(false); // Oyun başladığını gösteren flag'i sıfırla
    handleReset(); // Oyunu sıfırlamak için hook fonksiyonunu çağır
  };

  // Oyunu yeniden başlat
  const handleRestartGame = () => {
    setShowGameOver(false);
    setOyunBasladi(false); // Oyunu yeniden başlatırken flag'i sıfırla
    handleReset(); // Hook içindeki state’leri resetle
  };

  // Geri dön butonu
  const handleReturnToPreviousStage = () => {
    navigate(-1);
  };

  // -------------------------------------------------
  // "Başlat/Duraklat" butonu
  // -------------------------------------------------
  const handlePlayPause = () => {
    // Oyun ilk kez başlatılıyorsa, setle
    if (!oyunBasladi) {
      setOyunBasladi(true);
    }
    // Eğer zaten bitmişse, bir daha başlatmayalım (isteğe bağlı)
    if (oyunBitti) return;

    // Aksi halde Hook içindeki play/pause fonk. çağır
    hookPlayPause();
  };

  // -------------------------------------------------
  // HTML parse options
  // -------------------------------------------------
  const voidElements = new Set([
    'area','base','br','col','embed','hr','img',
    'input','keygen','link','meta','param','source',
    'track','wbr',
  ]);

  let globalWordCounter = 0; 

  const parseOptions = {
    replace: (node) => {
      // Etiket tipindeki düğümler
      if (node.type === 'tag') {
        if (voidElements.has(node.name)) {
          return React.createElement(
            node.name,
            { key: node.name, ...node.attribs },
            null
          );
        } else {
          return React.createElement(
            node.name,
            { key: node.name, ...node.attribs },
            domToReact(node.children, parseOptions)
          );
        }
      }

      // Metin düğümleri
      if (node.type === 'text') {
      
        const rawWords = node.data.split(' ');

        const replacedWords = rawWords.map((word, idx) => {
          const isHidden =
          globalWordCounter < gizlenenKelimeSayisi && word.trim() !== '';
        globalWordCounter++;

          const wordWithSpace =
          idx < rawWords.length - 1 ? word + ' ' : word;

          return (
            <span
            key={`word-${globalWordCounter}`}
            style={{
              visibility: isHidden ? 'hidden' : 'visible',
              marginRight: '2px',
            }}
          >
            {wordWithSpace}
          </span>
          );
        });

        return <>{replacedWords}</>;
      }
      return undefined;
    },
  };

  // -------------------------------------------------
  // 5) Paragraf içeriği
  // -------------------------------------------------
  const currentContent = paragraphs[selectedParagraphIndex]?.content || '';

  return (
    <div className={`kayan-yazilar-game-container theme-${theme}`}>
      {/* Üst kısım Header */}
      <GameHeader handleReturnToPreviousStage={handleReturnToPreviousStage} />

      {/* Başlık */}
      <div style={{ textAlign: 'center', marginBottom: '20px' }}>
        <h1 style={{ color: 'var(--button-background)' }}>
          Silinen Yazılar Oyunu
        </h1>
      </div>

      {/* Kullanıcı Bilgisi */}
      <div style={{ textAlign: 'center', marginBottom: '20px' }}>
        {userData ? (
          <p>
            Hoş geldiniz, {userRole === 'student' ? 'Öğrenci' : 'Öğretmen'}:{' '}
            <b>{userData.ad || 'İsimsiz'}</b>
          </p>
        ) : (
          <p>Misafir Kullanıcı</p>
        )}
      </div>

      {/* Zorluk & Paragraf & Tema & Hız Seçimi */}
      <div
        className="kayan-yazilar-game-controls"
        style={{ marginBottom: '1rem' }}
      >
        <div>
          <label htmlFor="difficultySelect">Zorluk Seç:</label>
          <select
            id="difficultySelect"
            value={difficulty}
            onChange={(e) => handleDifficultyChange(e.target.value)}
            aria-label="Zorluk Seç"
          >
            <option value="easy">Kolay</option>
            <option value="medium">Orta</option>
            <option value="hard">Zor</option>
          </select>
        </div>

        <div>
          <label htmlFor="paragraphSelect">Paragraf Seç:</label>
          <select
            id="paragraphSelect"
            value={selectedParagraphIndex}
            onChange={(e) => handleParagraphChange(Number(e.target.value))}
            aria-label="Paragraf Seç"
          >
            {paragraphs.map((pg, idx) => (
              <option key={idx} value={idx}>
                {pg.title || `Paragraf ${idx + 1}`}
              </option>
            ))}
          </select>
        </div>

        <div>
          <label htmlFor="themeSelect">Tema Seç:</label>
          <select
            id="themeSelect"
            value={theme}
            onChange={handleThemeChange}
            aria-label="Tema Seç"
          >
            <option value="dark">Koyu</option>
            <option value="light">Açık</option>
            <option value="blue">Mavi</option>
            <option value="green">Yeşil</option>
          </select>
        </div>

        <div>
          <label htmlFor="speedSelect">Hız (ms):</label>
          <input
            type="number"
            id="speedSelect"
            min="100"
            max="2000"
            step="100"
            value={speed}
            onChange={handleSpeedChange}
            aria-label="Hız"
          />
        </div>
      </div>

      {/* Timer */}
      <GameTimer timeLeft={timeLeft} />

      {/* Başlat / Duraklat / Reset Butonları */}
      <div className="kayan-yazilar-controls">
        <button
          onClick={handlePlayPause}
          className="kayan-yazilar-button"
          disabled={oyunBitti}
        >
          {calisiyorMu ? 'Duraklat' : 'Başlat'}
        </button>
        <button onClick={handleRestartGame} className="kayan-yazilar-button">
          Yeniden Başlat
        </button>
      </div>

      {/* Progress Bar */}
      <ProgressBar progress={progress} />

      {/* Metin Gösterimi */}
      <div className="kayan-yazilar-game-display">
        <div
          className="kayan-yazilar-content"
          key={`${selectedParagraphIndex}-${gizlenenKelimeSayisi}`}
        >
          {parse(currentContent, parseOptions)}
        </div>
      </div>

      {/* Durum mesajı */}
      <p className="kayan-yazilar-message">
        {oyunBitti
          ? 'Oyun Bitti! Süre doldu veya tüm kelimeler silindi.'
          : `Silinen kelime sayısı: ${gizlenenKelimeSayisi}/${kelimeler.length}`}
      </p>

      {/* Modal: Oyun Bittiyse Göster */}
      {showGameOver && (
        <GameOverModal
          score={gizlenenKelimeSayisi}
          elapsedTime={((speed * gizlenenKelimeSayisi) / 1000).toFixed(1)}
          onRestart={handleRestartGame}
          onClose={handleCloseModal}
          userData={userData}
        />
      )}
    </div>
  );
};

export default SilinenYaziOyunu;
