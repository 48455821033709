import React from 'react';
import { Typography, Grid, Box, Container, IconButton, Card, CardContent } from '@mui/material';
import { Computer as ComputerIcon, Group as GroupIcon, Diamond as DiamondIcon, CheckCircleOutline as CheckCircleOutlineIcon } from '@mui/icons-material';
import { WhatsApp as WhatsAppIcon, Instagram as InstagramIcon, YouTube as YouTubeIcon } from '@mui/icons-material';
import { styled } from '@mui/material/styles';
import Header from '../components/Header';
import Footer from '../components/Footer';
import HeaderBanner from '../components/ui/Banner'; 
import PackagePromotion from '../components/paketler/PackagePromotion';
import HomeBlogSection from '../components/blog/BlogList';

const StyledCard = styled(Card)(({ theme }) => ({
  minWidth: 275,
  boxShadow: 10,
  borderRadius: 3,
  color: '#fff',
  transition: 'transform 0.3s, box-shadow 0.3s',
  position: 'relative',
  overflow: 'hidden',
  '&:hover': {
    transform: 'scale(1.05)',
    boxShadow: '-3px 8px 23px -2px rgba(0,0,0,0.75)',
  },
}));

const StyledCardContent = styled(CardContent)(({ theme }) => ({
  position: 'relative',
  zIndex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  paddingTop: theme.spacing(6),
  paddingBottom: theme.spacing(6),
  [theme.breakpoints.between('sm','md')]: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
}));

const TitleTypography = styled(Typography)(({ theme }) => ({
  fontWeight: 'bold',
  textShadow: '1px 1px 2px rgba(0,0,0,0.3)',
  [theme.breakpoints.between('sm','md')]: {
    fontSize: '1.8rem',
  },
}));

const SubtitleTypography = styled(Typography)(({ theme }) => ({
  fontFamily: '"Roboto Condensed", sans-serif',
  fontWeight: 700,
  fontSize: 20,
  color: '#fff',
  textShadow: '1px 1px 2px rgba(0,0,0,0.3)',
  marginTop: theme.spacing(1),
  [theme.breakpoints.between('sm','md')]: {
    fontSize: '1rem',
  },
}));

function MainCard({ Icon, title, subtitle, bgColor }) {
  return (
    <StyledCard style={{ background: bgColor }}>
      <Box
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '10px',
          backgroundColor: 'rgba(255,255,255,0.3)',
        }}
      ></Box>

      <StyledCardContent>
        <Icon 
          sx={{ 
            fontSize: 60, 
            mb: 2,
            textShadow: '1px 1px 2px rgba(0,0,0,0.3)',
            '@media (max-width:600px)': { fontSize: 50 },
            '@media (min-width:600px) and (max-width:960px)': { fontSize: 55 }, 
          }} 
        />
        <TitleTypography variant="h4" component="div">
          {title}
        </TitleTypography>
        <SubtitleTypography variant="subtitle1">
          {subtitle}
        </SubtitleTypography>
      </StyledCardContent>
    </StyledCard>
  );
}

function Home() {
  const phoneNumber = '5303033539';
  const whatsappLink = `https://wa.me/90${phoneNumber}`;
  const instagramLink = 'https://www.instagram.com/oku.maks/profilecard/?igsh=d2NwNXkxcGk1MWJs';
  const youtubeLink = 'https://www.youtube.com/@OKUMAKS';

  return (
    <div>
      <Header />
      <HeaderBanner />

      <Box sx={{ flexGrow: 1, py: 8, backgroundColor: '#f0f2f5' }}>
        <Container maxWidth="lg">
          <Typography
            variant="h3"
            align="center"
            gutterBottom
            sx={{
              fontWeight: 'bold', 
              mb: 2, 
              color: '#333',
              '@media (min-width:600px) and (max-width:960px)': {
                fontSize: '2rem',
              },
            }}
          >
            Bizimle Neler Başarabilirsiniz?
          </Typography>

          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <Typography
              variant="h6"
              align="center"
              gutterBottom
              sx={{
                mb: 6,
                color: '#555',
                fontWeight: '500',
                fontStyle: 'italic',
                letterSpacing: '0.5px',
                lineHeight: 1.6,
                background: 'linear-gradient(90deg, #ece9e6, #ffffff)',
                display: 'inline-block',
                padding: '8px 16px',
                borderRadius: '8px',
                boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                '@media (min-width:600px) and (max-width:960px)': {
                  fontSize: '1rem',
                  padding: '6px 12px',
                },
              }}
            >
              Seri Okuma, Okuduğunu Anlama, Paragraf Teknikleri ve Hafıza Teknikleri
            </Typography>
          </Box>

          <Grid container spacing={4}>
            <Grid item xs={12} sm={6} md={3}>
              <MainCard
                Icon={ComputerIcon}
                title="71k+"
                subtitle="Eğitim Kursu Sunulan"
                bgColor="linear-gradient(135deg, #7b1fa2, #9c27b0)"
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <MainCard
                Icon={GroupIcon}
                title="50k+"
                subtitle="Öğrenci Memnuniyeti"
                bgColor="linear-gradient(135deg, #00796b, #009688)"
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <MainCard
                Icon={DiamondIcon}
                title="200+"
                subtitle="Deneyimli Öğretmen"
                bgColor="linear-gradient(135deg, #c2185b, #e91e63)"
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <MainCard
                Icon={CheckCircleOutlineIcon}
                title="95%"
                subtitle="Başarı Oranı"
                bgColor="linear-gradient(135deg, #fbc02d, #ffc107)"
              />
            </Grid>
          </Grid>
        </Container>
      </Box>

      <PackagePromotion />
      <HomeBlogSection />

      <Box
        sx={{
          position: 'fixed',
          top: '50%',
          right: 16,
          transform: 'translateY(-50%)',
          zIndex: 1000,
          display: 'flex',
          flexDirection: 'column',
          gap: 2,
          backgroundColor: 'rgba(255,255,255,0.8)',
          borderRadius: '16px',
          boxShadow: '0 4px 12px rgba(0,0,0,0.15)',
          p: 2,
          '@media (min-width:600px) and (max-width:960px)': {
            right: 8,
            gap: 1.5,
            p: 1.5,
          },
        }}
      >
        <IconButton
          href={instagramLink}
          target="_blank"
          sx={{
            width: 56,
            height: 56,
            bgcolor: '#C13584',
            color: '#fff',
            borderRadius: '50%',
            transition: 'transform 0.3s, box-shadow 0.3s',
            '&:hover': {
              transform: 'scale(1.1)',
              boxShadow: '0 8px 16px rgba(0,0,0,0.2)',
            },
            '@media (min-width:600px) and (max-width:960px)': {
              width: 50,
              height: 50,
            },
          }}
        >
          <InstagramIcon sx={{ fontSize: 28 }} />
        </IconButton>

        <IconButton
          href={youtubeLink}
          target="_blank"
          sx={{
            width: 56,
            height: 56,
            bgcolor: '#FF0000',
            color: '#fff',
            borderRadius: '50%',
            transition: 'transform 0.3s, box-shadow 0.3s',
            '&:hover': {
              transform: 'scale(1.1)',
              boxShadow: '0 8px 16px rgba(0,0,0,0.2)',
            },
            '@media (min-width:600px) and (max-width:960px)': {
              width: 50,
              height: 50,
            },
          }}
        >
          <YouTubeIcon sx={{ fontSize: 28 }} />
        </IconButton>

        <IconButton
          href={whatsappLink}
          target="_blank"
          sx={{
            width: 56,
            height: 56,
            bgcolor: '#25D366',
            color: '#fff',
            borderRadius: '50%',
            transition: 'transform 0.3s, box-shadow 0.3s',
            '&:hover': {
              transform: 'scale(1.1)',
              boxShadow: '0 8px 16px rgba(0,0,0,0.2)',
            },
            '@media (min-width:600px) and (max-width:960px)': {
              width: 50,
              height: 50,
            },
          }}
        >
          <WhatsAppIcon sx={{ fontSize: 28 }} />
        </IconButton>
      </Box>

      <Footer />
    </div>
  );
}

export default Home;
