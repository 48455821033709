import { useState, useEffect, useRef } from 'react';
import axiosInstance from '../../../../utils/axiosInstance';
import { API_URLS } from '../../../../config/config';

const useCumleleriOkuLogic = (userData, token) => {
  const [difficulty, setDifficulty] = useState('easy');
  const [groups, setGroups] = useState([]); // Kelime grupları
  const [displayedGroups, setDisplayedGroups] = useState([]);
  const [isPlaying, setIsPlaying] = useState(false);
  const [isFinished, setIsFinished] = useState(false);
  const [speed, setSpeed] = useState(3000); 
  const [currentIndex, setCurrentIndex] = useState(-1);

  const [elapsedTime, setElapsedTime] = useState(0);
  const [gameTime, setGameTime] = useState(0);
  const [timeLeft, setTimeLeft] = useState(0);

  const timerRef = useRef(null);
  const timeRef = useRef(null);

  const handleReset = () => {
    setIsPlaying(false);
    setIsFinished(false);
    setDisplayedGroups([]);
    setCurrentIndex(-1);
    setElapsedTime(0);
    clearTimeout(timerRef.current);
    clearInterval(timeRef.current);
    // Oyun süresi yeniden ayarlanacak, fetchParagraphs sonrasında
  };

  const getGroupSize = (diff) => {
    if (diff === 'easy') return 1;
    if (diff === 'medium') return 2;
    return 3; // hard ise 3'lük grup
  };

  const createWordGroups = (allWords, groupSize) => {
    const result = [];
    for (let i = 0; i < allWords.length; i += groupSize) {
      const group = allWords.slice(i, i + groupSize).join(' ');
      result.push(group);
    }
    return result;
  };

  useEffect(() => {
    const fetchParagraphs = async () => {
      try {
        const url = `${API_URLS.HIZLIOKUMA_URL}akanyazi/${difficulty}`; 
        const response = await axiosInstance.get(url);
        const data = response.data;
        
        if (data && data.length > 0) {
          const combinedText = data.map(p => p.content).join(' ');
          const cleanedText = combinedText.replace(/<\/?[^>]+(>|$)/g, '').trim();
          const words = cleanedText.split(/\s+/).filter(w => w.length > 0);

          const groupSize = getGroupSize(difficulty);
          const wordGroups = createWordGroups(words, groupSize);
          setGroups(wordGroups);
        } else {
          setGroups([]);
        }

        handleReset();
      } catch (error) {
        console.error('Veri alınırken hata oluştu:', error);
        setGroups([]);
        handleReset();
      }
    };

    fetchParagraphs();
  }, [difficulty, token]);

  // difficulty değiştiğinde hız ayarlarını güncelle
  useEffect(() => {
    if (difficulty === 'easy') {
      setSpeed(1000); // 1 saniye
    } else if (difficulty === 'medium') {
      setSpeed(700); // 0.7 saniye
    } else {
      setSpeed(500); // 0.5 saniye
    }
  }, [difficulty]);

  // Grup sayısı ve hız belli olduktan sonra toplam süreyi ayarla
  useEffect(() => {
    if (groups.length > 0 && speed > 0) {
      const estimatedTime = groups.length * speed; 
      // Kullanıcı yok veya inactive ise 15sn üst limit, aksi halde full estimatedTime
      const maxTime = (!userData || userData.status === 'inactive')
        ? Math.min(15 * 1000, estimatedTime)
        : estimatedTime;

      setGameTime(maxTime);
      setTimeLeft(maxTime);
    }
  }, [groups, speed, userData]);

  useEffect(() => {
    if (isPlaying && groups.length > 0 && currentIndex < groups.length - 1) {
      timerRef.current = setTimeout(() => {
        setCurrentIndex(prev => prev + 1);
      }, speed);

      // Zaman sayacını başlat
      timeRef.current = setInterval(() => {
        setElapsedTime(prev => {
          const newElapsed = prev + 100; // 0.1sn arayla artırım (daha hassas)
          const newTimeLeft = gameTime - newElapsed;
          if (newTimeLeft <= 0) {
            clearInterval(timeRef.current);
            clearTimeout(timerRef.current);
            setIsPlaying(false);
            setIsFinished(true);
          }
          setTimeLeft(newTimeLeft > 0 ? newTimeLeft : 0);
          return newElapsed;
        });
      }, 100);

    } else if (isPlaying && currentIndex >= groups.length - 1) {
      // Tüm gruplar gösterildi
      setIsPlaying(false);
      setIsFinished(true);
    }

    return () => {
      clearTimeout(timerRef.current);
      clearInterval(timeRef.current);
    };
  }, [isPlaying, groups, currentIndex, speed, gameTime]);

  useEffect(() => {
    if (currentIndex >= 0 && currentIndex < groups.length) {
      setDisplayedGroups(prev => [...prev, groups[currentIndex]]);
    }
  }, [currentIndex, groups]);

  const handlePlayPause = () => {
    if (isFinished) return; 
    setIsPlaying(prev => !prev);
  };

  const handleDifficultyChange = (e) => {
    const newDifficulty = e.target.value;
    setDifficulty(newDifficulty);
    // handleReset fetch sonrası zaten yapılıyor
  };

  const handleSpeedChange = (e) => {
    const newSpeed = Number(e.target.value) * 1000;
    setSpeed(newSpeed);
  };

  const getSpeedInSeconds = (speedMs) => {
    return (speedMs / 1000).toFixed(1);
  };

  return {
    difficulty,
    displayedSentences: displayedGroups,
    isPlaying,
    isFinished,
    speed,
    elapsedTime,
    gameTime,
    timeLeft,
    handlePlayPause,
    handleReset,
    handleDifficultyChange,
    handleSpeedChange,
    getSpeedInSeconds
  };
};

export default useCumleleriOkuLogic;
