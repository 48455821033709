import React, { useEffect, useState } from 'react';
import { Container, Typography, Box, Grid, Avatar, Divider } from '@mui/material';
import { useParams } from 'react-router-dom';
import { API_URLS } from '../../config/config';
import Header from '../components/Header';
import Footer from '../components/Footer';

function SingleBlogPage() {
  const { title } = useParams(); 
  const [blog, setBlog] = useState(null);

  useEffect(() => {
    fetch(`${API_URLS.HOME_URL}/blog/${title}`)
      .then(response => response.json())
      .then(data => {
        if (data.success && data.data) {
          setBlog(data.data);
        }
      })
      .catch(error => {
        console.error('Tekil blog getirilirken hata:', error);
      });
  }, [title]);

  if (!blog) {
    return (
      <>
        <Header />
        <Box sx={{ py: 10, backgroundColor: '#fafafa' }}>
          <Container maxWidth="md">
            <Typography 
              variant="h6" 
              align="center"
              sx={{
                fontFamily: 'Merriweather, serif',
                color: '#555'
              }}
            >
              Yükleniyor...
            </Typography>
          </Container>
        </Box>
        <Footer />
      </>
    );
  }

  const imageUrl = blog.image ? `${API_URLS.BLOG_RESİM_URL}/${blog.image.replace(/\\/g, '/')}` : null;

  return (
    <>
      <Header />
      <Box 
        sx={{ 
          backgroundColor: '#fefefe', 
          pt: { xs: 6, md: 10 }, 
          pb: { xs: 6, md: 10 },
          position: 'relative',
          overflow: 'hidden',
        }}
      >
        {/* Hafif bir kâğıt hissi veren arka plan gradyanı */}
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            width: '100%',
            height: '100%',
            background: 'linear-gradient(to bottom, #faf7f2 0%, #ffffff 100%)',
            zIndex: 0
          }}
        />
        <Container maxWidth="md" sx={{ position: 'relative', zIndex: 1 }}>
          <Typography 
            variant="h2"
            align="center"
            sx={{
              fontFamily: 'Merriweather, serif',
              fontWeight: '700',
              color: '#333',
              mb: 4,
              fontSize: { xs: '2.4rem', md: '3rem' },
              lineHeight: 1.3,
              textShadow: '1px 1px 2px rgba(0,0,0,0.1)',
            }}
          >
            {blog.title}
          </Typography>
          {imageUrl && (
            <Box sx={{ mb: 4, textAlign: 'center' }}>
              <Box
                component="img"
                src={imageUrl}
                alt={blog.title}
                sx={{
                  maxWidth: '100%',
                  height: 'auto',
                  borderRadius: 2,
                  boxShadow: '0 4px 15px rgba(0,0,0,0.1)',
                  display: 'inline-block'
                }}
              />
            </Box>
          )}
          <Grid container spacing={2} sx={{ mb: 4, alignItems: 'center', justifyContent: 'center' }}>
            <Grid item>
              <Avatar 
                sx={{ 
                  bgcolor: '#62544C', 
                  width: 56, 
                  height: 56, 
                  fontFamily: 'Merriweather, serif', 
                  fontWeight: '700',
                  color: '#fff'
                }}
              >
                {blog.author && blog.author.charAt(0).toUpperCase()}
              </Avatar>
            </Grid>
            <Grid item>
              <Typography variant="subtitle1" 
                sx={{ 
                  fontWeight: '600', 
                  color: '#333', 
                  fontFamily: 'Merriweather, serif',
                }}
              >
                {blog.author || 'Okumaks'}
              </Typography>
              <Typography variant="body2" sx={{ color: '#666', fontFamily: 'Merriweather, serif' }}>
                {new Date(blog.createdAt).toLocaleDateString('tr-TR', { year: 'numeric', month: 'long', day: 'numeric' })}
              </Typography>
            </Grid>
          </Grid>
          <Divider sx={{ mb: 4, borderColor: 'rgba(0,0,0,0.1)' }} />

          <Box
            sx={{
              p: { xs: 3, md: 5 },
              backgroundColor: 'rgba(255,255,250,0.95)',
              borderRadius: 2,
              boxShadow: '0 2px 8px rgba(0,0,0,0.05)',
              fontFamily: 'Merriweather, serif',
              fontSize: { xs: '1.1rem', md: '1.2rem' },
              color: '#333',
              lineHeight: 1.2, // satır aralığı düşürüldü
              textAlign: 'justify',
              letterSpacing: 'normal', // normal harf aralığı
              maxWidth: '60ch',
              margin: '0 auto',
              '& p': {
                marginBottom: '1em', // paragraf aralıkları azaltıldı
                textIndent: '1em',  // metin girintisi azaltıldı
                position: 'relative',
              },
              '& p:first-of-type::first-letter': {
                fontSize: '1.5rem', // ilk harf boyutu küçültüldü
                fontWeight: '700',
                color: '#62544C',
                // float ve marginRight kaldırıldı
              },
            }}
            dangerouslySetInnerHTML={{ __html: blog.content }}
          />
        </Container>
      </Box>
      <Footer />
    </>
  );
}

export default SingleBlogPage;
