import React, { useState, useEffect } from 'react';
import axios from '../../../utils/axiosInstance'; 
import { Typography, Button, Card, CardContent, Grid, Box, Avatar } from '@mui/material';
import { ArrowForwardIos, Lock, CheckCircle, ArrowBackIos } from '@mui/icons-material';
import { API_URLS } from '../../../config/config';
import { useNavigate } from 'react-router-dom';
import { styled } from '@mui/system';

const StyledCard = styled(Card)(({ theme }) => ({
  position: 'relative',
  overflow: 'visible',
  marginBottom: theme.spacing(4),
  borderRadius: '15px',
  transition: 'transform 0.3s, box-shadow 0.3s',
  boxShadow: theme.shadows[3],
  '&:hover': {
    transform: 'translateY(-8px)',
    boxShadow: theme.shadows[6],
  },
}));

const ExerciseList = ({ stage, userData, onBack }) => {
  const [exercises, setExercises] = useState([]);
  const [progressData, setProgressData] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchExercises = async () => {
      try {
        // Aşamaları al
        let response = await axios.get(`${API_URLS.STUDENT_URL}akillikurs/progress/stages`);
        let stages = response.data.stages || [];
        let currentStage = stages.find((s) => s.stageNumber === stage.number);

        // Eğer mevcut aşama yoksa, aşamayı başlatmaya çalış
        if (!currentStage) {
          try {
            const initResponse = await axios.post(
              `${API_URLS.STUDENT_URL}akillikurs/progress/initialize-stage`,
              {}
            );
            stages = initResponse.data.progress.stages;
            currentStage = stages.find((s) => s.stageNumber === stage.number);
          } catch (initError) {
            if (initError.response && initError.response.status === 400) {
              // Önceki aşama tamamlanmadı
              console.error(initError.response.data.message);
              return;
            } else {
              throw initError;
            }
          }
        }

        // Egzersizleri ayarla
        if (currentStage) {
          setExercises(
            currentStage.games.map((game) => ({
              ...game,
              studentId: userData.id,
            }))
          );
        } else {
          console.error(`Aşama numarası ${stage.number} bulunamadı.`);
        }

        // İlerleme verilerini ayarla
        setProgressData({ stages });
      } catch (error) {
        console.error('Egzersizler alınırken hata oluştu:', error);
      }
    };

    fetchExercises();
  }, [stage, userData.id]);

  const stageProgress = progressData?.stages.find((s) => s.stageNumber === stage.number);

  let prevExerciseCompleted = true;

  // Tüm egzersizlerin tamamlanmış olup olmadığını kontrol et
  const allExercisesCompleted =
    exercises.length > 0 &&
    exercises.every((exercise) => {
      const gameProgress = stageProgress?.games.find((g) => g.gameName === exercise.gameName);
      return gameProgress?.completed;
    });

  const handleCompleteStage = () => {
    navigate('/seviye-tespit-sinav', { state: { stage, fromStage: true } });
  };

  return (
    <Box sx={{ padding: '40px', backgroundColor: '#FFF8E1', minHeight: '100vh' }}>
      <Button
        variant="contained"
        color="primary"
        startIcon={<ArrowBackIos />}
        onClick={onBack}
        sx={{ mb: 2 }}
      >
        Geri
      </Button>
      <Typography
        variant="h3"
        gutterBottom
        sx={{ fontFamily: 'Comic Sans MS', color: '#4E342E', textAlign: 'center', mb: 6 }}
      >
        {stage.title} Egzersizleri
      </Typography>
      <Box sx={{ position: 'relative', marginLeft: '20px' }}>
        {exercises.map((exercise, index) => {
          const gameProgress = stageProgress?.games.find(
            (g) => g.gameName === exercise.gameName
          );
          const completed = gameProgress?.completed || false;

          // Önceki egzersize göre kilit açma mantığı
          let isUnlocked = prevExerciseCompleted;

          // "inactive" durumunda 2. egzersizden sonra kilitle
          if (userData && userData.status === 'inactive' && index > 1) {
            isUnlocked = false;
          }

          prevExerciseCompleted = completed;

          return (
            <Box key={index} sx={{ position: 'relative' }}>
              <StyledCard
                sx={{
                  opacity: isUnlocked ? 1 : 0.6,
                  pointerEvents: isUnlocked ? 'auto' : 'none',
                  backgroundColor: completed
                    ? '#B2FF59'
                    : isUnlocked
                    ? '#FFF176'
                    : '#E0E0E0',
                }}
              >
                {index < exercises.length - 1 && (
                  <Box
                    sx={{
                      position: 'absolute',
                      top: '30px',
                      left: '-15px',
                      width: '2px',
                      height: '100%',
                      bgcolor: '#e0e0e0',
                    }}
                  ></Box>
                )}
                <CardContent>
                  <Grid container alignItems="center">
                    <Grid item xs={10}>
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Avatar
                          sx={{
                            bgcolor: completed ? 'success.main' : 'primary.main',
                            mr: 2,
                          }}
                        >
                          {completed ? <CheckCircle /> : <ArrowForwardIos />}
                        </Avatar>
                        <Typography
                          variant="h5"
                          component="h2"
                          sx={{ fontFamily: 'Comic Sans MS', color: '#BF360C' }}
                        >
                          {exercise.gameName}
                        </Typography>
                      </Box>
                      <Typography variant="body2" component="p" sx={{ mt: 1, color: '#6D4C41' }}>
                        {exercise.description}
                      </Typography>
                    </Grid>
                    <Grid item xs={2} sx={{ textAlign: 'right' }}>
                      {isUnlocked ? (
                        <Button
                          variant="contained"
                          color={completed ? 'success' : 'primary'}
                          onClick={() => navigate(exercise.path, { state: { stage, exercise } })}
                          endIcon={<ArrowForwardIos />}
                          sx={{ fontWeight: 'bold' }}
                        >
                          {completed ? 'Tekrar Oyna' : 'Başla'}
                        </Button>
                      ) : (
                        <Button
                          variant="contained"
                          color="secondary"
                          disabled
                          startIcon={<Lock />}
                          sx={{ fontWeight: 'bold' }}
                        >
                          Kilitli
                        </Button>
                      )}
                    </Grid>
                  </Grid>
                </CardContent>
                {!isUnlocked && (
                  <Box
                    sx={{
                      position: 'absolute',
                      top: 0,
                      left: 0,
                      width: '100%',
                      height: '100%',
                      bgcolor: 'rgba(255, 255, 255, 0.7)',
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      justifyContent: 'center',
                      borderRadius: '15px',
                      p: 2,
                    }}
                  >
                    {userData && userData.status === 'inactive' && index > 1 ? (
                      <>
                        <Typography
                          variant="body1"
                          sx={{ color: '#333', textAlign: 'center', fontWeight: 'bold' }}
                        >
                          Bu egzersize erişmek için hesabınızı aktif etmelisiniz.
                        </Typography>
                        <Button
                          variant="contained"
                          color="secondary"
                          onClick={() =>
                            navigate('/ogrenci-dashboard', { state: { selectedMenu: 'BakiyeYukle' } })
                          }
                          sx={{ mt: 2 }}
                        >
                          Hesabı Aktif Et
                        </Button>
                      </>
                    ) : (
                      <Typography variant="body1" sx={{ color: '#333', textAlign: 'center' }}>
                        Bu egzersiz henüz kilitli. Devam etmek için önceki egzersizi tamamlayın.
                      </Typography>
                    )}
                  </Box>
                )}
              </StyledCard>
            </Box>
          );
        })}

        {allExercisesCompleted && (
          <Box sx={{ mt: 4 }}>
            <StyledCard
              sx={{
                backgroundColor: '#64B5F6',
              }}
            >
              <CardContent>
                <Grid container alignItems="center">
                  <Grid item xs={10}>
                    <Typography
                      variant="h5"
                      component="h2"
                      sx={{ fontFamily: 'Comic Sans MS', color: '#FFFFFF' }}
                    >
                      Aşamayı Tamamla
                    </Typography>
                    <Typography variant="body2" component="p" sx={{ mt: 1, color: '#FFFFFF' }}>
                      Tüm egzersizleri tamamladınız. Aşamayı tamamlamak için testi geçin.
                    </Typography>
                  </Grid>
                  <Grid item xs={2} sx={{ textAlign: 'right' }}>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleCompleteStage}
                      endIcon={<ArrowForwardIos />}
                      sx={{ fontWeight: 'bold' }}
                    >
                      Teste Git
                    </Button>
                  </Grid>
                </Grid>
              </CardContent>
            </StyledCard>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default ExerciseList;
