// OgretmenRaporuModal.jsx
import React, { useState, useEffect } from 'react';
import {Dialog, DialogTitle, DialogContent, AppBar, Toolbar, IconButton, Tabs, Tab, Box, Typography, CircularProgress, Paper, Table, TableHead, TableBody, TableCell, TableRow,
  Card, CardContent,} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { API_URLS } from '../../../../config/config';  // Projenizdeki konuma göre ayarlayın

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div role="tabpanel" hidden={value !== index} {...other}>
      {value === index && <Box sx={{ p: 2 }}>{children}</Box>}
    </div>
  );
}

const OgretmenRaporuModal = ({ open, onClose, teacherId, teacherName = '' }) => {
  const [tabValue, setTabValue] = useState(0);

  // Veriler
  const [ogrenciler, setOgrenciler] = useState([]);
  const [books, setBooks] = useState([]);

  // Loading state
  const [loadingOgrenciler, setLoadingOgrenciler] = useState(false);
  const [loadingBooks, setLoadingBooks] = useState(false);

  // Error state
  const [errorOgrenciler, setErrorOgrenciler] = useState('');
  const [errorBooks, setErrorBooks] = useState('');

  // Rapor sayıları
  const ogrenciSayisi = ogrenciler.length;
  const kitapSayisi = books.length;

  // Modal açıldığında verileri çek
  useEffect(() => {
    if (open && teacherId) {
      fetchOgrenciler();
      fetchBooks();
    }
    // eslint-disable-next-line
  }, [open, teacherId]);

  const fetchOgrenciler = async () => {
    setLoadingOgrenciler(true);
    setErrorOgrenciler('');
    try {
      const token = localStorage.getItem('userToken');
      const res = await fetch(
        `${API_URLS.SCHOOL_URL}rapor/${teacherId}/ogrenciler`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      if (!res.ok) throw new Error('Öğrenciler getirilemedi.');
      const data = await res.json();
      setOgrenciler(data);
    } catch (err) {
      setErrorOgrenciler(err.message);
    } finally {
      setLoadingOgrenciler(false);
    }
  };

  const fetchBooks = async () => {
    setLoadingBooks(true);
    setErrorBooks('');
    try {
      const token = localStorage.getItem('userToken');
      const res = await fetch(
        `${API_URLS.SCHOOL_URL}rapor/${teacherId}/books`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      if (!res.ok) throw new Error('Kitaplar getirilemedi.');
      const data = await res.json();
      setBooks(data);
    } catch (err) {
      setErrorBooks(err.message);
    } finally {
      setLoadingBooks(false);
    }
  };

  const handleChangeTab = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleClose = () => {
    onClose();
  };

  return (
    <Dialog fullWidth maxWidth="lg" open={open} onClose={handleClose}>
      <AppBar sx={{ position: 'relative' }}>
        <Toolbar>
          <DialogTitle sx={{ flex: 1, color: '#fff' }}>
            {/* Örnek: "Öğretmen Raporu – Ahmet Yılmaz" */}
            Öğretmen Raporu – {teacherName || teacherId}
          </DialogTitle>
          <IconButton edge="end" color="inherit" onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </AppBar>

      <DialogContent dividers>
        {/* ÖZET KARTLAR */}
        <Box sx={{ display: 'flex', gap: 2, mb: 2, flexWrap: 'wrap' }}>
          <Card sx={{ boxShadow: 3, flex: '1 1 200px' }}>
            <CardContent>
              <Typography variant="h6" gutterBottom color="primary">
                Öğrenci Sayısı
              </Typography>
              <Typography variant="h4">{ogrenciSayisi}</Typography>
            </CardContent>
          </Card>

          <Card sx={{ boxShadow: 3, flex: '1 1 200px' }}>
            <CardContent>
              <Typography variant="h6" gutterBottom color="primary">
                Kitap Sayısı
              </Typography>
              <Typography variant="h4">{kitapSayisi}</Typography>
            </CardContent>
          </Card>
        </Box>

        {/* TABLAR */}
        <Tabs
          value={tabValue}
          onChange={handleChangeTab}
          textColor="primary"
          indicatorColor="primary"
          variant="scrollable"
        >
          <Tab label="Öğrenciler" />
          <Tab label="Kitaplar" />
        </Tabs>

        <TabPanel value={tabValue} index={0}>
          {loadingOgrenciler ? (
            <CircularProgress />
          ) : errorOgrenciler ? (
            <Typography color="error">{errorOgrenciler}</Typography>
          ) : (
            <Paper sx={{ mt: 2 }}>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>Ad</TableCell>
                    <TableCell>Soyad</TableCell>
                    <TableCell>Email</TableCell>
                    <TableCell>Kayıt Tarihi</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {ogrenciler.map((ogr) => (
                    <TableRow key={ogr._id}>
                      <TableCell>{ogr.ad || 'Ad Yok'}</TableCell>
                      <TableCell>{ogr.soyad || 'Soyad Yok'}</TableCell>
                      <TableCell>{ogr.email || 'Email Yok'}</TableCell>
                      <TableCell>
                        {ogr.createdAt
                          ? new Date(ogr.createdAt).toLocaleDateString()
                          : 'Tarih Yok'}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Paper>
          )}
        </TabPanel>

        <TabPanel value={tabValue} index={1}>
          {loadingBooks ? (
            <CircularProgress />
          ) : errorBooks ? (
            <Typography color="error">{errorBooks}</Typography>
          ) : (
            <Paper sx={{ mt: 2 }}>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>Kitap Adı</TableCell>
                    <TableCell>Zorluk</TableCell>
                    <TableCell>Eklenme Tarihi</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {books.map((book) => (
                    <TableRow key={book._id}>
                      <TableCell>{book.title || 'Kitap Adı Yok'}</TableCell>
                      <TableCell>{book.difficulty || 'Zorluk Yok'}</TableCell>
                      <TableCell>
                        {book.createdAt
                          ? new Date(book.createdAt).toLocaleDateString()
                          : 'Tarih Yok'}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Paper>
          )}
        </TabPanel>
      </DialogContent>
    </Dialog>
  );
};

export default OgretmenRaporuModal;
