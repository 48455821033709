import React, { useEffect, useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, FormControl, InputLabel, Select, MenuItem, Radio, RadioGroup, FormControlLabel, FormLabel, Typography
} from '@mui/material';
import { API_URLS } from '../../../../config/config'; 

const OgretmenAtamaDialog = ({ open, onClose, ogretmen }) => {
  const [assignType, setAssignType] = useState('okul'); // 'okul' veya 'kurum'
  const [schoolList, setSchoolList] = useState([]);
  const [institutionList, setInstitutionList] = useState([]);
  const [selectedSchoolId, setSelectedSchoolId] = useState('');
  const [selectedInstitutionId, setSelectedInstitutionId] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (open) {
      // Dialog açıldığında, okul ve kurum listelerini çek
      fetchSchools();
      fetchInstitutions();
    }
  }, [open]);

  const fetchSchools = async () => {
    try {
      const response = await fetch(API_URLS.ADMIN_URL + 'schools');
      if (!response.ok) throw new Error('Okul listesi yüklenemedi!');
      const data = await response.json();
      setSchoolList(data);
    } catch (error) {
      console.error(error);
    }
  };

  const fetchInstitutions = async () => {
    try {
      const response = await fetch(API_URLS.ADMIN_URL + 'kurumsal');
      if (!response.ok) throw new Error('Kurum listesi yüklenemedi!');
      const data = await response.json();
      setInstitutionList(data);
    } catch (error) {
      console.error(error);
    }
  };

  const handleAssign = async () => {
    if (!ogretmen) return;

    setLoading(true);

    // İstek gövdesinde kurumId veya okulId göndereceğiz
    const bodyData = {};
    if (assignType === 'okul' && selectedSchoolId) {
      bodyData.okulId = selectedSchoolId;
    } else if (assignType === 'kurum' && selectedInstitutionId) {
      bodyData.kurumId = selectedInstitutionId;
    } else {
      alert('Lütfen geçerli bir seçim yapın!');
      setLoading(false);
      return;
    }

    try {
      // Örnek: PATCH veya POST kullanılabilir
      const response = await fetch(
        `${API_URLS.ADMIN_URL}teachers/${ogretmen._id}/assign`, 
        {
          method: 'PATCH', 
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(bodyData),
        }
      );

      const result = await response.json();

      if (!response.ok) {
        alert(result.message || 'Atama işlemi başarısız.');
      } else {
        alert('Öğretmen ataması başarılı!');
        onClose();
      }
    } catch (error) {
      console.error('Atama işlemi hatası:', error);
      alert('Bir hata oluştu.');
    } finally {
      setLoading(false);
    }
  };

  // Dialog'u kapatma
  const handleCancel = () => {
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>Öğretmen Atama</DialogTitle>
      <DialogContent>
        {ogretmen && (
          <Typography variant="subtitle1" gutterBottom>
            <strong>Öğretmen:</strong> {ogretmen.ad} {ogretmen.soyad}
          </Typography>
        )}

        {/* Kurum mu okul mu? */}
        <FormControl component="fieldset" margin="normal">
          <FormLabel component="legend">Atama Türü</FormLabel>
          <RadioGroup
            row
            value={assignType}
            onChange={(e) => setAssignType(e.target.value)}
          >
            <FormControlLabel value="okul" control={<Radio />} label="Okul" />
            <FormControlLabel value="kurum" control={<Radio />} label="Kurum" />
          </RadioGroup>
        </FormControl>

        {assignType === 'okul' && (
          <FormControl fullWidth variant="outlined" margin="normal">
            <InputLabel id="select-school-label">Okul Seç</InputLabel>
            <Select
              labelId="select-school-label"
              label="Okul Seç"
              value={selectedSchoolId}
              onChange={(e) => setSelectedSchoolId(e.target.value)}
            >
              <MenuItem value="">
                <em>Seçiniz</em>
              </MenuItem>
              {schoolList.map((school) => (
                <MenuItem key={school._id} value={school._id}>
                  {school.okul_adi}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}

        {assignType === 'kurum' && (
          <FormControl fullWidth variant="outlined" margin="normal">
            <InputLabel id="select-institution-label">Kurum Seç</InputLabel>
            <Select
              labelId="select-institution-label"
              label="Kurum Seç"
              value={selectedInstitutionId}
              onChange={(e) => setSelectedInstitutionId(e.target.value)}
            >
              <MenuItem value="">
                <em>Seçiniz</em>
              </MenuItem>
              {institutionList.map((inst) => (
                <MenuItem key={inst._id} value={inst._id}>
                  {inst.kurum_adi}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
      </DialogContent>

      <DialogActions>
        <Button onClick={handleCancel} disabled={loading}>
          İptal
        </Button>
        <Button onClick={handleAssign} variant="contained" color="primary" disabled={loading}>
          Kaydet
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default OgretmenAtamaDialog;
