// src/components/okul/OkulBalanceTransferDialog.jsx (Örnek konum)

import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Select,
  MenuItem,
  TextField
} from '@mui/material';

/**
 * Okullara bakiye transferi için dialog bileşeni.
 * 
 * @param {boolean} open - Dialog açık mı?
 * @param {function} onClose - Dialog kapatma fonksiyonu
 * @param {string} packageType - Seçilen paket tipi (bakiye3Ay, bakiye6Ay, bakiye12Ay)
 * @param {function} setPackageType - Paket tipini güncelleme fonksiyonu
 * @param {number} transferAmount - Transfer edilecek tutar
 * @param {function} setTransferAmount - Tutarı güncelleme fonksiyonu
 * @param {function} handleTransfer - "Transfer Et" butonuna basıldığında çalışacak fonksiyon
 */
const OkulBalanceTransferDialog = ({
  open,
  onClose,
  packageType,
  setPackageType,
  transferAmount,
  setTransferAmount,
  handleTransfer,
}) => {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Okul Bakiye Transferi</DialogTitle>
      <DialogContent>
        <Select
          fullWidth
          value={packageType}
          onChange={(e) => setPackageType(e.target.value)}
          sx={{ mt: 1 }}
        >
          <MenuItem value="bakiye3Ay">3 Ay</MenuItem>
          <MenuItem value="bakiye6Ay">6 Ay</MenuItem>
          <MenuItem value="bakiye12Ay">12 Ay</MenuItem>
        </Select>

        <TextField
          fullWidth
          label="Tutar"
          type="number"
          value={transferAmount}
          onChange={(e) => setTransferAmount(Number(e.target.value))}
          sx={{ mt: 2 }}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>İptal</Button>
        <Button onClick={handleTransfer} color="primary" variant="contained">
          Transfer Et
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default OkulBalanceTransferDialog;
