import React, { useState } from 'react';
import {
  AppBar, Toolbar, IconButton, Button, Drawer, List, ListItem, ListItemIcon, ListItemText, useMediaQuery, Box, Menu, MenuItem, Avatar
} from '@mui/material';
import {
  Menu as MenuIcon, Home as HomeIcon, Speed as SpeedIcon, FitnessCenter as FitnessCenterIcon, Assignment as AssignmentIcon, TrendingUp as TrendingUpIcon,
  Login as LoginIcon, PersonAdd as PersonAddIcon, AccountCircle as AccountCircleIcon, Close as CloseIcon, Logout as LogoutIcon,
  School as SchoolIcon, Article as ArticleIcon, Phone as PhoneIcon
} from '@mui/icons-material';
import { Link, useNavigate } from 'react-router-dom';
import { styled, useTheme } from '@mui/material/styles';
import axios from '../../utils/axiosInstance';
import { API_URLS } from '../../config/config';
import useFetchStudentDataForHome from '../../hooks/ogrenci/useFetchStudentDataForHome';
import useFetchTeacherDataForHome from '../../hooks/ogretmen/useFetchTeacherDataForHome';
import Logo from '../../assets/images/logo/120.png';

const StyledAppBar = styled(AppBar)(({ theme }) => ({
  background: 'linear-gradient(135deg, #0A192F 0%, #203A43 25%, #2C5364 50%, #47688E 75%, #6F9AB8 100%)',
  color: '#FFFFFF',
  transition: 'background 0.5s',
  backdropFilter: 'blur(10px)',
}));

const AnimatedIconButton = styled(IconButton)(({ theme }) => ({
  color: '#FFFFFF',
  '&:hover': {
    color: '#FFD700',
    transform: 'scale(1.1)',
    transition: 'transform 0.3s, color 0.3s',
  },
}));

const StyledButton = styled(Button)(({ theme }) => ({
  color: '#FFFFFF',
  fontWeight: 'bold',
  margin: theme.spacing(1),
  fontFamily: 'Roboto, sans-serif',
  '&:hover': {
    color: '#FFD700',
    transform: 'scale(1.05)',
    transition: 'transform 0.3s, color 0.3s',
  },
  [theme.breakpoints.between('sm','md')]: {
    fontSize: '0.9rem',
    margin: theme.spacing(0.5),
  },
}));

const LogoImage = styled('img')(({ theme }) => ({
  width: '200px',
  height: 'auto',
  [theme.breakpoints.between('sm','md')]: {
    width: '180px', 
  },
  [theme.breakpoints.down('sm')]: {
    width: '150px',
  },
}));

const LogoContainer = styled(Box)(({ theme }) => ({
  backgroundColor: 'rgba(255, 255, 255, 0.1)',
  padding: theme.spacing(1, 2),
  borderRadius: theme.shape.borderRadius,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
  [theme.breakpoints.between('sm','md')]: {
    padding: theme.spacing(1, 1.5),
  },
}));

const TelefonContainer = styled(Box)(({ theme }) => ({
  backgroundColor: 'rgba(255,255,255,0.1)',
  padding: theme.spacing(0.5, 1.5),
  borderRadius: theme.shape.borderRadius,
  boxShadow: '0 4px 6px rgba(0,0,0,0.1)',
  display: 'flex',
  alignItems: 'center',
  [theme.breakpoints.between('sm','md')]: {
    padding: theme.spacing(0.5, 1),
  },
}));

function Header() {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [userData, setUserData] = useState(null);
  const [teacherData, setTeacherData] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const navigate = useNavigate();

  useFetchStudentDataForHome(setUserData);
  useFetchTeacherDataForHome(setTeacherData);

  const handleDrawerToggle = () => {
    setDrawerOpen(!drawerOpen);
  };

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleLogoutStudent = async () => {
    try {
      await axios.post(`${API_URLS.STUDENT_URL}/logout`);
      navigate('/ogrenci-giris');
    } catch (error) {
      console.error('Çıkış işlemi sırasında bir hata oluştu', error);
    }
  };

  const handleLogoutTeacher = async () => {
    try {
      await fetch(API_URLS.TEACHER_URL + 'logout', {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('userToken')}`,
          'Content-Type': 'application/json',
        },
      });
      localStorage.removeItem('userToken');
      navigate('/ogretmen-giris');
    } catch (error) {
      console.error('Çıkış işlemi sırasında bir hata oluştu', error);
    }
  };

  const navigateToAkilliKurs = () => {
    navigate('/ogrenci-dashboard', { state: { selectedMenu: 'AkilliKurs' } });
  };

  const navigateToTeacherDashboard = () => {
    navigate('/ogretmen-dashboard');
  };

  return (
    <StyledAppBar position="sticky">
      <Toolbar>
        {isMobile && (
          <AnimatedIconButton edge="start" aria-label="menu" onClick={handleDrawerToggle}>
            <MenuIcon />
          </AnimatedIconButton>
        )}
        <Box
          sx={{
            flexGrow: 1,
            display: 'flex',
            flexDirection: isMobile ? 'column' : 'row',
            justifyContent: isMobile ? 'center' : 'flex-start',
            alignItems: 'center',
          }}
        >
          <LogoContainer>
            <Link to="/">
              <LogoImage src={Logo} alt="Okumaks Logo" />
            </Link>
          </LogoContainer>
          
          <Box sx={{ 
            mt: isMobile ? 1 : 0, 
            ml: isMobile ? 0 : 2,
            display: 'flex', 
            justifyContent: 'center', 
            width: isMobile ? '100%' : 'auto' 
          }}>
            <TelefonContainer sx={{
              justifyContent: 'center', 
              width: isMobile ? '100%' : 'auto'
            }}>
              <StyledButton href="tel:+905303033539" startIcon={<PhoneIcon />}>
                Telefonla Arayınız
              </StyledButton>
            </TelefonContainer>
          </Box>
        </Box>
        {!isMobile ? (
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <StyledButton component={Link} to="/" startIcon={<HomeIcon />}>Anasayfa</StyledButton>
            <StyledButton component={Link} to="/hizli-okuma" startIcon={<SpeedIcon />}>Hızlı Okuma</StyledButton>
            <StyledButton component={Link} to="/egsersizler" startIcon={<FitnessCenterIcon />}>Egzersizler</StyledButton>
            <StyledButton component={Link} to="/testler" startIcon={<AssignmentIcon />}>Testler</StyledButton>
            <StyledButton component={Link} to="/seviye-tespit-sinav" startIcon={<TrendingUpIcon />}>Hız Testi</StyledButton>
            <StyledButton component={Link} to="/blog" startIcon={<ArticleIcon />}>Blog</StyledButton>

            {userData && (
              <StyledButton onClick={navigateToAkilliKurs} startIcon={<SchoolIcon />}>
                Akıllı Kurs (Öğrenci)
              </StyledButton>
            )}

            {teacherData && (
              <StyledButton onClick={navigateToTeacherDashboard} startIcon={<SchoolIcon />}>
                Öğretmen Paneli
              </StyledButton>
            )}

            {!userData && !teacherData && (
              <>
                <StyledButton component={Link} to="/login" startIcon={<LoginIcon />}>Giriş Yap</StyledButton>
                <StyledButton component={Link} to="/register" startIcon={<PersonAddIcon />}>Kayıt Ol</StyledButton>
              </>
            )}

            {(userData || teacherData) && (
              <AnimatedIconButton onClick={handleMenuOpen}>
                <AccountCircleIcon sx={{ fontSize: 40 }} />
              </AnimatedIconButton>
            )}

          </Box>
        ) : (
          <Drawer anchor="left" open={drawerOpen} onClose={handleDrawerToggle}>
            <Box sx={{ width: 250 }}>
              <List>
                <ListItem>
                  <ListItemIcon>
                    <Avatar sx={{ bgcolor: 'transparent' }}>
                      <CloseIcon onClick={handleDrawerToggle} />
                    </Avatar>
                  </ListItemIcon>
                </ListItem>
                <ListItem button component={Link} to="/" onClick={handleDrawerToggle}>
                  <ListItemIcon><HomeIcon /></ListItemIcon>
                  <ListItemText primary="Anasayfa" />
                </ListItem>
                <ListItem button component={Link} to="/hizli-okuma" onClick={handleDrawerToggle}>
                  <ListItemIcon><SpeedIcon /></ListItemIcon>
                  <ListItemText primary="Hızlı Okuma" />
                </ListItem>
                <ListItem button component={Link} to="/egsersizler" onClick={handleDrawerToggle}>
                  <ListItemIcon><FitnessCenterIcon /></ListItemIcon>
                  <ListItemText primary="Egzersizler" />
                </ListItem>
                <ListItem button component={Link} to="/testler" onClick={handleDrawerToggle}>
                  <ListItemIcon><AssignmentIcon /></ListItemIcon>
                  <ListItemText primary="Testler" />
                </ListItem>
                <ListItem button component={Link} to="/seviye-tespit-sinav" onClick={handleDrawerToggle}>
                  <ListItemIcon><TrendingUpIcon /></ListItemIcon>
                  <ListItemText primary="Hız Testi" />
                </ListItem>
                <ListItem button component={Link} to="/blog" onClick={handleDrawerToggle}>
                  <ListItemIcon><ArticleIcon /></ListItemIcon>
                  <ListItemText primary="Blog" />
                </ListItem>

                {userData && (
                  <ListItem
                    button
                    onClick={() => {
                      navigateToAkilliKurs();
                      handleDrawerToggle();
                    }}
                  >
                    <ListItemIcon><SchoolIcon /></ListItemIcon>
                    <ListItemText primary="Akıllı Kurs (Öğrenci)" />
                  </ListItem>
                )}

                {teacherData && (
                  <ListItem
                    button
                    onClick={() => {
                      navigateToTeacherDashboard();
                      handleDrawerToggle();
                    }}
                  >
                    <ListItemIcon><SchoolIcon /></ListItemIcon>
                    <ListItemText primary="Öğretmen Paneli" />
                  </ListItem>
                )}

                <ListItem button component="a" href="tel:+905303033539" onClick={handleDrawerToggle}>
                  <ListItemIcon><PhoneIcon /></ListItemIcon>
                  <ListItemText primary="Telefonla Arayınız" />
                </ListItem>

                {!userData && !teacherData ? (
                  <>
                    <ListItem button component={Link} to="/login" onClick={handleDrawerToggle}>
                      <ListItemIcon><LoginIcon /></ListItemIcon>
                      <ListItemText primary="Giriş Yap" />
                    </ListItem>
                    <ListItem button component={Link} to="/register" onClick={handleDrawerToggle}>
                      <ListItemIcon><PersonAddIcon /></ListItemIcon>
                      <ListItemText primary="Kayıt Ol" />
                    </ListItem>
                  </>
                ) : (
                  <>
                    {userData && !teacherData && (
                      <>
                        <ListItem button component={Link} to="/ogrenci-dashboard" onClick={handleDrawerToggle}>
                          <ListItemIcon><AccountCircleIcon /></ListItemIcon>
                          <ListItemText primary="Profilim/Ayarlar" />
                        </ListItem>
                        <ListItem button onClick={handleLogoutStudent}>
                          <ListItemIcon><LogoutIcon /></ListItemIcon>
                          <ListItemText primary="Çıkış Yap (Öğrenci)" />
                        </ListItem>
                      </>
                    )}
                    {teacherData && (
                      <>
                        <ListItem button component={Link} to="/ogretmen-dashboard" onClick={handleDrawerToggle}>
                          <ListItemIcon><AccountCircleIcon /></ListItemIcon>
                          <ListItemText primary="Öğretmen Profil/Ayarlar" />
                        </ListItem>
                        <ListItem button onClick={handleLogoutTeacher}>
                          <ListItemIcon><LogoutIcon /></ListItemIcon>
                          <ListItemText primary="Çıkış Yap (Öğretmen)" />
                        </ListItem>
                      </>
                    )}
                  </>
                )}
              </List>
            </Box>
          </Drawer>
        )}
        <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleMenuClose}>
          {userData && !teacherData && (
            <>
              <MenuItem component={Link} to="/ogrenci-dashboard" onClick={handleMenuClose}>
                Profilim/Ayarlar
              </MenuItem>
              <MenuItem onClick={handleLogoutStudent}>Çıkış Yap (Öğrenci)</MenuItem>
            </>
          )}
          {teacherData && (
            <>
              <MenuItem component={Link} to="/ogretmen-dashboard" onClick={handleMenuClose}>
                Öğretmen Profil/Ayarlar
              </MenuItem>
              <MenuItem onClick={handleLogoutTeacher}>Çıkış Yap (Öğretmen)</MenuItem>
            </>
          )}
        </Menu>
      </Toolbar>
    </StyledAppBar>
  );
}

export default Header;
