import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate, useLocation } from 'react-router-dom';
import { useLetterGridGame } from './hooks/useLetterGridGame';
import HeaderSection from './components/HeaderSection';
import GameControls from './components/GameControls';
import GameStatus from './components/GameStatus';
import LetterGrid from './components/LetterGrid';
import MessageAlert from './components/MessageAlert';
import GameOverModal from './components/GameOverModal';
import { API_URLS } from '../../../config/config';
import './styles/harfIzgarasiStyle.css';
import axiosInstance from '../../../utils/axiosInstance';

const HarfIzgarasiOyunu = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { stage, exercise } = location.state || {};
  const token = localStorage.getItem('userToken');

  const [userData, setUserData] = useState(null);
  const [userRole, setUserRole] = useState(null);

  // Aşamaya göre zorluk ayarlama
  const stageNumber = stage?.number || null;
  let initialDifficulty = 'easy';
  let difficultyLocked = false;

  if (stageNumber) {
    difficultyLocked = true;
    if (stageNumber >= 1 && stageNumber <= 7) {
      initialDifficulty = 'easy';
    } else if (stageNumber >= 8 && stageNumber <= 14) {
      initialDifficulty = 'medium';
    } else {
      initialDifficulty = 'hard';
    }
  }

  // Kullanıcı verileri çekme
  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const studentResponse = await axiosInstance.get(`${API_URLS.STUDENT_URL}/me`);
        if (
          studentResponse.status === 200 &&
          studentResponse.data &&
          studentResponse.data.status === 'active'
        ) {
          const studentData = studentResponse.data;
          setUserData(studentData);
          setUserRole('student');
          return;
        } else {
          setUserData(null);
          setUserRole(null);
        }
      } catch (error) {
        console.error('Öğrenci verisi alınırken hata oluştu:', error);
      }

      // Öğrenci yoksa veya aktif değilse öğretmene bak
      try {
        const teacherResponse = await fetch(`${API_URLS.TEACHER_URL}/me`, {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });
        if (teacherResponse.ok) {
          const teacherData = await teacherResponse.json();
          setUserData(teacherData);
          setUserRole('teacher');
          return;
        }
      } catch (error) {
        console.error('Öğretmen verisi alınırken hata oluştu:', error);
      }

      setUserData(null);
      setUserRole(null);
    };

    fetchUserData();
  }, [token]);

  const [finalScore, setFinalScore] = useState(0);
  const [finalCorrectSelections, setFinalCorrectSelections] = useState(0);
  const [finalIncorrectSelections, setFinalIncorrectSelections] = useState(0);

  const onGameOver = (score, correctSelections, incorrectSelections) => {
    console.log('Çalışma bitti:', score, correctSelections, incorrectSelections);
    setShowGameOver(true);
    setFinalScore(score);
    setFinalCorrectSelections(correctSelections);
    setFinalIncorrectSelections(incorrectSelections);
    updateProgress(score);
  };

  const {
    letters,
    targetLetters,
    message,
    timeRemaining,
    score,
    isPlaying,
    clickedLetters,
    difficulty,
    gridSize,
    handlePlayPause,
    handleReset,
    handleLetterClick,
    handleDifficultyChange: originalHandleDifficultyChange,
  } = useLetterGridGame(userData, onGameOver, initialDifficulty);

  const [showInstructions, setShowInstructions] = useState(true);
  const [showGameOver, setShowGameOver] = useState(false);

  const updateProgress = async (score) => {
    if (!userData || !userRole) return;
    try {
      const payload = {
        stageNumber: stage?.number || 1,
        gameName: exercise?.gameName || 'Harf Izgara',
        score: score,
        time: (userData && userData.status === 'inactive' ? 15 : 60) - timeRemaining,
        completed: true,
      };

      if (userRole === 'student') {
        const apiUrl = `${API_URLS.STUDENT_URL}akillikurs/progress/update-game`;
        await axiosInstance.post(apiUrl, payload);
      } else {
        const apiUrl = `${API_URLS.TEACHER_URL}akillikurs/progress/update-game`;
        const teacherToken = localStorage.getItem('userToken');
        await axios.post(apiUrl, payload, {
          headers: {
            Authorization: `Bearer ${teacherToken}`,
            'Content-Type': 'application/json',
          },
        });
      }
      console.log('İlerleme başarıyla güncellendi.');
    } catch (error) {
      console.error('İlerleme güncellenirken hata oluştu:', error);
    }
  };

  const startGame = () => {
    setShowInstructions(false);
  };

  const handleRestart = () => {
    setShowGameOver(false);
    handleReset();
  };

  const handleReturnToPreviousStage = () => {
    if (stage) {
      navigate(
        userRole === 'student' ? '/ogrenci-dashboard' : '/ogretmen-dashboard',
        {
          state: {
            selectedMenu: 'ExerciseList',
            selectedStage: stage,
          },
        }
      );
    } else {
      alert('Aşama bilgisi bulunmamaktadır.');
    }
  };

  const handleCloseModal = () => {
    setShowGameOver(false);
  };

  // Zorluk değiştirirken aşama varsa izin verme
  const handleDifficultyChange = (e) => {
    if (difficultyLocked) return;
    originalHandleDifficultyChange(e);
  };

  return (
    <>
      {showInstructions ? (
        <div className="harf-izgarasi-intro-container">
          <div className="harf-izgarasi-intro">
            <div className="harf-izgarasi-card">
              <div className="harf-izgarasi-card-body">
                <HeaderSection handleReturnToPreviousStage={handleReturnToPreviousStage} />
                <h1 className="harf-izgarasi-title">Harf Izgarası Çalışması</h1>
                {stage ? (
                  <p className="harf-izgarasi-description">
                    Bu çalışmada aşamanıza göre zorluk seviyesi otomatik belirlenecek. Verilen süre içinde hedef harfleri ızgarada bulmaya çalışın.
                  </p>
                ) : (
                  <p className="harf-izgarasi-description">
                    Bu çalışma amacı, verilen süre içerisinde hedef harfleri ızgarada bulmaktır.
                  </p>
                )}
                <h3 className="harf-izgarasi-instructions-title">Talimatlar</h3>
                <ul className="harf-izgarasi-instructions-list">
                  <li>
                    <strong>1. Hedef Harfleri Bulun:</strong> Verilen hedef harfleri ızgarada bulun ve tıklayın.
                  </li>
                  <li>
                    <strong>2. Puan Kazanın:</strong> Her doğru seçim için puan kazanırsınız.
                  </li>
                  <li>
                    <strong>3. Süreye Dikkat Edin:</strong> Süre dolduğunda çalışma sona erer.
                  </li>
                </ul>
                <button className="harf-izgarasi-button" onClick={startGame}>
                  BAŞLAT
                </button>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="harf-izgarasi-container">
          <div className="harf-izgarasi-gameplay">
            <HeaderSection handleReturnToPreviousStage={handleReturnToPreviousStage} />
            <GameControls
              isPlaying={isPlaying}
              handlePlayPause={handlePlayPause}
              handleReset={handleReset}
            />
            <GameStatus
              timeRemaining={timeRemaining}
              score={score}
              difficulty={difficulty}
              handleDifficultyChange={handleDifficultyChange}
              difficultyLocked={difficultyLocked}
            />
            <LetterGrid
              letters={letters}
              targetLetters={targetLetters}
              clickedLetters={clickedLetters}
              handleLetterClick={handleLetterClick}
              gridSize={gridSize}
            />
            <MessageAlert message={message} />
          </div>
        </div>
      )}

      {showGameOver && (
        <GameOverModal
          score={finalScore}
          correctCount={finalCorrectSelections}
          incorrectCount={finalIncorrectSelections}
          onRestart={handleRestart}
          userData={userData}
          onClose={handleCloseModal}
        />
      )}
    </>
  );
};

export default HarfIzgarasiOyunu;
