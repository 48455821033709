// src/pages/okul/components/OkulRaporuModal.jsx
import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  AppBar,
  Tabs,
  Tab,
  Box,
  Typography,
  CircularProgress,
  Paper,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  Toolbar,
  IconButton,
  Card,
  CardContent,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { API_URLS } from '../../../../config/config';

// Sekme paneli yardımcı bileşeni
function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      {...other}
      style={{ paddingTop: 16 }}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

const OkulRaporuModal = ({ open, onClose, okulId, okulAdi = '' }) => {
  const [tabValue, setTabValue] = useState(0);

  // Veriler
  const [ogretmenler, setOgretmenler] = useState([]);
  const [ogrenciler, setOgrenciler] = useState([]);
  const [books, setBooks] = useState([]);

  // Loading state
  const [loadingOgretmenler, setLoadingOgretmenler] = useState(false);
  const [loadingOgrenciler, setLoadingOgrenciler] = useState(false);
  const [loadingBooks, setLoadingBooks] = useState(false);

  // Error state
  const [errorOgretmenler, setErrorOgretmenler] = useState('');
  const [errorOgrenciler, setErrorOgrenciler] = useState('');
  const [errorBooks, setErrorBooks] = useState('');

  // Modal açıldığında veya okulId değiştiğinde verileri çek
  useEffect(() => {
    if (open && okulId) {
      fetchOgretmenler();
      fetchOgrenciler();
      fetchBooks();
    }
    // eslint-disable-next-line
  }, [open, okulId]);

  // ---- Fetch fonksiyonları ----
  const fetchOgretmenler = async () => {
    setLoadingOgretmenler(true);
    setErrorOgretmenler('');
    try {
      const token = localStorage.getItem('userToken');
      // Burada KURUMSAL_URL kullandığımıza dikkat edin
      const res = await fetch(
        `${API_URLS.KURUMSAL_URL}schools/${okulId}/ogretmenler`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (!res.ok) throw new Error('Öğretmenler getirilemedi.');
      const data = await res.json();
      setOgretmenler(data);
    } catch (err) {
      setErrorOgretmenler(err.message);
    } finally {
      setLoadingOgretmenler(false);
    }
  };

  const fetchOgrenciler = async () => {
    setLoadingOgrenciler(true);
    setErrorOgrenciler('');
    try {
      const token = localStorage.getItem('userToken');
      const res = await fetch(
        `${API_URLS.KURUMSAL_URL}schools/${okulId}/ogrenciler`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (!res.ok) throw new Error('Öğrenciler getirilemedi.');
      const data = await res.json();
      setOgrenciler(data);
    } catch (err) {
      setErrorOgrenciler(err.message);
    } finally {
      setLoadingOgrenciler(false);
    }
  };

  const fetchBooks = async () => {
    setLoadingBooks(true);
    setErrorBooks('');
    try {
      const token = localStorage.getItem('userToken');
      const res = await fetch(
        `${API_URLS.KURUMSAL_URL}schools/${okulId}/books`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (!res.ok) throw new Error('Kitaplar getirilemedi.');
      const data = await res.json();
      setBooks(data);
    } catch (err) {
      setErrorBooks(err.message);
    } finally {
      setLoadingBooks(false);
    }
  };

  // Sekme değişimi
  const handleChangeTab = (event, newValue) => {
    setTabValue(newValue);
  };

  // Modal kapatma
  const handleClose = () => {
    onClose();
  };

  // Kartlarda gösterilecek veriler
  const ogretmenSayisi = ogretmenler.length;
  const ogrenciSayisi = ogrenciler.length;
  const kitapSayisi = books.length;

  return (
    <Dialog
      fullWidth
      maxWidth="lg"
      open={open}
      onClose={handleClose}
      sx={{
        '& .MuiPaper-root': {
          borderRadius: 4,   // Modal köşelerini yuvarla
          backgroundColor: '#f7f7f7', // Arkaplan rengi
        },
      }}
    >
      {/* Üst başlık alanı (AppBar) */}
      <AppBar
        sx={{
          position: 'relative',
          backgroundColor: '#1976d2', // AppBar rengi
        }}
      >
        <Toolbar>
          <DialogTitle
            sx={{
              flex: 1,
              color: '#fff',
              fontWeight: 'bold',
            }}
          >
            Okul Raporu – {okulAdi || okulId}
          </DialogTitle>
          <IconButton edge="end" color="inherit" onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </AppBar>

      {/* İçerik */}
      <DialogContent dividers>
        {/* Kartların olduğu alan */}
        <Box
          sx={{
            display: 'flex',
            flexWrap: 'wrap',
            gap: 2,
            mb: 2,
          }}
        >
          {/* Öğretmen Kartı */}
          <Card
            sx={{
              flex: '1 1 200px',
              boxShadow: 3,
              borderRadius: 2,
            }}
          >
            <CardContent>
              <Typography variant="h6" color="primary" gutterBottom>
                Öğretmen Sayısı
              </Typography>
              <Typography variant="h4">{ogretmenSayisi}</Typography>
            </CardContent>
          </Card>

          {/* Öğrenci Kartı */}
          <Card
            sx={{
              flex: '1 1 200px',
              boxShadow: 3,
              borderRadius: 2,
            }}
          >
            <CardContent>
              <Typography variant="h6" color="primary" gutterBottom>
                Öğrenci Sayısı
              </Typography>
              <Typography variant="h4">{ogrenciSayisi}</Typography>
            </CardContent>
          </Card>

          {/* Kitap Kartı */}
          <Card
            sx={{
              flex: '1 1 200px',
              boxShadow: 3,
              borderRadius: 2,
            }}
          >
            <CardContent>
              <Typography variant="h6" color="primary" gutterBottom>
                Kitap Sayısı
              </Typography>
              <Typography variant="h4">{kitapSayisi}</Typography>
            </CardContent>
          </Card>
        </Box>

        {/* Sekmeler */}
        <Tabs
          value={tabValue}
          onChange={handleChangeTab}
          textColor="primary"
          indicatorColor="primary"
          variant="scrollable"
          sx={{ backgroundColor: '#fff', borderRadius: 1 }}
        >
          <Tab label="Öğretmenler" />
          <Tab label="Öğrenciler" />
          <Tab label="Kitaplar" />
        </Tabs>

        {/* Sekme içerikleri */}
        <TabPanel value={tabValue} index={0}>
          {loadingOgretmenler && <CircularProgress />}
          {!loadingOgretmenler && errorOgretmenler && (
            <Typography color="error">{errorOgretmenler}</Typography>
          )}
          {!loadingOgretmenler && !errorOgretmenler && (
            <Paper sx={{ mt: 2 }}>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>Ad</TableCell>
                    <TableCell>Soyad</TableCell>
                    <TableCell>Email</TableCell>
                    <TableCell>Kayıt Tarihi</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {ogretmenler.map((ogrt) => (
                    <TableRow key={ogrt._id}>
                      <TableCell>{ogrt.ad || 'Ad Yok'}</TableCell>
                      <TableCell>{ogrt.soyad || 'Soyad Yok'}</TableCell>
                      <TableCell>{ogrt.email || 'Email Yok'}</TableCell>
                      <TableCell>
                        {ogrt.createdAt
                          ? new Date(ogrt.createdAt).toLocaleString()
                          : 'Tarih Yok'}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Paper>
          )}
        </TabPanel>

        <TabPanel value={tabValue} index={1}>
          {loadingOgrenciler && <CircularProgress />}
          {!loadingOgrenciler && errorOgrenciler && (
            <Typography color="error">{errorOgrenciler}</Typography>
          )}
          {!loadingOgrenciler && !errorOgrenciler && (
            <Paper sx={{ mt: 2 }}>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>Ad</TableCell>
                    <TableCell>Soyad</TableCell>
                    <TableCell>Email</TableCell>
                    <TableCell>Kayıt Tarihi</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {ogrenciler.map((ogr) => (
                    <TableRow key={ogr._id}>
                      <TableCell>{ogr.ad || 'Ad Yok'}</TableCell>
                      <TableCell>{ogr.soyad || 'Soyad Yok'}</TableCell>
                      <TableCell>{ogr.email || 'Email Yok'}</TableCell>
                      <TableCell>
                        {ogr.createdAt
                          ? new Date(ogr.createdAt).toLocaleString()
                          : 'Tarih Yok'}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Paper>
          )}
        </TabPanel>

        <TabPanel value={tabValue} index={2}>
          {loadingBooks && <CircularProgress />}
          {!loadingBooks && errorBooks && (
            <Typography color="error">{errorBooks}</Typography>
          )}
          {!loadingBooks && !errorBooks && (
            <Paper sx={{ mt: 2 }}>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>Kitap Adı</TableCell>
                    <TableCell>Zorluk</TableCell>
                    <TableCell>Kayıt Tarihi</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {books.map((book) => (
                    <TableRow key={book._id}>
                      <TableCell>{book.title || 'Kitap Adı Yok'}</TableCell>
                      <TableCell>{book.difficulty || 'Zorluk Yok'}</TableCell>
                      <TableCell>
                        {book.createdAt
                          ? new Date(book.createdAt).toLocaleString()
                          : 'Tarih Yok'}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Paper>
          )}
        </TabPanel>
      </DialogContent>
    </Dialog>
  );
};

export default OkulRaporuModal;
