import React, { useRef, useEffect } from 'react';
import '../styles/SayfaTarama.css';

const GameBoard = ({
  lineWords,
  highlightedLine,
  highlightedWordIndexInLine,
}) => {
  const highlightedWordRef = useRef(null);
  const gameContainerRef = useRef(null);

  useEffect(() => {
    if (highlightedWordRef.current && gameContainerRef.current) {
      const highlightedElement = highlightedWordRef.current;
      const container = gameContainerRef.current;

      // Elemanın konteyner içerisindeki konumunu hesapla
      const elementOffsetTop = highlightedElement.offsetTop;
      const elementHeight = highlightedElement.offsetHeight;
      const containerHeight = container.clientHeight;

      // Vurgulanan kelimeyi konteynerin ortasına gelecek şekilde konumla
      const scrollPosition = elementOffsetTop - (containerHeight / 2) + (elementHeight / 2);

      container.scrollTo({
        top: scrollPosition,
        behavior: 'smooth'
      });
    }
  }, [highlightedLine, highlightedWordIndexInLine]);

  if (!lineWords || !Array.isArray(lineWords) || lineWords.length === 0) {
    return (
      <div className="kayu-game-container">
        <div className="kayu-message-display">
          Seçili paragrafta içerik bulunamadı. Lütfen farklı bir paragraf seçin.
        </div>
      </div>
    );
  }

  return (
    <div
      className="kayu-game-container"
      style={{ textAlign: 'justify', overflowY: 'auto' }}
      ref={gameContainerRef}
    >
      {lineWords.map((words, lineIndex) => (
        <div key={`line-${lineIndex}`} style={{ marginBottom: '10px' }}>
          {words.map((word, wordIndex) => {
            const isHighlighted =
              lineIndex === highlightedLine && wordIndex === highlightedWordIndexInLine;
            const className = `kayu-word ${isHighlighted ? 'highlighted' : ''}`;
            return (
              <span
                key={`word-${lineIndex}-${wordIndex}`}
                className={className}
                ref={isHighlighted ? highlightedWordRef : null}
              >
                {word}{' '}
              </span>
            );
          })}
        </div>
      ))}
    </div>
  );
};

export default GameBoard;
