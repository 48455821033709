import React, { useState } from 'react';
import {
  Typography,
  TextField,
  IconButton,
  Tooltip,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  Menu,
  TablePagination,
  Table,
  TableBody,
  TableRow,
  TableContainer,
  Paper,
  TableHead,
  TableCell,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Chip,
  Stack,
  Grid,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import InfoIcon from '@mui/icons-material/Info';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd'; // YENİ: Atama
import * as XLSX from 'xlsx';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import ResultDialog from '../../../../pages/components/Auth/Dialog';
import { API_URLS } from '../../../../config/config';

// YENİ: Öğrenci Atama Diyaloğu
import StudentAtamaDialog from './StudentAtamaDialog';

const StudentTable = ({
  filteredOgrenciListesi,
  searchTerm,
  setSearchTerm,
  statusFilter,
  setStatusFilter,
  cityFilter,
  setCityFilter,
  ilceFilter,
  setIlceFilter,
  ekleyenFilter,
  setEkleyenFilter,
  sehirListesi,
  handleEdit,
  handleOpenDeleteDialog,
  handleStatusChange,

  // ATAMA İÇİN GEREKLİ LİSTELER
  okulListesi = [],
  kurumListesi = [],
  ogretmenListesi = [],
  setShouldFetchList, // atama sonrası liste yenilemek için
}) => {
  // Dışa aktarma menüsü için
  const [anchorEl, setAnchorEl] = useState(null);

  // Detay modalı için
  const [detailOpen, setDetailOpen] = useState(false);
  const [selectedStudent, setSelectedStudent] = useState(null);

  // Sayfalama için
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);

  // Yeni bitiş tarihi DatePicker değerleri
  const [newEndDates, setNewEndDates] = useState({});

  // Sonuç diyaloğu
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogContent, setDialogContent] = useState('');
  const [dialogSeverity, setDialogSeverity] = useState('info');

  // ATAMA DİYALOĞU STATE
  const [atamaDialogOpen, setAtamaDialogOpen] = useState(false);
  const [selectedStudentId, setSelectedStudentId] = useState('');

  /**
   * Dışa Aktar Menüsü Aç/Kapa
   */
  const handleExportClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleExportClose = () => {
    setAnchorEl(null);
  };

  /**
   * CSV Olarak İndir
   */
  const exportToCSV = () => {
    if (filteredOgrenciListesi.length === 0) return;

    const headers = [
      'No',
      'Ekleyen',
      'Adı',
      'Soyadı',
      'Şifre',
      'Telefon',
      'Okul Adı',
      'Sınıf',
      'Yaş',
      'E-posta',
      'Şehir',
      'İlçe',
      'Durum',
      'Başlangıç Tarihi',
      'Bitiş Tarihi',
      'Paket Süresi (Ay)',
      'Paket Türü',
      'Kayıt Tarihi',
    ];

    const rows = filteredOgrenciListesi.map((ogrenci, index) => {
      const ekleyen = ogrenci.ekleyenAdi || '';
      const baslangicTarihi = ogrenci.abonelik?.baslangicTarihi
        ? new Date(ogrenci.abonelik.baslangicTarihi).toLocaleDateString()
        : 'N/A';
      const bitisTarihi = ogrenci.abonelik?.bitisTarihi
        ? new Date(ogrenci.abonelik.bitisTarihi).toLocaleDateString()
        : 'N/A';
      const kayitTarihi = ogrenci.createdAt
        ? new Date(ogrenci.createdAt).toLocaleDateString()
        : 'Yok';

      return [
        index + 1,
        ekleyen,
        ogrenci.ad,
        ogrenci.soyad,
        ogrenci.password_open || '',
        ogrenci.telefon,
        ogrenci.okul_adi || '',
        ogrenci.sinif || '',
        ogrenci.yas || '',
        ogrenci.email,
        ogrenci.sehirAdi || '',
        ogrenci.ilceAdi || '',
        ogrenci.status === 'active' ? 'Aktif' : 'Pasif',
        baslangicTarihi,
        bitisTarihi,
        ogrenci.abonelik?.paketSuresi || 'N/A',
        ogrenci.abonelik?.packageType || 'N/A',
        kayitTarihi,
      ];
    });

    const csvContent =
      'data:text/csv;charset=utf-8,' +
      [headers.join(','), ...rows.map((r) => r.join(','))].join('\n');

    const encodedUri = encodeURI(csvContent);
    const link = document.createElement('a');
    link.href = encodedUri;
    link.download = 'ogrenci_listesi.csv';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    handleExportClose();
  };

  /**
   * TXT Olarak İndir
   */
  const exportToTXT = () => {
    if (filteredOgrenciListesi.length === 0) return;

    let txtContent = '';
    txtContent +=
      'No | Ekleyen | Adı | Soyadı | Şifre | Telefon | Okul Adı | Sınıf | Yaş | E-posta | Şehir | İlçe | Durum | Başlangıç Tarihi | Bitiş Tarihi | Paket Süresi (Ay) | Paket Türü | Kayıt Tarihi\n';

    filteredOgrenciListesi.forEach((ogrenci, index) => {
      const ekleyen = ogrenci.ekleyenAdi || '';
      const baslangicTarihi = ogrenci.abonelik?.baslangicTarihi
        ? new Date(ogrenci.abonelik.baslangicTarihi).toLocaleDateString()
        : 'N/A';
      const bitisTarihi = ogrenci.abonelik?.bitisTarihi
        ? new Date(ogrenci.abonelik.bitisTarihi).toLocaleDateString()
        : 'N/A';
      const kayitTarihi = ogrenci.createdAt
        ? new Date(ogrenci.createdAt).toLocaleDateString()
        : 'Yok';

      txtContent += `${index + 1} | ${ekleyen} | ${ogrenci.ad} | ${
        ogrenci.soyad
      } | ${ogrenci.password_open || ''} | ${ogrenci.telefon} | ${
        ogrenci.okul_adi || ''
      } | ${ogrenci.sinif || ''} | ${ogrenci.yas || ''} | ${
        ogrenci.email
      } | ${ogrenci.sehirAdi || ''} | ${
        ogrenci.ilceAdi || ''
      } | ${ogrenci.status === 'active' ? 'Aktif' : 'Pasif'} | ${
        baslangicTarihi
      } | ${bitisTarihi} | ${ogrenci.abonelik?.paketSuresi || 'N/A'} | ${
        ogrenci.abonelik?.packageType || 'N/A'
      } | ${kayitTarihi}\n`;
    });

    const blob = new Blob([txtContent], { type: 'text/plain;charset=utf-8' });
    const url = URL.createObjectURL(blob);

    const link = document.createElement('a');
    link.href = url;
    link.download = 'ogrenci_listesi.txt';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    handleExportClose();
  };

  /**
   * Excel (XLSX) Olarak İndir
   */
  const exportToXLSX = () => {
    if (filteredOgrenciListesi.length === 0) return;

    const headers = [
      'No',
      'Ekleyen',
      'Adı',
      'Soyadı',
      'Şifre',
      'Telefon',
      'Okul Adı',
      'Sınıf',
      'Yaş',
      'E-posta',
      'Şehir',
      'İlçe',
      'Durum',
      'Başlangıç Tarihi',
      'Bitiş Tarihi',
      'Paket Süresi (Ay)',
      'Paket Türü',
      'Kayıt Tarihi',
    ];

    const wsData = [headers];

    filteredOgrenciListesi.forEach((ogrenci, index) => {
      const ekleyen = ogrenci.ekleyenAdi || '';
      const baslangicTarihi = ogrenci.abonelik?.baslangicTarihi
        ? new Date(ogrenci.abonelik.baslangicTarihi).toLocaleDateString()
        : 'N/A';
      const bitisTarihi = ogrenci.abonelik?.bitisTarihi
        ? new Date(ogrenci.abonelik.bitisTarihi).toLocaleDateString()
        : 'N/A';
      const kayitTarihi = ogrenci.createdAt
        ? new Date(ogrenci.createdAt).toLocaleDateString()
        : 'Yok';

      const row = [
        index + 1,
        ekleyen,
        ogrenci.ad,
        ogrenci.soyad,
        ogrenci.password_open || '',
        ogrenci.telefon,
        ogrenci.okul_adi || '',
        ogrenci.sinif || '',
        ogrenci.yas || '',
        ogrenci.email,
        ogrenci.sehirAdi || '',
        ogrenci.ilceAdi || '',
        ogrenci.status === 'active' ? 'Aktif' : 'Pasif',
        baslangicTarihi,
        bitisTarihi,
        ogrenci.abonelik?.paketSuresi || 'N/A',
        ogrenci.abonelik?.packageType || 'N/A',
        kayitTarihi,
      ];
      wsData.push(row);
    });

    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.aoa_to_sheet(wsData);
    XLSX.utils.book_append_sheet(wb, ws, 'Öğrenci Listesi');

    const wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const blob = new Blob([wbout], { type: 'application/octet-stream' });

    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = 'ogrenci_listesi.xlsx';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    handleExportClose();
  };

  /**
   * Abonelik Bitiş Tarihi Güncelleme
   */
  const handleUpdateEndDate = async (ogrenciId, selectedDate) => {
    if (!selectedDate) {
      setDialogSeverity('error');
      setDialogContent('Lütfen yeni bitiş tarihini seçin.');
      setDialogOpen(true);
      return;
    }

    try {
      const response = await fetch(API_URLS.ADMIN_URL + 'payments/updateSubscriptionEndDate', {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('userToken')}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          userId: ogrenciId,
          userType: 'Ogrenci',
          newEndDate: selectedDate.toISOString(),
        }),
      });

      const data = await response.json();

      if (response.ok) {
        setDialogSeverity('success');
        setDialogContent('Abonelik bitiş tarihi başarıyla güncellendi.');
        setDialogOpen(true);

        // Güncelleme sonrası DatePicker değerini sıfırla
        setNewEndDates((prevDates) => ({
          ...prevDates,
          [ogrenciId]: null,
        }));
      } else {
        setDialogSeverity('error');
        setDialogContent(data.message || 'Abonelik bitiş tarihi güncellenemedi.');
        setDialogOpen(true);
        console.error('Abonelik bitiş tarihi güncellenemedi:', data.message);
      }
    } catch (error) {
      setDialogSeverity('error');
      setDialogContent('Abonelik bitiş tarihi güncellenirken bir hata oluştu.');
      setDialogOpen(true);
      console.error('Abonelik bitiş tarihi güncellenirken bir hata oluştu:', error);
    }
  };

  /**
   * Sayfalama Kontrolleri
   */
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const totalCount = filteredOgrenciListesi.length;
  const rowsPerPageOptions = [];
  for (let i = 25; i <= totalCount; i += 25) {
    rowsPerPageOptions.push(i);
  }
  if (rowsPerPageOptions.length === 0) {
    rowsPerPageOptions.push(25);
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // Sayfalama uygulanmış veri
  const paginatedData = filteredOgrenciListesi.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );

  /**
   * Detay Modalı Aç/Kapat
   */
  const handleOpenDetails = (ogrenci) => {
    setSelectedStudent(ogrenci);
    setDetailOpen(true);
  };
  const handleCloseDetails = () => {
    setDetailOpen(false);
    setSelectedStudent(null);
  };

  /**
   * Öğrenciyi okul/kurum/öğretmen(ler)e atamak için 
   */
  const handleAssign = async (ogrenciId, { okulId, kurumId, ogretmenId }) => {
    try {
      const body = {};
      if (okulId) body.okulId = okulId;
      if (kurumId) body.kurumId = kurumId;
      if (ogretmenId && ogretmenId.length > 0) {
        body.ogretmenId = ogretmenId;
      }

      const response = await fetch(`${API_URLS.ADMIN_URL}student/${ogrenciId}/assign`, {
        method: 'POST',
        headers: { 
              Authorization: `Bearer ${localStorage.getItem('userToken')}`,
              'Content-Type': 'application/json' },
        body: JSON.stringify(body),
      });
      if (!response.ok) {
        throw new Error('Öğrenci atama işlemi başarısız.');
      }
      const data = await response.json();

      // Listeyi güncellemek için
      if (setShouldFetchList) {
        setShouldFetchList(true);
      }

      alert('Öğrenci ataması başarılı: ' + data.message);
      setAtamaDialogOpen(false);
    } catch (error) {
      console.error('Öğrenci atama hatası:', error);
      alert('Öğrenci atama hatası: ' + error.message);
    }
  };

  return (
    <>
      {/* Başlık */}
      <Typography variant="h5" component="h3" marginBottom={2}>
        Kayıtlı Öğrenciler Listesi
      </Typography>

      {/* Arama Alanı */}
      <TextField
        fullWidth
        label="Ara..."
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        margin="normal"
      />

      {/* Filtre Bileşenleri */}
      <Grid container spacing={2} sx={{ mb: 2 }}>
        {/* Durum Filtreleme */}
        <Grid item xs={12} sm={3}>
          <FormControl fullWidth variant="outlined">
            <InputLabel id="status-filter-label">Durum</InputLabel>
            <Select
              labelId="status-filter-label"
              id="status-filter"
              value={statusFilter}
              onChange={(e) => setStatusFilter(e.target.value)}
              label="Durum"
            >
              <MenuItem value="">
                <em>Tümü</em>
              </MenuItem>
              <MenuItem value="active">Aktif</MenuItem>
              <MenuItem value="inactive">Pasif</MenuItem>
            </Select>
          </FormControl>
        </Grid>

        {/* Şehir Filtreleme */}
        <Grid item xs={12} sm={3}>
          <FormControl fullWidth variant="outlined">
            <InputLabel id="city-filter-label">Şehir</InputLabel>
            <Select
              labelId="city-filter-label"
              id="city-filter"
              value={cityFilter}
              onChange={(e) => {
                setCityFilter(e.target.value);
                setIlceFilter(''); // Şehir değişince ilçe sıfırla
              }}
              label="Şehir"
            >
              <MenuItem value="">
                <em>Tümü</em>
              </MenuItem>
              {sehirListesi.map((sehir) => (
                <MenuItem key={sehir._id} value={sehir._id}>
                  {sehir.sehir_adi}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        {/* İlçe Filtreleme */}
        <Grid item xs={12} sm={3}>
          <FormControl fullWidth variant="outlined">
            <InputLabel id="ilce-filter-label">İlçe</InputLabel>
            <Select
              labelId="ilce-filter-label"
              id="ilce-filter"
              value={ilceFilter}
              onChange={(e) => setIlceFilter(e.target.value)}
              label="İlçe"
            >
              <MenuItem value="">
                <em>Tümü</em>
              </MenuItem>
              {cityFilter &&
                sehirListesi
                  .find((s) => s._id === cityFilter)
                  ?.ilceler.map((ilce) => (
                    <MenuItem key={ilce._id} value={ilce._id}>
                      {ilce.ilce_adi}
                    </MenuItem>
                  ))}
            </Select>
          </FormControl>
        </Grid>

        {/* Ekleyen Filtreleme */}
        <Grid item xs={12} sm={3}>
          <FormControl fullWidth variant="outlined">
            <InputLabel id="ekleyen-filter-label">Ekleyen</InputLabel>
            <Select
              labelId="ekleyen-filter-label"
              id="ekleyen-filter"
              value={ekleyenFilter}
              onChange={(e) => setEkleyenFilter(e.target.value)}
              label="Ekleyen"
            >
              <MenuItem value="">
                <em>Tümü</em>
              </MenuItem>
              {Array.from(new Set(filteredOgrenciListesi.map((ogr) => ogr.ekleyenAdi || '')))
                .filter((name) => name !== '')
                .map((name) => (
                  <MenuItem key={name} value={name}>
                    {name}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </Grid>

        {/* Filtreleri Temizle */}
        <Grid item xs={12} sm={3}>
          <Button
            fullWidth
            variant="outlined"
            color="secondary"
            onClick={() => {
              setSearchTerm('');
              setStatusFilter('');
              setCityFilter('');
              setIlceFilter('');
              setEkleyenFilter('');
            }}
            sx={{ height: '100%' }}
          >
            Filtreleri Temizle
          </Button>
        </Grid>
      </Grid>

      {/* ÜST TARAFTAKİ SAYFALAMA */}
      <TablePagination
        rowsPerPageOptions={rowsPerPageOptions}
        component="div"
        count={filteredOgrenciListesi.length}
        rowsPerPage={rowsPerPage}
        page={page}
        labelRowsPerPage="Sayfa başına kayıt:"
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        sx={{
          '& .MuiTablePagination-toolbar': {
            display: 'flex',
            justifyContent: 'flex-start',
          },
          '& .MuiTablePagination-spacer': {
            flex: '0',
          },
          '& .MuiTablePagination-actions': {
            marginLeft: 0,
          },
          mb: 2,
        }}
      />

      {/* Dışa Aktar Butonu */}
      <Grid container spacing={2} sx={{ mb: 2 }}>
        <Grid item>
          <Button variant="outlined" onClick={handleExportClick}>
            Dışa Aktar
          </Button>
          <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleExportClose}>
            <MenuItem onClick={exportToCSV}>CSV Olarak İndir</MenuItem>
            <MenuItem onClick={exportToTXT}>TXT Olarak İndir</MenuItem>
            <MenuItem onClick={exportToXLSX}>Excel (XLSX) Olarak İndir</MenuItem>
          </Menu>
        </Grid>
      </Grid>

      {/* Tablo */}
      <TableContainer component={Paper}>
        <Table size="small">
          <TableHead sx={{ backgroundColor: 'primary.main' }}>
            <TableRow>
              <TableCell sx={{ color: '#fff', fontWeight: 'bold' }}>No</TableCell>
              <TableCell sx={{ color: '#fff', fontWeight: 'bold' }}>Ekleyen</TableCell>
              <TableCell sx={{ color: '#fff', fontWeight: 'bold' }}>Adı</TableCell>
              <TableCell sx={{ color: '#fff', fontWeight: 'bold' }}>Soyadı</TableCell>
              <TableCell sx={{ color: '#fff', fontWeight: 'bold' }}>Şifre</TableCell>
              <TableCell sx={{ color: '#fff', fontWeight: 'bold' }}>E-posta</TableCell>
              <TableCell sx={{ color: '#fff', fontWeight: 'bold' }}>Durum</TableCell>
              <TableCell sx={{ color: '#fff', fontWeight: 'bold' }}>Aktif Eden</TableCell>
              <TableCell sx={{ color: '#fff', fontWeight: 'bold' }}>Başlangıç</TableCell>
              <TableCell sx={{ color: '#fff', fontWeight: 'bold' }}>Bitiş</TableCell>
              <TableCell sx={{ color: '#fff', fontWeight: 'bold' }}>Paket Süresi (Ay)</TableCell>
              <TableCell sx={{ color: '#fff', fontWeight: 'bold' }}>Kayıt Tarihi</TableCell>
              <TableCell sx={{ color: '#fff', fontWeight: 'bold', minWidth: 220 }}>
                Yeni Bitiş Tarihi
              </TableCell>
              <TableCell sx={{ color: '#fff', fontWeight: 'bold' }}>İşlem</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {paginatedData.map((ogrenci, index) => {
              const siraNo = page * rowsPerPage + index + 1;
              const kayitTarihi = ogrenci.createdAt
                ? new Date(ogrenci.createdAt).toLocaleDateString()
                : 'Yok';

              return (
                <TableRow key={ogrenci._id}>
                  <TableCell>{siraNo}</TableCell>
                  <TableCell>{ogrenci.ekleyenAdi || ''}</TableCell>
                  <TableCell>{ogrenci.ad || ''}</TableCell>
                  <TableCell>{ogrenci.soyad || ''}</TableCell>
                  <TableCell>{ogrenci.password_open || ''}</TableCell>
                  <TableCell>{ogrenci.email || ''}</TableCell>
                  <TableCell>
                    <Chip
                      label={ogrenci.status === 'active' ? 'Aktif' : 'Pasif'}
                      color={ogrenci.status === 'active' ? 'success' : 'error'}
                      onClick={() => handleStatusChange(ogrenci._id, ogrenci.status)}
                      sx={{
                        cursor: 'pointer',
                        '&:hover': { backgroundColor: 'lightgray' },
                      }}
                    />
                  </TableCell>
                  <TableCell>{ogrenci.aktifEdenAdi || ''}</TableCell>
                 
                  <TableCell>
                    {ogrenci.abonelik?.baslangicTarihi
                      ? new Date(ogrenci.abonelik.baslangicTarihi).toLocaleDateString()
                      : 'N/A'}
                  </TableCell>
                  <TableCell>
                    {ogrenci.abonelik?.bitisTarihi
                      ? new Date(ogrenci.abonelik.bitisTarihi).toLocaleDateString()
                      : 'N/A'}
                  </TableCell>
                  <TableCell>{ogrenci.abonelik?.paketSuresi || 'N/A'}</TableCell>
                  <TableCell>{kayitTarihi}</TableCell>

                  {/* Yeni Bitiş Tarihi (Abonelik Güncelleme) */}
                  <TableCell>
                    <DatePicker
                      selected={newEndDates[ogrenci._id] || null}
                      onChange={(date) =>
                        setNewEndDates((prev) => ({ ...prev, [ogrenci._id]: date }))
                      }
                      dateFormat="dd/MM/yyyy"
                      placeholderText="Yeni Bitiş Tarihi"
                      customInput={
                        <TextField
                          fullWidth
                          variant="outlined"
                          size="small"
                          label="Yeni Bitiş Tarihi"
                        />
                      }
                    />
                    <Button
                      variant="contained"
                      color="primary"
                      fullWidth
                      sx={{ marginTop: 1 }}
                      onClick={() => handleUpdateEndDate(ogrenci._id, newEndDates[ogrenci._id])}
                    >
                      Tarihi Güncelle
                    </Button>
                  </TableCell>

                  {/* İşlem Sütunu */}
                  <TableCell>
                    <Stack direction="row" spacing={1}>
                      {/* Detay Göster */}
                      <Tooltip title="Detaylar">
                        <IconButton onClick={() => handleOpenDetails(ogrenci)} color="info">
                          <InfoIcon />
                        </IconButton>
                      </Tooltip>

                      {/* YENİ: ATAMA BUTONU */}
                      <Tooltip title="Atama (Okul/Kurum/Öğretmen)" arrow>
                        <IconButton
                          color="success"
                          onClick={() => {
                            setSelectedStudentId(ogrenci._id);
                            setAtamaDialogOpen(true);
                          }}
                        >
                          <AssignmentIndIcon />
                        </IconButton>
                      </Tooltip>

                      {/* Düzenle */}
                      <Button
                        variant="contained"
                        color="primary"
                        startIcon={<EditIcon />}
                        onClick={() => handleEdit(ogrenci._id)}
                        sx={{
                          '&:hover': {
                            backgroundColor: 'lightblue',
                            color: '#fff',
                          },
                        }}
                      >
                        Düzenle
                      </Button>

                      {/* Sil */}
                      <Button
                        variant="contained"
                        color="secondary"
                        startIcon={<DeleteIcon />}
                        onClick={() => handleOpenDeleteDialog(ogrenci._id)}
                      >
                        Sil
                      </Button>
                    </Stack>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>

      {/* ALT TARAFTAKİ SAYFALAMA */}
      <TablePagination
        rowsPerPageOptions={rowsPerPageOptions}
        component="div"
        count={filteredOgrenciListesi.length}
        rowsPerPage={rowsPerPage}
        page={page}
        labelRowsPerPage="Sayfa başına kayıt:"
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        sx={{
          '& .MuiTablePagination-toolbar': {
            display: 'flex',
            justifyContent: 'flex-start',
          },
          '& .MuiTablePagination-spacer': {
            flex: '0',
          },
          '& .MuiTablePagination-actions': {
            marginLeft: 0,
          },
          mt: 2,
        }}
      />

      {/* Detay Modalı */}
      {selectedStudent && (
        <Dialog open={detailOpen} onClose={handleCloseDetails} maxWidth="md" fullWidth>
          <DialogTitle>Öğrenci Detayları</DialogTitle>
          <DialogContent dividers>
            <Typography variant="body1">
              <b>Ad Soyad:</b> {selectedStudent.ad} {selectedStudent.soyad}
            </Typography>
            <Typography variant="body1">
              <b>Telefon:</b> {selectedStudent.telefon || 'Yok'}
            </Typography>
            <Typography variant="body1">
              <b>Okul Adı:</b> {selectedStudent.okul_adi || 'Belirtilmemiş'}
            </Typography>
            <Typography variant="body1">
              <b>Sınıf:</b> {selectedStudent.sinif || 'Yok'}
            </Typography>
            <Typography variant="body1">
              <b>Yaş:</b> {selectedStudent.yas || 'Yok'}
            </Typography>
            <Typography variant="body1">
              <b>Ekleyen:</b> {selectedStudent.ekleyenAdi || 'Belirtilmemiş'}
            </Typography>
            <Typography variant="body1">
              <b>Şifre:</b> {selectedStudent.password_open || 'Yok'}
            </Typography>
            <Typography variant="body1">
              <b>Email:</b> {selectedStudent.email || 'Yok'}
            </Typography>
            <Typography variant="body1">
              <b>Şehir - İlçe:</b> {selectedStudent.sehirAdi || 'Yok'} -{' '}
              {selectedStudent.ilceAdi || 'Yok'}
            </Typography>
            <Typography variant="body1">
              <b>Durum:</b> {selectedStudent.status === 'active' ? 'Aktif' : 'Pasif'}
            </Typography>
            <Typography variant="body1">
              <b>Abonelik Başlangıç:</b>{' '}
              {selectedStudent.abonelik?.baslangicTarihi
                ? new Date(selectedStudent.abonelik.baslangicTarihi).toLocaleDateString()
                : 'N/A'}
            </Typography>
            <Typography variant="body1">
              <b>Abonelik Bitiş:</b>{' '}
              {selectedStudent.abonelik?.bitisTarihi
                ? new Date(selectedStudent.abonelik.bitisTarihi).toLocaleDateString()
                : 'N/A'}
            </Typography>
            <Typography variant="body1">
              <b>Paket Süresi:</b> {selectedStudent.abonelik?.paketSuresi || 'N/A'}
            </Typography>
            <Typography variant="body1">
              <b>Paket Türü:</b> {selectedStudent.abonelik?.packageType || 'N/A'}
            </Typography>
            <Typography variant="body1">
              <b>Kayıt Tarihi:</b>{' '}
              {selectedStudent.createdAt
                ? new Date(selectedStudent.createdAt).toLocaleDateString()
                : 'Yok'}
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseDetails} variant="outlined">
              Kapat
            </Button>
          </DialogActions>
        </Dialog>
      )}

      {/* Sonuç Diyaloğu */}
      <ResultDialog
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        title={dialogSeverity === 'error' ? 'Hata Oluştu' : 'İşlem Başarılı'}
        message={dialogContent}
        severity={dialogSeverity}
      />

      {/* ATAMA DİYALOĞU */}
      <StudentAtamaDialog
        open={atamaDialogOpen}
        onClose={() => setAtamaDialogOpen(false)}
        ogrenciId={selectedStudentId}
        okulListesi={okulListesi}
        kurumListesi={kurumListesi}
        ogretmenListesi={ogretmenListesi}
        handleAssign={handleAssign}
      />
    </>
  );
};

export default StudentTable;
