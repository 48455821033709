import React from 'react';
import '../styles/SayfaTarama.css';

const ProgressBar = ({ timeRemaining, totalTime }) => {
  const percentage = (timeRemaining / totalTime) * 100;

  return (
    <div className="kayu-progress-container">
      <div className="kayu-progress-bar" style={{ width: `${percentage}%` }}></div>
    </div>
  );
};

export default ProgressBar;
