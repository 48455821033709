import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate, useLocation } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import { fetchParagraphs } from './hooks/apiService';
import { useGameLogic } from './hooks/useGameLogic';
import NavigationButtons from './components/NavigationButtons';
import GameControls from './components/GameControls';
import TimerDisplay from './components/TimerDisplay';
import DropdownSelectors from './components/DropdownSelectors';
import GameBoard from './components/GameBoard';
import MessageDisplay from './components/MessageDisplay';
import GameExplanation from './components/GameExplanation';
import GameOverModal from './components/GameOverModal';
import ProgressBar from './components/ProgressBar'; 
import './styles/SayfaTarama.css';
import { API_URLS } from '../../../config/config';
import axiosInstance from '../../../utils/axiosInstance';

const SayfaTaramaOne = () => {
  const [showInstructions, setShowInstructions] = useState(true);
  const [showGameOverModal, setShowGameOverModal] = useState(false);
  const [difficulty, setDifficulty] = useState('easy');
  const [highlightSpeed, setHighlightSpeed] = useState(500);
  const [paragraphsList, setParagraphsList] = useState([]);
  const [selectedParagraphIndex, setSelectedParagraphIndex] = useState(0);
  const [paragraph, setParagraph] = useState('');
  const [userData, setUserData] = useState(null);
  const [userRole, setUserRole] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();
  const { stage, exercise } = location.state || {};
  const token = localStorage.getItem('userToken');

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const studentResponse = await axiosInstance.get(`${API_URLS.STUDENT_URL}/me`);
        if (
          studentResponse.status === 200 &&
          studentResponse.data &&
          studentResponse.data.status === 'active'
        ) {
          const studentData = studentResponse.data;
          setUserData(studentData);
          setUserRole('student');
          return;
        } else {
          setUserData(null);
          setUserRole(null);
        }
      } catch (error) {
        console.error('Öğrenci verisi alınırken hata oluştu:', error);
      }

      try {
        const teacherResponse = await fetch(`${API_URLS.TEACHER_URL}/me`, {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });
        if (teacherResponse.ok) {
          const teacherData = await teacherResponse.json();
          setUserData(teacherData);
          setUserRole('teacher');
          return;
        }
      } catch (error) {
        console.error('Öğretmen verisi alınırken hata oluştu:', error);
      }

      setUserData(null);
      setUserRole(null);
    };

    fetchUserData();
  }, [token]);

  useEffect(() => {
    async function fetchData() {
      try {
        const data = await fetchParagraphs(difficulty);
        setParagraphsList(data);
        if (data.length > 0) {
          setSelectedParagraphIndex(0);
        } else {
          setParagraph('');
        }
      } catch (error) {
        console.error('Paragraflar alınırken hata oluştu:', error);
      }
    }
    fetchData();
  }, [difficulty]);

  useEffect(() => {
    if (paragraphsList.length > 0) {
      const rawParagraphContent = paragraphsList[selectedParagraphIndex]?.content || '';
      setParagraph(rawParagraphContent);
    } else {
      setParagraph('');
    }
  }, [paragraphsList, selectedParagraphIndex]);

  const {
    isPlaying,
    timeRemaining,
    message,
    handlePlayPause,
    handleReset,
    highlightedLine,
    highlightedWordIndexInLine,
    lineWords,
    highlightNextWordImmediately,
    highlightedWordCount,
    wpmEstimate
  } = useGameLogic(paragraph, userData, difficulty, highlightSpeed);

  const startGame = () => {
    // Sadece talimat ekranını kapatıyoruz, oyunu otomatik başlatmıyoruz
    setShowInstructions(false);
  };

  const handleRestart = () => {
    setShowGameOverModal(false);
    handleReset();
    handlePlayPause();
    highlightNextWordImmediately();
  };

  const onGameOver = () => {
    setShowGameOverModal(true);
    updateProgress();
  };

  useEffect(() => {
    if (timeRemaining === 0) {
      onGameOver();
    }
  }, [timeRemaining]);

  const updateProgress = async () => {
    try {
      if (userData && userRole) {
        const payload = {
          stageNumber: stage?.number || 1,
          gameName: exercise?.gameName || 'Kelime Bulma ve Seçme',
          score: highlightedWordCount,
          time: (!userData || userData.status === 'inactive' ? 15 : 60) - timeRemaining,
          wordCount: highlightedWordCount,
          completed: true,
        };

        if (userRole === 'student') {
          const apiUrl = `${API_URLS.STUDENT_URL}akillikurs/progress/update-game`;
          await axiosInstance.post(apiUrl, payload);
        } else {
          const apiUrl = `${API_URLS.TEACHER_URL}akillikurs/progress/update-game`;
          const teacherToken = localStorage.getItem('userToken');
          await axios.post(apiUrl, payload, {
            headers: {
              Authorization: `Bearer ${teacherToken}`,
              'Content-Type': 'application/json',
            },
          });
        }
      }
    } catch (error) {
      console.error('İlerleme güncellenirken hata oluştu:', error);
    }
  };

  const handleReturnToPreviousStage = () => {
    if (stage) {
      navigate(
        userRole === 'student' ? '/ogrenci-dashboard' : '/ogretmen-dashboard',
        {
          state: {
            selectedMenu: 'ExerciseList',
            selectedStage: stage,
          },
        }
      );
    } else {
      alert('Aşama bilgisi bulunmamaktadır.');
    }
  };

  const handleDifficultyChange = (e) => {
    setDifficulty(e.target.value);
    setSelectedParagraphIndex(0);
  };

  const handleParagraphChange = (e) => {
    setSelectedParagraphIndex(parseInt(e.target.value));
  };

  const handleSpeedChange = (e) => {
    setHighlightSpeed(parseInt(e.target.value));
  };

  const handleClose = () => {
    setShowGameOverModal(false);
  };

  return (
    <div className="kayu-oyun-container">
      <NavigationButtons handleReturnToPreviousStage={handleReturnToPreviousStage} />
      {showInstructions ? (
        <div className="kayu-card text-center">
          <div className="kayu-card-body">
            <div className="kayu-oyun-title">
              <h1 className="kayu-title">Kelime Geçişli Hızlı Okuma Egzersizi</h1>
            </div>
            <GameExplanation />
            <div className="d-flex justify-content-center mt-3">
              <button 
                className="btn btn-warning" 
                style={{ fontWeight: 'bold' }} 
                onClick={startGame}
              >
                Başlat
              </button>
            </div>
          </div>
        </div>
      ) : (
        <div className="kayu-card">
          <div className="kayu-card-body">
            <div className="kayu-control-timer-container">
              <GameControls
                isPlaying={isPlaying}
                handlePlayPause={handlePlayPause}
                handleReset={handleReset}
              />
              <TimerDisplay timeRemaining={timeRemaining} />
            </div>
            <ProgressBar timeRemaining={timeRemaining} totalTime={!userData || userData.status === 'inactive' ? 15 : 60}/>
            <DropdownSelectors
              difficulty={difficulty}
              selectedParagraphIndex={selectedParagraphIndex}
              paragraphsList={paragraphsList}
              handleDifficultyChange={handleDifficultyChange}
              handleParagraphChange={handleParagraphChange}
            />
            
            <div className="kayu-speed-control" style={{ marginBottom: '20px', display: 'inline-flex', alignItems: 'center', gap: '10px' }}>
              <span style={{ fontWeight: 'bold', color: '#ffcc00', fontSize: '1.1rem' }}>Hız:</span>
              <select 
                className="kayu-select" 
                value={highlightSpeed} 
                onChange={handleSpeedChange}
                style={{
                  padding: '5px 10px',
                  borderRadius: '5px',
                  border: '1px solid #ffcc00',
                  backgroundColor: '#2c2c2c',
                  color: '#fff',
                  fontSize: '1rem'
                }}
              >
                <option value={200}>Çok Hızlı (200ms)</option>
                <option value={500}>Hızlı (500ms)</option>
                <option value={1000}>Orta (1000ms)</option>
                <option value={1500}>Yavaş (1500ms)</option>
                <option value={2000}>Çok Yavaş (2000ms)</option>
              </select>
            </div>

            <GameBoard
              lineWords={lineWords}
              highlightedLine={highlightedLine}
              highlightedWordIndexInLine={highlightedWordIndexInLine}
            />
            <MessageDisplay message={message} />
          </div>
        </div>
      )}

      {showGameOverModal && (
        <GameOverModal
          score={highlightedWordCount}
          onRestart={handleRestart}
          onClose={handleClose}
          userData={userData}
          wpmEstimate={wpmEstimate}
        />
      )}
    </div>
  );
};

export default SayfaTaramaOne;
