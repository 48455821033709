// src/pages/Okullar.jsx
import React, { useState, useEffect } from 'react';
import { Grid, Paper, Typography, TextField } from '@mui/material';
import { API_URLS } from '../../config/config';
import ResultDialog from '../../pages/components/Auth/Dialog';
import useFetchUserData from '../../hooks/kurum/useFetchUserData';

// Alt bileşenler
import OkulEkleFormu from './okul/components/OkulEkleFormu';
import OkulListesi from './okul/components/OkulListesi';
import OkulDuzenleDialog from './okul/components/OkulDuzenleDialog';
import OkulBalanceTransferDialog from './okul/components/OkulBalanceTransferDialog';

// YENİ: Rapor Modal'ı import ediyoruz
import OkulRaporuModal from './okul/components/OkulRaporuModal';

const Okullar = () => {
  // ------------------------------------------------------
  // 1) State Tanımları
  // ------------------------------------------------------
  const [okulListesi, setOkulListesi] = useState([]);
  const [sehirListesi, setSehirListesi] = useState([]);
  const [shouldFetch, setShouldFetch] = useState(true);

  const [userData, setUserData] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');

  // Dialog için durumlar
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogContent, setDialogContent] = useState('');
  const [dialogSeverity, setDialogSeverity] = useState('info');
  const [eklemeBasariliDialogOpen, setEklemeBasariliDialogOpen] = useState(false);
  const [silmeBasariliDialogOpen, setSilmeBasariliDialogOpen] = useState(false);

  // Düzenleme Dialog
  const [duzenlemeDialogAcik, setDuzenlemeDialogAcik] = useState(false);
  const [duzenlenenKurum, setDuzenlenenKurum] = useState({
    okulAdi: '',
    email: '',
    telefon: '',
    sehirId: '',
    ilceId: '',
    kurumTuru: [],
    ilceler: [],
  });

  // Bakiye Transfer Dialog
  const [okulTransferDialogOpen, setOkulTransferDialogOpen] = useState(false);
  const [packageType, setPackageType] = useState('bakiye3Ay');
  const [transferAmount, setTransferAmount] = useState(0);
  const [selectedSchoolId, setSelectedSchoolId] = useState('');

  // Rapor Modal State (YENİ)
  const [raporModalOpen, setRaporModalOpen] = useState(false);
  const [selectedSchoolRaporId, setSelectedSchoolRaporId] = useState('');
  const [selectedSchoolName, setSelectedSchoolName] = useState('');

  // Kullanıcı bilgilerini çekme (kurum) - custom hook
  useFetchUserData(setUserData);

  // ------------------------------------------------------
  // 2) Filtrelenmiş Okul Listesi (Arama)
  // ------------------------------------------------------
  const filteredOkulListesi = okulListesi.filter((kurum) =>
    (kurum.okul_adi && kurum.okul_adi.toLowerCase().includes(searchQuery.toLowerCase())) ||
    (kurum.email && kurum.email.toLowerCase().includes(searchQuery.toLowerCase()))
  );

  // ------------------------------------------------------
  // 3) useEffect ile Şehirler ve Okulların Yüklenmesi
  // ------------------------------------------------------
  useEffect(() => {
    const fetchSehirler = async () => {
      if (!shouldFetch) return;

      try {
        const response = await fetch(API_URLS.ADMIN_URL + 'sehirler');
        if (!response.ok) {
          throw new Error('Veri çekme işlemi başarısız');
        }
        const sehirler = await response.json();
        setSehirListesi(sehirler);
      } catch (err) {
        console.error('Şehirler yüklenirken bir hata oluştu:', err.message);
      } finally {
        setShouldFetch(false);
      }
    };

    const fetchSchools = async () => {
      if (!userData || !userData._id) {
        console.log('Kullanıcı bilgisi yüklenemedi veya kullanıcı ID\'si bulunamadı.');
        return;
      }

      try {
        // GET /schools/:kurumId
        const response = await fetch(`${API_URLS.KURUMSAL_URL}schools/${userData._id}`);
        if (!response.ok) {
          throw new Error('Okullar yüklenirken bir hata oluştu.');
        }
        const data = await response.json();
        setOkulListesi(data);
      } catch (error) {
        console.error('Okulları yükleme hatası:', error);
        setDialogSeverity('error');
        setDialogContent('Okullar yüklenirken bir hata oluştu. Lütfen daha sonra tekrar deneyin.');
        setEklemeBasariliDialogOpen(true);
      }
    };

    fetchSchools();
    fetchSehirler();
  }, [userData, shouldFetch]);

  // ------------------------------------------------------
  // 4) Şehir / İlçe Filtreleri vs.
  // ------------------------------------------------------
  const fetchIlceler = async (sehirId, seciliIlceId) => {
    try {
      const response = await fetch(`${API_URLS.ADMIN_URL}sehirler/ilceler/${sehirId}`);
      if (!response.ok) throw new Error('İlçeler yüklenirken bir hata oluştu.');

      const data = await response.json();
      const ilceler = data.ilceler ? data.ilceler : [];

      setDuzenlenenKurum((prev) => ({
        ...prev,
        ilceler,
        ilceId:
          seciliIlceId && ilceler.some((ilce) => ilce._id === seciliIlceId)
            ? seciliIlceId
            : prev.ilceId,
      }));
    } catch (error) {
      console.error('İlçeleri yüklerken bir hata oluştu:', error);
    }
  };

  // ------------------------------------------------------
  // 5) Okul Düzenleme İşlemleri
  // ------------------------------------------------------
  const handleEdit = (kurumId) => {
    const seciliKurum = okulListesi.find((kurum) => kurum._id === kurumId);
    if (!seciliKurum) {
      console.error('Seçilen kurum bulunamadı.');
      return;
    }

    const sehirId = seciliKurum.sehir;
    setDuzenlenenKurum((prev) => ({
      ...prev,
      _id: seciliKurum._id,
      okulAdi: seciliKurum.okul_adi,
      email: seciliKurum.email,
      telefon: seciliKurum.telefon,
      sehirId,
      ilceId: seciliKurum.ilce,
      kurumTuru: seciliKurum.kurum_turu,
    }));

    fetchIlceler(sehirId);
    setDuzenlemeDialogAcik(true);
  };

  const handleGuncelle = async () => {
    let eksikAlanlar = [];

    if (!duzenlenenKurum.okulAdi) eksikAlanlar.push('Okul Adı');
    if (!duzenlenenKurum.email) eksikAlanlar.push('Email');
    if (!duzenlenenKurum.telefon) eksikAlanlar.push('Telefon');
    if (!duzenlenenKurum.sehirId) eksikAlanlar.push('Şehir');
    if (!duzenlenenKurum.ilceId) eksikAlanlar.push('İlçe');
    if (duzenlenenKurum.kurumTuru.length === 0) eksikAlanlar.push('Kurum Türleri');

    if (eksikAlanlar.length > 0) {
      setDialogSeverity('error');
      setDialogContent(`Lütfen ${eksikAlanlar.join(', ')} alanlarını doldurunuz.`);
      setDialogOpen(true);
      return;
    }

    try {
      // PUT /schools/:okulId
      const response = await fetch(`${API_URLS.KURUMSAL_URL}schools/${duzenlenenKurum._id}`, {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          okul_adi: duzenlenenKurum.okulAdi,
          email: duzenlenenKurum.email,
          telefon: duzenlenenKurum.telefon,
          sehir: duzenlenenKurum.sehirId,
          ilce: duzenlenenKurum.ilceId,
          kurum_turu: duzenlenenKurum.kurumTuru,
        }),
      });

      const data = await response.json();
      if (response.ok) {
        setOkulListesi(
          okulListesi.map((kurum) => (kurum._id === duzenlenenKurum._id ? data : kurum))
        );
        setDialogSeverity('success');
        setDialogContent('Kurum başarıyla güncellendi.');
        setDialogOpen(true);
        setDuzenlemeDialogAcik(false);
      } else {
        throw new Error(data.message || 'Bir hata oluştu');
      }
    } catch (err) {
      setDialogSeverity('error');
      setDialogContent(err.message);
      setDialogOpen(true);
    }
  };

  // ------------------------------------------------------
  // 6) Okul Silme İşlemi
  // ------------------------------------------------------
  const handleDelete = async (kurumId) => {
    const onay = window.confirm('Bu okulu silmek istediğinize emin misiniz?');
    if (!onay) return;

    try {
      const response = await fetch(`${API_URLS.KURUMSAL_URL}schools/${kurumId}`, {
        method: 'DELETE',
      });
      const data = await response.json();

      if (!response.ok) {
        setDialogSeverity('error');
        setDialogContent(data.message || 'Kurum silinirken bir hata oluştu.');
        setSilmeBasariliDialogOpen(true);
      } else {
        setDialogSeverity('success');
        setDialogContent(`${data.message}`);
        setSilmeBasariliDialogOpen(true);

        // Başarılı silme sonrası local state'ten çıkar
        setOkulListesi((prev) => prev.filter((k) => k._id !== kurumId));
      }
    } catch (err) {
      setDialogSeverity('error');
      setDialogContent('Hata: ' + err.message);
      setSilmeBasariliDialogOpen(true);
    }
  };

  // ------------------------------------------------------
  // 7) Bakiye Transferi (Okul)
  // ------------------------------------------------------
  const handleOpenTransferDialog = (schoolId) => {
    setSelectedSchoolId(schoolId);
    setOkulTransferDialogOpen(true);
  };

  const handleOkulTransfer = async () => {
    if (!selectedSchoolId) {
      setDialogSeverity('error');
      setDialogContent('Herhangi bir okul seçilmedi.');
      setDialogOpen(true);
      return;
    }
    if (transferAmount <= 0) {
      setDialogSeverity('error');
      setDialogContent('Lütfen geçerli bir bakiye tutarı girin.');
      setDialogOpen(true);
      return;
    }

    try {
      // POST /schools/transferBalance/:schoolId
      const response = await fetch(
        `${API_URLS.KURUMSAL_URL}schools/transferBalance/${selectedSchoolId}`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem('userToken')}`,
          },
          body: JSON.stringify({
            packageType,
            amount: transferAmount,
          }),
        }
      );

      const data = await response.json();
      if (!response.ok) {
        throw new Error(data.message || 'Bir hata oluştu');
      }

      setDialogSeverity('success');
      setDialogContent('Bakiye başarıyla transfer edildi.');
      setDialogOpen(true);
      setOkulTransferDialogOpen(false);

      // Burada isterseniz Okul listesini yeniden çekebilirsiniz
      // setShouldFetch(true);

    } catch (err) {
      setDialogSeverity('error');
      setDialogContent(err.message);
      setDialogOpen(true);
    }
  };

  // ------------------------------------------------------
  // 8) Rapor Modal Açma/Kapama (ÖNEMLİ)
  // ------------------------------------------------------
  const handleOpenRapor = (okulId, okulAdi) => {
    setSelectedSchoolRaporId(okulId);
    setSelectedSchoolName(okulAdi || '');
    setRaporModalOpen(true);
  };

  const handleCloseRapor = () => {
    setSelectedSchoolRaporId('');
    setSelectedSchoolName('');
    setRaporModalOpen(false);
  };

  // ------------------------------------------------------
  // Render
  // ------------------------------------------------------
  return (
    <Paper style={{ padding: 20 }}>
      <Grid container spacing={3}>
        {/* SOL TARAF: OKUL EKLEME FORMU */}
        <Grid item xs={12} md={3}>
          <Typography variant="h5" component="h3" marginBottom={2}>
            Okul Kayıt Formu
          </Typography>

          <OkulEkleFormu
            sehirListesi={sehirListesi}
            setSehirListesi={setSehirListesi}
            setOkulListesi={setOkulListesi}
            okulListesi={okulListesi}
            userData={userData}
            setDialogSeverity={setDialogSeverity}
            setDialogContent={setDialogContent}
            setEklemeBasariliDialogOpen={setEklemeBasariliDialogOpen}
          />
        </Grid>

        {/* SAĞ TARAF: KAYITLI OKULLAR LİSTESİ */}
        <Grid item xs={12} md={9}>
          <Typography variant="h5" component="h3" marginBottom={2}>
            Kayıtlı Okullar Listesi
          </Typography>

          <TextField
            fullWidth
            label="Ara..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            margin="normal"
          />

          <OkulListesi
            filteredOkulListesi={filteredOkulListesi}
            handleEdit={handleEdit}
            handleDelete={handleDelete}
            handleOpenTransferDialog={handleOpenTransferDialog}
            // Rapor Modalını açacak fonksiyon
            handleOpenRapor={handleOpenRapor}
          />
        </Grid>
      </Grid>

      {/* ---- SONUÇ DİYALOG BİLEŞENLERİ ---- */}
      <ResultDialog
        open={eklemeBasariliDialogOpen}
        onClose={() => setEklemeBasariliDialogOpen(false)}
        title={dialogSeverity === 'error' ? 'Hata Oluştu' : 'İşlem Başarılı'}
        message={dialogContent}
        severity={dialogSeverity}
      />

      <ResultDialog
        open={silmeBasariliDialogOpen}
        onClose={() => setSilmeBasariliDialogOpen(false)}
        title={dialogSeverity === 'error' ? 'Hata Oluştu' : 'Silme İşlemi Başarılı'}
        message={dialogContent}
        severity={dialogSeverity}
      />

      <ResultDialog
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        title={dialogSeverity === 'error' ? 'Hata Oluştu' : 'İşlem Sonucu'}
        message={dialogContent}
        severity={dialogSeverity}
      />

      {/* ---- OKUL DÜZENLEME DİYALOG ---- */}
      <OkulDuzenleDialog
        open={duzenlemeDialogAcik}
        setOpen={setDuzenlemeDialogAcik}
        duzenlenenKurum={duzenlenenKurum}
        setDuzenlenenKurum={setDuzenlenenKurum}
        sehirListesi={sehirListesi}
        fetchIlceler={fetchIlceler}
        handleGuncelle={handleGuncelle}
      />

      {/* ---- BAKİYE TRANSFER DİYALOG ---- */}
      <OkulBalanceTransferDialog
        open={okulTransferDialogOpen}
        onClose={() => setOkulTransferDialogOpen(false)}
        packageType={packageType}
        setPackageType={setPackageType}
        transferAmount={transferAmount}
        setTransferAmount={setTransferAmount}
        handleTransfer={handleOkulTransfer}
      />

      {/* ---- YENİ: OKUL RAPORU MODAL ---- */}
      <OkulRaporuModal
        open={raporModalOpen}
        onClose={handleCloseRapor}
        okulId={selectedSchoolRaporId}
        okulAdi={selectedSchoolName}
      />
    </Paper>
  );
};

export default Okullar;
