// GameOverModal.js

import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faTimes } from '@fortawesome/free-solid-svg-icons';

const GameOverModal = ({ score, onRestart, userData, onClose }) => {
  const navigate = useNavigate();

  // Kayıt ol / giriş yap sayfasına yönlendirme fonksiyonu
  const handleLoginRedirect = () => {
    navigate('/register');
  };

  // ESC tuşunu dinleyerek modal'ı kapatma
  useEffect(() => {
    const handleEsc = (event) => {
      if (event.key === 'Escape') {
        onClose();
      }
    };

    window.addEventListener('keydown', handleEsc);

    return () => {
      window.removeEventListener('keydown', handleEsc);
    };
  }, [onClose]);

  const isActiveUser = userData && userData.status !== 'inactive';

  return (
    <div className="modal-backdrop" onClick={onClose} aria-modal="true" role="dialog">
      <div className="modal-content" onClick={(e) => e.stopPropagation()}>
        {/* Kapatma Butonu */}
        <button className="close-button" onClick={onClose} aria-label="Close Modal">
          <FontAwesomeIcon icon={faTimes} />
        </button>

        <h2 className="modal-title">Bitti!</h2>

        {isActiveUser ? (
          <>
            <p className="modal-score">Toplam Skorunuz: {Math.floor(score)} </p>
            <div className="button-group">
              <button className="btn btn-outline" onClick={onClose}>
                Bitti
              </button>
              <button className="btn btn-secondary" onClick={() => navigate(-1)}>
                <FontAwesomeIcon icon={faArrowLeft} /> Geri Dön
              </button>
            </div>
          </>
        ) : (
          <>
            <p className="modal-message">Giriş yapmadığınız için skoru göremiyorsunuz.</p>
            <div className="button-group">
              <button className="btn btn-primary" onClick={handleLoginRedirect}>
                Kayıt Ol
              </button>
              <button className="btn btn-secondary" onClick={() => navigate(-1)}>
                <FontAwesomeIcon icon={faArrowLeft} /> Geri Dön
              </button>
            </div>
          </>
        )}

        {/* Stil tanımlamaları */}
        <style jsx>{`
          .modal-backdrop {
            position: fixed;
            top: 0;
            left: 0;
            width: 100vw;
            height: 100vh;
            background: rgba(0, 0, 0, 0.7);
            display: flex;
            justify-content: center;
            align-items: center;
            z-index: 1000;
            animation: fadeIn 0.3s ease-out;
          }

          @keyframes fadeIn {
            from {
              opacity: 0;
              transform: translateY(-20px);
            }
            to {
              opacity: 1;
              transform: translateY(0);
            }
          }

          .modal-content {
            background: #2c3e50;
            color: #ecf0f1;
            padding: 2rem;
            border-radius: 12px;
            text-align: center;
            max-width: 450px;
            width: 90%;
            box-shadow: 0 4px 12px rgba(0, 0, 0, 0.3);
            position: relative;
            animation: fadeIn 0.3s ease-out;
          }

          /* Kapatma Butonu Stilleri */
          .close-button {
            position: absolute;
            top: 1rem;
            right: 1rem;
            background: transparent;
            border: none;
            color: #ecf0f1;
            font-size: 1.5rem;
            cursor: pointer;
            transition: color 0.2s ease;
          }

          .close-button:hover {
            color: #e74c3c;
          }

          .modal-title {
            margin-bottom: 1.5rem;
            font-size: 2rem;
            color: #ff6347;
          }

          .modal-score,
          .modal-message {
            font-size: 1.2rem;
            margin-bottom: 1rem;
          }

          .button-group {
            display: flex;
            flex-direction: column;
            gap: 1rem;
            margin-top: 1rem;
          }

          .btn {
            padding: 0.75rem 1.5rem;
            font-size: 1rem;
            border: none;
            border-radius: 8px;
            cursor: pointer;
            transition: background-color 0.2s ease;
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 0.5rem;
          }

          .btn-primary {
            background-color: #ff6347;
            color: #ffffff;
          }

          .btn-primary:hover {
            background-color: #ff4500;
          }

          .btn-secondary {
            background-color: #6c757d;
            color: #ffffff;
          }

          .btn-secondary:hover {
            background-color: #5a6268;
          }

          .btn-outline {
            background-color: transparent;
            border: 2px solid #ff6347;
            color: #ff6347;
          }

          .btn-outline:hover {
            background-color: #ff6347;
            color: #ffffff;
          }
        `}</style>
      </div>
    </div>
  );
};

export default GameOverModal;
