// GameControls.js
import React from 'react';
import '../styles/kayanYazilarUcerStyle.css';

const GameControls = ({
  difficulty,
  paragraphsList,
  selectedParagraphIndex,
  speed,
  handleDifficultyChange,
  handleParagraphChange,
  handleSpeedChange,
  difficultyLocked,
}) => {
  const getWordCount = (htmlContent) => {
    if (!htmlContent) return 0;
    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = htmlContent;
    const textContent = tempDiv.textContent || tempDiv.innerText || '';
    const words = textContent.trim().split(/\s+/);
    return words.length;
  };

  return (
    <div className="kayan-yazilar-ucer-game-controls">
      {difficultyLocked ? (
        <div>
          <strong>Zorluk:</strong> {difficulty === 'easy' ? 'Kolay' : difficulty === 'medium' ? 'Orta' : 'Zor'}
        </div>
      ) : (
        <div>
          <label htmlFor="difficultySelect">Zorluk Seç:</label>
          <select
            id="difficultySelect"
            value={difficulty}
            onChange={handleDifficultyChange}
          >
            <option value="easy">Kolay</option>
            <option value="medium">Orta</option>
            <option value="hard">Zor</option>
          </select>
        </div>
      )}
      <div>
        <label htmlFor="paragraphSelect">Paragraf Seç:</label>
        <select
          id="paragraphSelect"
          value={selectedParagraphIndex}
          onChange={handleParagraphChange}
        >
          {paragraphsList.map((paragraph, index) => {
            const wordCount = getWordCount(paragraph.content);
            return (
              <option key={index} value={index}>
                {paragraph.title} - {wordCount} kelime
              </option>
            );
          })}
        </select>
      </div>
      <div>
        <label htmlFor="speed">Hız:</label>
        <input
          type="range"
          id="speed"
          min="1"
          max="10"
          step="1"
          value={speed}
          onChange={handleSpeedChange}
        />
        <span>{speed}</span>
      </div>
    </div>
  );
};

export default GameControls;
