import React from 'react';
import '../styles/cumleleriOkuStyle.css';

const CumleGameArea = ({ displayedSentences }) => {
  return (
    <div className="cumle-game-area">
      {displayedSentences.map((sentence, index) => (
        <div
          key={index}
          className={`cumle-item ${index === displayedSentences.length - 1 ? 'highlight' : ''}`}
        >
          {sentence}
        </div>
      ))}
    </div>
  );
};

export default CumleGameArea;
