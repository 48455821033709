// src/components/KayanYazilarUcerOyunu/hooks/useKayanYazilarUcerOyunu.js

import { useState, useEffect, useCallback } from 'react';
import { API_URLS } from '../../../../config/config';
import axiosInstance from '../../../../utils/axiosInstance';

/**
 * Üçer kelime kayan yazılar oyununa ait mantığı yönetir.
 *
 * @param {Function} navigate - React Router navigate fonksiyonu
 * @param {Object} userData   - Kullanıcı bilgileri
 * @param {string} token      - JWT token
 * @param {Object} stage      - Aşama bilgisi (varsa)
 * @returns {Object} - Hook fonksiyonları ve durum bilgileri
 */
export const useKayanYazilarUcerOyunu = (navigate, userData, token, stage) => {
  // Stage bilgisi varsa zorluk otomatik ayarlanır, yoksa kullanıcı seçebilir
  const stageNumber = stage?.number || null;

  let initialDifficulty = 'easy';
  let difficultyLocked = false;

  if (stageNumber) {
    difficultyLocked = true;
    if (stageNumber >= 1 && stageNumber <= 7) {
      initialDifficulty = 'easy';
    } else if (stageNumber >= 8 && stageNumber <= 14) {
      initialDifficulty = 'medium';
    } else {
      initialDifficulty = 'hard';
    }
  }

  const [difficulty, setDifficulty] = useState(initialDifficulty);
  const [paragraphsList, setParagraphsList] = useState([]);
  const [selectedParagraphIndex, setSelectedParagraphIndex] = useState(0);

  // Ekranda gösterilen "kelime grupları" üçer kelime (ör: ["Merhaba dünya nasılsın", "Bu bir örnek", ...])
  const [displayedWords, setDisplayedWords] = useState([]);
  const [currentWordIndex, setCurrentWordIndex] = useState(0);

  // Oyun durumu
  const [isPlaying, setIsPlaying] = useState(false);
  const [speed, setSpeed] = useState(2);
  const [elapsedTime, setElapsedTime] = useState(0);  // Geçen süre (saniye)
  const [gameTime, setGameTime] = useState(0);        // Toplam oyun süresi (saniye)
  const [timeLeft, setTimeLeft] = useState(0);        // Kalan süre (saniye)
  const [message, setMessage] = useState('');
  const [gameOver, setGameOver] = useState(false);

  // Bütün kelimelerin ham hali
  const [allWords, setAllWords] = useState([]);

  /**
   * Metnin tüm kelimelerini diziye atar.
   */
  const prepareWords = (content) => {
    const words = content.trim().split(/\s+/);
    setAllWords(words);
  };

  /**
   * Seçilen zorluk ve token'a göre backend'den paragraf verilerini çeker.
   */
  const fetchParagraphs = async () => {
    try {
      const url = `${API_URLS.HIZLIOKUMA_URL}akanyazi/${difficulty}`;
      const response = await axiosInstance.get(url);
      const data = response.data;

      setParagraphsList(data);
      setSelectedParagraphIndex(0);

      if (data[0]) {
        prepareWords(data[0].content);
      }
      handleReset();
    } catch (error) {
      console.error('Error fetching paragraphs:', error);
    }
  };

  // difficulty veya token değişince paragrafları çek
  useEffect(() => {
    fetchParagraphs();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [difficulty, token]);

  // Paragraf değiştiğinde allWords ve displayedWords'i sıfırla
  useEffect(() => {
    if (paragraphsList[selectedParagraphIndex]) {
      prepareWords(paragraphsList[selectedParagraphIndex].content);
      handleReset();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedParagraphIndex, paragraphsList]);

  /**
   * Her yeni paragraf yüklendiğinde ya da hız güncellenince
   * toplam oyun süresi (gameTime) ve timeLeft'i güncelleriz.
   */
  useEffect(() => {
    if (allWords.length > 0) {
      const wordCount = allWords.length;
      // Kaç tane üçlü grup var
      const totalWordGroups = Math.ceil(wordCount / 3);

      // Hız (speed) => saniyede kaç “3’lü grup” geçecek
      // totalWordGroups / speed => toplam kaç sn sürecek
      const estimatedTime = totalWordGroups / speed;

      // Kullanıcı "inactive" ise max 15sn, değilse hesaplanan kadar
      const maxTime = userData
        ? userData.status === 'inactive'
          ? 15
          : estimatedTime
        : 15;

      setGameTime(maxTime);
      setTimeLeft(maxTime);
    }
  }, [speed, allWords, userData]);

  /**
   * Her intervalde displayedWords'e sıradaki 3 kelimeyi ekler
   * timeLeft’i azaltır, elapsedTime’i artırır.
   */
  const updateWords = useCallback(() => {
    if (!isPlaying || allWords.length === 0) return;

    // Kelime bitti ise oyun biter
    if (currentWordIndex >= allWords.length) {
      setIsPlaying(false);
      setGameOver(true);
      return;
    }

    // 3 kelimeyi birleştir
    const nextWords = allWords.slice(currentWordIndex, currentWordIndex + 3).join(' ');
    setDisplayedWords((prev) => [...prev, nextWords]);
    setCurrentWordIndex((prev) => prev + 3);

    // Zaman hesaplaması (saniye bazında)
    const intervalInSeconds = 1 / speed;  // speed = saniyede kaç 3’lü grup

    setElapsedTime((prev) => prev + intervalInSeconds);
    setTimeLeft((prev) => {
      const newTime = prev - intervalInSeconds;
      // Süre bitmişse oyunu durdur
      if (newTime <= 0) {
        setIsPlaying(false);
        setGameOver(true);
        return 0;
      }
      return newTime;
    });
  }, [isPlaying, allWords, currentWordIndex, speed]);

  // isPlaying true ise interval kurarak updateWords’i çağır
  useEffect(() => {
    let wordInterval;
    if (isPlaying) {
      // 1 saniyede speed kadar 3’lü grup göstermek isteriz
      // => her (1000 / speed) ms’de bir updateWords()
      const intervalDuration = 1000 / speed;
      wordInterval = setInterval(() => {
        updateWords();
      }, intervalDuration);
    }
    return () => clearInterval(wordInterval);
  }, [isPlaying, speed, updateWords]);

  /**
   * Oyun oynama/duraklatma butonu
   */
  const handlePlayPause = () => {
    if (gameOver) return;
    if (isPlaying) {
      // Durakladığımız an, mesaj olarak şu ana kadar kaç kelime okundu gösterebiliriz
      setMessage(`Şu ana kadar okuduğunuz kelime sayısı: ${currentWordIndex}`);
    } else {
      setMessage('');
    }
    setIsPlaying(!isPlaying);
  };

  /**
   * Oyunu baştan başlat
   */
  const handleReset = () => {
    setIsPlaying(false);
    setDisplayedWords([]);
    setCurrentWordIndex(0);
    setElapsedTime(0);
    setMessage('');
    setGameOver(false);

    if (allWords.length > 0) {
      const wordCount = allWords.length;
      const totalWordGroups = Math.ceil(wordCount / 3);
      const estimatedTime = totalWordGroups / speed;
      const maxTime = userData
        ? userData.status === 'inactive'
          ? 15
          : estimatedTime
        : 15;

      setGameTime(maxTime);
      setTimeLeft(maxTime);
    }
  };

  /**
   * Hız slider’ı değiştiğinde speed güncellenir,
   * timeLeft de anlık olarak yeniden hesaplanır.
   */
  const handleSpeedChange = (e) => {
    const newSpeed = Number(e.target.value);
    setSpeed(newSpeed);

    const wordCount = allWords.length;
    const totalWordGroups = Math.ceil(wordCount / 3);
    const groupsLeft = totalWordGroups - Math.floor(currentWordIndex / 3);
    const estimatedTime = groupsLeft / newSpeed;
    setTimeLeft(Math.max(0, estimatedTime));
  };

  /**
   * Paragraf seçme dropdown'ı
   */
  const handleParagraphChange = (e) => {
    setSelectedParagraphIndex(Number(e.target.value));
  };

  /**
   * Zorluk seçme dropdown'ı
   */
  const handleDifficultyChange = (e) => {
    if (!difficultyLocked) {
      setDifficulty(e.target.value);
    }
  };

  /**
   * Süre dolarsa oyun biter
   */
  const onTimeUp = () => {
    setIsPlaying(false);
    setGameOver(true);
  };

  return {
    difficulty,
    paragraphsList,
    selectedParagraphIndex,
    displayedWords,
    currentWordIndex,
    isPlaying,
    speed,
    elapsedTime,
    gameTime,
    timeLeft,
    message,
    gameOver,
    setGameOver,
    handlePlayPause,
    handleReset,
    handleSpeedChange,
    handleParagraphChange,
    handleDifficultyChange,
    onTimeUp,
    updateWords,
    difficultyLocked
  };
};
