import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faTimes } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';

const GameOverModal = ({ score, onRestart, userData, onClose }) => {
  const navigate = useNavigate();
  const handleLoginRedirect = () => {
    navigate('/register');
  };

  return (
    <div className="kelimebulmaca-backdrop">
      <div className="kelimebulmaca-modal-container">
        <button className="kelimebulmaca-close-button" onClick={onClose}>
          <FontAwesomeIcon icon={faTimes} />
        </button>
        <h2 className="kelimebulmaca-modal-title">Oyun Bitti!</h2>

        {userData ? (
          <>
            <p className="kelimebulmaca-modal-score">Toplam Skorunuz: {score}</p>
            <div className="kelimebulmaca-modal-button-group">
              <button className="kelimebulmaca-modal-button outline" onClick={() => { onRestart(); onClose(); }}>
                Bitti
              </button>

              <button className="kelimebulmaca-modal-button secondary" onClick={() => navigate(-1)}>
                <FontAwesomeIcon icon={faArrowLeft} /> Geri Dön
              </button>
            </div>
          </>
        ) : (
          <div>
            <p className="kelimebulmaca-modal-score">Skoru görmek için giriş yapmalısınız.</p>
            <div className="kelimebulmaca-modal-button-group">
              <button className="kelimebulmaca-modal-button primary" onClick={handleLoginRedirect}>
                Kayıt Ol
              </button>
              <button className="kelimebulmaca-modal-button secondary" onClick={() => navigate(-1)}>
                <FontAwesomeIcon icon={faArrowLeft} /> Geri Dön
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default GameOverModal;
