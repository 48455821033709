const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'http://localhost:5000';

export const API_URLS = {

  ADMIN_URL: `${API_BASE_URL}/api/admin/`, 
  STUDENT_URL: `${API_BASE_URL}/api/student/`, 
  SCHOOL_URL: `${API_BASE_URL}/api/schools/`, 
  KURUMSAL_URL: `${API_BASE_URL}/api/kurumsal/`, 
  TEACHER_URL: `${API_BASE_URL}/api/teachers/`,   
  HIZLIOKUMA_URL: `${API_BASE_URL}/api/hizliokuma/`,
  TESTLER_URL: `${API_BASE_URL}/api/testler/`,
  PAYMENT_URL: `${API_BASE_URL}/api/payment/`,
  STUDENT_PAYMENT_URL: `${API_BASE_URL}/api/payment/student`, 
  HOME_URL :`${API_BASE_URL}/api/home`,
  BLOG_RESİM_URL: `${API_BASE_URL}`,
};
 