import React from 'react';
import '../styles/KelimeBulmaOyunu.css';

const DropdownSelectors = ({
  difficulty,
  selectedParagraphIndex,
  paragraphsList,
  handleDifficultyChange,
  handleParagraphChange,
  difficultyLocked,
}) => {
  const getWordCount = (htmlContent) => {
    if (!htmlContent) return 0;
    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = htmlContent;
    const textContent = tempDiv.textContent || tempDiv.innerText || '';
    const words = textContent.trim().split(/\s+/);
    return words.length;
  };

  return (
    <div className="row mb-3">
      <div className="col-12 col-md-6 mb-2 mb-md-0">
        <label htmlFor="difficultySelect" className="form-label kayu-label">
          Zorluk Seç:
        </label>
        {difficultyLocked ? (
          // Eğer aşamadan geliyorsa zorluk göster, ancak select kullanma
          <div className="kayu-locked-difficulty">
            <strong>
              {difficulty === 'easy'
                ? 'Kolay'
                : difficulty === 'medium'
                ? 'Orta'
                : 'Zor'}
            </strong>
          </div>
        ) : (
          <select
            className="form-select kayu-select"
            id="difficultySelect"
            value={difficulty}
            onChange={handleDifficultyChange}
          >
            <option value="easy">Kolay</option>
            <option value="medium">Orta</option>
            <option value="hard">Zor</option>
          </select>
        )}
      </div>
      <div className="col-12 col-md-6">
        <label htmlFor="paragraphSelect" className="form-label kayu-label">
          Paragraf Seç:
        </label>
        <select
          className="form-select kayu-select"
          id="paragraphSelect"
          value={selectedParagraphIndex}
          onChange={handleParagraphChange}
        >
          {paragraphsList.map((paragraph, index) => {
            const wordCount = getWordCount(paragraph.content);

            return (
              <option key={index} value={index}>
                {paragraph.title} - {wordCount} kelime
              </option>
            );
          })}
        </select>
      </div>
    </div>
  );
};

export default DropdownSelectors;
