import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import Header from './components/Header';
import Instructions from './components/Instructions';
import ExamArea from './components/ExamArea';
import ResultsModal from './components/ResultsModal';
import './styles/okumaSeviyeTestiStyle.css';
import { API_URLS } from '../../config/config';
import axiosInstance from '../../utils/axiosInstance';

const OkumaSeviyeTesti = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { stage, fromStage, initialTest } = location.state || {};

  const [paragraf, setParagraf] = useState('');
  const [loading, setLoading] = useState(false);
  const [readingTime, setReadingTime] = useState(0);      // Saniye cinsinden
  const [readingSpeed, setReadingSpeed] = useState(null); // Dakikada kelime (WPM)
  const [step, setStep] = useState(0);
  const [isRunning, setIsRunning] = useState(false);
  const timerRef = useRef(null);
  const [bookId, setBookId] = useState(null);
  const [wordCount, setWordCount] = useState(0);
  const [userData, setUserData] = useState(null);
  const [userRole, setUserRole] = useState(null);

  // Gerçekçi olmayan hız uyarısı
  const [showUnrealisticWarning, setShowUnrealisticWarning] = useState(false);
  // Sonuç modalını açmak için
  const [showResultsModal, setShowResultsModal] = useState(false);

  // Seçilen zorluk seviyesi
  const [selectedDifficulty, setSelectedDifficulty] = useState('');

  /**
   * 1) Öğrenci veya öğretmen verisini çek (me endpointleri)
   */
  useEffect(() => {
    const fetchUserData = async () => {
      try {
        // Öğrenci verisini çek (cookie tabanlı)
        const studentResponse = await axiosInstance.get(`${API_URLS.STUDENT_URL}me`);
        if (studentResponse.status === 200 && studentResponse.data) {
          setUserData(studentResponse.data);
          setUserRole('student');
          return;
        }
      } catch (error) {
        console.error('Öğrenci verisi alınırken hata oluştu:', error);
      }

      // Öğrenci bulunamadıysa ya da hata aldıysak öğretmeni kontrol et
      const token = localStorage.getItem('userToken');
      if (token) {
        try {
          const teacherResponse = await axios.get(`${API_URLS.TEACHER_URL}/me`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
          if (teacherResponse.status === 200 && teacherResponse.data) {
            setUserData(teacherResponse.data);
            setUserRole('teacher');
            return;
          }
        } catch (error) {
          console.error('Öğretmen verisi alınırken hata oluştu:', error);
        }
      }

      // Ne öğrenci ne de öğretmen verisi alınamadı
      setUserData(null);
      setUserRole(null);
    };

    fetchUserData();
  }, []);

  /**
   * 2) Seçilen zorluk (easy, medium, hard) için metni API'den getiren fonksiyon
   */
  const fetchParagraf = async (difficulty) => {
    setLoading(true);
    try {
      // /testler/seviye/easy, /testler/seviye/medium, /testler/seviye/hard
      const response = await axiosInstance.get(`${API_URLS.TESTLER_URL}seviye/${difficulty}`);
      const { content, _id } = response.data;
      setParagraf(content);
      setBookId(_id);
    } catch (error) {
      console.error('Paragraf alınırken hata oluştu:', error);
    } finally {
      setLoading(false);
    }
  };

  /**
   * 3) Zamanlayıcı kontrolü
   */
  useEffect(() => {
    if (isRunning) {
      timerRef.current = setInterval(() => {
        setReadingTime((prevTime) => prevTime + 1);
      }, 1000);
    } else if (!isRunning && timerRef.current) {
      clearInterval(timerRef.current);
    }
    return () => clearInterval(timerRef.current);
  }, [isRunning]);

  /**
   * 4) Zorluk seçildikten sonra "Devam" butonuna basılınca metni getirilir
   *    ve bir sonraki adıma (step=1) geçilir.
   */
  const handleSelectDifficulty = async () => {
    if (!selectedDifficulty) return;
    // Seçilen zorluk derecesine göre metni çek
    await fetchParagraf(selectedDifficulty);
    // Metin çekildikten sonra, talimat ekranına geç
    setStep(1);
  };

  /**
   * 5) Sınavı başlat (zamanlayıcıyı başlat ve step'i 2'ye al)
   */
  const handleStart = () => {
    setIsRunning(true);
    setStep(2);
  };

  /**
   * 6) Duraklat / Devam ettir
   */
  const handlePauseResume = () => {
    setIsRunning((prev) => !prev);
  };

  /**
   * 7) Okuma bittiğinde hesaplama ve uyarı kontrolü
   */
  const handleFinishReading = async () => {
    setIsRunning(false);

    // Okuma hızını (dakikada kelime) hesapla
    const wc = paragraf.split(' ').length;
    const timeInMinutes = readingTime / 60;
    const spd = wc / timeInMinutes;

    setWordCount(wc);
    setReadingSpeed(Math.round(spd));
    setStep(3);

    // Eğer kullanıcı dakikada 1000 kelimeden fazla okuyorsa uyarı ver
    if (spd > 1000) {
      setShowUnrealisticWarning(true);
      return;
    }

    // Eğer ölçüm gerçekçi ise, sonuç modalını göster
    setShowResultsModal(true);

    // Sonuçları kaydet (öğrenci veya öğretmense)
    if (userData && userRole) {
      const headers =
        userRole === 'teacher'
          ? { Authorization: `Bearer ${localStorage.getItem('userToken')}` }
          : undefined;

      const payload = {
        wordCount: wc,
        totalTime: readingTime,
        readingSpeed: Math.round(spd),
      };

      try {
        let apiUrl = '';
        // Aşama (stage) testi ise
        if (fromStage && stage) {
          payload.stageNumber = stage.number;
          payload.bookId = bookId;
          apiUrl =
            userRole === 'student'
              ? `${API_URLS.STUDENT_URL}akillikurs/progress/update-stage-test`
              : `${API_URLS.TEACHER_URL}akillikurs/progress/update-stage-test`;
        }
        // İlk hız testi (initialTest) ise
        else if (initialTest) {
          apiUrl =
            userRole === 'student'
              ? `${API_URLS.STUDENT_URL}akillikurs/test/initial-test`
              : `${API_URLS.TEACHER_URL}akillikurs/test/initial-test`;
        } else {
          // Ne fromStage ne de initialTest -> kayıt yok
          return;
        }

        if (userRole === 'student') {
          await axiosInstance.post(apiUrl, payload);
        } else {
          await axios.post(apiUrl, payload, { headers });
        }
      } catch (error) {
        console.error('Sonuç kaydedilirken hata oluştu:', error);
      }
    }
  };

  /**
   * 8) Sonuç modalını kapatma
   */
  const handleCloseResultsModal = () => {
    setShowResultsModal(false);
  };

  /**
   * 9) Çok yüksek hız uyarısında testi başa alma
   */
  const handleRetakeTest = () => {
    setShowUnrealisticWarning(false);
    setReadingTime(0);
    setStep(1);
  };

  return (
    <div className="okuma-seviye-testi-container">
      <Header />

      {/**
       * Adım 0: Kullanıcıdan zorluk seçmesini istediğimiz kısım
       * (selectedDifficulty state'i bu ekranda yönetiliyor).
       * Bu alanı ortalayacak ve daha güzel tasarlayacak şekilde
       * CSS ekleyeceğiz (aşağıda .select-difficulty-container).
       */}
      {step === 0 && (
        <div className="select-difficulty-container">
          <h2>Okuma Testi - Zorluk Seçimi</h2>
          <select
            value={selectedDifficulty}
            onChange={(e) => setSelectedDifficulty(e.target.value)}
          >
            <option value="">Lütfen bir zorluk seçin</option>
            <option value="easy">Kolay</option>
            <option value="medium">Orta</option>
            <option value="hard">Zor</option>
          </select>
          <button
            className="okuma-seviye-testi-button"
            onClick={handleSelectDifficulty}
            disabled={!selectedDifficulty}
          >
            Devam
          </button>
        </div>
      )}

      {/**
       * Adım 1: Yalnızca Instructions bileşenindeki "Sınavı Başlat" butonunu gösteriyoruz.
       * İkinci defa "Sınavı Başlat" yazan bölümü kaldırdık.
       */}
      {step === 1 && !showUnrealisticWarning && (
        <>
          {loading ? (
            <p>Metin yükleniyor...</p>
          ) : (
            <>
              {/* 
                Instructions bileşeninin içindeki "Sınavı Başlat" butonu 
                onStart={() => handleStart()} şeklinde ayarlayın.
              */}
              <Instructions onStart={handleStart} />
            </>
          )}
        </>
      )}

      {/**
       * Adım 2: Sınav esnasında paragrafın gösterildiği ve sayaçların çalıştığı kısım
       */}
      {step === 2 && (
        <ExamArea
          isRunning={isRunning}
          readingTime={readingTime}
          paragraf={paragraf}
          handlePauseResume={handlePauseResume}
          handleFinishReading={handleFinishReading}
          loading={loading}
        />
      )}

      {/**
       * Gerçekçi olmayan hız uyarısı
       */}
      {showUnrealisticWarning && (
        <div className="unrealistic-warning fade-in">
          <div className="unrealistic-warning-content">
            <h3>Okuma hızınız gerçekçi görünmüyor</h3>
            <p>
              Bu kadar kısa sürede bu metni bu hızla okumanız muhtemelen mümkün değil. 
              Lütfen testi yeniden yapınız.
            </p>
            <button
              className="unrealistic-warning-button"
              onClick={handleRetakeTest}
            >
              Tekrar Dene
            </button>
          </div>
        </div>
      )}

      {/**
       * Sonuç ekranı (Modal)
       */}
      {showResultsModal && (
        <ResultsModal
          wordCount={wordCount}
          readingTime={readingTime}
          readingSpeed={readingSpeed}
          userData={userData}
          fromStage={fromStage}
          stage={stage}
          bookId={bookId}
          initialTest={initialTest}
          userRole={userRole}
          onClose={handleCloseResultsModal}
        />
      )}
    </div>
  );
};

export default OkumaSeviyeTesti;
