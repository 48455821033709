// GameOverModal.js

import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faTimes } from '@fortawesome/free-solid-svg-icons';
import styled, { keyframes } from 'styled-components';

// Animasyonlar
const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

// Stil Bileşenleri
const Backdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const ModalContainer = styled.div`
  background: #1e1e2f;
  color: #ffffff;
  padding: 2.5rem;
  border-radius: 12px;
  width: 90%;
  max-width: 450px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.25);
  animation: ${fadeIn} 0.3s ease-out;
  position: relative;
`;

const CloseButton = styled.button`
  position: absolute;
  top: 1rem;
  right: 1rem;
  background: transparent;
  border: none;
  color: #ffffff;
  font-size: 1.2rem;
  cursor: pointer;

  &:hover {
    color: #ff4d4f;
  }
`;

const Title = styled.h2`
  margin-bottom: 1.5rem;
  font-size: 2rem;
  text-align: center;
  color: #ff6347;
`;

const Score = styled.p`
  font-size: 1.5rem;
  margin-bottom: 1rem;
  text-align: center;
`;

const AttemptsInfo = styled.p`
  font-size: 1.2rem;
  margin-bottom: 1rem;
  text-align: center;
`;

const AttemptsList = styled.div`
  text-align: left;
  margin-top: 1rem;
  max-height: 200px;
  overflow-y: auto;
`;

const AttemptsListItem = styled.li`
  margin-bottom: 0.5rem;
`;

const AttemptsListUl = styled.ul`
  list-style: none;
  padding: 0;
`;

const Message = styled.p`
  font-size: 1.2rem;
  margin-bottom: 1rem;
  text-align: center;
`;

const ButtonGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const Button = styled.button`
  padding: 0.75rem;
  font-size: 1rem;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.2s ease;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;

  &.primary {
    background-color: #ff6347;
    color: #ffffff;

    &:hover {
      background-color: #ff4500;
    }
  }

  &.secondary {
    background-color: #6c757d;
    color: #ffffff;

    &:hover {
      background-color: #5a6268;
    }
  }

  &.outline {
    background-color: transparent;
    border: 2px solid #ff6347;
    color: #ff6347;

    &:hover {
      background-color: #ff6347;
      color: #ffffff;
    }
  }
`;

const GameOverModal = ({ score, onRestart, userData, onClose, attempts,  handleReturnToPreviousStage }) => {
  const navigate = useNavigate();

  const handleLoginRedirect = () => {
    navigate('/register');
  };



  // ESC tuşunu dinleyerek modal'ı kapatma
  useEffect(() => {
    const handleEsc = (event) => {
      if (event.key === 'Escape') {
        onClose();
      }
    };

    window.addEventListener('keydown', handleEsc);

    return () => {
      window.removeEventListener('keydown', handleEsc);
    };
  }, [onClose]);

  // Toplam doğru ve yanlış sayıları hesaplama
  const totalAttempts = attempts.length;
  const correctAttempts = attempts.filter((attempt) => attempt.isCorrect).length;

  return (
    <Backdrop onClick={onClose}>
      <ModalContainer onClick={(e) => e.stopPropagation()}>
        <CloseButton onClick={onClose}>
          <FontAwesomeIcon icon={faTimes} />
        </CloseButton>
        <Title>Çalışma Bitti!</Title>

        {!userData ? (
          // Kullanıcı giriş yapmamış
          <div>
            <Message>Giriş yapmadığınız için skoru göremiyorsunuz.</Message>
            <ButtonGroup>
              <Button className="primary" onClick={handleLoginRedirect}>
                Kayıt Ol
              </Button>
              <Button className="secondary" onClick={() => navigate(-1)}>
                <FontAwesomeIcon icon={faArrowLeft} /> Geri Dön
              </Button>
            </ButtonGroup>
          </div>
        ) : userData.status === 'inactive' ? (
          // Kullanıcı giriş yapmış ama hesabı aktif değil
          <div>
            <Message>Hesabınız aktif değil, lütfen hesabınızı aktifleştirin.</Message>
            <ButtonGroup>
              <Button className="primary" onClick={handleReturnToPreviousStage}>
                Hesabı Aktifleştir
              </Button>
              <Button className="secondary" onClick={() => navigate(-1)}>
                <FontAwesomeIcon icon={faArrowLeft} /> Geri Dön
              </Button>
            </ButtonGroup>
          </div>
        ) : (
          // Kullanıcı giriş yapmış ve hesabı aktif
          <>
            <Score>Toplam Skorunuz: {score}</Score>
            <AttemptsInfo>
              Doğru Cevap Sayısı: {correctAttempts} / {totalAttempts}
            </AttemptsInfo>
            <AttemptsList>
              <h3>Denemeleriniz:</h3>
              <AttemptsListUl>
                {attempts.map((attempt, index) => (
                  <AttemptsListItem key={index}>
                    <strong>{index + 1}. Deneme:</strong> Gördüğünüz Harfler: {attempt.correctLetters.join(', ')} - Sizin Cevabınız: {attempt.userInput.join(', ')} - {attempt.isCorrect ? 'Doğru' : 'Yanlış'}
                  </AttemptsListItem>
                ))}
              </AttemptsListUl>
            </AttemptsList>
            <ButtonGroup>
              <Button className="outline" onClick={onClose}>
                Bitti
              </Button>
              <Button className="secondary" onClick={() => navigate(-1)}>
                <FontAwesomeIcon icon={faArrowLeft} /> Geri Dön
              </Button>
            </ButtonGroup>
          </>
        )}
      </ModalContainer>
    </Backdrop>
  );
};

export default GameOverModal;

