import React from 'react';
import { Route, Routes } from 'react-router-dom';
import NoktaTakipEt from '../pages/Egzersizler/NoktaTakipEt/GameSetup';
import NesneyiTakipEtGame from '../pages/Egzersizler/NesneyiTakipEt/GameSetup';
import ZigzagTracker from '../pages/Egzersizler/ZigzagTakipOyunu/GameSetup';
import RotatingDiamondGame from '../pages/Egzersizler/HarfDongusuOne/GameSetup';
import RotatingRectangleGame from '../pages/Egzersizler/HarfDongusuSecond/GameSetup';
import HangiSayiGordun from '../pages/Egzersizler/HangiSayiGordun/GameSetup';
import HangiHarfiGordun from '../pages/Egzersizler/HangiHarfiGordun/GameSetup';
import CumleleriOkuGame from '../pages/Egzersizler/CumleOku/CumleleriOkuGame';
import KelimeBulmaca from '../pages/Egzersizler/KelimeBulmaca/GameSetup';


function EgzersizRouter() {
    return (   
      <Routes>  
          <Route path="/nokta-takip-et" element={<NoktaTakipEt />} />  
          <Route path="/nesne-takip-et" element={<NesneyiTakipEtGame />} />  
          <Route path="/zigzag" element={<ZigzagTracker />} />  
          <Route path="/harf-dongusu" element={<RotatingDiamondGame />} />
          <Route path="/harf-dongusu-second" element={<RotatingRectangleGame />} />
          <Route path="/sayi-tahmin-et" element={<HangiSayiGordun />} />
          <Route path="/gordugun-harfi-tahmin-et" element={<HangiHarfiGordun />} />  
          <Route path="/cumleyi-oku" element={<CumleleriOkuGame />} />  
          <Route path="/kelime-bulmaca" element={<KelimeBulmaca />} />  
          </Routes>
    );
  }
  
  export default EgzersizRouter;