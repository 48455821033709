import React, { useState, useEffect } from 'react';
import { Card, CardContent, Typography, Collapse, Box, TextField, FormControl, InputLabel, Select, MenuItem, Stack, IconButton, Table, TableBody, TableCell, TableContainer,
  TableHead, TableRow, Paper, Button, Dialog, DialogTitle, DialogContent, DialogActions} from '@mui/material';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import RefreshIcon from '@mui/icons-material/Refresh';
import DOMPurify from 'dompurify';
import parse, { domToReact } from 'html-react-parser';
import useFetchUserData from '../../../../../hooks/kurum/useFetchUserData';
import { API_URLS } from '../../../../../config/config';

// Boş elementleri tanımlıyoruz (HTML parse işlemi için)
const voidElements = new Set([
  'area', 'base', 'br', 'col', 'embed', 'hr',
  'img', 'input', 'keygen', 'link', 'meta',
  'param', 'source', 'track', 'wbr'
]);

const BenimListe = () => {
  const [books, setBooks] = useState([]);
  const [userData, setUserData] = useState(null);

  // Filtreleme ile ilgili state'ler
  const [searchQuery, setSearchQuery] = useState('');
  const [difficultyFilter, setDifficultyFilter] = useState('');

  // Collapsible (açılır/kapanır) satır için
  const [expandedBookId, setExpandedBookId] = useState(null);

  // Düzenleme ile ilgili state'ler
  const [duzenlenenKitap, setDuzenlenenKitap] = useState({
    _id: '',
    title: '',
    content: '',
    difficulty: '',
  });
  const [duzenlemeDialogAcik, setDuzenlemeDialogAcik] = useState(false);

  // Test ekleme ile ilgili state'ler
  const [testFormAcik, setTestFormAcik] = useState(false);
  const [secilenKitapId, setSecilenKitapId] = useState('');
  const [testSorusu, setTestSorusu] = useState('');
  const [dogruCevap, setDogruCevap] = useState(0);
  const [testAciklamasi, setTestAciklamasi] = useState('');
  const [testSiklari, setTestSiklari] = useState([]);

  // Kullanıcı verisini çekiyoruz
  useFetchUserData(setUserData);

  // Kitapları çeken fonksiyon
  const fetchBooks = async () => {
    if (!userData || !userData._id) {
      console.log("Kullanıcı bilgisi yüklenemedi veya kullanıcı ID'si bulunamadı.");
      return;
    }

    try {
      const response = await fetch(`${API_URLS.KURUMSAL_URL}kutuphane/${userData._id}`);
      const data = await response.json();

      if (response.ok) {
        // data => { success: true, data: [ ... ] } formatında
        setBooks(data.data);
      } else {
        console.error('Kitapları yüklerken bir sorun oluştu: ' + data.message);
      }
    } catch (error) {
      console.error('Fetch işlemi sırasında hata oluştu:', error);
    }
  };

  // Sayfa yüklendiğinde / userData değiştiğinde kitapları getir
  useEffect(() => {
    fetchBooks();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData]);

  // Tabloda bir satıra tıklandığında açılır/kapanır içerik
  const handleRowClick = (bookId) => {
    setExpandedBookId((prev) => (prev === bookId ? null : bookId));
  };

  // Düzenleme butonuna basınca çalışan fonksiyon
  const handleEditBook = (bookId) => {
    const seciliKitap = books.find((kitap) => kitap._id === bookId);
    if (seciliKitap) {
      setDuzenlenenKitap({
        _id: seciliKitap._id,
        title: seciliKitap.title,
        content: seciliKitap.content,
        difficulty: seciliKitap.difficulty,
      });
      setDuzenlemeDialogAcik(true);
    } else {
      console.error('Seçilen kitap bulunamadı.');
    }
  };

  // Düzenleme inputları
  const handleKitapDegisiklikleri = (e) => {
    setDuzenlenenKitap({
      ...duzenlenenKitap,
      [e.target.name]: e.target.value,
    });
  };

  // Güncelleme isteği
  const handleGuncelle = async () => {
    try {
      const response = await fetch(`${API_URLS.KURUMSAL_URL}kutuphane/${duzenlenenKitap._id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          title: duzenlenenKitap.title,
          content: duzenlenenKitap.content,
          difficulty: duzenlenenKitap.difficulty,
        }),
      });

      const data = await response.json();
      if (!response.ok) throw new Error(data.message || 'Bir hata oluştu');

      // Geri dönen data, güncellenmiş kitap objesi ise,
      // state içindeki ilgili kitabı güncelle
      setBooks(books.map((kitap) => (kitap._id === duzenlenenKitap._id ? data : kitap)));
      setDuzenlemeDialogAcik(false);
    } catch (err) {
      console.error(err);
    }
  };

  // Silme işlemi
  const handleDeleteBook = async (bookId) => {
    try {
      const response = await fetch(`${API_URLS.KURUMSAL_URL}kutuphane/${bookId}`, {
        method: 'DELETE',
      });
      const data = await response.json();
      if (response.ok) {
        console.log(data.message);
        fetchBooks();
      } else {
        console.error('Kitap silme işlemi sırasında bir sorun oluştu: ' + data.message);
      }
    } catch (error) {
      console.error('Fetch işlemi sırasında hata oluştu:', error);
    }
  };

  // Test ekleme butonuna basınca
  const handleCreateTest = (bookId) => {
    setSecilenKitapId(bookId);
    setTestFormAcik(true);
  };

  const handleTestFormKapat = () => {
    setTestFormAcik(false);
    setTestSorusu('');
    setTestSiklari(['', '', '', '', '']);
    setDogruCevap(0);
    setTestAciklamasi('');
  };

  const handleSikEkle = () => {
    if (testSiklari.length < 5) {
      setTestSiklari([...testSiklari, '']);
    }
  };

  const handleSikSil = (index) => {
    const yeniSiklar = [...testSiklari];
    yeniSiklar.splice(index, 1);
    setTestSiklari(yeniSiklar);
  };

  // Test kaydet
  const handleTestFormSubmit = async (e) => {
    e.preventDefault();

    if (testSiklari.length < 2 || testSiklari.length > 5) {
      alert('En az 2, en fazla 5 seçenek girilmelidir.');
      return;
    }

    if (dogruCevap < 0 || dogruCevap >= testSiklari.length) {
      alert('Geçerli bir doğru cevap seçin.');
      return;
    }

    try {
      const response = await fetch(`${API_URLS.KURUMSAL_URL}kutuphane/tests`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          bookId: secilenKitapId,
          question: testSorusu,
          options: testSiklari,
          correctAnswer: testSiklari[dogruCevap],
          explanation: testAciklamasi,
        }),
      });

      const data = await response.json();
      if (!response.ok) {
        throw new Error(data.message || 'Bir hata oluştu');
      }

      alert('Test başarıyla oluşturuldu.');
      handleTestFormKapat();
    } catch (err) {
      console.error(err);
      alert(`Hata: ${err.message}`);
    }
  };

  // HTML parse ve sanitize ayarları
  const htmlRenderOptions = {
    replace: (domNode) => {
      if (domNode.type === 'tag') {
        if (voidElements.has(domNode.name)) {
          return React.createElement(domNode.name, { key: domNode.name, ...domNode.attribs }, null);
        } else {
          return React.createElement(
            domNode.name,
            { key: domNode.name, ...domNode.attribs },
            domToReact(domNode.children, htmlRenderOptions)
          );
        }
      }
    },
  };

  // Filtrelenmiş kitaplar
  const filteredBooks = books.filter((book) => {
    const matchesSearch = book.title?.toLowerCase().includes(searchQuery.toLowerCase());
    const matchesDifficulty = difficultyFilter
      ? book.difficulty === difficultyFilter
      : true;
    return matchesSearch && matchesDifficulty;
  });

  // İçerik görselleştirme (collapsible)
  const handleCollapse = (bookId) => handleRowClick(bookId);

  // Düzenleme dialog
  const renderDuzenlemeFormuDialog = () => (
    <Dialog open={duzenlemeDialogAcik} onClose={() => setDuzenlemeDialogAcik(false)}>
      <DialogTitle>Kitap Düzenle</DialogTitle>
      <DialogContent>
        <TextField
          fullWidth
          label="Kitap Adı"
          name="title"
          value={duzenlenenKitap.title}
          onChange={handleKitapDegisiklikleri}
          margin="normal"
        />
        <TextField
          fullWidth
          label="İçerik"
          name="content"
          value={duzenlenenKitap.content}
          onChange={handleKitapDegisiklikleri}
          margin="normal"
          multiline
          rows={4}
        />
        <FormControl fullWidth margin="normal">
          <InputLabel>Zorluk Seviyesi</InputLabel>
          <Select
            name="difficulty"
            value={duzenlenenKitap.difficulty}
            label="Zorluk Seviyesi"
            onChange={handleKitapDegisiklikleri}
          >
            <MenuItem value="easy">Kolay</MenuItem>
            <MenuItem value="medium">Orta</MenuItem>
            <MenuItem value="hard">Zor</MenuItem>
          </Select>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setDuzenlemeDialogAcik(false)}>İptal</Button>
        <Button onClick={handleGuncelle} color="primary" variant="contained">
          Güncelle
        </Button>
      </DialogActions>
    </Dialog>
  );

  // Test formu dialog
  const renderTestForm = () => (
    <Dialog open={testFormAcik} onClose={handleTestFormKapat} maxWidth="md" fullWidth>
      <DialogTitle>Test Oluştur</DialogTitle>
      <DialogContent>
        <TextField
          fullWidth
          label="Soru"
          value={testSorusu}
          onChange={(e) => setTestSorusu(e.target.value)}
          margin="normal"
        />
        {testSiklari.map((sik, index) => (
          <div key={index} style={{ display: 'flex', alignItems: 'center' }}>
            <TextField
              fullWidth
              label={`Şık ${index}`}
              value={sik}
              onChange={(e) => {
                const yeniSiklar = [...testSiklari];
                yeniSiklar[index] = e.target.value;
                setTestSiklari(yeniSiklar);
              }}
              margin="normal"
            />
            <IconButton onClick={() => handleSikSil(index)}>
              <DeleteIcon />
            </IconButton>
          </div>
        ))}
        <Button
          onClick={handleSikEkle}
          color="primary"
          variant="contained"
          style={{ marginTop: '10px' }}
          disabled={testSiklari.length >= 5}
        >
          Şık Ekle
        </Button>
        <FormControl fullWidth margin="normal">
          <InputLabel>Doğru Cevap</InputLabel>
          <Select
            value={dogruCevap}
            onChange={(e) => setDogruCevap(e.target.value)}
            displayEmpty
          >
            <MenuItem value="" disabled>
              Doğru cevabı seçin
            </MenuItem>
            {testSiklari.map((_, index) => (
              <MenuItem key={index} value={index}>
                Şık {index}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <TextField
          fullWidth
          label="Açıklama"
          value={testAciklamasi}
          onChange={(e) => setTestAciklamasi(e.target.value)}
          margin="normal"
          multiline
          rows={4}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleTestFormKapat}>İptal</Button>
        <Button onClick={handleTestFormSubmit} color="primary" variant="contained">
          Kaydet
        </Button>
      </DialogActions>
    </Dialog>
  );

  return (
    <Card sx={{ mt: 2 }}>
      <CardContent>
        <Typography
          variant="h5"
          sx={{ mb: 2, fontWeight: 'bold', textAlign: 'center' }}
        >
          Tüm Liste
        </Typography>
        {/* Filtreler */}
        <Stack direction="row" spacing={2} sx={{ mb: 2, justifyContent: 'center' }}>
          <TextField
            label="Kitap Ara"
            variant="outlined"
            size="small"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
          <FormControl variant="outlined" size="small" sx={{ minWidth: 120 }}>
            <InputLabel>Zorluk</InputLabel>
            <Select
              label="Zorluk"
              value={difficultyFilter}
              onChange={(e) => setDifficultyFilter(e.target.value)}
            >
              <MenuItem value="">Tümü</MenuItem>
              <MenuItem value="easy">Kolay</MenuItem>
              <MenuItem value="medium">Orta</MenuItem>
              <MenuItem value="hard">Zor</MenuItem>
            </Select>
          </FormControl>
          <Button
            startIcon={<RefreshIcon />}
            onClick={fetchBooks}
            variant="contained"
            color="primary"
          >
            Yenile
          </Button>
        </Stack>

        <TableContainer component={Paper}>
          <Table>
            <TableHead sx={{ backgroundColor: '#f5f5f5' }}>
              <TableRow>
                <TableCell sx={{ fontWeight: 'bold' }}>Kitap Adı</TableCell>
                <TableCell sx={{ fontWeight: 'bold' }}>Zorluk</TableCell>
                <TableCell sx={{ fontWeight: 'bold' }}>İşlem</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredBooks.length === 0 ? (
                <TableRow>
                  <TableCell colSpan={3} align="center">
                    Aradığınız kriterlere uygun kitap bulunmuyor.
                  </TableCell>
                </TableRow>
              ) : (
                filteredBooks.map((book) => {
                  const isExpanded = expandedBookId === book._id;
                  const sanitizedContent = DOMPurify.sanitize(book.content || '');

                  return (
                    <React.Fragment key={book._id}>
                      <TableRow
                        hover
                        onClick={() => handleCollapse(book._id)}
                        sx={{
                          cursor: 'pointer',
                          transition: 'background-color 0.3s',
                          '&:hover': { backgroundColor: '#f0f0f0' }
                        }}
                      >
                        <TableCell>{book.title}</TableCell>
                        <TableCell>
                          {book.difficulty === 'easy'
                            ? 'Kolay'
                            : book.difficulty === 'medium'
                            ? 'Orta'
                            : book.difficulty === 'hard'
                            ? 'Zor'
                            : book.difficulty}
                        </TableCell>
                        <TableCell>
                          <IconButton
                            onClick={(e) => {
                              e.stopPropagation();
                              handleEditBook(book._id);
                            }}
                          >
                            <EditIcon />
                          </IconButton>
                          <IconButton
                            onClick={(e) => {
                              e.stopPropagation();
                              handleDeleteBook(book._id);
                            }}
                          >
                            <DeleteIcon />
                          </IconButton>
                          <IconButton
                            onClick={(e) => {
                              e.stopPropagation();
                              handleCreateTest(book._id);
                            }}
                          >
                            <AddCircleIcon />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                      {/* Collapsible Content Row */}
                      <TableRow>
                        <TableCell colSpan={3} sx={{ p: 0 }}>
                          <Collapse in={isExpanded} timeout="auto" unmountOnExit>
                            <Box
                              sx={{
                                p: 2,
                                backgroundColor: '#fefaf2',
                                fontFamily: 'serif',
                                lineHeight: '1.6',
                                borderLeft: '4px solid #cab18c',
                                mt: 1
                              }}
                            >
                              <Typography variant="h6" sx={{ fontFamily: 'serif', mb: 2 }}>
                                Kitap İçeriği
                              </Typography>
                              <Typography variant="body1" sx={{ whiteSpace: 'pre-wrap' }} component="div">
                                {parse(sanitizedContent, htmlRenderOptions)}
                              </Typography>
                            </Box>
                          </Collapse>
                        </TableCell>
                      </TableRow>
                    </React.Fragment>
                  );
                })
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </CardContent>
      {/* Düzenleme Dialog */}
      {renderDuzenlemeFormuDialog()}
      {/* Test Oluştur Dialog */}
      {renderTestForm()}
    </Card>
  );
};

export default BenimListe;
