import React, { useEffect, useState } from 'react';
import { API_URLS } from '../../../config/config';
import { 
  BarChart, Bar, LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer 
} from 'recharts';
import { 
  Card, CardContent, Typography, Tabs, Tab, Grid, Box, Paper, Divider, useTheme, useMediaQuery 
} from '@mui/material';
import axios from '../../../utils/axiosInstance';
import { styled } from '@mui/system';

const StyledSection = styled(Box)(({ theme }) => ({
  padding: theme.spacing(4),
  borderRadius: theme.shape.borderRadius,
  marginBottom: theme.spacing(4),
  boxShadow: theme.shadows[2],
  background: 'linear-gradient(135deg, #1a237e 0%, #4a148c 100%)', 
  color: '#FAFAFA',
  fontFamily: 'Poppins, sans-serif',
}));

const StyledCard = styled(Card)(({ theme }) => ({
  boxShadow: theme.shadows[8],
  borderRadius: theme.shape.borderRadius * 2,
  background: 'linear-gradient(135deg, #ff4081 0%, #ff6f00 100%)', 
  color: '#FAFAFA',
  fontFamily: 'Poppins, sans-serif',
}));

const StyledChartContainer = styled(Box)(({ theme }) => ({
  width: '100%',
  height: 300,
  [theme.breakpoints.up('md')]: {
    height: 350,
  },
}));

const SectionTitle = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(3),
  fontWeight: '700',
  textAlign: 'center',
  textTransform: 'uppercase',
  letterSpacing: '0.05em',
  fontSize: '2rem',
  color: '#FFD700',
  fontFamily: 'Poppins, sans-serif',
  textShadow: '2px 2px 4px rgba(0,0,0,0.5)',
}));

const SubTitle = styled(Typography)(({ theme }) => ({
  fontWeight: '600',
  marginBottom: theme.spacing(2),
  color: '#FAFAFA',
  fontFamily: 'Poppins, sans-serif',
  fontSize: '1.2rem',
  textShadow: '1px 1px 2px rgba(0,0,0,0.3)',
}));

const InfoText = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(1),
  color: '#FAFAFA',
  fontFamily: 'Poppins, sans-serif',
}));

const ProgressReport = () => {
  const [progressData, setProgressData] = useState(null);
  const [initialTestResult, setInitialTestResult] = useState(null);
  const [selectedStage, setSelectedStage] = useState(0);

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [progressResponse, initialTestResponse] = await Promise.all([
          axios.get(`${API_URLS.STUDENT_URL}akillikurs/progress`),
          axios.get(`${API_URLS.STUDENT_URL}akillikurs/test/initial-test`),
        ]);

        setProgressData(progressResponse.data.progress);

        if (initialTestResponse.status === 200 && initialTestResponse.data.testResult) {
          setInitialTestResult(initialTestResponse.data.testResult);
        } else {
          setInitialTestResult(null);
        }

      } catch (error) {
        console.error('Veriler alınırken bir hata oluştu:', error);
      }
    };

    fetchData();
  }, []);

  if (!progressData) {
    return (
      <Box sx={{ textAlign: 'center', mt: 4, color: '#FAFAFA', fontFamily: 'Poppins, sans-serif' }}>
        <Typography variant="h6">İlerleme verileri yükleniyor...</Typography>
      </Box>
    );
  }

  const stages = progressData.stages;

  const prepareChartData = (stageIndex) => {
    const chartData = [];
    const stage = stages[stageIndex];

    if (stage) {
      stage.games.forEach((game) => {
        chartData.push({
          gameName: game.gameName,
          score: game.score || 0,
          time: game.time || 0,
          wordCount: game.wordCount || 0,
          completedAt: game.completedAt
            ? new Date(game.completedAt).toLocaleDateString()
            : 'Tamamlanmadı',
        });
      });
    }

    return chartData;
  };

  const chartData = prepareChartData(selectedStage);

  const handleTabChange = (event, newValue) => {
    setSelectedStage(newValue);
  };

  const prepareOverallChartData = () => {
    const overallChartData = [];

    stages.forEach((stage) => {
      const stageData = {
        stageNumber: `Aşama ${stage.stageNumber}`,
        totalScore: 0,
        totalTime: 0,
        totalWordCount: 0,
      };

      stage.games.forEach((game) => {
        stageData.totalScore += game.score || 0;
        stageData.totalTime += game.time || 0;
        stageData.totalWordCount += game.wordCount || 0;
      });

      overallChartData.push(stageData);
    });

    return overallChartData;
  };

  const overallChartData = prepareOverallChartData();

  const formatTime = (totalSeconds) => {
    const minutes = Math.floor(totalSeconds / 60);
    const seconds = totalSeconds % 60;
    return `${minutes} dakika ${seconds} saniye`;
  };

  // Anlama Oranı Hesaplama Fonksiyonu
  const getComprehensionRate = (stage, initialResult) => {
    let comprehensionRate = 0;

    if (stage.testResult && stage.testResult.totalQuestions > 0) {
      // Soru tabanlı hesaplama
      comprehensionRate = (stage.testResult.correctAnswers / stage.testResult.totalQuestions) * 100;
    } else if (stage.testResult && initialResult && initialResult.readingSpeed > 0) {
      // Okuma hızı tabanlı hesaplama
      comprehensionRate = (stage.testResult.readingSpeed / initialResult.readingSpeed) * 100;
    } else {
      comprehensionRate = 0;
    }

    // %100'ü aşmamasını sağla
    comprehensionRate = Math.min(comprehensionRate, 100);
    comprehensionRate = parseFloat(comprehensionRate.toFixed(2));

    return comprehensionRate;
  };

  const prepareComprehensionData = () => {
    const data = [];

    if (initialTestResult) {
      data.push({
        name: 'Başlangıç Testi',
        comprehensionRate: 0, 
      });
    }

    stages.forEach((stage) => {
      const comprehensionRate = getComprehensionRate(stage, initialTestResult);
      data.push({
        name: `Aşama ${stage.stageNumber}`,
        comprehensionRate,
      });
    });

    return data;
  };

  const comprehensionData = prepareComprehensionData();

  // Desteklenen easing fonksiyonlarını kullanıyoruz (örn: "ease-out")
  const animationProps = {
    isAnimationActive: true,
    animationBegin: 300,
    animationDuration: 1500,
    animationEasing: "ease-out", 
  };

  return (
    <Box sx={{ background: '#0A1929', minHeight: '100vh', fontFamily: 'Poppins, sans-serif' }}>
      {/* Anlama Oranı Bölümü */}
      <StyledSection>
        <SectionTitle variant="h4">Anlama Oranı</SectionTitle>
        <InfoText variant="body1" sx={{ textAlign: 'center', marginBottom: 4, fontSize: '1rem' }}>
          Okuduğunuz metinlerin ne kadarını anladığınızı, zaman içinde artan oranda görün!
        </InfoText>

        <StyledCard sx={{ marginBottom: 4 }}>
          <CardContent>
            <SubTitle variant="h6">Anlama Oranı Grafiği</SubTitle>
            <Divider sx={{ marginBottom: 2, borderColor: '#FAFAFA' }} />
            <StyledChartContainer>
              <ResponsiveContainer width="100%" height="100%">
                <LineChart data={comprehensionData}
                  margin={{ top: 20, right: 30, left: 0, bottom: 0 }}
                >
                  <CartesianGrid strokeDasharray="3 3" stroke="rgba(255,255,255,0.3)" />
                  <XAxis dataKey="name" stroke="#FAFAFA" />
                  <YAxis stroke="#FAFAFA" domain={[0, 100]} />
                  <Tooltip
                    formatter={(value) => [`${value}%`, 'Anlama Oranı']}
                    contentStyle={{
                      backgroundColor: 'rgba(0,0,0,0.8)',
                      color: '#FFD700',
                      borderRadius: '10px',
                      boxShadow: '0px 0px 10px rgba(0,0,0,0.5)',
                      fontFamily: 'Poppins, sans-serif',
                    }}
                  />
                  <Legend wrapperStyle={{ color: '#FAFAFA', fontFamily: 'Poppins, sans-serif' }} />
                  <Line
                    {...animationProps}
                    type="monotone"
                    dataKey="comprehensionRate"
                    name="Anlama Oranı (%)"
                    stroke="#FFD700"
                    strokeWidth={3}
                    activeDot={{ r: 8 }}
                  />
                </LineChart>
              </ResponsiveContainer>
            </StyledChartContainer>
          </CardContent>
        </StyledCard>

        {initialTestResult ? (
          <StyledCard sx={{ marginBottom: 4 }}>
            <CardContent>
              <SubTitle variant="h6">Başlangıç Test Sonucu</SubTitle>
              <Divider sx={{ marginBottom: 2, borderColor: '#FAFAFA' }} />
              <InfoText>Okuduğunuz Kelime Sayısı: <strong>{initialTestResult.wordCount}</strong></InfoText>
              <InfoText>Toplam Süre: <strong>{formatTime(initialTestResult.totalTime)}</strong></InfoText>
              <InfoText>Okuma Hızınız: <strong>{initialTestResult.readingSpeed} kelime/dakika</strong></InfoText>
            </CardContent>
          </StyledCard>
        ) : (
          <Typography variant="body1" sx={{ color: '#FAFAFA' }}>
            Başlangıç test sonucu bulunamadı.
          </Typography>
        )}

      {stages.map((stage, index) => {
        const comprehensionRate = getComprehensionRate(stage, initialTestResult);

        return (
          <StyledCard key={index} sx={{ marginBottom: 4 }}>
            <CardContent>
              <SubTitle variant="h6">Aşama {stage.stageNumber} Test Sonucu</SubTitle>
              <Divider sx={{ marginBottom: 2, borderColor: '#FAFAFA' }} />
              {stage.testResult ? (
                <>
                  <InfoText>Okuduğunuz Kelime Sayısı: <strong>{stage.testResult.wordCount}</strong></InfoText>
                  <InfoText>Toplam Süre: <strong>{formatTime(stage.testResult.totalTime)}</strong></InfoText>
                  <InfoText>Okuma Hızınız: <strong>{stage.testResult.readingSpeed} kelime/dakika</strong></InfoText>
                  
                  {/* Yeni Eklenen Bilgiler */}
                  {typeof stage.testResult.totalQuestions === 'number' && (
                    <InfoText>Toplam Soru Sayısı: <strong>{stage.testResult.totalQuestions}</strong></InfoText>
                  )}
                  {typeof stage.testResult.correctAnswers === 'number' && (
                    <InfoText>Doğru Cevap Sayısı: <strong>{stage.testResult.correctAnswers}</strong></InfoText>
                  )}
                  {typeof stage.testResult.incorrectAnswers === 'number' && (
                    <InfoText>Yanlış Cevap Sayısı: <strong>{stage.testResult.incorrectAnswers}</strong></InfoText>
                  )}

                  <InfoText>Anlama Oranı: <strong>{comprehensionRate}%</strong></InfoText>
                </>
              ) : (
                <Typography variant="body1" sx={{ color: '#FAFAFA' }}>
                  Test sonucu bulunamadı.
                </Typography>
              )}
            </CardContent>
          </StyledCard>
        );
      })}

      </StyledSection>

      <StyledSection>
        <SectionTitle variant="h4">İlerleme Raporu</SectionTitle>
        <InfoText variant="body1" sx={{ textAlign: 'center', marginBottom: 4, fontSize: '1rem' }}>
          Her aşamadaki skor, süre ve kelime sayısı performansınızı izlemenize yardımcı olur.
        </InfoText>

        <Grid container spacing={4} sx={{ marginBottom: 4 }}>
          <Grid item xs={12} md={4}>
            <StyledCard>
              <CardContent>
                <SubTitle>Aşamaların Toplam Skorları</SubTitle>
                <Divider sx={{ marginBottom: 2, borderColor: '#FAFAFA' }} />
                <StyledChartContainer>
                  <ResponsiveContainer width="100%" height="100%">
                    <BarChart data={overallChartData}
                      margin={{ top: 20, right: 30, left: 0, bottom: 0 }}
                    >
                      <CartesianGrid strokeDasharray="3 3" stroke="rgba(255,255,255,0.3)" />
                      <XAxis dataKey="stageNumber" stroke="#FAFAFA" />
                      <YAxis stroke="#FAFAFA" />
                      <Tooltip
                        formatter={(value) => [value, 'Toplam Skor']}
                        contentStyle={{
                          backgroundColor: 'rgba(0,0,0,0.8)',
                          color: '#00e5ff',
                          borderRadius: '10px',
                          boxShadow: '0px 0px 10px rgba(0,0,0,0.5)',
                          fontFamily: 'Poppins, sans-serif',
                        }}
                      />
                      <Legend wrapperStyle={{ color: '#FAFAFA', fontFamily: 'Poppins, sans-serif' }} />
                      <Bar dataKey="totalScore" name="Toplam Skor" fill="#00e5ff" barSize={40} {...animationProps} />
                    </BarChart>
                  </ResponsiveContainer>
                </StyledChartContainer>
              </CardContent>
            </StyledCard>
          </Grid>

          <Grid item xs={12} md={4}>
            <StyledCard>
              <CardContent>
                <SubTitle>Aşamaların Toplam Süreleri</SubTitle>
                <Divider sx={{ marginBottom: 2, borderColor: '#FAFAFA' }} />
                <StyledChartContainer>
                  <ResponsiveContainer width="100%" height="100%">
                    <LineChart data={overallChartData}
                      margin={{ top: 20, right: 30, left: 0, bottom: 0 }}
                    >
                      <CartesianGrid strokeDasharray="3 3" stroke="rgba(255,255,255,0.3)" />
                      <XAxis dataKey="stageNumber" stroke="#FAFAFA" />
                      <YAxis stroke="#FAFAFA" />
                      <Tooltip
                        formatter={(value) => [`${value} saniye`, 'Toplam Süre']}
                        contentStyle={{
                          backgroundColor: 'rgba(0,0,0,0.8)',
                          color: '#ffeb3b',
                          borderRadius: '10px',
                          boxShadow: '0px 0px 10px rgba(0,0,0,0.5)',
                          fontFamily: 'Poppins, sans-serif',
                        }}
                      />
                      <Legend wrapperStyle={{ color: '#FAFAFA', fontFamily: 'Poppins, sans-serif' }} />
                      <Line
                        {...animationProps}
                        type="monotone"
                        dataKey="totalTime"
                        name="Toplam Süre (saniye)"
                        stroke="#ffeb3b"
                        strokeWidth={3}
                        activeDot={{ r: 8 }}
                      />
                    </LineChart>
                  </ResponsiveContainer>
                </StyledChartContainer>
              </CardContent>
            </StyledCard>
          </Grid>

          {overallChartData.some((data) => data.totalWordCount > 0) && (
            <Grid item xs={12} md={4}>
              <StyledCard>
                <CardContent>
                  <SubTitle>Aşamaların Toplam Kelime Sayıları</SubTitle>
                  <Divider sx={{ marginBottom: 2, borderColor: '#FAFAFA' }} />
                  <StyledChartContainer>
                    <ResponsiveContainer width="100%" height="100%">
                      <BarChart data={overallChartData}
                        margin={{ top: 20, right: 30, left: 0, bottom: 0 }}
                      >
                        <CartesianGrid strokeDasharray="3 3" stroke="rgba(255,255,255,0.3)" />
                        <XAxis dataKey="stageNumber" stroke="#FAFAFA" />
                        <YAxis stroke="#FAFAFA" />
                        <Tooltip
                          formatter={(value) => [value, 'Toplam Kelime Sayısı']}
                          contentStyle={{
                            backgroundColor: 'rgba(0,0,0,0.8)',
                            color: '#00ff00',
                            borderRadius: '10px',
                            boxShadow: '0px 0px 10px rgba(0,0,0,0.5)',
                            fontFamily: 'Poppins, sans-serif',
                          }}
                        />
                        <Legend wrapperStyle={{ color: '#FAFAFA', fontFamily: 'Poppins, sans-serif' }} />
                        <Bar dataKey="totalWordCount" name="Toplam Kelime Sayısı" fill="#00ff00" barSize={40} {...animationProps} />
                      </BarChart>
                    </ResponsiveContainer>
                  </StyledChartContainer>
                </CardContent>
              </StyledCard>
            </Grid>
          )}
        </Grid>

        <Paper
          elevation={3}
          sx={{
            borderRadius: 3,
            padding: 2,
            backgroundColor: 'rgba(0,0,0,0.5)',
            marginBottom: 4,
          }}
        >
          <Tabs
            value={selectedStage}
            onChange={handleTabChange}
            variant="scrollable"
            scrollButtons="auto"
            indicatorColor="secondary"
            textColor="inherit"
            sx={{
              '& .MuiTab-root': {
                fontWeight: '600',
                textTransform: 'none',
                minWidth: isSmallScreen ? 'auto' : 'inherit',
                color: '#FAFAFA',
                fontFamily: 'Poppins, sans-serif',
              },
              '& .Mui-selected': {
                color: '#FFD700 !important',
              },
            }}
          >
            {stages.map((stage, index) => (
              <Tab key={index} label={`Aşama ${stage.stageNumber}`} />
            ))}
          </Tabs>
        </Paper>

        {chartData.length === 0 ? (
          <Box sx={{ marginTop: 4, textAlign: 'center' }}>
            <Typography variant="h6" color="#FAFAFA" sx={{ fontFamily: 'Poppins, sans-serif' }}>
              Henüz tamamlanmış bir oyun bulunmamaktadır.
            </Typography>
          </Box>
        ) : (
          <Grid container spacing={4} sx={{ marginTop: 4 }}>
            <Grid item xs={12} md={4}>
              <StyledCard>
                <CardContent>
                  <SubTitle>Skor Grafiği</SubTitle>
                  <Divider sx={{ marginBottom: 2, borderColor: '#FAFAFA' }} />
                  <StyledChartContainer>
                    <ResponsiveContainer width="100%" height="100%">
                      <BarChart data={chartData}
                        margin={{ top: 20, right: 30, left: 0, bottom: 0 }}
                      >
                        <CartesianGrid strokeDasharray="3 3" stroke="rgba(255,255,255,0.3)" />
                        <XAxis dataKey="gameName" stroke="#FAFAFA" />
                        <YAxis stroke="#FAFAFA" />
                        <Tooltip
                          formatter={(value) => [value, 'Skor']}
                          contentStyle={{
                            backgroundColor: 'rgba(0,0,0,0.8)',
                            color: '#ff80ab',
                            borderRadius: '10px',
                            boxShadow: '0px 0px 10px rgba(0,0,0,0.5)',
                            fontFamily: 'Poppins, sans-serif',
                          }}
                        />
                        <Legend wrapperStyle={{ color: '#FAFAFA', fontFamily: 'Poppins, sans-serif' }} />
                        <Bar dataKey="score" name="Skor" fill="#ff80ab" barSize={40} {...animationProps} />
                      </BarChart>
                    </ResponsiveContainer>
                  </StyledChartContainer>
                </CardContent>
              </StyledCard>
            </Grid>

            <Grid item xs={12} md={4}>
              <StyledCard>
                <CardContent>
                  <SubTitle>Süre Grafiği</SubTitle>
                  <Divider sx={{ marginBottom: 2, borderColor: '#FAFAFA' }} />
                  <StyledChartContainer>
                    <ResponsiveContainer width="100%" height="100%">
                      <LineChart data={chartData}
                        margin={{ top: 20, right: 30, left: 0, bottom: 0 }}
                      >
                        <CartesianGrid strokeDasharray="3 3" stroke="rgba(255,255,255,0.3)" />
                        <XAxis dataKey="gameName" stroke="#FAFAFA" />
                        <YAxis stroke="#FAFAFA" />
                        <Tooltip
                          formatter={(value) => [`${value} saniye`, 'Süre']}
                          contentStyle={{
                            backgroundColor: 'rgba(0,0,0,0.8)',
                            color: '#ffeb3b',
                            borderRadius: '10px',
                            boxShadow: '0px 0px 10px rgba(0,0,0,0.5)',
                            fontFamily: 'Poppins, sans-serif',
                          }}
                        />
                        <Legend wrapperStyle={{ color: '#FAFAFA', fontFamily: 'Poppins, sans-serif' }} />
                        <Line
                          {...animationProps}
                          type="monotone"
                          dataKey="time"
                          name="Süre (saniye)"
                          stroke="#ffeb3b"
                          strokeWidth={3}
                          activeDot={{ r: 8 }}
                        />
                      </LineChart>
                    </ResponsiveContainer>
                  </StyledChartContainer>
                </CardContent>
              </StyledCard>
            </Grid>

            {chartData.some((data) => data.wordCount > 0) && (
              <Grid item xs={12} md={4}>
                <StyledCard>
                  <CardContent>
                    <SubTitle>Kelime Sayısı Grafiği</SubTitle>
                    <Divider sx={{ marginBottom: 2, borderColor: '#FAFAFA' }} />
                    <StyledChartContainer>
                      <ResponsiveContainer width="100%" height="100%">
                        <BarChart data={chartData}
                          margin={{ top: 20, right: 30, left: 0, bottom: 0 }}
                        >
                          <CartesianGrid strokeDasharray="3 3" stroke="rgba(255,255,255,0.3)" />
                          <XAxis dataKey="gameName" stroke="#FAFAFA" />
                          <YAxis stroke="#FAFAFA" />
                          <Tooltip
                            formatter={(value) => [value, 'Kelime Sayısı']}
                            contentStyle={{
                              backgroundColor: 'rgba(0,0,0,0.8)',
                              color: '#00ff00',
                              borderRadius: '10px',
                              boxShadow: '0px 0px 10px rgba(0,0,0,0.5)',
                              fontFamily: 'Poppins, sans-serif',
                            }}
                          />
                          <Legend wrapperStyle={{ color: '#FAFAFA', fontFamily: 'Poppins, sans-serif' }} />
                          <Bar dataKey="wordCount" name="Kelime Sayısı" fill="#00ff00" barSize={40} {...animationProps} />
                        </BarChart>
                      </ResponsiveContainer>
                    </StyledChartContainer>
                  </CardContent>
                </StyledCard>
              </Grid>
            )}
          </Grid>
        )}
      </StyledSection>
    </Box>
  );
};

export default ProgressReport;
