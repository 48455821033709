// useNesneyiTakipEtGame.js

import { useRef, useState, useEffect } from 'react';

export default function useGameLogic(userData) {
  const canvasRef = useRef(null);
  const [canvasReady, setCanvasReady] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const [speed, setSpeed] = useState(500);
  const [score, setScore] = useState(0);
  const [isGameOver, setIsGameOver] = useState(false);

  const [gameTime, setGameTime] = useState(60);
  const [initialGameTime, setInitialGameTime] = useState(60);

  const [shapeSides, setShapeSides] = useState(3);
  const [positions, setPositions] = useState([]);

  const currentPositionIndexRef = useRef(0);
  const reverseLimitRef = useRef(0);
  const animationFrameIdRef = useRef(null);
  const scoreRef = useRef(0);
  const lastMoveTimeRef = useRef(0);

  useEffect(() => {
    if (userData) {
      const newGameTime = userData.status === 'inactive' ? 15 : 60;
      setGameTime(newGameTime);
      setInitialGameTime(newGameTime);
    } else {
      setGameTime(15);
      setInitialGameTime(15);
    }
  }, [userData]);

  useEffect(() => {
    if (canvasReady) {
      const newPositions = calculatePositions();
      setPositions(newPositions);
      currentPositionIndexRef.current = 0;
      reverseLimitRef.current = 0;
      drawShape();
    }
  }, [canvasReady, shapeSides]);

  useEffect(() => {
    if (!isPlaying) return;

    if (gameTime > 0) {
      const timer = setInterval(() => {
        setGameTime((prevTime) => prevTime - 1);
      }, 1000);
      return () => clearInterval(timer);
    } else {
      setIsPlaying(false);
      setIsGameOver(true);
    }
  }, [isPlaying, gameTime]);

  const calculatePositions = () => {
    const canvas = canvasRef.current;
    if (!canvas) return [];

    const width = canvas.width;
    const height = canvas.height;

    const positions = [];
    const centerX = width / 2;
    const centerY = height / 2;
    const radius = Math.min(width, height) / 2.5;
    const angleOffset = (Math.PI * 2) / shapeSides;

    for (let i = 0; i < shapeSides; i++) {
      const angle = i * angleOffset - Math.PI / 2;
      const x = centerX + radius * Math.cos(angle);
      const y = centerY + radius * Math.sin(angle);
      positions.push({ x, y });
    }

    return positions;
  };

  const drawShape = () => {
    const canvas = canvasRef.current;
    if (!canvas) return;

    const ctx = canvas.getContext('2d');
    const width = canvas.width;
    const height = canvas.height;

    ctx.clearRect(0, 0, width, height);

    ctx.fillStyle = '#2c2c2c';
    ctx.fillRect(0, 0, width, height);

    ctx.beginPath();
    ctx.strokeStyle = '#ff6600';
    ctx.lineWidth = 4;

    const centerX = width / 2;
    const centerY = height / 2;
    const radius = Math.min(width, height) / 2.5;
    const angleOffset = (Math.PI * 2) / shapeSides;

    for (let i = 0; i < shapeSides; i++) {
      const angle = i * angleOffset - Math.PI / 2;
      const x = centerX + radius * Math.cos(angle);
      const y = centerY + radius * Math.sin(angle);
      if (i === 0) {
        ctx.moveTo(x, y);
      } else {
        ctx.lineTo(x, y);
      }
    }
    ctx.closePath();
    ctx.stroke();
  };

  const animateObject = () => {
    const canvas = canvasRef.current;
    if (!canvas || positions.length === 0) return;

    const ctx = canvas.getContext('2d');

    drawShape();

    const { x, y } = positions[currentPositionIndexRef.current];
    ctx.beginPath();
    ctx.arc(x, y, 15, 0, Math.PI * 2);
    ctx.fillStyle = '#ff6600';
    ctx.fill();

    let nextIndex = currentPositionIndexRef.current + 1;

    if (nextIndex >= positions.length) {
      nextIndex = 0;
      reverseLimitRef.current += 1;

      if (shapeSides > 2 && reverseLimitRef.current >= 5) {
        setPositions((prevPositions) => [...prevPositions].reverse());
        reverseLimitRef.current = 0;
      }
    }

    currentPositionIndexRef.current = nextIndex;

    scoreRef.current += 1;
    setScore(scoreRef.current);
  };

  const animate = (timestamp) => {
    if (!isPlaying) return;

    if (!lastMoveTimeRef.current) {
      lastMoveTimeRef.current = timestamp;
    }

    const elapsed = timestamp - lastMoveTimeRef.current;

    if (elapsed >= speed) {
      animateObject();
      lastMoveTimeRef.current = timestamp;
    }

    animationFrameIdRef.current = requestAnimationFrame(animate);
  };

  const startGameLoop = () => {
    if (animationFrameIdRef.current) {
      cancelAnimationFrame(animationFrameIdRef.current);
    }
    lastMoveTimeRef.current = null;
    animationFrameIdRef.current = requestAnimationFrame(animate);
  };

  const stopGameLoop = () => {
    if (animationFrameIdRef.current) {
      cancelAnimationFrame(animationFrameIdRef.current);
      animationFrameIdRef.current = null;
    }
  };

  useEffect(() => {
    if (isPlaying && canvasReady) {
      startGameLoop();
    } else {
      stopGameLoop();
    }
    return () => stopGameLoop();
  }, [isPlaying, speed, canvasReady]);

  const resetGame = () => {
    setIsPlaying(false);
    setScore(0);
    scoreRef.current = 0;
    setGameTime(initialGameTime);
    setIsGameOver(false);
    currentPositionIndexRef.current = 0;
    reverseLimitRef.current = 0;
    lastMoveTimeRef.current = null;
    stopGameLoop();
    if (canvasReady) {
      const newPositions = calculatePositions();
      setPositions(newPositions);
      drawShape();
    }
  };

  const setSpeedWithLimit = (newSpeed) => {
    setSpeed(Math.max(newSpeed, 50));
  };

  const increaseSides = () => {
    setShapeSides((prevSides) => Math.min(prevSides + 1, 10));
  };

  const decreaseSides = () => {
    setShapeSides((prevSides) => Math.max(prevSides - 1, 3));
  };

  return {
    canvasRef,
    isPlaying,
    setIsPlaying,
    speed,
    setSpeed: setSpeedWithLimit,
    score,
    gameTime,
    initialGameTime,
    resetGame,
    isGameOver,
    setIsGameOver,
    shapeSides,
    increaseSides,
    decreaseSides,
    setCanvasReady,
    setShapeSides, // setShapeSides fonksiyonunu dışarı çıkarıyoruz
  };
}
