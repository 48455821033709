import React from 'react';
import {Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Button, Stack,} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import VisibilityIcon from '@mui/icons-material/Visibility';

const TeacherTable = ({
  ogretmenListesi,
  handleEdit,
  setSelectedTeacherId,
  setTransferDialogOpen,
  setRevokeDialogOpen,
  handleOpenRapor, // <-- Rapor modalını açmak için eklenen prop
}) => {
  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Adı</TableCell>
            <TableCell>Soyadı</TableCell>
            <TableCell>Şifre</TableCell>
            <TableCell>Telefon</TableCell>
            <TableCell>E-posta</TableCell>
            <TableCell>Şehir</TableCell>
            <TableCell>İlçe</TableCell>
            <TableCell>Bakiye 3 Ay</TableCell>
            <TableCell>Bakiye 6 Ay</TableCell>
            <TableCell>Bakiye 12 Ay</TableCell>
            <TableCell>Toplam Bakiye</TableCell>
            <TableCell>İşlemler</TableCell>
          </TableRow>
        </TableHead>

        {ogretmenListesi.length > 0 ? (
          <TableBody>
            {ogretmenListesi.map((ogretmen) => {
              const toplamBakiye =
                (ogretmen.bakiye3Ay || 0) +
                (ogretmen.bakiye6Ay || 0) +
                (ogretmen.bakiye12Ay || 0);

              return (
                <TableRow key={ogretmen._id}>
                  <TableCell>{ogretmen.ad}</TableCell>
                  <TableCell>{ogretmen.soyad}</TableCell>
                  <TableCell>{ogretmen.password_open}</TableCell>
                  <TableCell>{ogretmen.telefon}</TableCell>
                  <TableCell>{ogretmen.email}</TableCell>
                  <TableCell>{ogretmen.sehirAdi}</TableCell>
                  <TableCell>{ogretmen.ilceAdi}</TableCell>
                  <TableCell>{ogretmen.bakiye3Ay || 0}</TableCell>
                  <TableCell>{ogretmen.bakiye6Ay || 0}</TableCell>
                  <TableCell>{ogretmen.bakiye12Ay || 0}</TableCell>
                  <TableCell>{toplamBakiye}</TableCell>
                  <TableCell>
                    <Stack direction="row" spacing={1}>
                      {/* DÜZENLE */}
                      <Button
                        variant="contained"
                        color="primary"
                        startIcon={<EditIcon />}
                        onClick={() => handleEdit(ogretmen._id)}
                      >
                        Düzenle
                      </Button>

                      {/* BAKİYE TRANSFERİ */}
                      <Button
                        variant="contained"
                        color="success"
                        onClick={() => {
                          setSelectedTeacherId(ogretmen._id);
                          setTransferDialogOpen(true);
                        }}
                      >
                        Bakiye Transferi
                      </Button>

                      {/* BAKİYE GERİ AL */}
                      <Button
                        variant="contained"
                        color="warning"
                        onClick={() => {
                          setSelectedTeacherId(ogretmen._id);
                          setRevokeDialogOpen(true);
                        }}
                      >
                        Bakiye Geri Al
                      </Button>

                      {/* RAPOR GÖRÜNTÜLE */}
                      <Button
                        variant="outlined"
                        color="secondary"
                        startIcon={<VisibilityIcon />}
                        onClick={() =>
                          handleOpenRapor(
                            ogretmen._id,
                            `${ogretmen.ad} ${ogretmen.soyad}`
                          )
                        }
                      >
                        Rapor
                      </Button>
                    </Stack>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        ) : (
          <TableBody>
            <TableRow>
              <TableCell colSpan={12} align="center">
                Bu kuruma ait öğretmen bulunamadı.
              </TableCell>
            </TableRow>
          </TableBody>
        )}
      </Table>
    </TableContainer>
  );
};

export default TeacherTable;
