// TeacherPage.jsx
import React, { useState, useEffect } from 'react';
import { Paper, Grid, Card, Typography } from '@mui/material';
import ResultDialog from './components/ResultDialog';
import TeacherForm from './components/TeacherForm';
import TeacherFilters from './components/TeacherFilters';
import TeacherTable from './components/TeacherTable';
import TeacherEditDialog from './components/TeacherEditDialog';
import BalanceTransferDialog from './components/BalanceTransferDialog';
import RevokeBalanceDialog from './components/RevokeBalanceDialog';
import OgretmenRaporuModal from './components/OgretmenRaporuModal';
import { API_URLS } from '../../../config/config'; 
import useFetchUserData from '../../../hooks/okul/useFetchUserData';

const TeacherPage = () => {
  const [ogretmenListesi, setOgretmenListesi] = useState([]);
  const [sehirListesi, setSehirListesi] = useState([]);
  const [shouldFetch, setShouldFetch] = useState(true);
  const [userData, setUserData] = useState(null);

  // Genel uyarı dialogları
  const [dialogContent, setDialogContent] = useState('');
  const [dialogSeverity, setDialogSeverity] = useState('info');
  const [dialogOpen, setDialogOpen] = useState(false);

  // Sonuç dialogları
  const [resultDialogOpen, setResultDialogOpen] = useState(false);
  const [resultDialogMessage, setResultDialogMessage] = useState('');
  const [resultDialogSeverity, setResultDialogSeverity] = useState('info');
  const [eklemeBasariliDialogOpen, setEklemeBasariliDialogOpen] = useState(false);

  // Filtre state'leri
  const [filterSehirId, setFilterSehirId] = useState('');
  const [filterPackageType, setFilterPackageType] = useState('');
  const [searchQuery, setSearchQuery] = useState('');

  // Düzenleme
  const [duzenlemeDialogAcik, setDuzenlemeDialogAcik] = useState(false);
  const [duzenlenenOgretmen, setDuzenlenenOgretmen] = useState({
    ad: '',
    soyad: '',
    okulAdi: '',
    email: '',
    telefon: '',
    sehirId: '',
    ilceId: '',
    ilceler: [],
  });

  // Bakiye transfer/gün geri alma
  const [transferDialogOpen, setTransferDialogOpen] = useState(false);
  const [revokeDialogOpen, setRevokeDialogOpen] = useState(false);
  const [selectedTeacherId, setSelectedTeacherId] = useState('');
  const [transferAmount, setTransferAmount] = useState(0);
  const [packageType, setPackageType] = useState('bakiye3Ay');
  const [revokeAmount, setRevokeAmount] = useState(0);
  const [revokePackageType, setRevokePackageType] = useState('bakiye3Ay');

  // Rapor Modal
  const [raporModalOpen, setRaporModalOpen] = useState(false);
  const [selectedRaporTeacherId, setSelectedRaporTeacherId] = useState('');
  const [selectedRaporTeacherName, setSelectedRaporTeacherName] = useState('');

  // Kullanıcı verisini çek (okul bilgisi vs.)
  useFetchUserData(setUserData);

  const handleDialogClose = () => {
    setDialogOpen(false);
    setResultDialogOpen(false);
  };

  // -- 1) ÖĞRETMEN LİSTESİ ÇEKME
  const fetchOgretmenListesi = async () => {
    if (!userData || !userData._id) {
      console.log("Kullanıcı bilgisi yüklenemedi veya kullanıcı ID'si bulunamadı.");
      return;
    }

    try {
      const response = await fetch(`${API_URLS.SCHOOL_URL}teachers/${userData._id}`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('userToken')}`,
          'Content-Type': 'application/json',
        },
      });

      if (!response.ok) {
        throw new Error('Öğretmen listesi yüklenirken bir hata oluştu.');
      }

      const data = await response.json();
      setOgretmenListesi(data);
    } catch (error) {
      console.error(error);
    }
  };

  // -- 2) ŞEHİRLERİ ÇEKME
  const fetchSehirler = async () => {
    if (!shouldFetch) return;
    try {
      const response = await fetch(API_URLS.ADMIN_URL + 'sehirler');
      if (!response.ok) {
        throw new Error('Şehir verisi çekilemedi');
      }
      const sehirler = await response.json();
      setSehirListesi(sehirler);
    } catch (err) {
      console.error("Şehirler yüklenirken hata:", err.message);
    } finally {
      setShouldFetch(false);
    }
  };

  useEffect(() => {
    fetchSehirler();
  }, []);

  useEffect(() => {
    // userData geldiğinde öğretmenler çekilsin
    if (userData) {
      fetchOgretmenListesi();
    }
    // eslint-disable-next-line
  }, [userData]);

  // -- FİLTRELENMİŞ LİSTE
  const getFilteredOgretmenListesi = () => {
    return ogretmenListesi.filter((ogretmen) => {
      const matchesSearchQuery =
        ogretmen.ad.toLowerCase().includes(searchQuery.toLowerCase()) ||
        ogretmen.soyad.toLowerCase().includes(searchQuery.toLowerCase()) ||
        ogretmen.telefon.includes(searchQuery) ||
        ogretmen.email.toLowerCase().includes(searchQuery.toLowerCase()) ||
        (ogretmen.sehirAdi &&
          ogretmen.sehirAdi.toLowerCase().includes(searchQuery.toLowerCase())) ||
        (ogretmen.ilceAdi &&
          ogretmen.ilceAdi.toLowerCase().includes(searchQuery.toLowerCase()));

      const matchesSehir = !filterSehirId || ogretmen.sehir === filterSehirId;

      const matchesPackageType =
        !filterPackageType ||
        (filterPackageType === 'bakiye3Ay' && ogretmen.bakiye3Ay > 0) ||
        (filterPackageType === 'bakiye6Ay' && ogretmen.bakiye6Ay > 0) ||
        (filterPackageType === 'bakiye12Ay' && ogretmen.bakiye12Ay > 0);

      return matchesSearchQuery && matchesSehir && matchesPackageType;
    });
  };

  const filteredOgretmenListesi = getFilteredOgretmenListesi();

  // -- 3) ÖĞRETMEN EKLE
  const handleEkle = async (yeniOgretmenData, secilenSehirId, secilenIlceId) => {
    try {
      const response = await fetch(`${API_URLS.SCHOOL_URL}teachers`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('userToken')}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          ...yeniOgretmenData,
          okulId: userData?._id,
        }),
      });

      const responseData = await response.json();
      if (response.ok) {
        // Yeni eklenen öğretmenin şehir/ilçe adlarını da ekleyelim
        const yeniOgretmen = {
          ...responseData,
          sehirAdi:
            sehirListesi.find((sehir) => sehir._id === secilenSehirId)?.sehir_adi || '',
          ilceAdi:
            (sehirListesi
              .find((sehir) => sehir._id === secilenSehirId)
              ?.ilceler.find((ilce) => ilce._id === secilenIlceId)?.ilce_adi) || '',
        };
        setOgretmenListesi([...ogretmenListesi, yeniOgretmen]);

        setDialogSeverity('success');
        setDialogContent('Yeni Öğretmen başarıyla eklendi.');
        setEklemeBasariliDialogOpen(true);
      } else {
        throw new Error(responseData.message || 'Bir hata oluştu');
      }
    } catch (err) {
      setDialogSeverity('error');
      setDialogContent(err.message);
      setEklemeBasariliDialogOpen(true);
    }
  };

  // -- 4) ÖĞRETMEN SİL
  const handleDeleteOgretmen = async (ogretmenId) => {
    try {
      const response = await fetch(`${API_URLS.SCHOOL_URL}teachers/${ogretmenId}`, {
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('userToken')}`,
          'Content-Type': 'application/json',
        },
      });

      if (!response.ok) throw new Error('Öğretmen silinirken bir hata oluştu.');

      // Listeden çıkar
      setOgretmenListesi((prev) =>
        prev.filter((ogretmen) => ogretmen._id !== ogretmenId)
      );

      setResultDialogMessage('Öğretmen başarıyla silindi.');
      setResultDialogSeverity('success');
      setResultDialogOpen(true);
    } catch (error) {
      setResultDialogMessage(error.message);
      setResultDialogSeverity('error');
      setResultDialogOpen(true);
    }
  };

  // -- 5) DÜZENLEME AÇ
  const handleEdit = (ogretmenId) => {
    const seciliOgretmen = ogretmenListesi.find((o) => o._id === ogretmenId);

    if (seciliOgretmen) {
      const sehirId = seciliOgretmen.sehir;
      setDuzenlenenOgretmen({
        _id: seciliOgretmen._id,
        ad: seciliOgretmen.ad,
        soyad: seciliOgretmen.soyad,
        okulAdi: seciliOgretmen.okul_adi,
        email: seciliOgretmen.email,
        telefon: seciliOgretmen.telefon,
        sehirId: sehirId,
        ilceId: seciliOgretmen.ilce,
        ilceler: sehirListesi.find((s) => s._id === sehirId)?.ilceler || [],
      });
      setDuzenlemeDialogAcik(true);
    } else {
      console.error('Seçilen öğretmen bulunamadı.');
    }
  };

  // -- 6) ÖĞRETMEN GÜNCELLE
  const handleGuncelle = async (ogretmenData) => {
    try {
      const response = await fetch(`${API_URLS.ADMIN_URL}teachers/${ogretmenData._id}`, {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          ad: ogretmenData.ad,
          soyad: ogretmenData.soyad,
          okul_adi: ogretmenData.okulAdi,
          email: ogretmenData.email,
          telefon: ogretmenData.telefon,
          sehir: ogretmenData.sehirId,
          ilce: ogretmenData.ilceId,
        }),
      });

      const data = await response.json();
      if (!response.ok) throw new Error(data.message || 'Bir hata oluştu');

      // Gelen response’dan güncel şehir/ilçe adını bulalım
      const guncellenmisOgretmen = {
        ...data.ogretmen,
        sehirAdi:
          sehirListesi.find((s) => s._id === data.ogretmen.sehir)?.sehir_adi || '',
        ilceAdi:
          sehirListesi
            .find((s) => s._id === data.ogretmen.sehir)
            ?.ilceler.find((i) => i._id === data.ogretmen.ilce)?.ilce_adi || '',
      };

      setOgretmenListesi((prev) =>
        prev.map((ogretmen) =>
          ogretmen._id === ogretmenData._id ? guncellenmisOgretmen : ogretmen
        )
      );

      setDialogSeverity('success');
      setDialogContent('Öğretmen başarıyla güncellendi.');
      setDialogOpen(true);
      setDuzenlemeDialogAcik(false);
    } catch (err) {
      setDialogSeverity('error');
      setDialogContent(err.message);
      setDialogOpen(true);
    }
  };

  // -- 7) BAKİYE TRANSFER
  const handleTransfer = async () => {
    try {
      const response = await fetch(
        `${API_URLS.SCHOOL_URL}teachers/transferBalance/${selectedTeacherId}`,
        {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${localStorage.getItem('userToken')}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            amount: transferAmount,
            packageType: packageType,
          }),
        }
      );

      const data = await response.json();
      if (!response.ok) throw new Error(data.message || 'Bir hata oluştu');

      await fetchOgretmenListesi();
      setDialogSeverity('success');
      setDialogContent('Bakiye başarıyla transfer edildi.');
      setDialogOpen(true);
      setTransferDialogOpen(false);
    } catch (err) {
      setDialogSeverity('error');
      setDialogContent(err.message);
      setDialogOpen(true);
    }
  };

  // -- 8) BAKİYE GERİ AL
  const handleGeriAl = async () => {
    try {
      const response = await fetch(
        `${API_URLS.SCHOOL_URL}teachers/revokeBalance/${selectedTeacherId}`,
        {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${localStorage.getItem('userToken')}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            amount: revokeAmount,
            packageType: revokePackageType,
          }),
        }
      );

      const data = await response.json();
      if (!response.ok) throw new Error(data.message || 'Bir hata oluştu');

      await fetchOgretmenListesi();
      setDialogSeverity('success');
      setDialogContent('Bakiye başarıyla geri alındı.');
      setDialogOpen(true);
      setRevokeDialogOpen(false);
    } catch (err) {
      setDialogSeverity('error');
      setDialogContent(err.message);
      setDialogOpen(true);
    }
  };

  // -- 9) RAPOR MODAL AÇ/KAPAT
  const handleOpenRapor = (teacherId, teacherFullName) => {
    setSelectedRaporTeacherId(teacherId);
    setSelectedRaporTeacherName(teacherFullName);
    setRaporModalOpen(true);
  };
  const handleCloseRapor = () => {
    setRaporModalOpen(false);
    setSelectedRaporTeacherId('');
    setSelectedRaporTeacherName('');
  };

  return (
    <Paper style={{ padding: 20 }}>
      <Grid container spacing={3} direction="column">
        {/* 1) Öğretmen Formu */}
        <Grid item xs={12}>
          <Card style={{ padding: 20, marginBottom: 20 }}>
            <Typography variant="h5" component="h3" marginBottom={2}>
              Öğretmen Kayıt Formu
            </Typography>
            <TeacherForm
              sehirListesi={sehirListesi}
              onEkle={handleEkle}
              dialogSeverity={dialogSeverity}
              setDialogSeverity={setDialogSeverity}
              dialogContent={dialogContent}
              setDialogContent={setDialogContent}
              setEklemeBasariliDialogOpen={setEklemeBasariliDialogOpen}
            />
          </Card>
        </Grid>

        {/* 2) Filtreler */}
        <Grid item xs={12}>
          <Card style={{ padding: 20, marginBottom: 20 }}>
            <TeacherFilters
              sehirListesi={sehirListesi}
              searchQuery={searchQuery}
              setSearchQuery={setSearchQuery}
              filterSehirId={filterSehirId}
              setFilterSehirId={setFilterSehirId}
              filterPackageType={filterPackageType}
              setFilterPackageType={setFilterPackageType}
            />
          </Card>
        </Grid>

        {/* 3) Öğretmen Tablosu */}
        <Grid item xs={12}>
          <TeacherTable
            ogretmenListesi={filteredOgretmenListesi}
            onEdit={handleEdit}
            onDelete={handleDeleteOgretmen}      // Ekledik
            onSelectTeacherForTransfer={(id) => {
              setSelectedTeacherId(id);
              setTransferDialogOpen(true);
            }}
            onSelectTeacherForRevoke={(id) => {
              setSelectedTeacherId(id);
              setRevokeDialogOpen(true);
            }}
            onOpenRapor={handleOpenRapor}       // Rapor butonu için
          />
        </Grid>
      </Grid>

      {/* 4) Kayıt Sonrası/Ekleme Sonucu Dialogu */}
      <ResultDialog
        open={eklemeBasariliDialogOpen}
        onClose={() => setEklemeBasariliDialogOpen(false)}
        title={dialogSeverity === 'error' ? 'Hata Oluştu' : 'İşlem Başarılı'}
        message={dialogContent}
        severity={dialogSeverity}
      />

      {/* 5) Genel Sonuç Dialogu */}
      <ResultDialog
        open={resultDialogOpen}
        onClose={handleDialogClose}
        title={'İşlem Sonucu'}
        message={resultDialogMessage}
        severity={resultDialogSeverity}
      />

      {/* 6) Öğretmen Düzenleme Dialogu */}
      <TeacherEditDialog
        open={duzenlemeDialogAcik}
        onClose={() => setDuzenlemeDialogAcik(false)}
        duzenlenenOgretmen={duzenlenenOgretmen}
        setDuzenlenenOgretmen={setDuzenlenenOgretmen}
        sehirListesi={sehirListesi}
        onGuncelle={handleGuncelle}
      />

      {/* 7) Bakiye Transfer Dialogu */}
      <BalanceTransferDialog
        open={transferDialogOpen}
        onClose={() => setTransferDialogOpen(false)}
        packageType={packageType}
        setPackageType={setPackageType}
        transferAmount={transferAmount}
        setTransferAmount={setTransferAmount}
        onTransfer={handleTransfer}
      />

      {/* 8) Bakiye Geri Alma Dialogu */}
      <RevokeBalanceDialog
        open={revokeDialogOpen}
        onClose={() => setRevokeDialogOpen(false)}
        revokePackageType={revokePackageType}
        setRevokePackageType={setRevokePackageType}
        revokeAmount={revokeAmount}
        setRevokeAmount={setRevokeAmount}
        onRevoke={handleGeriAl}
      />

      {/* 9) RAPOR MODALI */}
      <OgretmenRaporuModal
        open={raporModalOpen}
        onClose={handleCloseRapor}
        teacherId={selectedRaporTeacherId}
        teacherName={selectedRaporTeacherName}
      />
    </Paper>
  );
};

export default TeacherPage;
