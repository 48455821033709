// src/components/KayanYazilarIkiserOyunu/components/GameTimerIkiser.js

import React from 'react';
import ProgressBarIkiser from './ProgressBarIkiser';

/**
 * Oyun süresini ve progress bar'ı gösteren basit bir zamanlayıcı bileşeni.
 * 
 * @param {number} timeLeft   - Kalan süre (saniye)
 * @param {boolean} isPlaying - Oyun oynanıyor mu
 * @param {number} gameTime   - Toplam oyun süresi (saniye)
 * @param {number} elapsedTime - Geçen süre (saniye)
 */
const GameTimerIkiser = ({ timeLeft, isPlaying, gameTime, elapsedTime }) => {
  /**
   * Aşağıdaki hesapta progress 0 ile 100 arasında değişir.
   * elapsedTime arttıkça bar dolmaya başlar (0'dan 100'e).
   */
  const progress = gameTime > 0 
    ? (elapsedTime / gameTime) * 100 
    : 0;

  return (
    <div className="kayan-yazilar-ikiser-timer">
      {/* DÜZELTME: timeLeft saniye cinsinden tutulduğu için /1000 yapmadan direkt gösteriyoruz */}
      <span>Kalan Süre: {Math.floor(timeLeft)} sn</span>
      <ProgressBarIkiser progress={progress} />
    </div>
  );
};

export default GameTimerIkiser;
