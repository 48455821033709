import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faHome } from '@fortawesome/free-solid-svg-icons';
import '../styles/noktaTakipEt.css';

const GameHeader = ({ handleGoHome, handleGoBack, handleReturnToPreviousStage }) => {
  return (
    <div className="nesneyi-takip-et-header text-center">
      <button className="nesneyi-takip-et-button" onClick={handleGoBack}>
        <FontAwesomeIcon icon={faArrowLeft} /> Geri Dön
      </button>
      <button className="nesneyi-takip-et-button" onClick={handleGoHome}>
        <FontAwesomeIcon icon={faHome} /> Ana Sayfa
      </button>
      <button className="nesneyi-takip-et-button" onClick={handleReturnToPreviousStage}>
        <FontAwesomeIcon icon={faArrowLeft} /> Önceki Sayfaya Dön
      </button>
    </div>
  );
};

export default GameHeader;
