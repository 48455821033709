// src/dashboard/okul/ogretmen/kutuphane/liste/BookListe.jsx

import React, { useState, useEffect } from 'react';
import {
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Collapse,
  Box,
  Typography,
  Stack,
} from '@mui/material';

import AddCircleIcon from '@mui/icons-material/AddCircle';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import RefreshIcon from '@mui/icons-material/Refresh';

// HTML parse & sanitization
import DOMPurify from 'dompurify';
import parse, { domToReact } from 'html-react-parser';

import useFetchUserData from '../../../../../hooks/okul/useFetchUserData';
import { API_URLS } from '../../../../../config/config';

const voidElements = new Set([
  'area',
  'base',
  'br',
  'col',
  'embed',
  'hr',
  'img',
  'input',
  'keygen',
  'link',
  'meta',
  'param',
  'source',
  'track',
  'wbr',
]);

const BookListe = () => {
  const [books, setBooks] = useState([]);
  const [userData, setUserData] = useState(null);

  // --- Arama & Filtre state'leri ---
  const [searchQuery, setSearchQuery] = useState('');
  const [difficultyFilter, setDifficultyFilter] = useState('');

  // --- Satır açma/kapama (Collapse) ---
  const [expandedBookId, setExpandedBookId] = useState(null);

  // --- Kitap düzenleme ile ilgili state ---
  const [duzenlemeDialogAcik, setDuzenlemeDialogAcik] = useState(false);
  const [duzenlenenKitap, setDuzenlenenKitap] = useState({
    _id: '',
    title: '',
    difficulty: '',
  });

  // --- Test ekleme ile ilgili state'ler ---
  const [testFormAcik, setTestFormAcik] = useState(false);
  const [secilenKitapId, setSecilenKitapId] = useState('');
  const [testSorusu, setTestSorusu] = useState('');
  const [dogruCevap, setDogruCevap] = useState(null);
  const [testAciklamasi, setTestAciklamasi] = useState('');
  const [testSiklari, setTestSiklari] = useState([]);

  useFetchUserData(setUserData);

  // --------------------- Kitapları Çekme ---------------------
  const fetchBooks = async () => {
    if (!userData || !userData._id) {
      console.log('Kullanıcı bilgisi yok veya ID bulunamadı.');
      return;
    }

    try {
      // Örn: GET /kutuphane/:okulId 
      // ya da GET /kutuphane/all-books (Projenize göre değiştirin)
      const response = await fetch(`${API_URLS.SCHOOL_URL}kutuphane/${userData._id}`);
      const data = await response.json();
      if (response.ok) {
        setBooks(data);
      } else {
        console.error('Kitapları yüklerken sorun:', data.message);
      }
    } catch (error) {
      console.error('Fetch sırasında hata:', error);
    }
  };

  useEffect(() => {
    fetchBooks();
    // eslint-disable-next-line
  }, [userData]);

  // --------------------- Filtre (Arama + Zorluk) ---------------------
  const filteredBooks = books.filter((book) => {
    const matchesSearch = book.title?.toLowerCase().includes(searchQuery.toLowerCase());
    const matchesDifficulty = difficultyFilter
      ? book.difficulty === difficultyFilter
      : true;
    return matchesSearch && matchesDifficulty;
  });

  // --------------------- Collapse Aç/Kapat ---------------------
  const handleRowClick = (bookId) => {
    setExpandedBookId((prev) => (prev === bookId ? null : bookId));
  };

  // --------------------- Kitap Düzenleme ---------------------
  const handleEditBook = (bookId) => {
    const seciliKitap = books.find((kitap) => kitap._id === bookId);
    if (seciliKitap) {
      setDuzenlenenKitap({
        _id: seciliKitap._id,
        title: seciliKitap.title,
        difficulty: seciliKitap.difficulty,
      });
      setDuzenlemeDialogAcik(true);
    } else {
      console.error('Seçilen kitap bulunamadı.');
    }
  };

  const handleKitapDegisiklikleri = (e) => {
    const { name, value } = e.target;
    setDuzenlenenKitap((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleGuncelle = async () => {
    try {
      const response = await fetch(
        `${API_URLS.SCHOOL_URL}kutuphane/${duzenlenenKitap._id}`,
        {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            title: duzenlenenKitap.title,
            difficulty: duzenlenenKitap.difficulty,
          }),
        }
      );
      const data = await response.json();
      if (!response.ok) throw new Error(data.message || 'Bir hata oluştu');

      // Local state güncelle
      setBooks((prev) =>
        prev.map((kitap) => (kitap._id === duzenlenenKitap._id ? data : kitap))
      );
      setDuzenlemeDialogAcik(false);
    } catch (err) {
      console.error('Kitap güncellerken hata:', err);
    }
  };

  // --------------------- Kitap Silme ---------------------
  const handleDeleteBook = async (bookId) => {
    try {
      const response = await fetch(`${API_URLS.SCHOOL_URL}kutuphane/${bookId}`, {
        method: 'DELETE',
      });
      const data = await response.json();
      if (response.ok) {
        console.log(data.message);
        // Kitabı listeden çıkar
        setBooks((prev) => prev.filter((kitap) => kitap._id !== bookId));
      } else {
        console.error('Kitap silme hatası:', data.message);
      }
    } catch (error) {
      console.error('Kitap silme işlemi sırasında hata:', error);
    }
  };

  // --------------------- Test Ekleme ---------------------
  const handleCreateTest = (bookId) => {
    setSecilenKitapId(bookId);
    setTestFormAcik(true);
    setTestSorusu('');
    setDogruCevap(0);
    setTestAciklamasi('');
    // En az 2 şık ile başlayalım 
    setTestSiklari(['', '']);
  };

  const handleTestFormKapat = () => {
    setTestFormAcik(false);
  };

  const handleSikEkle = () => {
    if (testSiklari.length < 5) {
      setTestSiklari((prev) => [...prev, '']);
    }
  };

  const handleSikSil = (index) => {
    setTestSiklari((prev) => prev.filter((_, i) => i !== index));
  };

  const handleTestFormSubmit = async (e) => {
    e.preventDefault();

    if (testSiklari.length < 2 || testSiklari.length > 5) {
      alert('En az 2, en fazla 5 seçenek girilmelidir.');
      return;
    }

    if (dogruCevap < 0 || dogruCevap >= testSiklari.length) {
      alert('Geçerli bir doğru cevap seçin.');
      return;
    }

    try {
      const response = await fetch(`${API_URLS.SCHOOL_URL}kutuphane/tests`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          bookId: secilenKitapId,
          question: testSorusu,
          options: testSiklari,
          correctAnswer: testSiklari[dogruCevap],
          explanation: testAciklamasi,
        }),
      });

      const data = await response.json();
      if (!response.ok) throw new Error(data.message || 'Bir hata oluştu');

      alert('Test başarıyla oluşturuldu.');
      setTestFormAcik(false);
    } catch (err) {
      console.error('Test ekleme hatası:', err);
      alert(`Hata: ${err.message}`);
    }
  };

  // --------------------- HTML içeriği için parse seçenekleri ---------------------
  const htmlRenderOptions = {
    replace: (domNode) => {
      if (domNode.type === 'tag') {
        if (voidElements.has(domNode.name)) {
          return React.createElement(
            domNode.name,
            { key: domNode.name, ...domNode.attribs },
            null
          );
        } else {
          return React.createElement(
            domNode.name,
            { key: domNode.name, ...domNode.attribs },
            domToReact(domNode.children, htmlRenderOptions)
          );
        }
      }
    },
  };

  // --------------------- Dialog Bileşenleri ---------------------
  const renderDuzenlemeFormuDialog = () => (
    <Dialog open={duzenlemeDialogAcik} onClose={() => setDuzenlemeDialogAcik(false)}>
      <DialogTitle>Kitap Düzenle</DialogTitle>
      <DialogContent>
        <TextField
          fullWidth
          label="Kitap Adı"
          name="title"
          value={duzenlenenKitap.title}
          onChange={handleKitapDegisiklikleri}
          margin="normal"
        />
        <FormControl fullWidth margin="normal">
          <InputLabel id="difficulty-label">Zorluk Seviyesi</InputLabel>
          <Select
            labelId="difficulty-label"
            id="difficulty"
            name="difficulty"
            value={duzenlenenKitap.difficulty}
            label="Zorluk Seviyesi"
            onChange={handleKitapDegisiklikleri}
          >
            <MenuItem value="easy">Kolay</MenuItem>
            <MenuItem value="medium">Orta</MenuItem>
            <MenuItem value="hard">Zor</MenuItem>
          </Select>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setDuzenlemeDialogAcik(false)}>İptal</Button>
        <Button onClick={handleGuncelle} color="primary" variant="contained">
          Güncelle
        </Button>
      </DialogActions>
    </Dialog>
  );

  const renderTestFormDialog = () => (
    <Dialog open={testFormAcik} onClose={handleTestFormKapat} maxWidth="md" fullWidth>
      <DialogTitle>Test Oluştur</DialogTitle>
      <DialogContent>
        <TextField
          fullWidth
          label="Soru"
          value={testSorusu}
          onChange={(e) => setTestSorusu(e.target.value)}
          margin="normal"
        />
        {testSiklari.map((sik, index) => (
          <Box
            key={index}
            sx={{ display: 'flex', alignItems: 'center', gap: 1 }}
          >
            <TextField
              fullWidth
              label={`Şık ${index + 1}`}
              value={sik}
              onChange={(e) => {
                const newVal = e.target.value;
                setTestSiklari((prev) =>
                  prev.map((item, i) => (i === index ? newVal : item))
                );
              }}
              margin="normal"
            />
            <IconButton onClick={() => handleSikSil(index)}>
              <DeleteIcon />
            </IconButton>
          </Box>
        ))}
        <Button
          onClick={handleSikEkle}
          color="primary"
          variant="contained"
          sx={{ mt: 1 }}
          disabled={testSiklari.length >= 5}
        >
          Şık Ekle
        </Button>

        <FormControl fullWidth margin="normal">
          <InputLabel>Doğru Cevap</InputLabel>
          <Select
            value={dogruCevap}
            label="Doğru Cevap"
            onChange={(e) => setDogruCevap(e.target.value)}
          >
            {testSiklari.map((_, i) => (
              <MenuItem key={i} value={i}>
                {i + 1}. Şık
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <TextField
          fullWidth
          label="Açıklama"
          value={testAciklamasi}
          onChange={(e) => setTestAciklamasi(e.target.value)}
          margin="normal"
          multiline
          rows={4}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleTestFormKapat}>İptal</Button>
        <Button onClick={handleTestFormSubmit} color="primary" variant="contained">
          Kaydet
        </Button>
      </DialogActions>
    </Dialog>
  );

  // --------------------- Render (JSX) ---------------------
  return (
    <Paper sx={{ p: 2, mt: 2 }}>
      {/* Filtreleme Alanları (Arama + Zorluk + Yenile Butonu) */}
      <Stack direction="row" spacing={2} sx={{ mb: 2 }}>
        <TextField
          label="Kitap Ara"
          variant="outlined"
          size="small"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
        />
        <FormControl variant="outlined" size="small" sx={{ minWidth: 120 }}>
          <InputLabel>Zorluk</InputLabel>
          <Select
            label="Zorluk"
            value={difficultyFilter}
            onChange={(e) => setDifficultyFilter(e.target.value)}
          >
            <MenuItem value="">Tümü</MenuItem>
            <MenuItem value="easy">Kolay</MenuItem>
            <MenuItem value="medium">Orta</MenuItem>
            <MenuItem value="hard">Zor</MenuItem>
          </Select>
        </FormControl>
        <Button
          startIcon={<RefreshIcon />}
          onClick={fetchBooks}
          variant="contained"
          color="primary"
        >
          Yenile
        </Button>
      </Stack>

      <TableContainer component={Paper}>
        <Table>
          <TableHead sx={{ backgroundColor: '#f5f5f5' }}>
            <TableRow>
              <TableCell sx={{ fontWeight: 'bold', width: '30%' }}>Kitap Adı</TableCell>
              <TableCell sx={{ fontWeight: 'bold', width: '20%' }}>Zorluk</TableCell>
              <TableCell sx={{ fontWeight: 'bold', width: '20%' }}>İşlem</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredBooks.length === 0 ? (
              <TableRow>
                <TableCell colSpan={3} align="center">
                  Aradığınız kriterlere uygun kitap bulunmuyor.
                </TableCell>
              </TableRow>
            ) : (
              filteredBooks.map((book) => {
                const isExpanded = expandedBookId === book._id;
                // İçeriği sanitize & parse
                const sanitizedContent = DOMPurify.sanitize(book.content || '');

                return (
                  <React.Fragment key={book._id}>
                    <TableRow
                      hover
                      onClick={() => handleRowClick(book._id)}
                      sx={{
                        cursor: 'pointer',
                        '&:hover': { backgroundColor: '#fafafa' },
                      }}
                    >
                      <TableCell>{book.title}</TableCell>
                      <TableCell>
                        {book.difficulty === 'easy'
                          ? 'Kolay'
                          : book.difficulty === 'medium'
                          ? 'Orta'
                          : book.difficulty === 'hard'
                          ? 'Zor'
                          : book.difficulty}
                      </TableCell>
                      <TableCell>
                        <IconButton
                          onClick={(e) => {
                            e.stopPropagation();
                            handleEditBook(book._id);
                          }}
                        >
                          <EditIcon />
                        </IconButton>
                        <IconButton
                          onClick={(e) => {
                            e.stopPropagation();
                            handleDeleteBook(book._id);
                          }}
                        >
                          <DeleteIcon />
                        </IconButton>
                        <IconButton
                          onClick={(e) => {
                            e.stopPropagation();
                            handleCreateTest(book._id);
                          }}
                        >
                          <AddCircleIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>

                    {/* Collapse ile içerik gösterme */}
                    <TableRow>
                      <TableCell colSpan={3} sx={{ p: 0 }}>
                        <Collapse in={isExpanded} timeout="auto" unmountOnExit>
                          <Box
                            sx={{
                              p: 2,
                              backgroundColor: '#fefaf2',
                              fontFamily: 'serif',
                              lineHeight: '1.6',
                              borderLeft: '4px solid #cab18c',
                            }}
                          >
                            <Typography variant="h6" sx={{ fontFamily: 'serif', mb: 2 }}>
                              Kitap İçeriği
                            </Typography>
                            <Typography
                              variant="body1"
                              sx={{ whiteSpace: 'pre-wrap' }}
                              component="div"
                            >
                              {parse(sanitizedContent, htmlRenderOptions)}
                            </Typography>
                          </Box>
                        </Collapse>
                      </TableCell>
                    </TableRow>
                  </React.Fragment>
                );
              })
            )}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Düzenleme Dialogu */}
      {renderDuzenlemeFormuDialog()}

      {/* Test Oluşturma Dialogu */}
      {renderTestFormDialog()}
    </Paper>
  );
};

export default BookListe;
