import React, { useState, useEffect } from 'react';
import { Button, TableContainer, Paper } from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';
import AddIcon from '@mui/icons-material/Add';
import { API_URLS } from '../../../../config/config';
import useFetchUserData from '../../../../hooks/kurum/useFetchUserData';
import ResultDialog from '../../../../pages/components/Auth/Dialog';

// Alt bileşenler
import TestsTable from './components/test/TestsTable';
import AddTestDialog from './components/test/AddTestDialog';
import EditTestDialog from './components/test/EditTestDialog';

const Tests = () => {
  const [tests, setTests] = useState([]);
  const [books, setBooks] = useState([]);
  const [userData, setUserData] = useState(null);

  // Düzenlenen test için state
  const [editedTest, setEditedTest] = useState({
    _id: '',
    question: '',
    options: [],
    correctAnswer: '',
    explanation: '',
    bookTitle: '',
  });

  // Modal açma/kapama state’leri
  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);
  const [isAddDialogOpen, setIsAddDialogOpen] = useState(false);

  // Yeni test ekleme formu state’leri
  const [testSorusu, setTestSorusu] = useState('');
  const [testSiklari, setTestSiklari] = useState(['', '']);
  const [dogruCevap, setDogruCevap] = useState('');
  const [testAciklamasi, setTestAciklamasi] = useState('');
  const [secilenKitapId, setSecilenKitapId] = useState('');

  // Diyalog mesajları için state’ler
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogContent, setDialogContent] = useState('');
  const [dialogSeverity, setDialogSeverity] = useState('info');

  // Özel hook: kullanıcı verisini çek
  useFetchUserData(setUserData);

  // Bilgilendirme / uyarı diyalogu gösterme fonksiyonu
  const showDialog = (message, severity = 'info') => {
    setDialogContent(message);
    setDialogSeverity(severity);
    setDialogOpen(true);
  };

  /**
   * Kitapları API'den çekmek için fonksiyon
   */
  const fetchBooks = async () => {
    try {
      if (!userData || !userData._id) {
        console.log("Kullanıcı bilgisi yüklenemedi veya kullanıcı ID'si bulunamadı.");
        return;
      }
  
      const userId = userData._id;
      console.log("Kullanıcı ID (kitapları çekerken):", userId);
  
      const response = await fetch(`${API_URLS.KURUMSAL_URL}kutuphane/${userId}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        }
      });
  
      const result = await response.json();   

      console.log(result)
  
      if (response.ok) {
        if (Array.isArray(result.data)) {
          setBooks(result.data);
        } else if (result.data && Array.isArray(result.data.books)) {
          setBooks(result.data.books);
        } else if (Array.isArray(result)) {
          setBooks(result);
        } else {
          setBooks([]);
        }
      } else {
        const msg = result.message || 'Kitaplar yüklenirken bir hata oluştu.';
        console.error('Kitapları yüklerken bir sorun oluştu:', msg);
        showDialog('Kitapları yüklerken bir sorun oluştu: ' + msg, 'error');
      }
    } catch (error) {
      console.error('Kitapları çekerken hata oluştu:', error);
      showDialog('Kitapları yüklerken bir hata oluştu. Lütfen daha sonra tekrar deneyin.', 'error');
    }
  };

  /**
   * Testleri API'den çekmek için fonksiyon
   */
  const fetchTests = async () => {
    try {
      // 1) Kurum ID kontrolü
      if (!userData || !userData._id) {
        console.log('Kurum ID bulunamadı veya userData henüz yüklenmedi.');
        return;
      }

      // 2) Kurum ID'yi al
      const kurumId = userData._id; // Burada userData._id'nin gerçekten kurum ID olduğunu varsayıyoruz
      console.log('Kurum ID (testleri çekerken):', kurumId);

      // 3) Testleri çek
      const response = await fetch(`${API_URLS.KURUMSAL_URL}kutuphane/tests/${kurumId}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('userToken')}`, // Token'ınızı ekliyoruz
        },
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      // 4) Veriyi JSON'a dönüştür
      const data = await response.json();
      console.log('Testler API’den dönen:', data);

      // 5) tests state'ini güncelle
      setTests(data);

    } catch (error) {
      console.error('Testleri yüklerken bir sorun oluştu:', error);
      showDialog('Testler yüklenirken bir hata oluştu. Lütfen daha sonra tekrar deneyin.', 'error');
    }
  };

  /**
   * Bileşen ilk yüklendiğinde (veya userData güncellendiğinde) testleri ve kitapları çek
   */
  useEffect(() => {
    if (userData && userData._id) {
      fetchTests();
      fetchBooks();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData]);

  /**
   * Test Düzenleme - formu açma ve doldurma
   */
  const handleEditTest = (testId) => {
    const selectedTest = tests.find((test) => test._id === testId);
    if (selectedTest) {
      setEditedTest({
        _id: selectedTest._id,
        question: selectedTest.question,
        options: selectedTest.options,
        correctAnswer: selectedTest.correctAnswer,
        explanation: selectedTest.explanation,
        bookTitle: selectedTest.bookTitle,
      });
      setIsEditDialogOpen(true);
    } else {
      console.error('Seçilen test bulunamadı.');
      showDialog('Seçilen test bulunamadı.', 'error');
    }
  };

  /**
   * Düzenleme formunda alan değiştikçe state güncelle
   */
  const handleTestChange = (e) => {
    const { name, value } = e.target;
    setEditedTest((prevTest) => ({
      ...prevTest,
      [name]: value,
    }));
  };

  /**
   * Test Güncelleme (PUT) fonksiyonu
   */
  const handleUpdate = async () => {
    try {
      if (
        !editedTest.question ||
        editedTest.options.length < 2 ||
        editedTest.correctAnswer === ''
      ) {
        showDialog('Lütfen tüm gerekli alanları doldurun ve en az 2 şık ekleyin.', 'error');
        return;
      }

      if (editedTest.options.some((option) => option.trim() === '')) {
        showDialog('Boş şıklar olamaz. Lütfen tüm şıkları doldurun.', 'error');
        return;
      }

      // correctAnswer metinsel index şeklinde ise sayısal hale getirelim
      const correctAnswerIndex = parseInt(editedTest.correctAnswer, 10);

      const updatedTest = {
        ...editedTest,
        correctAnswer: correctAnswerIndex,
      };

      const response = await fetch(
        `${API_URLS.KURUMSAL_URL}kutuphane/tests/${editedTest._id}`,
        {
          method: 'PUT',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(updatedTest),
        }
      );

      const data = await response.json();
      if (!response.ok) throw new Error(data.message || 'Bir hata oluştu');

      // Başarıyla güncellendiyse, local state’te güncelle
      setTests((prevTests) =>
        prevTests.map((test) =>
          test._id === editedTest._id ? { ...test, ...data } : test
        )
      );

      setIsEditDialogOpen(false);
      showDialog('Test başarıyla güncellendi.', 'success');
    } catch (err) {
      console.error('Test güncelleme hatası:', err);
      showDialog(`Test güncellenirken bir hata oluştu: ${err.message}`, 'error');
    }
  };

  /**
   * Test Silme (DELETE) fonksiyonu
   */
  const handleDeleteTest = async (testId) => {
    if (window.confirm('Bu testi silmek istediğinizden emin misiniz? Bu işlem geri alınamaz.')) {
      try {
        const response = await fetch(
          `${API_URLS.KURUMSAL_URL}kutuphane/tests/${testId}`,
          { method: 'DELETE' }
        );
        if (response.ok) {
          const data = await response.json();
          console.log(data.message);
          // Silinen testi local state’ten çıkar
          setTests((prevTests) => prevTests.filter((test) => test._id !== testId));
          showDialog('Test başarıyla silindi.', 'success');
        } else {
          const errorData = await response.json();
          showDialog('Test silinirken bir sorun oluştu: ' + errorData.message, 'error');
        }
      } catch (error) {
        console.error('Fetch işlemi sırasında hata oluştu:', error);
        showDialog('Test silinirken bir hata oluştu.', 'error');
      }
    }
  };

  /**
   * Yeni Test Ekleme Dialog Formu için şık ekleme fonksiyonu
   */
  const handleSikEkle = () => {
    if (testSiklari.length < 5) {
      setTestSiklari([...testSiklari, '']);
    }
  };

  /**
   * Yeni Test Ekleme Dialog Formu için şık silme fonksiyonu
   */
  const handleSikSil = (index) => {
    const yeniSiklar = [...testSiklari];
    yeniSiklar.splice(index, 1);
    setTestSiklari(yeniSiklar);
  };

  /**
   * Yeni Test Ekleme Dialog’unu kapatma ve formu sıfırlama
   */
  const handleTestFormKapat = () => {
    setIsAddDialogOpen(false);
    setTestSorusu('');
    setTestSiklari(['', '']);
    setDogruCevap('');
    setTestAciklamasi('');
    setSecilenKitapId('');
  };

  /**
   * Yeni Test Oluşturma (POST) fonksiyonu
   */
  const handleTestFormSubmit = async (e) => {
    e.preventDefault();

    if (testSiklari.length < 2 || testSiklari.length > 5) {
      showDialog('En az 2, en fazla 5 şık girebilirsiniz.', 'error');
      return;
    }

    if (dogruCevap === '' || dogruCevap < 0 || dogruCevap >= testSiklari.length) {
      showDialog('Geçerli bir doğru cevap seçin.', 'error');
      return;
    }

    if (!secilenKitapId) {
      showDialog('Lütfen bir kitap seçin.', 'error');
      return;
    }

    try {
      const response = await fetch(`${API_URLS.KURUMSAL_URL}kutuphane/tests`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          bookId: secilenKitapId,
          question: testSorusu,
          options: testSiklari,
          correctAnswer: testSiklari[dogruCevap],
          explanation: testAciklamasi,
        }),
      });

      const data = await response.json();
      if (!response.ok) {
        throw new Error(data.message || 'Bir hata oluştu');
      }

      showDialog('Test başarıyla oluşturuldu.', 'success');
      handleTestFormKapat();
      fetchTests(); // Yeni testi listeye eklemek için listeyi tekrar çek
    } catch (err) {
      console.error(err);
      showDialog(`Hata: ${err.message}`, 'error');
    }
  };

  return (
    <TableContainer component={Paper} style={{ marginTop: '20px' }}>
      {/* Üst kısımda tabloya etkileşime geçmek için butonlar */}
      <Button
        startIcon={<RefreshIcon />}
        onClick={fetchTests}
        variant="contained"
        color="primary"
        style={{ marginBottom: '10px', marginRight: '10px' }}
      >
        Yenile
      </Button>
      <Button
        startIcon={<AddIcon />}
        onClick={() => setIsAddDialogOpen(true)}
        variant="contained"
        color="secondary"
        style={{ marginBottom: '10px' }}
      >
        Yeni Test Ekle
      </Button>

      {/* Testleri Listeleyen Tablo */}
      <TestsTable
        tests={tests}
        books={books}
        handleEditTest={handleEditTest}
        handleDeleteTest={handleDeleteTest}
      />

      {/* Test Düzenleme Dialog Bileşeni */}
      <EditTestDialog
        isOpen={isEditDialogOpen}
        editedTest={editedTest}
        handleClose={() => setIsEditDialogOpen(false)}
        handleTestChange={handleTestChange}
        handleUpdate={handleUpdate}
      />

      {/* Yeni Test Ekleme Dialog Bileşeni */}
      <AddTestDialog
        isOpen={isAddDialogOpen}
        testSorusu={testSorusu}
        testSiklari={testSiklari}
        dogruCevap={dogruCevap}
        testAciklamasi={testAciklamasi}
        secilenKitapId={secilenKitapId}
        books={books}
        handleSikEkle={handleSikEkle}
        handleSikSil={handleSikSil}
        handleTestFormKapat={handleTestFormKapat}
        handleTestFormSubmit={handleTestFormSubmit}
        setTestSorusu={setTestSorusu}
        setTestSiklari={setTestSiklari}
        setDogruCevap={setDogruCevap}
        setTestAciklamasi={setTestAciklamasi}
        setSecilenKitapId={setSecilenKitapId}
      />

      {/* Genel sonuç diyalog component’i */}
      <ResultDialog
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        title={dialogSeverity === 'error' ? 'Hata' : 'Bilgi'}
        message={dialogContent}
        severity={dialogSeverity}
      />
    </TableContainer>
  );
};

export default Tests;
