import React, { useState, useEffect } from 'react';
import axios from 'axios'; // Eğer fetch kullanıyorsanız kaldırabilirsiniz
import { useNavigate, useLocation } from 'react-router-dom';
import { useKayanYazilarIkiserOyunu } from './hooks/useKayanYazilarIkiserOyunu';

// Bileşenler
import HeaderSection from './components/GameHeader';
import GameControls from './components/GameControls';
import GameDisplay from './components/GameDisplay';
import GameOverModal from './components/GameOverModal';
import GameTimerIkiser from './components/GameTimer';
// Config ve utils
import { API_URLS } from '../../../config/config';
import axiosInstance from '../../../utils/axiosInstance';

// Stil
import './styles/kayanYazilarIkiserStyle.css';

const KayanYazilarIkiserOyunu = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { stage, exercise } = location.state || {};

  const token = localStorage.getItem('userToken');

  const [userData, setUserData] = useState(null);
  const [userRole, setUserRole] = useState(null);
  const [showInstructions, setShowInstructions] = useState(true);
  const [showGameOver, setShowGameOver] = useState(false);

  // useKayanYazilarIkiserOyunu hook'una stage bilgisi gönderiyoruz
  const {
    difficulty,
    paragraphsList,
    selectedParagraphIndex,
    displayedWords,
    isPlaying,
    speed,
    elapsedTime,
    timeLeft,
    message,
    gameOver,
    setGameOver,
    updateWords,
    handlePlayPause,
    handleReset,
    handleSpeedChange,
    handleParagraphChange,
    handleDifficultyChange,
    gameTime,
    difficultyLocked
  } = useKayanYazilarIkiserOyunu(navigate, userData, token, stage);

  // Kullanıcı verilerini al (Önce öğrenci, yoksa öğretmen)
  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const studentResponse = await axiosInstance.get(`${API_URLS.STUDENT_URL}/me`);
        if (
          studentResponse.status === 200 &&
          studentResponse.data &&
          studentResponse.data.status === 'active'
        ) {
          const studentData = studentResponse.data;
          setUserData(studentData);
          setUserRole('student');
          return;
        } else {
          setUserData(null);
          setUserRole(null);
        }
      } catch (error) {
        console.error('Öğrenci verisi alınırken hata oluştu:', error);
      }

      // Öğrenci yoksa veya aktif değilse öğretmen verisine bak
      try {
        const teacherResponse = await fetch(`${API_URLS.TEACHER_URL}/me`, {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });
        if (teacherResponse.ok) {
          const teacherData = await teacherResponse.json();
          setUserData(teacherData);
          setUserRole('teacher');
          return;
        }
      } catch (error) {
        console.error('Öğretmen verisi alınırken hata oluştu:', error);
      }

      setUserData(null);
      setUserRole(null);
    };

    fetchUserData();
  }, [token]);

  const startGame = () => {
    handleReset();
    setShowInstructions(false);
  };

  // gameOver true ise GameOverModal aç
  useEffect(() => {
    if (gameOver && !showGameOver) {
      setShowGameOver(true);
    }
  }, [gameOver, showGameOver]);

  // Oyun bittiğinde ilerlemeyi güncelle
  useEffect(() => {
    if (gameOver) {
      const updateProgress = async () => {
        try {
          if (userData && userRole) {
            // Score: her pair 2 kelimeden oluştuğu için displayedWords.length * 2
            const payload = {
              stageNumber: stage?.number || 1,
              gameName: exercise?.gameName || 'Kayan Yazılar 2',
              score: displayedWords.length * 2,
              // BURADA DEĞİŞİKLİK YAPALIM: elapsedTime'i artık /1000'le bölmüyoruz, çünkü saniye cinsinden tutuyoruz.
              time: Math.floor(elapsedTime),
              wordCount: displayedWords.length * 2,
              completed: true,
            };

            if (userRole === 'student') {
              // Öğrenci -> axiosInstance ile istek
              const apiUrl = `${API_URLS.STUDENT_URL}akillikurs/progress/update-game`;
              await axiosInstance.post(apiUrl, payload);
            } else {
              // Öğretmen -> fetch veya axios ile token header ekle
              const apiUrl = `${API_URLS.TEACHER_URL}akillikurs/progress/update-game`;
              const teacherToken = localStorage.getItem('userToken');
              await axios.post(apiUrl, payload, {
                headers: {
                  Authorization: `Bearer ${teacherToken}`,
                  'Content-Type': 'application/json',
                },
              });
            }
          }
        } catch (error) {
          console.error('İlerleme güncellenirken hata oluştu:', error);
        }
      };
      updateProgress();
    }
  }, [gameOver, displayedWords.length, elapsedTime, userData, userRole, stage, exercise, token]);

  const handleRestart = () => {
    setShowGameOver(false);
    setGameOver(false);
    handleReset();
  };

  const handleReturnToPreviousStage = () => {
    if (stage) {
      // Stage seçiliyse, öğrenci veya öğretmen dashboard’una geri gönder
      navigate(
        userRole === 'student' ? '/ogrenci-dashboard' : '/ogretmen-dashboard',
        {
          state: {
            selectedMenu: 'ExerciseList',
            selectedStage: stage,
          },
        }
      );
    } else {
      // Stage yoksa normal geri
      navigate(-1);
    }
  };

  const handleClose = () => {
    setShowGameOver(false);
    setGameOver(false);
  };

  return (
    <>
      {/* Oyun başlamadan önce bilgilendirme */}
      {showInstructions ? (
        <div className="kayan-yazilar-ikiser-intro-container">
          <div className="kayan-yazilar-ikiser-intro">
            <div className="kayan-yazilar-ikiser-card">
              <div className="kayan-yazilar-ikiser-card-body">
                <HeaderSection handleReturnToPreviousStage={handleReturnToPreviousStage} />
                <h1 className="kayan-yazilar-ikiser-title">Kayan Yazılar 2 Çalışması</h1>
                {stage ? (
                  <p className="kayan-yazilar-ikiser-description">
                    Bu çalışmada aşamanıza göre zorluk seviyesi otomatik belirlenecek. 
                    İkişer kelime halinde ekranda beliren metni en hızlı şekilde okuyun.
                  </p>
                ) : (
                  <p className="kayan-yazilar-ikiser-description">
                    Bu çalışmada seçtiğiniz zorluk seviyesine göre ekranda 
                    ikişer kelime halinde metinler belirecek. Amacınız bu kelimeleri 
                    en hızlı şekilde okumak.
                  </p>
                )}
                <h3 className="kayan-yazilar-ikiser-instructions-title">Nasıl Oynanır?</h3>
                <ul className="kayan-yazilar-ikiser-instructions-list">
                  {stage ? (
                    <li>Aşamanıza göre zorluk seviyesi otomatik ayarlandı.</li>
                  ) : (
                    <li>Zorluk seviyesini seçin.</li>
                  )}
                  <li>"Başlat" butonuna tıklayarak çalışmayı başlatın.</li>
                  <li>Ekranda beliren ikili kelimeleri takip edin ve okuyun.</li>
                </ul>
                <button className="kayan-yazilar-ikiser-button" onClick={startGame}>
                  BAŞLAT
                </button>
              </div>
            </div>
          </div>
        </div>
      ) : (
        // Oyun ekranı
        <div className="kayan-yazilar-ikiser-game-container">
          <div className="kayan-yazilar-ikiser-gameplay">
            <HeaderSection handleReturnToPreviousStage={handleReturnToPreviousStage} />
            
            {/* Oyun ayarları (difficulty, speed, paragraf seçimi) */}
            <GameControls
              difficulty={difficulty}
              paragraphsList={paragraphsList}
              selectedParagraphIndex={selectedParagraphIndex}
              speed={speed}
              handleDifficultyChange={handleDifficultyChange}
              handleParagraphChange={handleParagraphChange}
              handleSpeedChange={handleSpeedChange}
              difficultyLocked={difficultyLocked}
            />

            {/* Timer ve ProgressBar */}
            <GameTimerIkiser
              timeLeft={timeLeft}
              isPlaying={isPlaying}
              gameTime={gameTime}
              elapsedTime={elapsedTime}
            />

            {/* Ana metin gösterimi */}
            <GameDisplay
              isPlaying={isPlaying}
              speed={speed}
              paragraphsList={paragraphsList}
              selectedParagraphIndex={selectedParagraphIndex}
              displayedWords={displayedWords}
              updateWords={updateWords}
              handlePlayPause={handlePlayPause}
              handleReset={handleReset}
              message={message}
              gameOver={gameOver}
              timeLeft={timeLeft}
              gameTime={gameTime}
            />
          </div>
        </div>
      )}

      {/* Oyun Bitti Modal */}
      {showGameOver && (
        <GameOverModal
          score={displayedWords.length * 2}
          // BURADA DA DÜZELTİYORUZ: elapsedTime saniye cinsinden tutuluyor, /1000 yapmadan gösterelim.
          elapsedTime={Math.floor(elapsedTime)}
          onRestart={handleRestart}
          onClose={handleClose}
          userData={userData}
        />
      )}
    </>
  );
};

export default KayanYazilarIkiserOyunu;
