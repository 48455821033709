// useKayanYazilarIkiserOyunu.js

import { useState, useEffect, useCallback } from 'react';
import { API_URLS } from '../../../../config/config';
import axiosInstance from '../../../../utils/axiosInstance';

export const useKayanYazilarIkiserOyunu = (navigate, userData, token, stage) => {
  // Eğer aşama bilgisi varsa zorluk otomatik ayarlanacak
  const stageNumber = stage?.number || null;
  
  let initialDifficulty = 'easy';
  let difficultyLocked = false;

  if (stageNumber) {
    difficultyLocked = true;
    if (stageNumber >= 1 && stageNumber <= 7) {
      initialDifficulty = 'easy';
    } else if (stageNumber >= 8 && stageNumber <= 14) {
      initialDifficulty = 'medium';
    } else {
      initialDifficulty = 'hard';
    }
  }

  const [difficulty, setDifficulty] = useState(initialDifficulty);
  const [paragraphsList, setParagraphsList] = useState([]);
  const [selectedParagraphIndex, setSelectedParagraphIndex] = useState(0);
  const [displayedWords, setDisplayedWords] = useState([]);
  const [currentWordIndex, setCurrentWordIndex] = useState(0);
  const [isPlaying, setIsPlaying] = useState(false);
  const [speed, setSpeed] = useState(2); // hız: saniyedeki kelime çifti
  const [elapsedTime, setElapsedTime] = useState(0); // saniye cinsinden
  const [gameTime, setGameTime] = useState(0); // saniye cinsinden
  const [timeLeft, setTimeLeft] = useState(0); // saniye cinsinden
  const [message, setMessage] = useState('');
  const [gameOver, setGameOver] = useState(false);
  const [allWords, setAllWords] = useState([]);

  const prepareWords = (content) => {
    const words = content.trim().split(/\s+/);
    setAllWords(words);
  };

  useEffect(() => {
    const fetchParagraphs = async () => {
      try {
        const url = `${API_URLS.HIZLIOKUMA_URL}akanyazi/${difficulty}`;
        const response = await axiosInstance.get(url);
        const data = response.data;

        setParagraphsList(data);
        setSelectedParagraphIndex(0);

        if (data[0]) {
          prepareWords(data[0].content);
        }
        handleReset();
      } catch (error) {
        console.error('Paragraflar alınırken hata oluştu:', error);
      }
    };

    fetchParagraphs();
  }, [difficulty, token]);

  useEffect(() => {
    if (paragraphsList[selectedParagraphIndex]) {
      prepareWords(paragraphsList[selectedParagraphIndex].content);
      handleReset();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedParagraphIndex, paragraphsList]);

  useEffect(() => {
    if (paragraphsList[selectedParagraphIndex]) {
      const words = allWords;
      const wordCount = words.length;
      const totalWordPairs = Math.ceil(wordCount / 2);

      // Dakikada 2 "pair" hız 2 ise, saniyede 2 pair demek vs. 
      // Aşağıda 'estimatedTime' çift başına 1/speed saniye. 
      const estimatedTime = totalWordPairs / speed;

      // userData kontrolü yapıyorsunuz, inactive ise 15 saniye sabit
      const maxTime = userData
        ? userData.status === 'inactive'
          ? 15
          : estimatedTime
        : 15;

      // maxTime doğrudan "saniye" cinsinden
      setGameTime(maxTime);
      setTimeLeft(maxTime);
    }
  }, [paragraphsList, selectedParagraphIndex, speed, userData, allWords]);

  const updateWords = useCallback(() => {
    if (!isPlaying || !paragraphsList[selectedParagraphIndex]) return;

    const words = allWords;

    if (currentWordIndex >= words.length) {
      setIsPlaying(false);
      setGameOver(true);
      return;
    }

    const nextWords = words.slice(currentWordIndex, currentWordIndex + 2);
    setDisplayedWords((prev) => [...prev, nextWords.join(' ')]);
    setCurrentWordIndex((prev) => prev + 2);

    // Kelime hızımız "speed" => saniyede 'speed' çift
    // Dolayısıyla 1 kelime çifti göstermek için intervalInSeconds = 1 / speed
    const intervalInSeconds = 1 / speed;

    setElapsedTime((prev) => prev + intervalInSeconds);
    setTimeLeft((prev) => prev - intervalInSeconds);

    // timeLeft'i bitirdiysek oyunu durdur
    if (timeLeft - intervalInSeconds <= 0) {
      setIsPlaying(false);
      setGameOver(true);
    }
  }, [
    isPlaying,
    paragraphsList,
    selectedParagraphIndex,
    currentWordIndex,
    speed,
    timeLeft,
    allWords,
  ]);

  useEffect(() => {
    let wordInterval;
    if (isPlaying) {
      // 1/sn * (1000ms) yerine saniye bazlı ilerliyoruz. 
      // Yine de setInterval milisaniye ister => intervalDuration = 1000 / speed
      const intervalDuration = 1000 / speed;
      wordInterval = setInterval(() => {
        updateWords();
      }, intervalDuration);
    }

    return () => clearInterval(wordInterval);
  }, [isPlaying, speed, updateWords]);

  const handlePlayPause = () => {
    if (gameOver) return;
    if (isPlaying) {
      setMessage(`Skorunuz: ${currentWordIndex} kelime`);
    } else {
      setMessage('');
    }
    setIsPlaying(!isPlaying);
  };

  const handleReset = () => {
    setIsPlaying(false);
    setDisplayedWords([]);
    setCurrentWordIndex(0);
    setElapsedTime(0);
    setMessage('');
    setGameOver(false);

    if (paragraphsList[selectedParagraphIndex]) {
      const words = allWords;
      const wordCount = words.length;
      const totalWordPairs = Math.ceil(wordCount / 2);
      const estimatedTime = totalWordPairs / speed;

      const maxTime = userData
        ? userData.status === 'inactive'
          ? 15
          : estimatedTime
        : 15;

      setGameTime(maxTime);
      setTimeLeft(maxTime);
    }
  };

  const handleSpeedChange = (e) => {
    const newSpeed = Number(e.target.value);
    setSpeed(newSpeed);

    if (paragraphsList[selectedParagraphIndex]) {
      const words = allWords;
      const wordCount = words.length;
      const totalWordPairs = Math.ceil(wordCount / 2);
      const wordPairsLeft = totalWordPairs - Math.floor(currentWordIndex / 2);
      const estimatedTime = wordPairsLeft / newSpeed;

      setTimeLeft(estimatedTime);
    }
  };

  const handleParagraphChange = (e) => {
    setSelectedParagraphIndex(Number(e.target.value));
  };

  const handleDifficultyChange = (e) => {
    if (!difficultyLocked) {
      setDifficulty(e.target.value);
    }
  };

  return {
    difficulty,
    paragraphsList,
    selectedParagraphIndex,
    displayedWords,
    currentWordIndex,
    isPlaying,
    speed,
    elapsedTime,
    gameTime,
    timeLeft,
    message,
    gameOver,
    setGameOver,
    handlePlayPause,
    handleReset,
    handleSpeedChange,
    handleParagraphChange,
    handleDifficultyChange,
    updateWords,
    difficultyLocked
  };
};
