// src/components/GameTimer.js
import React from "react";
import "../styles/kayanYazilarStyle.css"; // veya kendi stil dosyanızı

const GameTimer = ({ timeLeft }) => {
  // Milisaniyeyi mm:ss formatına çevir
  const formatTime = (ms) => {
    let totalSeconds = Math.ceil(ms / 1000);
    if (totalSeconds < 0) totalSeconds = 0;
    const minutes = Math.floor(totalSeconds / 60)
      .toString()
      .padStart(2, "0");
    const seconds = (totalSeconds % 60).toString().padStart(2, "0");
    return `${minutes}:${seconds}`;
  };

  return (
    <div className="kayan-yazilar-timer">
      <span>Kalan Süre: {formatTime(timeLeft)}</span>
    </div>
  );
};

export default GameTimer;
