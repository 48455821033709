import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { API_URLS } from '../../../config/config';
import useFetchUserData from '../../../hooks/kurum/useFetchUserData';
import {Card, CardContent, Typography, Tabs, Tab, Grid, Box, Divider, Button,} from '@mui/material';
import { BarChart, Bar, LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer,} from 'recharts';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import SchoolIcon from '@mui/icons-material/School';
import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';
import LocationCityIcon from '@mui/icons-material/LocationCity';
import MapIcon from '@mui/icons-material/Map';
import ClassIcon from '@mui/icons-material/Class';
import EmojiPeopleIcon from '@mui/icons-material/EmojiPeople';

function StudentProgressReport() {
  const [userData, setUserData] = useState({
    ad: '',
    soyad: '',
    sehir: '',
    ilce: '',
    telefon: '',
  });

  const location = useLocation();
  const navigate = useNavigate();
  const { ogrenci } = location.state || {};
  const [progressData, setProgressData] = useState(null);
  const [initialTestResult, setInitialTestResult] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');
  const [selectedStage, setSelectedStage] = useState(0);

  useFetchUserData(setUserData);

  useEffect(() => {
    const fetchProgressData = async () => {
      try {
        const token = localStorage.getItem('userToken');
        if (!token) {
          console.log('Yetkiniz yok');
          setErrorMessage('Yetkiniz yok, lütfen giriş yapın.');
          navigate('/kurumsal-giris');
          return;
        }

        if (!ogrenci || !ogrenci._id) {
          setErrorMessage('Öğrenci bilgisi eksik.');
          return;
        }

        const [progressResponse, initialTestResponse] = await Promise.all([
          fetch(
            `${API_URLS.KURUMSAL_URL}ogrenci/akillikursrapor/student/${ogrenci._id}/progress`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
              },
            }
          ),
          fetch(
            `${API_URLS.KURUMSAL_URL}ogrenci/akillikursrapor/student/${ogrenci._id}/test/initial-test`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
              },
            }
          ),
        ]);

        if (progressResponse.status === 401 || initialTestResponse.status === 401) {
          console.log('Yetkiniz yok');
          setErrorMessage('Yetkiniz yok, lütfen giriş yapın.');
          navigate('/kurumsal-giris');
          return;
        }

        if (!progressResponse.ok) {
          throw new Error('Bir hata oluştu');
        }

        const progressData = await progressResponse.json();
        setProgressData(progressData.progress);

        if (initialTestResponse.ok) {
          const initialTestData = await initialTestResponse.json();
          setInitialTestResult(initialTestData.testResult);
        } else {
          setInitialTestResult(null);
        }
      } catch (error) {
        console.error('İlerleme verileri alınırken bir hata oluştu:', error);
        setErrorMessage('Bu öğrenci henüz çalışmaya başlamadı.');
      }
    };

    fetchProgressData();
  }, [ogrenci._id, navigate]);

  if (!progressData) {
    return (
      <Box sx={{ padding: 4, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        {errorMessage ? (
          <Card
            sx={{
              maxWidth: 500,
              padding: 4,
              borderRadius: 3,
              boxShadow: 6,
              backgroundImage: 'linear-gradient(to right, #f44336, #e57373)',
              textAlign: 'center',
              color: '#fff',
            }}
          >
            <Typography
              variant="h5"
              sx={{ fontWeight: 'bold', marginBottom: 2, textShadow: '1px 1px rgba(0,0,0,0.2)' }}
            >
              {errorMessage}
            </Typography>
            <Typography
              variant="body1"
              sx={{ marginBottom: 3, textShadow: '1px 1px rgba(0,0,0,0.1)' }}
            >
              Lütfen tekrar deneyin veya geri dönün.
            </Typography>
            <Button
              variant="contained"
              startIcon={<ArrowBackIcon />}
              onClick={() => navigate(-1)}
              sx={{
                backgroundColor: '#c62828',
                color: '#fff',
                fontWeight: 'bold',
                '&:hover': {
                  backgroundColor: '#b71c1c',
                },
              }}
            >
              Geri Dön
            </Button>
          </Card>
        ) : (
          <Card
            sx={{
              maxWidth: 500,
              padding: 4,
              borderRadius: 3,
              boxShadow: 6,
              backgroundImage: 'linear-gradient(to right, #fff3cd, #ffecb3)',
              textAlign: 'center',
              color: '#856404',
            }}
          >
            <Typography
              variant="h6"
              sx={{ fontWeight: 'bold', marginBottom: 2, textShadow: '1px 1px rgba(0,0,0,0.1)' }}
            >
              Henüz teste başlamadığı için rapor görüntülenmemektedir.
            </Typography>
            <Button
              variant="contained"
              startIcon={<ArrowBackIcon />}
              onClick={() => navigate(-1)}
              sx={{
                backgroundColor: '#ffca28',
                color: '#000',
                fontWeight: 'bold',
                '&:hover': {
                  backgroundColor: '#ffb300',
                },
              }}
            >
              Geri Dön
            </Button>
          </Card>
        )}
      </Box>
    );
  }
   

  const stages = progressData.stages;

  const prepareChartData = (stageIndex) => {
    const chartData = [];

    const stage = stages[stageIndex];

    if (stage) {
      stage.games.forEach((game) => {
        chartData.push({
          gameName: game.gameName,
          score: game.score || 0,
          time: game.time || 0,
          wordCount: game.wordCount || 0,
          completedAt: game.completedAt
            ? new Date(game.completedAt).toLocaleDateString()
            : 'Tamamlanmadı',
        });
      });
    }

    return chartData;
  };

  const chartData = prepareChartData(selectedStage);

  const handleTabChange = (event, newValue) => {
    setSelectedStage(newValue);
  };

  const prepareOverallChartData = () => {
    const overallChartData = [];

    stages.forEach((stage) => {
      const stageData = {
        stageNumber: `Aşama ${stage.stageNumber}`,
        totalScore: 0,
        totalTime: 0,
        totalWordCount: 0,
      };

      stage.games.forEach((game) => {
        stageData.totalScore += game.score || 0;
        stageData.totalTime += game.time || 0;
        stageData.totalWordCount += game.wordCount || 0;
      });

      overallChartData.push(stageData);
    });

    return overallChartData;
  };

  const overallChartData = prepareOverallChartData();

  const formatTime = (totalSeconds) => {
    const minutes = Math.floor(totalSeconds / 60);
    const seconds = totalSeconds % 60;
    return `${minutes} dakika ${seconds} saniye`;
  };

  // Anlama oranını her zaman %100'e sınırlayan yardımcı fonksiyon
  const getComprehensionRate = (stage, initialResult) => {
    let comprehensionRate = 0;

    if (stage.testResult && stage.testResult.totalQuestions > 0) {
      // Soru tabanlı hesaplama
      comprehensionRate = (stage.testResult.correctAnswers / stage.testResult.totalQuestions) * 100;
    } else if (
      stage.testResult &&
      initialResult &&
      initialResult.readingSpeed > 0 &&
      stage.testResult.readingSpeed > 0
    ) {
      // Okuma hızı tabanlı hesaplama
      comprehensionRate = (stage.testResult.readingSpeed / initialResult.readingSpeed) * 100;
    } else {
      comprehensionRate = 0;
    }

    // %100'ü aşmasın
    comprehensionRate = Math.min(comprehensionRate, 100);
    comprehensionRate = parseFloat(comprehensionRate.toFixed(2));

    return comprehensionRate;
  };

  // Anlama oranı verilerini hazırlama
  const prepareComprehensionData = () => {
    const data = [];

    // Başlangıç testi için
    if (initialTestResult) {
      data.push({
        name: 'Başlangıç Testi',
        comprehensionRate: 0, // Başlangıç testinde anlama oranı yok
      });
    }

    stages.forEach((stage) => {
      const comprehensionRate = getComprehensionRate(stage, initialTestResult);
      data.push({
        name: `Aşama ${stage.stageNumber}`,
        comprehensionRate: comprehensionRate,
      });
    });

    return data;
  };

  const comprehensionData = prepareComprehensionData();

  return (
    <Box
      sx={{
        padding: { xs: 2, md: 4 },
        backgroundImage: 'linear-gradient(to right, #ff7e5f, #feb47b)',
        minHeight: '100vh',
      }}
    >
      {/* Geri Dön Butonu */}
      <Button
        variant="contained"
        startIcon={<ArrowBackIcon />}
        onClick={() => navigate(-1)}
        sx={{
          mb: 3,
          backgroundColor: '#ff5722',
          color: '#fff',
          fontWeight: 'bold',
          '&:hover': {
            backgroundColor: '#e64a19',
          },
        }}
      >
        Geri Dön
      </Button>

      <Typography
        variant="h4"
        gutterBottom
        align="center"
        sx={{
          marginBottom: 4,
          color: '#fff',
          fontWeight: 'bold',
          textShadow: '2px 2px #ff5722',
        }}
      >
        {ogrenci.ad} {ogrenci.soyad} - İlerleme Raporu
      </Typography>

      <Grid container spacing={4} sx={{ marginBottom: 4 }}>
        {/* Öğrenci Bilgileri Kartı */}
        <Grid item xs={12} md={6}>
          <Card
            sx={{
              boxShadow: 6,
              borderRadius: 3,
              backgroundImage: 'linear-gradient(to right, #ff512f, #dd2476)',
              color: '#fff',
              transition: 'transform 0.3s',
              '&:hover': {
                transform: 'scale(1.02)',
                boxShadow: 8,
              },
            }}
          >
            <CardContent>
              <Typography
                variant="h6"
                gutterBottom
                sx={{
                  fontWeight: 'bold',
                  display: 'flex',
                  alignItems: 'center',
                  gap: 1,
                  color: '#fff',
                }}
              >
                <SchoolIcon />
                Öğrenci Bilgileri
              </Typography>
              <Divider sx={{ marginY: 2, backgroundColor: '#fff' }} />
              <Typography variant="body1" sx={{ mb: 1 }}>
                <EmailIcon sx={{ verticalAlign: 'middle', mr: 1 }} />
                <strong>Email:</strong> {ogrenci.email}
              </Typography>
              <Typography variant="body1" sx={{ mb: 1 }}>
                <PhoneIcon sx={{ verticalAlign: 'middle', mr: 1 }} />
                <strong>Telefon:</strong> {ogrenci.telefon}
              </Typography>
              <Typography variant="body1" sx={{ mb: 1 }}>
                <SchoolIcon sx={{ verticalAlign: 'middle', mr: 1 }} />
                <strong>Okul:</strong> {ogrenci.okul_adi}
              </Typography>
            </CardContent>
          </Card>
        </Grid>

        {/* İletişim Bilgileri Kartı */}
        <Grid item xs={12} md={6}>
          <Card
            sx={{
              boxShadow: 6,
              borderRadius: 3,
              backgroundImage: 'linear-gradient(to right, #2196f3, #21cbf3)',
              color: '#fff',
              transition: 'transform 0.3s',
              '&:hover': {
                transform: 'scale(1.02)',
                boxShadow: 8,
              },
            }}
          >
            <CardContent>
              <Typography
                variant="h6"
                gutterBottom
                sx={{
                  fontWeight: 'bold',
                  display: 'flex',
                  alignItems: 'center',
                  gap: 1,
                  color: '#fff',
                }}
              >
                <LocationCityIcon />
                İletişim Bilgileri
              </Typography>
              <Divider sx={{ marginY: 2, backgroundColor: '#fff' }} />
              <Typography variant="body1" sx={{ mb: 1 }}>
                <LocationCityIcon sx={{ verticalAlign: 'middle', mr: 1 }} />
                <strong>Şehir:</strong> {ogrenci.sehirAdi}
              </Typography>
              <Typography variant="body1" sx={{ mb: 1 }}>
                <MapIcon sx={{ verticalAlign: 'middle', mr: 1 }} />
                <strong>İlçe:</strong> {ogrenci.ilceAdi}
              </Typography>
              <Typography variant="body1" sx={{ mb: 1 }}>
                <ClassIcon sx={{ verticalAlign: 'middle', mr: 1 }} />
                <strong>Sınıf:</strong> {ogrenci.sinif}
              </Typography>
              <Typography variant="body1" sx={{ mb: 1 }}>
                <EmojiPeopleIcon sx={{ verticalAlign: 'middle', mr: 1 }} />
                <strong>Yaş:</strong> {ogrenci.yas}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      {/* Anlama Oranı Bölümü */}
      <Box
        sx={{
          padding: { xs: 2, md: 4 },
          backgroundColor: '#fff3e0',
          color: '#000',
          borderRadius: 3,
          marginBottom: 4,
        }}
      >
        <Typography
          variant="h4"
          gutterBottom
          align="center"
          sx={{
            marginBottom: 4,
            fontWeight: 'bold',
          }}
        >
          Anlama Oranı
        </Typography>

        {/* Anlama Oranı Grafiği */}
        <Card
          sx={{
            boxShadow: 6,
            borderRadius: 3,
            backgroundImage: 'linear-gradient(to right, #f3e5f5, #e1bee7)',
            color: '#000',
            marginBottom: 4,
          }}
        >
          <CardContent>
            <Typography
              variant="h6"
              gutterBottom
              sx={{ fontWeight: 'bold', color: '#000' }}
            >
              Anlama Oranı Grafiği
            </Typography>
            <ResponsiveContainer width="100%" height={300}>
              <LineChart
                data={comprehensionData}
                margin={{ top: 20, right: 30, left: 0, bottom: 0 }}
              >
                <CartesianGrid strokeDasharray="3 3" stroke="#000" />
                <XAxis dataKey="name" stroke="#000" />
                <YAxis stroke="#000" domain={[0, 100]} />
                <Tooltip
                  formatter={(value) => [`${value}%`, 'Anlama Oranı']}
                  contentStyle={{
                    backgroundColor: '#f3e5f5',
                    color: '#000',
                    borderRadius: '10px',
                  }}
                />
                <Legend wrapperStyle={{ color: '#000' }} />
                <Line
                  type="monotone"
                  dataKey="comprehensionRate"
                  name="Anlama Oranı (%)"
                  stroke="#8e24aa"
                  strokeWidth={3}
                  activeDot={{ r: 8 }}
                />
              </LineChart>
            </ResponsiveContainer>
          </CardContent>
        </Card>

        {/* Başlangıç Test Sonucu */}
        {initialTestResult ? (
          <Box sx={{ marginBottom: 4 }}>
            <Typography variant="h6" gutterBottom>
              Başlangıç Test Sonucu:
            </Typography>
            <Typography variant="body1">
              Okuduğunuz Kelime Sayısı: {initialTestResult.wordCount}
            </Typography>
            <Typography variant="body1">
              Toplam Süre: {formatTime(initialTestResult.totalTime)}
            </Typography>
            <Typography variant="body1">
              Okuma Hızınız: {initialTestResult.readingSpeed} kelime/dakika
            </Typography>
          </Box>
        ) : (
          <Typography variant="body1">
            Başlangıç test sonucu bulunamadı.
          </Typography>
        )}

        {/* Aşama Test Sonuçları */}
        {stages.map((stage, index) => {
          const comprehensionRate = getComprehensionRate(stage, initialTestResult);

          return (
            <Box key={index} sx={{ marginBottom: 4 }}>
              <Typography variant="h6" gutterBottom>
                Aşama {stage.stageNumber} Test Sonucu:
              </Typography>
              {stage.testResult ? (
                <>
                  <Typography variant="body1">
                    Okuduğunuz Kelime Sayısı: {stage.testResult.wordCount}
                  </Typography>
                  <Typography variant="body1">
                    Toplam Süre: {formatTime(stage.testResult.totalTime)}
                  </Typography>
                  <Typography variant="body1">
                    Okuma Hızınız: {stage.testResult.readingSpeed} kelime/dakika
                  </Typography>

                  {/* Yeni Eklenen Alanlar */}
                  {typeof stage.testResult.totalQuestions === 'number' && (
                    <Typography variant="body1">
                      Toplam Soru Sayısı: {stage.testResult.totalQuestions}
                    </Typography>
                  )}
                  {typeof stage.testResult.correctAnswers === 'number' && (
                    <Typography variant="body1">
                      Doğru Cevap Sayısı: {stage.testResult.correctAnswers}
                    </Typography>
                  )}
                  {typeof stage.testResult.incorrectAnswers === 'number' && (
                    <Typography variant="body1">
                      Yanlış Cevap Sayısı: {stage.testResult.incorrectAnswers}
                    </Typography>
                  )}

                  <Typography variant="body1">
                    Anlama Oranı: {comprehensionRate}%
                  </Typography>
                </>
              ) : (
                <Typography variant="body1">
                  Test sonucu bulunamadı.
                </Typography>
              )}
            </Box>
          );
        })}

      </Box>

      {/* Genel Grafikler */}
      <Grid container spacing={4} sx={{ marginBottom: 4 }}>
        <Grid item xs={12} md={6}>
          <Card
            sx={{
              boxShadow: 6,
              borderRadius: 3,
              backgroundImage: 'linear-gradient(to right, #ffe259, #ffa751)',
              color: '#fff',
              transition: 'transform 0.3s',
              '&:hover': {
                transform: 'scale(1.02)',
                boxShadow: 8,
              },
            }}
          >
            <CardContent>
              <Typography
                variant="h6"
                gutterBottom
                sx={{ fontWeight: 'bold', color: '#fff' }}
              >
                Aşamaların Toplam Skorları
              </Typography>
              <ResponsiveContainer width="100%" height={300}>
                <BarChart
                  data={overallChartData}
                  margin={{ top: 20, right: 30, left: 0, bottom: 0 }}
                >
                  <CartesianGrid strokeDasharray="3 3" stroke="#fff" />
                  <XAxis dataKey="stageNumber" stroke="#fff" />
                  <YAxis stroke="#fff" />
                  <Tooltip
                    formatter={(value) => [value, 'Toplam Skor']}
                    contentStyle={{
                      backgroundColor: '#ffa751',
                      color: '#fff',
                      borderRadius: '10px',
                    }}
                  />
                  <Legend wrapperStyle={{ color: '#fff' }} />
                  <Bar dataKey="totalScore" name="Toplam Skor" fill="#ff5722" />
                </BarChart>
              </ResponsiveContainer>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} md={6}>
          <Card
            sx={{
              boxShadow: 6,
              borderRadius: 3,
              backgroundImage: 'linear-gradient(to right, #dd5e89, #f7bb97)',
              color: '#fff',
              transition: 'transform 0.3s',
              '&:hover': {
                transform: 'scale(1.02)',
                boxShadow: 8,
              },
            }}
          >
            <CardContent>
              <Typography
                variant="h6"
                gutterBottom
                sx={{ fontWeight: 'bold', color: '#fff' }}
              >
                Aşamaların Toplam Süreleri
              </Typography>
              <ResponsiveContainer width="100%" height={300}>
                <LineChart
                  data={overallChartData}
                  margin={{ top: 20, right: 30, left: 0, bottom: 0 }}
                >
                  <CartesianGrid strokeDasharray="3 3" stroke="#fff" />
                  <XAxis dataKey="stageNumber" stroke="#fff" />
                  <YAxis stroke="#fff" />
                  <Tooltip
                    formatter={(value) => [`${value} saniye`, 'Toplam Süre']}
                    contentStyle={{
                      backgroundColor: '#dd5e89',
                      color: '#fff',
                      borderRadius: '10px',
                    }}
                  />
                  <Legend wrapperStyle={{ color: '#fff' }} />
                  <Line
                    type="monotone"
                    dataKey="totalTime"
                    name="Toplam Süre (saniye)"
                    stroke="#fff"
                    activeDot={{ r: 8 }}
                  />
                </LineChart>
              </ResponsiveContainer>
            </CardContent>
          </Card>
        </Grid>

        {overallChartData.some((data) => data.totalWordCount > 0) && (
          <Grid item xs={12} md={6}>
            <Card
              sx={{
                boxShadow: 6,
                borderRadius: 3,
                backgroundImage: 'linear-gradient(to right, #43cea2, #185a9d)',
                color: '#fff',
                transition: 'transform 0.3s',
                '&:hover': {
                  transform: 'scale(1.02)',
                  boxShadow: 8,
                },
              }}
            >
              <CardContent>
                <Typography
                  variant="h6"
                  gutterBottom
                  sx={{ fontWeight: 'bold', color: '#fff' }}
                >
                  Aşamaların Toplam Kelime Sayıları
                </Typography>
                <ResponsiveContainer width="100%" height={300}>
                  <BarChart
                    data={overallChartData}
                    margin={{ top: 20, right: 30, left: 0, bottom: 0 }}
                  >
                    <CartesianGrid strokeDasharray="3 3" stroke="#fff" />
                    <XAxis dataKey="stageNumber" stroke="#fff" />
                    <YAxis stroke="#fff" />
                    <Tooltip
                      formatter={(value) => [value, 'Toplam Kelime Sayısı']}
                      contentStyle={{
                        backgroundColor: '#185a9d',
                        color: '#fff',
                        borderRadius: '10px',
                      }}
                    />
                    <Legend wrapperStyle={{ color: '#fff' }} />
                    <Bar dataKey="totalWordCount" name="Toplam Kelime Sayısı" fill="#ffeb3b" />
                  </BarChart>
                </ResponsiveContainer>
              </CardContent>
            </Card>
          </Grid>
        )}
      </Grid>

      {/* Aşamaların detaylı verileri için sekmeler */}
      <Box
        sx={{
          width: '100%',
          backgroundColor: 'rgba(255, 255, 255, 0.8)',
          boxShadow: 6,
          borderRadius: 3,
          padding: 2,
          marginBottom: 4,
        }}
      >
        <Tabs
          value={selectedStage}
          onChange={handleTabChange}
          variant="scrollable"
          scrollButtons="auto"
          indicatorColor="secondary"
          textColor="secondary"
        >
          {stages.map((stage, index) => (
            <Tab
              key={index}
              label={`Aşama ${stage.stageNumber}`}
              sx={{
                textTransform: 'none',
                fontWeight: 'bold',
                color: '#555',
                '&.Mui-selected': {
                  color: '#ff5722',
                },
              }}
            />
          ))}
        </Tabs>
      </Box>

      {chartData.length === 0 ? (
        <Box sx={{ marginTop: 4, textAlign: 'center' }}>
          <Typography variant="h6" color="#fff">
            Henüz tamamlanmış bir oyun bulunmamaktadır.
          </Typography>
        </Box>
      ) : (
        <Grid container spacing={4} sx={{ marginTop: 4 }}>
          <Grid item xs={12} md={6}>
            <Card
              sx={{
                boxShadow: 6,
                borderRadius: 3,
                backgroundImage: 'linear-gradient(to right, #8360c3, #2ebf91)',
                color: '#fff',
                transition: 'transform 0.3s',
                '&:hover': {
                  transform: 'scale(1.02)',
                  boxShadow: 8,
                },
              }}
            >
              <CardContent>
                <Typography
                  variant="h6"
                  gutterBottom
                  sx={{ fontWeight: 'bold', color: '#fff' }}
                >
                  Skor Grafiği
                </Typography>
                <ResponsiveContainer width="100%" height={300}>
                  <BarChart
                    data={chartData}
                    margin={{ top: 20, right: 30, left: 0, bottom: 0 }}
                  >
                    <CartesianGrid strokeDasharray="3 3" stroke="#fff" />
                    <XAxis dataKey="gameName" stroke="#fff" />
                    <YAxis stroke="#fff" />
                    <Tooltip
                      formatter={(value) => [value, 'Skor']}
                      contentStyle={{
                        backgroundColor: '#2ebf91',
                        color: '#fff',
                        borderRadius: '10px',
                      }}
                    />
                    <Legend wrapperStyle={{ color: '#fff' }} />
                    <Bar dataKey="score" name="Skor" fill="#ffeb3b" />
                  </BarChart>
                </ResponsiveContainer>
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12} md={6}>
            <Card
              sx={{
                boxShadow: 6,
                borderRadius: 3,
                backgroundImage: 'linear-gradient(to right, #ff7e5f, #feb47b)',
                color: '#fff',
                transition: 'transform 0.3s',
                '&:hover': {
                  transform: 'scale(1.02)',
                  boxShadow: 8,
                },
              }}
            >
              <CardContent>
                <Typography
                  variant="h6"
                  gutterBottom
                  sx={{ fontWeight: 'bold', color: '#fff' }}
                >
                  Süre Grafiği
                </Typography>
                <ResponsiveContainer width="100%" height={300}>
                  <LineChart
                    data={chartData}
                    margin={{ top: 20, right: 30, left: 0, bottom: 0 }}
                  >
                    <CartesianGrid strokeDasharray="3 3" stroke="#fff" />
                    <XAxis dataKey="gameName" stroke="#fff" />
                    <YAxis stroke="#fff" />
                    <Tooltip
                      formatter={(value) => [`${value} saniye`, 'Süre']}
                      contentStyle={{
                        backgroundColor: '#ff7e5f',
                        color: '#fff',
                        borderRadius: '10px',
                      }}
                    />
                    <Legend wrapperStyle={{ color: '#fff' }} />
                    <Line
                      type="monotone"
                      dataKey="time"
                      name="Süre (saniye)"
                      stroke="#fff"
                      activeDot={{ r: 8 }}
                    />
                  </LineChart>
                </ResponsiveContainer>
              </CardContent>
            </Card>
          </Grid>

          {chartData.some((data) => data.wordCount > 0) && (
            <Grid item xs={12} md={6}>
              <Card
                sx={{
                  boxShadow: 6,
                  borderRadius: 3,
                  backgroundImage: 'linear-gradient(to right, #00b4db, #0083b0)',
                  color: '#fff',
                  transition: 'transform 0.3s',
                  '&:hover': {
                    transform: 'scale(1.02)',
                    boxShadow: 8,
                  },
                }}
              >
                <CardContent>
                  <Typography
                    variant="h6"
                    gutterBottom
                    sx={{ fontWeight: 'bold', color: '#fff' }}
                  >
                    Kelime Sayısı Grafiği
                  </Typography>
                  <ResponsiveContainer width="100%" height={300}>
                    <BarChart
                      data={chartData}
                      margin={{ top: 20, right: 30, left: 0, bottom: 0 }}
                    >
                      <CartesianGrid strokeDasharray="3 3" stroke="#fff" />
                      <XAxis dataKey="gameName" stroke="#fff" />
                      <YAxis stroke="#fff" />
                      <Tooltip
                        formatter={(value) => [value, 'Kelime Sayısı']}
                        contentStyle={{
                          backgroundColor: '#0083b0',
                          color: '#fff',
                          borderRadius: '10px',
                        }}
                      />
                      <Legend wrapperStyle={{ color: '#fff' }} />
                      <Bar dataKey="wordCount" name="Kelime Sayısı" fill="#ff5722" />
                    </BarChart>
                  </ResponsiveContainer>
                </CardContent>
              </Card>
            </Grid>
          )}
        </Grid>
      )}
    </Box>
  );
}

export default StudentProgressReport;
