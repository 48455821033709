import React, { useState, useRef } from 'react';
import {
  Box,
  Typography,
  Button,
  CircularProgress,
} from '@mui/material';
import { styled } from '@mui/system';
import useFetchUserData from '../../../../hooks/ogrenci/useFetchUserData';

// ---------------------------------------------------
//  Basit Renk (Buton için)
// ---------------------------------------------------
const GOLD_ACCENT = '#C8A036';

// ---------------------------------------------------
//  Sayfa Dışı Container
// ---------------------------------------------------
const PageWrapper = styled(Box)({
  width: '100%',
  minHeight: '100vh',
  backgroundColor: '#eee',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '2rem 0',
  boxSizing: 'border-box',
});

// ---------------------------------------------------
//  iFrame Konteyner (A4'e yakın boyut)
// ---------------------------------------------------
const CertificateFrameContainer = styled(Box)({
  position: 'relative',
  width: '1000px',
  height: '1000px', // ~A4 oranı
  maxWidth: '90%',
  boxShadow: '0 0 10px rgba(0,0,0,0.3)',
  borderRadius: '12px',
  overflow: 'hidden',
  backgroundColor: '#fff',
});

// ---------------------------------------------------
//  iFrame (Canva Tasarım) — Arka plan
// ---------------------------------------------------
const StyledIframe = styled('iframe')({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  border: 'none',
});

// ---------------------------------------------------
//  Overlay — İçerik Katmanı (iFrame'in Üstünde)
// ---------------------------------------------------
const OverlayContainer = styled(Box)({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  // Eğer iFrame tıklanabilir olsun diyorsanız pointerEvents: 'none' ekleyin.
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
});

// ---------------------------------------------------
//  İçerik Sarmalayıcı (İstediğiniz metinleri ekleyeceğiniz alan)
// ---------------------------------------------------
const ContentWrapper = styled(Box)(({ theme }) => ({
  position: 'relative',
  zIndex: 2,
  padding: theme.spacing(4),
  textAlign: 'center',
  maxWidth: '80%',
  margin: '0 auto',
  color: '#333',
  backgroundColor: 'rgba(255,255,255,0.85)', // İstediğiniz şeffaflık
  borderRadius: '8px',
}));

// ---------------------------------------------------
//  Başlık, Kullanıcı Adı, Paragraflar ve Footer
// ---------------------------------------------------
const MainTitle = styled(Typography)(() => ({
  fontFamily: '"Cinzel", serif',
  fontWeight: 700,
  fontSize: '2.8rem',
  letterSpacing: '0.5px',
  marginBottom: '1.5rem',
  textTransform: 'uppercase',
}));

const UserName = styled(Typography)(() => ({
  fontFamily: '"Great Vibes", cursive',
  fontWeight: 400,
  fontSize: '3rem',
  color: GOLD_ACCENT,
  marginBottom: '1.5rem',
}));

const Paragraph = styled(Typography)(() => ({
  fontFamily: '"Merriweather", serif',
  fontSize: '1.2rem',
  lineHeight: 1.7,
  marginBottom: '1.5rem',
  maxWidth: '600px',
  margin: '0 auto',
}));

const SecondaryTitle = styled(Typography)(() => ({
  fontFamily: '"Merriweather", serif',
  fontSize: '1.3rem',
  fontWeight: 500,
  marginTop: '2rem',
}));

const FooterInfo = styled(Typography)(() => ({
  fontFamily: '"Merriweather", serif',
  marginTop: '2rem',
  textAlign: 'center',
  fontSize: '1rem',
}));

// ---------------------------------------------------
//  Ana Bileşen
// ---------------------------------------------------
function CertificateFancyDesign() {
  const [userData, setUserData] = useState(null);
  useFetchUserData(setUserData); // Öğrenci verilerini çekiyor

  const containerRef = useRef(null);

  // Yazdırma/PDF
  const handlePrintCustom = () => {
    window.print();
  };

  // Kullanıcı verileri yüklenene kadar Spinner göster
  if (!userData) {
    return (
      <Box
        sx={{
          height: '100vh',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  // Dinamik yıl
  const currentYear = new Date().getFullYear();

  // Yeni paylaştığınız Canva tasarımının embed linki:
  // => https://www.canva.com/design/DAGbPdtdbXY/R0MECu_fVeDomPxUZEVj4Q/view?embed
  const canvaEmbedUrl =
    'https://www.canva.com/design/DAGbPdtdbXY/R0MECu_fVeDomPxUZEVj4Q/view?embed';

  return (
    <PageWrapper>
      {/* Yazdır / PDF Butonu */}
      <Button
        variant="contained"
        sx={{
          fontWeight: 'bold',
          fontSize: '1rem',
          backgroundColor: GOLD_ACCENT,
          mb: 3,
          '&:hover': {
            backgroundColor: '#b8952b',
          },
        }}
        onClick={handlePrintCustom}
      >
        Sertifikayı Yazdır / PDF Olarak Kaydet
      </Button>

      {/* iFrame Arkaplan + Overlay İçerik */}
      <CertificateFrameContainer ref={containerRef}>
        <StyledIframe
          title="Canva Certificate Design"
          src={canvaEmbedUrl}
          allowFullScreen
        />

        <OverlayContainer>
          {/* Sadece istediğiniz "ContentWrapper" */}
          <ContentWrapper>
            <MainTitle>BAŞARI SERTİFİKASI</MainTitle>

            <UserName>Sn. {userData.ad} {userData.soyad}</UserName>

            <Paragraph>
              Bu sertifika, <strong>Okumaks</strong> tarafından düzenlenen
              <strong> “Anlayarak Hızlı Okuma Eğitimi” </strong> 21 saatlik aşama programına
              katılımı ve başarıyla tamamlaması sebebiyle kendilerine takdim edilmiştir.
            </Paragraph>

            

            <SecondaryTitle>Başarılarınızın devamını dileriz.</SecondaryTitle>

            <FooterInfo>
              <strong>Okumaks</strong>
              <br />
              {currentYear}
            </FooterInfo>
          </ContentWrapper>
        </OverlayContainer>
      </CertificateFrameContainer>
    </PageWrapper>
  );
}

export default CertificateFancyDesign;
