import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import GameOverModal from './components/GameOverModal';
import GameHeader from './components/GameHeader';
import { API_URLS } from '../../../config/config';
import './styles/hangiHarfiGordun.css';
import axiosInstance from '../../../utils/axiosInstance'; 
import axios from 'axios'; 

function HangiHarfiGordun() {
  const navigate = useNavigate();
  const location = useLocation();
  const { stage, exercise } = location.state || {};

  const [difficulty, setDifficulty] = useState('kolay');
  const [letters, setLetters] = useState([]);
  const [input, setInput] = useState('');
  const [score, setScore] = useState(0);
  const [gameActive, setGameActive] = useState(false);
  const [timeLeft, setTimeLeft] = useState(15);
  const [showLetters, setShowLetters] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showIntroduction, setShowIntroduction] = useState(true);
  const canvasRef = useRef(null);
  const inputRef = useRef(null);
  const [attempts, setAttempts] = useState([]);

  const [userData, setUserData] = useState(null);
  const [userRole, setUserRole] = useState(null);
  
  const [totalTime, setTotalTime] = useState(15);

  // Harflerin ekranda görünme süresi (ms)
  const [displayDuration, setDisplayDuration] = useState(1000);

  // userData gelince totalTime'ı güncelle
  useEffect(() => {
    if (userData && userData.status === 'active') {
      setTotalTime(40);
      setTimeLeft(40);
    } else {
      setTotalTime(15);
      setTimeLeft(15);
    }
  }, [userData]);

  // Kullanıcı verilerini alırken önce öğrenci, ardından öğretmen kontrolü
  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const studentResponse = await axiosInstance.get(`${API_URLS.STUDENT_URL}/me`);
        if (
          studentResponse.status === 200 &&
          studentResponse.data &&
          studentResponse.data.status === 'active'
        ) {
          const studentData = studentResponse.data;
          setUserData(studentData);
          setUserRole('student');
          return;
        } else {
          setUserData(null);
          setUserRole(null);
        }
      } catch (error) {
        console.error('Öğrenci verisi alınırken hata oluştu:', error);
      }
      
      // Öğrenci yoksa öğretmen verisine bak
      try {
        const token = localStorage.getItem('userToken');
        const teacherResponse = await fetch(`${API_URLS.TEACHER_URL}/me`, {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });
        if (teacherResponse.ok) {
          const teacherData = await teacherResponse.json();
          setUserData(teacherData);
          setUserRole('teacher');
          return;
        }
      } catch (error) {
        console.error('Öğretmen verisi alınırken hata oluştu:', error);
      }

      setUserData(null);
      setUserRole(null);
    };

    fetchUserData();
  }, []);

  useEffect(() => {
    if (gameActive && timeLeft > 0) {
      const timer = setTimeout(() => setTimeLeft(timeLeft - 1), 1000);
      return () => clearTimeout(timer);
    } else if (timeLeft === 0) {
      onGameOver();
    }
  }, [timeLeft, gameActive]);

  useEffect(() => {
    if (gameActive && showLetters) {
      drawCanvas();
    } else {
      clearCanvas();
    }
  }, [gameActive, showLetters, letters]);

  useEffect(() => {
    if (gameActive) {
      generateLetters();
    }
  }, [gameActive]);

  useEffect(() => {
    if (gameActive && !showLetters && inputRef.current) {
      inputRef.current.focus();
    }
  }, [gameActive, showLetters]);

  const enterGame = () => {
    setShowIntroduction(false);
  };

  const startGame = () => {
    setScore(0);
    setTimeLeft(totalTime);
    setGameActive(true);
    setAttempts([]);
  };

  const stopGame = () => {
    setGameActive(false);
  };

  const resetGame = () => {
    clearCanvas();
    setGameActive(false);
    setScore(0);
    setTimeLeft(totalTime);
    setLetters([]);
    setInput('');
    setShowLetters(false);
    setShowModal(false);
    setShowIntroduction(false);
    setAttempts([]);
  };

  const onGameOver = () => {
    setGameActive(false);
    setShowModal(true);
    updateProgress();
  };

  const generateLetters = () => {
    if (!canvasRef.current) return;

    // Zorluk seviyesine göre harf sayısı
    let letterCount = 2;
    switch (difficulty) {
      case 'kolay':
        letterCount = 2;
        break;
      case 'orta':
        letterCount = 3;
        break;
      case 'zor':
        letterCount = Math.floor(Math.random() * 2) + 4; // 4 veya 5
        break;
      default:
        letterCount = 2;
    }

    const canvas = canvasRef.current;
    const centerX = canvas.width / 2;
    const centerY = canvas.height / 2;

    // Kenar boşlukları (margin) ayarlayalım
    const margin = 60;
    const maxDimension = Math.min(canvas.width, canvas.height);
    const maxAllowedRadius = (maxDimension / 2) - margin;
    const minRadius = 50;

    // Zaman ilerledikçe yarıçapı artırmak istersek
    const elapsed = totalTime - timeLeft;
    const baseRadius = minRadius + ((maxAllowedRadius - minRadius) * elapsed) / totalTime;

    const lettersArray = [];
    const positions = [];

    // Harfleri sağa/sola dağıtmak için yön
    let directions = [];

    if (letterCount === 2) {
      directions = [1, -1];
    } else {
      directions = [1, -1];
      for (let i = 2; i < letterCount; i++) {
        directions.push(Math.random() < 0.5 ? -1 : 1);
      }
      directions = shuffleArray(directions);
    }

    for (let i = 0; i < letterCount; i++) {
      let direction = directions[i];

      // radius hesapla
      let radius = baseRadius + i * 30;
      // Canvas'ın dışına taşmaması için kısıtlayalım
      if (radius > maxAllowedRadius) {
        radius = maxAllowedRadius;
      }

      const x = centerX + direction * radius;
      const y = centerY;

      // Yakın pozisyon çakışmasını önle
      const overlap = positions.some((pos) => {
        const dx = pos.x - x;
        const distance = Math.abs(dx);
        return distance < 30;
      });

      if (!overlap) {
        positions.push({ x });
        const turkishAlphabet = [
          'A','B','C','Ç','D','E','F','G','Ğ','H','I','İ',
          'J','K','L','M','N','O','Ö','P','R','S','Ş','T',
          'U','Ü','V','Y','Z',
        ];
        const randomLetter = turkishAlphabet[Math.floor(Math.random() * turkishAlphabet.length)];
        lettersArray.push({ value: randomLetter, x, y });
      } else {
        i--;
      }
    }

    setLetters(lettersArray);
    setShowLetters(true);

    // Harfleri displayDuration süresi kadar göster
    setTimeout(() => {
      setShowLetters(false);
      clearCanvas();
    }, displayDuration);
  };

  const shuffleArray = (array) => {
    const shuffled = [...array];
    for (let i = shuffled.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [shuffled[i], shuffled[j]] = [shuffled[j], shuffled[i]];
    }
    return shuffled;
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Kullanıcının girdiği harfler
    let userInput = input
      .replace(/[^a-zA-ZığüşöçİĞÜŞÖÇ]/gi, '')
      .toLocaleUpperCase('tr')
      .split('');

    // Doğru harfler
    let correctLetters = letters.map((letterObj) =>
      letterObj.value.toLocaleUpperCase('tr')
    );

    // Karşılaştırma için sıralayalım
    userInput.sort();
    correctLetters.sort();

    const isCorrect =
      userInput.length === correctLetters.length &&
      userInput.every((letter, idx) => letter === correctLetters[idx]);

    setAttempts([
      ...attempts,
      {
        correctLetters: [...correctLetters],
        userInput: [...userInput],
        isCorrect,
      },
    ]);

    if (isCorrect) {
      setScore(score + 1);
      setInput('');
      generateLetters();
    } else {
      alert('Yanlış cevap!');
      setInput('');
      generateLetters();
    }
  };

  const drawCanvas = () => {
    const canvas = canvasRef.current;
    if (!canvas) return;
    const ctx = canvas.getContext('2d');
    const centerX = canvas.width / 2;
    const centerY = canvas.height / 2;

    clearCanvas();

    // Ortadaki küçük nokta (isteğe bağlı)
    ctx.beginPath();
    ctx.arc(centerX, centerY, 10, 0, 2 * Math.PI);
    ctx.fillStyle = '#888';
    ctx.fill();

    // Harfleri çiz
    letters.forEach((letterObj) => {
      const x = letterObj.x;
      const y = letterObj.y;

      ctx.fillStyle = '#ffcc00';
      ctx.font = 'bold 40px Arial';
      ctx.textAlign = 'center';
      ctx.textBaseline = 'middle';
      ctx.fillText(letterObj.value, x, y);
    });
  };

  const clearCanvas = () => {
    const canvas = canvasRef.current;
    if (canvas) {
      const ctx = canvas.getContext('2d');
      ctx.clearRect(0, 0, canvas.width, canvas.height);
    }
  };

  const handleReturnToPreviousStage = () => {
    if (stage) {
      navigate(
        userRole === 'student' ? '/ogrenci-dashboard' : '/ogretmen-dashboard',
        {
          state: {
            selectedMenu: 'ExerciseList',
            selectedStage: stage,
          },
        }
      );
    } else {
      alert('Aşama bilgisi bulunmamaktadır.');
    }
  };

  const updateProgress = async () => {
    if (!userData || !userRole) return;
    const gameTime = totalTime - timeLeft;
    try {
      const payload = {
        stageNumber: stage?.number || 1,
        gameName: exercise?.gameName || 'Hangi Harfi Gördün?',
        score: score,
        time: gameTime,
        completed: true,
      };

      if (userRole === 'student') {
        // Öğrenci: cookie üzerinden token gidiyor, axiosInstance kullan
        const apiUrl = `${API_URLS.STUDENT_URL}akillikurs/progress/update-game`;
        await axiosInstance.post(apiUrl, payload);
      } else {
        // Öğretmen: localStorage token ile Authorization header ekle
        const token = localStorage.getItem('userToken');
        const apiUrl = `${API_URLS.TEACHER_URL}akillikurs/progress/update-game`;
        await axios.post(apiUrl, payload, {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });
      }
    } catch (error) {
      console.error('İlerleme güncellenirken hata oluştu:', error);
    }
  };

  const handleDifficultyChange = (e) => {
    const newDifficulty = e.target.value;
    setDifficulty(newDifficulty);
    if (gameActive) {
      resetGame();
    }
  };

  const handleDisplayDurationChange = (e) => {
    const newDuration = Number(e.target.value);
    setDisplayDuration(newDuration);
  };

  const handleClose = () => {
    setShowModal(false);
  };

  return (
    <div className="hangi-harfi-gordun-container">
      <GameHeader handleReturnToPreviousStage={handleReturnToPreviousStage} />
      {showIntroduction ? (
        <div className="hangi-harfi-gordun-intro">
          <div className="hangi-harfi-gordun-card">
            <div className="hangi-harfi-gordun-card-body">
              <h1 className="hangi-harfi-gordun-title">Hangi Harfi Gördün?</h1>
              <p className="hangi-harfi-gordun-description">
                Bu egzersizde, ekranda kısa bir süreliğine gösterilen harfleri
                hatırlayıp doğru şekilde girmeye çalışacaksınız.
              </p>
              <div className="hangi-harfi-gordun-instructions">
                <h3>Talimatlar</h3>
                <ul>
                  <li>Harfler ekranda belirecek, dikkatlice izleyin.</li>
                  <li>Harfler kaybolduktan sonra gördüğünüz harfleri girin.</li>
                  <li>Zorluk seviyesini seçerek egzersizi özelleştirebilirsiniz.</li>
                </ul>
              </div>
              <button
                onClick={enterGame}
                className="hangi-harfi-gordun-button hangi-harfi-gordun-start-button"
              >
                BAŞLAT
              </button>
            </div>
          </div>
        </div>
      ) : (
        <div className="hangi-harfi-gordun-gameplay">
          <div className="hangi-harfi-gordun-card">
            <div className="hangi-harfi-gordun-card-body">
              <div className="hangi-harfi-gordun-settings">
                <div className="hangi-harfi-gordun-setting">
                  <label htmlFor="difficultySelect">Zorluk Seviyesi:</label>
                  <select
                    id="difficultySelect"
                    value={difficulty}
                    onChange={handleDifficultyChange}
                  >
                    <option value="kolay">Kolay</option>
                    <option value="orta">Orta</option>
                    <option value="zor">Zor</option>
                  </select>
                </div>
                <div className="hangi-harfi-gordun-setting">
                  <label htmlFor="displayDurationRange">Gösterim Süresi:</label>
                  <input
                    type="range"
                    id="displayDurationRange"
                    min="500"
                    max="5000"
                    step="500"
                    value={displayDuration}
                    onChange={handleDisplayDurationChange}
                  />
                  <span>{displayDuration} ms</span>
                </div>
                <div className="hangi-harfi-gordun-buttons">
                  {gameActive ? (
                    <>
                      <button onClick={stopGame} className="hangi-harfi-gordun-button">
                        Durdur
                      </button>
                      <button onClick={resetGame} className="hangi-harfi-gordun-button">
                        Yeniden Başlat
                      </button>
                    </>
                  ) : (
                    <>
                      <button onClick={startGame} className="hangi-harfi-gordun-button">
                        Başlat
                      </button>
                      <button onClick={resetGame} className="hangi-harfi-gordun-button">
                        Yeniden Başlat
                      </button>
                    </>
                  )}
                </div>
              </div>
              <div className="hangi-harfi-gordun-status">
                <p>Kalan Süre: {timeLeft} saniye</p>
                <p>Skor: {score}</p>
              </div>
              <div className="hangi-harfi-gordun-canvas-container">
                {/* Canvas boyutunu 600×600 yaptık */}
                <canvas
                  ref={canvasRef}
                  width={600}
                  height={600}
                  className="hangi-harfi-gordun-canvas"
                  style={{ display: gameActive ? 'block' : 'none' }}
                />
                {gameActive && !showLetters && (
                  <form onSubmit={handleSubmit} className="hangi-harfi-gordun-input-form">
                    <input
                      ref={inputRef}
                      type="text"
                      value={input}
                      onChange={(e) =>
                        setInput(e.target.value.toLocaleUpperCase('tr'))
                      }
                      placeholder="Gördüğün harfleri gir (bitişik veya boşluklu)"
                      style={{ textTransform: 'uppercase' }}
                    />
                    <button type="submit" className="hangi-harfi-gordun-button">
                      Gönder
                    </button>
                  </form>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
      {showModal && (
        <GameOverModal
          score={score}
          onRestart={resetGame}
          userData={userData}
          onClose={handleClose}
          attempts={attempts}
          totalTime={totalTime}
        />
      )}
    </div>
  );
}

export default HangiHarfiGordun;
