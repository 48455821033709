import React from 'react';
import {Container, Typography, Box, Stack, Card, CardActionArea, CardContent,} from '@mui/material';
import Header from '../home/components/Header';
import Footer from '../home/components/Footer';
import { useNavigate } from 'react-router-dom';
import StarIcon from '@mui/icons-material/Star';
import TimelineIcon from '@mui/icons-material/Timeline';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import '../assets/Egzersiz.css';

function Egzersiz() {
  const navigate = useNavigate();

  const menuItems = [


    {
      title: 'Noktayı Takip Et',
      description:
        'Gözün sayfa üzerindeki hareketlendirini hızlandırmak, göz kaslarını güçlendirmek',
      path: '/nokta-takip-et',
      icon: <TimelineIcon sx={{ fontSize: 60, color: '#fff' }} />,
    },
    {
      title: 'Nesneyi Takip Et',
      description:
        'Gözün sayfa üzerindeki hareketlendirini hızlandırmak, göz kaslarını güçlendirmek',
      path: '/nesne-takip-et',
      icon: <TimelineIcon sx={{ fontSize: 60, color: '#fff' }} />,
    },
    {
      title: 'Zigzag',
      description:
        'Zigzag göz takibi egzersizi, göz koordinasyonunu ve odaklanma yeteneğini artırarak göz yorgunluğunu azaltır.',
      path: '/zigzag',
      icon: <TimelineIcon sx={{ fontSize: 60, color: '#fff' }} />,
    },
    {
      title: 'Harf Döngüsü',
      description:
        'Harf Döngüsü, hızlı düşünmeyi ve kelime becerilerini geliştirmek için tasarlanmış, dinamik bir dikkat çalışmasıdır.',
      path: '/harf-dongusu',
      icon: <HelpOutlineIcon sx={{ fontSize: 60, color: '#fff' }} />,
    },
    {
      title: 'Harf Döngüsü 2',
      description:
        'Harf Döngüsü 2, hızlı düşünmeyi ve kelime becerilerini geliştirmek için tasarlanmış, dinamik bir dikkat çalışmasıdır.',
      path: '/harf-dongusu-second',
      icon: <HelpOutlineIcon sx={{ fontSize: 60, color: '#fff' }} />,
    },
    {
      title: 'Hangi Sayıyı Gördün',
      description:
        'Görüş alanını genişletmek görüş çalışmasıdır. Ekranda beliren sayıları tahmin edin.',
      path: '/sayi-tahmin-et',
      icon: <HelpOutlineIcon sx={{ fontSize: 60, color: '#fff' }} />,
    },
    {
      title: 'Hangi Harfi Gördün',
      description:
        'Görüş alanını genişletmek görüş çalışmasıdır. Ekranda beliren harfleri tahmin edin.',
      path: '/gordugun-harfi-tahmin-et',
      icon: <HelpOutlineIcon sx={{ fontSize: 60, color: '#fff' }} />,
    },

    {
      title: 'Cümleyi Oku',
      description:
        'Blok okumaya alışarak, göz kaslarını güçlendirmek',
      path: '/cumleyi-oku',
      icon: <TimelineIcon sx={{ fontSize: 60, color: '#fff' }} />,
    },

    {
      title: 'Kelime Bulmaca',
      description:
        'Sizden istenen kelimeleri bulup tıklayın',
      path: '/kelime-bulmaca',
      icon: <TimelineIcon sx={{ fontSize: 60, color: '#fff' }} />,
    },

  ];

  const handleCardClick = (path) => {
    navigate(path);
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
      <Header />
      <div className="bg-main">
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            backgroundColor: 'transparent',
            py: 5,
            px: 2,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Container
            maxWidth="md"
            className="main-container"
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              borderRadius: '12px',
              color: '#fff', // Yazı rengini beyaz yaptık
              backgroundColor: '#1a1a1a', // Arka plan rengini siyah yaptık
              boxShadow: '0 4px 12px rgba(0,0,0,0.5)',
              p: { xs: 2, sm: 4 },
              width: '100%',
              maxWidth: '100%',
            }}
          >
            <Typography
              variant="h4"
              component="h1"
              sx={{
                mb: 4,
                fontWeight: 'bold',
                color: '#ff6600', // Başlık rengini turuncu yaptık
              }}
              align="center"
            >
              Egzersizler
            </Typography>

            {/* Scrollbar eklemek için Box ile sarmalama */}
            <Box
              sx={{
                maxHeight: '60vh',
                overflowY: 'auto',
                width: '100%',
                '&::-webkit-scrollbar': {
                  width: '8px',
                },
                '&::-webkit-scrollbar-track': {
                  background: '#333',
                  borderRadius: '10px',
                },
                '&::-webkit-scrollbar-thumb': {
                  background: '#ff6600',
                  borderRadius: '10px',
                },
              }}
            >
              <Stack
                direction="column"
                spacing={3}
                sx={{
                  width: '100%',
                }}
              >
                {menuItems.map((item, index) => (
                  <Card
                    key={index}
                    sx={{
                      borderRadius: '15px',
                      backgroundColor: '#2c2c2c', // Kart arka planı koyu gri
                      boxShadow: '0 4px 12px rgba(0,0,0,0.3)',
                      transition: 'transform 0.3s, box-shadow 0.3s',
                      '&:hover': {
                        transform: 'translateY(-5px)',
                        boxShadow: '0 8px 20px rgba(0,0,0,0.5)',
                      },
                    }}
                  >
                    <CardActionArea
                      onClick={() => handleCardClick(item.path)}
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      <Box
                        sx={{
                          p: 2,
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          background: '#ff6600', // İkon arka planı turuncu
                          borderRadius: '15px 0 0 15px',
                          minWidth: '100px',
                        }}
                      >
                        {item.icon}
                      </Box>
                      <CardContent sx={{ flexGrow: 1, padding: '16px' }}>
                        <Typography
                          variant="h6"
                          sx={{
                            fontWeight: '600',
                            color: '#ffcc00', // Başlık rengini açık turuncu yaptık
                          }}
                        >
                          {item.title}
                        </Typography>
                        <Typography variant="body2" sx={{ color: '#ddd', mt: 1 }}>
                          {item.description}
                        </Typography>
                      </CardContent>
                    </CardActionArea>
                  </Card>
                ))}
              </Stack>
            </Box>
          </Container>
        </Box>
      </div>
      <Footer />
    </Box>
  );
}

export default Egzersiz;
