// GameStatus.js

import React from 'react';
import '../styles/harfIzgarasiStyle.css';

const GameStatus = ({ timeRemaining, score, difficulty, handleDifficultyChange, difficultyLocked }) => {
  return (
    <div className="harf-izgarasi-status">
      <div className="harf-izgarasi-timer-score">
        <div>
          <strong>Kalan Süre: </strong>
          <span>{timeRemaining} sn</span>
        </div>
        <div>
          <strong>Skor: </strong>
          <span>{score}</span>
        </div>
      </div>
      <div className="harf-izgarasi-game-controls">
        {difficultyLocked ? (
          <div className="harf-izgarasi-locked-difficulty">
            <strong>
              {difficulty === 'easy' ? 'Kolay' : difficulty === 'medium' ? 'Orta' : 'Zor'}
            </strong>
          </div>
        ) : (
          <select
            id="difficultySelect"
            value={difficulty}
            onChange={handleDifficultyChange}
            className="harf-izgarasi-button"
          >
            <option value="easy">Kolay</option>
            <option value="medium">Orta</option>
            <option value="hard">Zor</option>
          </select>
        )}
      </div>
    </div>
  );
};

export default GameStatus;
