import React from 'react';
import {
  Grid,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
} from '@mui/material';

const FiltrelemeAlanlari = ({
  searchQuery,
  setSearchQuery,
  statusFilter,
  setStatusFilter,
  cityFilter,
  setCityFilter,
  ilceFilter,
  setIlceFilter,
  sehirListesi,
}) => {
  return (
    <Grid container spacing={2} alignItems="center">

      {/* Tüm sütunlar için genel arama alanı */}
      <Grid item xs={12} sm={6} md={4}>
        <TextField
          fullWidth
          label="Ara..."
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          margin="normal"
        />
      </Grid>

      {/* Durum Filtreleme */}
      <Grid item xs={12} sm={6} md={4}>
        <FormControl fullWidth margin="normal">
          <InputLabel id="status-filter-label">Durum</InputLabel>
          <Select
            labelId="status-filter-label"
            value={statusFilter}
            onChange={(e) => setStatusFilter(e.target.value)}
            displayEmpty
          >
            <MenuItem value="">
              <em>Tüm Durumlar</em>
            </MenuItem>
            <MenuItem value="active">Aktif</MenuItem>
            <MenuItem value="inactive">Pasif</MenuItem>
          </Select>
        </FormControl>
      </Grid>

      {/* Şehir Filtreleme */}
      <Grid item xs={12} sm={6} md={4}>
        <FormControl fullWidth margin="normal">
          <InputLabel id="city-filter-label">Şehir</InputLabel>
          <Select
            labelId="city-filter-label"
            value={cityFilter}
            onChange={(e) => {
              setCityFilter(e.target.value);
              setIlceFilter('');
            }}
            displayEmpty
          >
            <MenuItem value="">
              <em>Tüm Şehirler</em>
            </MenuItem>
            {sehirListesi.map((sehir) => (
              <MenuItem key={sehir._id} value={sehir._id}>
                {sehir.sehir_adi}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>

      {/* İlçe Filtreleme (Şehir seçiliyse göster) */}
      {cityFilter && (
        <Grid item xs={12} sm={6} md={4}>
          <FormControl fullWidth margin="normal">
            <InputLabel id="ilce-filter-label">İlçe</InputLabel>
            <Select
              labelId="ilce-filter-label"
              value={ilceFilter}
              onChange={(e) => setIlceFilter(e.target.value)}
              displayEmpty
            >
              <MenuItem value="">
                <em>Tüm İlçeler</em>
              </MenuItem>
              {sehirListesi
                .find((sehir) => sehir._id === cityFilter)
                ?.ilceler.map((ilce) => (
                  <MenuItem key={ilce._id} value={ilce._id}>
                    {ilce.ilce_adi}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </Grid>
      )}

      {/* Filtreleri Sıfırla Butonu */}
      <Grid item xs={12} sm={6} md={4}>
        <Button
          fullWidth
          variant="outlined"
          color="secondary"
          onClick={() => {
            setSearchQuery('');
            setStatusFilter('');
            setCityFilter('');
            setIlceFilter('');
          }}
          sx={{ marginTop: '16px' }}
        >
          Filtreleri Sıfırla
        </Button>
      </Grid>
    </Grid>
  );
};

export default FiltrelemeAlanlari;
