import React, { useState } from 'react';
import {Paper, Table, TableHead, TableBody, TableRow, TableCell, TableContainer, TablePagination, Button, Menu, MenuItem, Typography, IconButton, Collapse, Chip, TextField,
  Stack,} from '@mui/material';
import { KeyboardArrowDown, KeyboardArrowUp, Delete as DeleteIcon, Edit as EditIcon, AttachMoney as AttachMoneyIcon, Assignment as AssignmentIcon,} from '@mui/icons-material';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import * as XLSX from 'xlsx';
import SonucDialogu from './SonucDialogu';
import OgretmenAtamaDialog from './OgretmenAtamaDialog'; 
import { API_URLS } from '../../../../config/config';
import OgretmenRaporuModal from './OgretmenRaporuModal';

/**
 * Tek öğretmen satırını collapsible olarak gösteren alt bileşen
 */
function CollapsibleOgretmenRow({
  index,
  ogretmen,
  page,
  rowsPerPage,
  handleStatusChange,
  handleEdit,
  handleDeleteOgretmen,
  handleAdjustBalance,
  handleOpenAtamaDialog,      
  handleOpenRapor,            
  newEndDates,
  setNewEndDates,
  handleUpdateEndDate,
}) {
  const [open, setOpen] = useState(false);

  // Toplam bakiye
  const toplamBakiye =
    (ogretmen.bakiye3Ay || 0) + (ogretmen.bakiye6Ay || 0) + (ogretmen.bakiye12Ay || 0);

  // Ekleyen
  let ekleyen = 'Kendi';
  if (ogretmen.adminAdi && ogretmen.adminAdi !== 'Bulunamadı' && ogretmen.adminAdi !== 'null') {
    ekleyen = ogretmen.adminAdi;
  } else if (
    ogretmen.okulAdi &&
    ogretmen.okulAdi !== 'Bulunamadı' &&
    ogretmen.okulAdi !== 'null'
  ) {
    ekleyen = ogretmen.okulAdi;
  } else if (
    ogretmen.kurumAdi &&
    ogretmen.kurumAdi !== 'Bulunamadı' &&
    ogretmen.kurumAdi !== 'null'
  ) {
    ekleyen = ogretmen.kurumAdi;
  }

  // Kayıt tarihi
  const kayitTarihi = ogretmen.createdAt ? new Date(ogretmen.createdAt) : null;
  const kayitTarihiMetin = kayitTarihi ? kayitTarihi.toLocaleDateString() : 'Yok';

  // En yeni abonelik
  let abonelikBaslangic = 'Yok';
  let abonelikBitis = 'Yok';
  if (ogretmen.abonelikler && ogretmen.abonelikler.length > 0) {
    const latest = ogretmen.abonelikler.reduce((acc, curr) =>
      new Date(curr.baslangicTarihi) > new Date(acc.baslangicTarihi) ? curr : acc
    );
    abonelikBaslangic = new Date(latest.baslangicTarihi).toLocaleDateString();
    abonelikBitis = new Date(latest.bitisTarihi).toLocaleDateString();
  } else if (ogretmen.status === 'active' && kayitTarihi) {
    // Varsayılan 1 yıllık
    const endDate = new Date(kayitTarihi);
    endDate.setFullYear(endDate.getFullYear() + 1);
    abonelikBaslangic = kayitTarihi.toLocaleDateString();
    abonelikBitis = endDate.toLocaleDateString();
  }

  return (
    <>
      {/* ANA SATIR */}
      <TableRow>
        <TableCell>
          <IconButton size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
          </IconButton>
        </TableCell>
        <TableCell>{page * rowsPerPage + index + 1}</TableCell>
        <TableCell>{ekleyen}</TableCell>
        <TableCell>{ogretmen.ad}</TableCell>
        <TableCell>{ogretmen.soyad}</TableCell>
        <TableCell>{ogretmen.email}</TableCell>
        <TableCell>{ogretmen.password_open}</TableCell>
        <TableCell>{ogretmen.telefon}</TableCell>
        <TableCell>{ogretmen.okul_adi || ''}</TableCell>   
        <TableCell>
          <Chip
            label={ogretmen.status === 'active' ? 'Aktif' : 'Pasif'}
            color={ogretmen.status === 'active' ? 'success' : 'error'}
            onClick={() => handleStatusChange(ogretmen._id, ogretmen.status)}
            sx={{ cursor: 'pointer', '&:hover': { backgroundColor: 'lightgray' } }}
          />
        </TableCell>
        <TableCell>{ogretmen.aktifEdenAdi}</TableCell>
        <TableCell>{kayitTarihiMetin}</TableCell>

        <TableCell>
          <Stack direction="row" spacing={1}>
            {/* Bakiye Ayarla */}
            <IconButton
              onClick={() => handleAdjustBalance(ogretmen)}
              color="success"
              size="small"
              sx={{
                backgroundColor: '#4caf50',
                color: '#fff',
                '&:hover': {
                  backgroundColor: '#388e3c',
                  transform: 'scale(1.1)',
                },
                borderRadius: '8px',
              }}
            >
              <AttachMoneyIcon fontSize="small" />
            </IconButton>

            {/* Düzenle */}
            <IconButton
              onClick={() => handleEdit(ogretmen._id)}
              color="primary"
              size="small"
              sx={{
                backgroundColor: '#1976d2',
                color: '#fff',
                '&:hover': {
                  backgroundColor: '#1565c0',
                  transform: 'scale(1.1)',
                },
                borderRadius: '8px',
              }}
            >
              <EditIcon fontSize="small" />
            </IconButton>

            {/* Sil */}
            <IconButton
              onClick={() => handleDeleteOgretmen(ogretmen._id)}
              color="error"
              size="small"
              sx={{
                backgroundColor: '#f44336',
                color: '#fff',
                '&:hover': {
                  backgroundColor: '#d32f2f',
                  transform: 'scale(1.1)',
                },
                borderRadius: '8px',
              }}
            >
              <DeleteIcon fontSize="small" />
            </IconButton>

            {/* Atama */}
            <IconButton
              onClick={() => handleOpenAtamaDialog(ogretmen)}
              color="warning"
              size="small"
              sx={{
                backgroundColor: '#ff9800',
                color: '#fff',
                '&:hover': {
                  backgroundColor: '#fb8c00',
                  transform: 'scale(1.1)',
                },
                borderRadius: '8px',
              }}
            >
              <AssignmentIcon fontSize="small" />
            </IconButton>

            {/* RAPOR (YENİ) */}
            <Button
              variant="outlined"
              size="small"
              color="secondary"
              onClick={() => handleOpenRapor(ogretmen._id)}
            >
              Rapor
            </Button>
          </Stack>
        </TableCell>
      </TableRow>

      {/* DETAY SATIR (Collapse) */}
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={15}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Paper sx={{ margin: 1, padding: 2, backgroundColor: '#f5f5f5' }}>
              <Typography variant="h6" gutterBottom component="div">
                Detay Bilgileri
              </Typography>
              <Typography variant="body1">
                <strong>Şehir:</strong> {ogretmen.sehirAdi}
              </Typography>

              <Typography variant="body1">
                <strong>İlçe:</strong> {ogretmen.ilceAdi}
              </Typography>

              <Typography variant="body1">
                <strong>Bakiye Toplam:</strong> {toplamBakiye}
              </Typography>
              <Typography variant="body1">
                <strong>Bakiye 3 Ay / 6 Ay / 12 Ay:</strong>{' '}
                {ogretmen.bakiye3Ay || 0} / {ogretmen.bakiye6Ay || 0} / {ogretmen.bakiye12Ay || 0}
              </Typography>
              <Typography variant="body1">
                <strong>Abonelik Başlangıç:</strong> {abonelikBaslangic}
              </Typography>
              <Typography variant="body1">
                <strong>Abonelik Bitiş:</strong> {abonelikBitis}
              </Typography>
              {/* Yeni bitiş tarihi seçici */}
              <div style={{ marginTop: 12 }}>
                <DatePicker
                  selected={newEndDates[ogretmen._id] || null}
                  onChange={(date) => setNewEndDates({ ...newEndDates, [ogretmen._id]: date })}
                  dateFormat="dd/MM/yyyy"
                  placeholderText="Yeni Bitiş Tarihi"
                  customInput={
                    <TextField variant="outlined" size="small" label="Yeni Bitiş Tarihi" />
                  }
                />
                <Button
                  variant="contained"
                  color="primary"
                  sx={{ marginLeft: 1, marginTop: 1 }}
                  onClick={() => handleUpdateEndDate(ogretmen._id, newEndDates[ogretmen._id])}
                >
                  Tarihi Güncelle
                </Button>
              </div>
            </Paper>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}

const OgretmenListesi = ({
  ogretmenListesi,
  handleStatusChange,
  handleEdit,
  handleDeleteOgretmen,
  handleAdjustBalance,
}) => {
  // Sayfalama
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);

  // Export menüsü anchor
  const [anchorEl, setAnchorEl] = useState(null);

  // İşlem sonucu diyaloğu
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogContent, setDialogContent] = useState('');
  const [dialogSeverity, setDialogSeverity] = useState('info');

  // Bitiş tarihi
  const [newEndDates, setNewEndDates] = useState({});

  // YENİ: Atama Dialogu (opsiyonel)
  const [atamaDialogOpen, setAtamaDialogOpen] = useState(false);
  const [selectedOgretmen, setSelectedOgretmen] = useState(null);

  // YENİ: Rapor Modal
  const [raporModalOpen, setRaporModalOpen] = useState(false);
  const [selectedTeacherId, setSelectedTeacherId] = useState('');

  // Atama dialogunu aç/kapat
  const handleOpenAtamaDialog = (ogretmen) => {
    setSelectedOgretmen(ogretmen);
    setAtamaDialogOpen(true);
  };
  const handleCloseAtamaDialog = () => {
    setAtamaDialogOpen(false);
    setSelectedOgretmen(null);
  };


const [selectedTeacherName, setSelectedTeacherName] = useState('');
  // Rapor aç/kapat
  const handleOpenRapor = (teacherId, teacherFullName) => {
    setSelectedTeacherId(teacherId);
    setSelectedTeacherName(teacherFullName); // örn: "Ahmet Yılmaz"
    setRaporModalOpen(true);
  };
  const handleCloseRapor = () => {
    setRaporModalOpen(false);
    setSelectedTeacherId('');
  };

  // Dışa Aktar Menüsü
  const handleExportClick = (event) => setAnchorEl(event.currentTarget);
  const handleExportClose = () => setAnchorEl(null);

  // CSV
  const exportToCSV = () => {
    if (ogretmenListesi.length === 0) return;
    const headers = [
      'Sıra No',
      'Ekleyen',
      'Adı',
      'Soyadı',
      'Şifre',
      'Telefon',
      'Okul',
      'Şehir',
      'İlçe',
      'Bakiye(Toplam)',
      'Bakiye 3 Ay',
      'Bakiye 6 Ay',
      'Bakiye 12 Ay',
      'Abonelik Başlangıç',
      'Abonelik Bitiş',
      'Durum',
      'Kayıt Tarihi',
    ];
    const rows = ogretmenListesi.map((ogretmen, index) => {
      const toplamBakiye =
        (ogretmen.bakiye3Ay || 0) + (ogretmen.bakiye6Ay || 0) + (ogretmen.bakiye12Ay || 0);

      let ekleyen = 'Kendi';
      if (ogretmen.adminAdi && ogretmen.adminAdi !== 'Bulunamadı' && ogretmen.adminAdi !== 'null') {
        ekleyen = ogretmen.adminAdi;
      } else if (
        ogretmen.okulAdi &&
        ogretmen.okulAdi !== 'Bulunamadı' &&
        ogretmen.okulAdi !== 'null'
      ) {
        ekleyen = ogretmen.okulAdi;
      } else if (
        ogretmen.kurumAdi &&
        ogretmen.kurumAdi !== 'Bulunamadı' &&
        ogretmen.kurumAdi !== 'null'
      ) {
        ekleyen = ogretmen.kurumAdi;
      }

      const kayitTarihi = ogretmen.createdAt
        ? new Date(ogretmen.createdAt).toLocaleDateString()
        : 'Yok';

      // Abonelik
      let abonelikBaslangic = 'Yok';
      let abonelikBitis = 'Yok';
      if (ogretmen.abonelikler && ogretmen.abonelikler.length > 0) {
        const latest = ogretmen.abonelikler.reduce((acc, curr) =>
          new Date(curr.baslangicTarihi) > new Date(acc.baslangicTarihi) ? curr : acc
        );
        abonelikBaslangic = new Date(latest.baslangicTarihi).toLocaleDateString();
        abonelikBitis = new Date(latest.bitisTarihi).toLocaleDateString();
      }

      return [
        index + 1,
        ekleyen,
        ogretmen.ad,
        ogretmen.soyad,
        ogretmen.password_open || '',
        ogretmen.telefon,
        ogretmen.okul_adi || '',
        ogretmen.sehirAdi || '',
        ogretmen.ilceAdi || '',
        toplamBakiye,
        ogretmen.bakiye3Ay || 0,
        ogretmen.bakiye6Ay || 0,
        ogretmen.bakiye12Ay || 0,
        abonelikBaslangic,
        abonelikBitis,
        ogretmen.status === 'active' ? 'Aktif' : 'Pasif',
        kayitTarihi,
      ];
    });

    const csvContent =
      'data:text/csv;charset=utf-8,' +
      [headers.join(','), ...rows.map((r) => r.join(','))].join('\n');
    const encodedUri = encodeURI(csvContent);

    const link = document.createElement('a');
    link.href = encodedUri;
    link.download = 'ogretmen_listesi.csv';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    handleExportClose();
  };

  // TXT
  const exportToTXT = () => {
    if (ogretmenListesi.length === 0) return;
    let txtContent =
      'Sıra No | Ekleyen | Adı | Soyadı | Şifre | Telefon | Okul | Şehir | İlçe | Bakiye(Toplam) | Bakiye 3 Ay | Bakiye 6 Ay | Bakiye 12 Ay | Abonelik Başlangıç | Abonelik Bitiş | Durum | Kayıt Tarihi\n';

    ogretmenListesi.forEach((ogretmen, index) => {
      const toplamBakiye =
        (ogretmen.bakiye3Ay || 0) + (ogretmen.bakiye6Ay || 0) + (ogretmen.bakiye12Ay || 0);

      let ekleyen = 'Kendi';
      if (ogretmen.adminAdi && ogretmen.adminAdi !== 'Bulunamadı' && ogretmen.adminAdi !== 'null') {
        ekleyen = ogretmen.adminAdi;
      } else if (
        ogretmen.okulAdi &&
        ogretmen.okulAdi !== 'Bulunamadı' &&
        ogretmen.okulAdi !== 'null'
      ) {
        ekleyen = ogretmen.okulAdi;
      } else if (
        ogretmen.kurumAdi &&
        ogretmen.kurumAdi !== 'Bulunamadı' &&
        ogretmen.kurumAdi !== 'null'
      ) {
        ekleyen = ogretmen.kurumAdi;
      }

      const kayitTarihi = ogretmen.createdAt
        ? new Date(ogretmen.createdAt).toLocaleDateString()
        : 'Yok';

      let abonelikBaslangic = 'Yok';
      let abonelikBitis = 'Yok';
      if (ogretmen.abonelikler && ogretmen.abonelikler.length > 0) {
        const latest = ogretmen.abonelikler.reduce((acc, curr) =>
          new Date(curr.baslangicTarihi) > new Date(acc.baslangicTarihi) ? curr : acc
        );
        abonelikBaslangic = new Date(latest.baslangicTarihi).toLocaleDateString();
        abonelikBitis = new Date(latest.bitisTarihi).toLocaleDateString();
      }

      txtContent += `${index + 1} | ${ekleyen} | ${ogretmen.ad} | ${
        ogretmen.soyad
      } | ${ogretmen.password_open || ''} | ${ogretmen.telefon} | ${
        ogretmen.okul_adi || ''
      } | ${ogretmen.sehirAdi || ''} | ${ogretmen.ilceAdi || ''} | ${toplamBakiye} | ${
        ogretmen.bakiye3Ay || 0
      } | ${ogretmen.bakiye6Ay || 0} | ${ogretmen.bakiye12Ay || 0} | ${abonelikBaslangic} | ${abonelikBitis} | ${
        ogretmen.status === 'active' ? 'Aktif' : 'Pasif'
      } | ${kayitTarihi}\n`;
    });

    const blob = new Blob([txtContent], { type: 'text/plain;charset=utf-8' });
    const url = URL.createObjectURL(blob);

    const link = document.createElement('a');
    link.href = url;
    link.download = 'ogretmen_listesi.txt';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    handleExportClose();
  };

  // XLSX
  const exportToXLSX = () => {
    if (ogretmenListesi.length === 0) return;
    const headers = [
      'Sıra No',
      'Ekleyen',
      'Adı',
      'Soyadı',
      'Şifre',
      'Telefon',
      'Okul',
      'Şehir',
      'İlçe',
      'Bakiye(Toplam)',
      'Bakiye 3 Ay',
      'Bakiye 6 Ay',
      'Bakiye 12 Ay',
      'Abonelik Başlangıç',
      'Abonelik Bitiş',
      'Durum',
      'Kayıt Tarihi',
    ];
    const wsData = [];
    wsData.push(headers);

    ogretmenListesi.forEach((ogretmen, index) => {
      const toplamBakiye =
        (ogretmen.bakiye3Ay || 0) + (ogretmen.bakiye6Ay || 0) + (ogretmen.bakiye12Ay || 0);

      let ekleyen = 'Kendi';
      if (ogretmen.adminAdi && ogretmen.adminAdi !== 'Bulunamadı' && ogretmen.adminAdi !== 'null') {
        ekleyen = ogretmen.adminAdi;
      } else if (
        ogretmen.okulAdi &&
        ogretmen.okulAdi !== 'Bulunamadı' &&
        ogretmen.okulAdi !== 'null'
      ) {
        ekleyen = ogretmen.okulAdi;
      } else if (
        ogretmen.kurumAdi &&
        ogretmen.kurumAdi !== 'Bulunamadı' &&
        ogretmen.kurumAdi !== 'null'
      ) {
        ekleyen = ogretmen.kurumAdi;
      }

      const kayitTarihi = ogretmen.createdAt
        ? new Date(ogretmen.createdAt).toLocaleDateString()
        : 'Yok';

      let abonelikBaslangic = 'Yok';
      let abonelikBitis = 'Yok';
      if (ogretmen.abonelikler && ogretmen.abonelikler.length > 0) {
        const latest = ogretmen.abonelikler.reduce((acc, curr) =>
          new Date(curr.baslangicTarihi) > new Date(acc.baslangicTarihi) ? curr : acc
        );
        abonelikBaslangic = new Date(latest.baslangicTarihi).toLocaleDateString();
        abonelikBitis = new Date(latest.bitisTarihi).toLocaleDateString();
      }

      const row = [
        index + 1,
        ekleyen,
        ogretmen.ad,
        ogretmen.soyad,
        ogretmen.password_open || '',
        ogretmen.telefon,
        ogretmen.okul_adi || '',
        ogretmen.sehirAdi || '',
        ogretmen.ilceAdi || '',
        toplamBakiye,
        ogretmen.bakiye3Ay || 0,
        ogretmen.bakiye6Ay || 0,
        ogretmen.bakiye12Ay || 0,
        abonelikBaslangic,
        abonelikBitis,
        ogretmen.status === 'active' ? 'Aktif' : 'Pasif',
        kayitTarihi,
      ];
      wsData.push(row);
    });

    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.aoa_to_sheet(wsData);
    XLSX.utils.book_append_sheet(wb, ws, 'Ogretmen Listesi');
    const wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const blob = new Blob([wbout], { type: 'application/octet-stream' });
    const url = URL.createObjectURL(blob);

    const link = document.createElement('a');
    link.href = url;
    link.download = 'ogretmen_listesi.xlsx';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    handleExportClose();
  };

  // Abonelik bitiş tarihi güncelle
  const handleUpdateEndDate = async (ogretmenId, selectedDate) => {
    if (!selectedDate) {
      setDialogSeverity('error');
      setDialogContent('Lütfen yeni bitiş tarihini seçin.');
      setDialogOpen(true);
      return;
    }
    try {
      const response = await fetch(API_URLS.ADMIN_URL + 'payments/updateSubscriptionEndDate', {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('userToken')}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          userId: ogretmenId,
          userType: 'Ogretmen',
          newEndDate: selectedDate.toISOString(),
        }),
      });
      const data = await response.json();
      if (!response.ok) {
        setDialogSeverity('error');
        setDialogContent(data.message || 'Abonelik bitiş tarihi güncellenemedi.');
        setDialogOpen(true);
        return;
      }

      setDialogSeverity('success');
      setDialogContent('Abonelik bitiş tarihi başarıyla güncellendi.');
      setDialogOpen(true);
    } catch (error) {
      setDialogSeverity('error');
      setDialogContent('Abonelik bitiş tarihi güncellenirken bir hata oluştu.');
      setDialogOpen(true);
    }
  };

  // Sayfa değiştirme
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // Dinamik "rowsPerPageOptions"
  const totalCount = ogretmenListesi.length;
  const rowsPerPageOptions = [];
  for (let i = 25; i <= totalCount; i += 25) {
    rowsPerPageOptions.push(i);
  }
  if (rowsPerPageOptions.length === 0) rowsPerPageOptions.push(25);

  // Paginated
  const paginatedData = ogretmenListesi.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );

  return (
    <>
      {/* ÜST SAYFALAMA + DIŞA AKTAR MENÜSÜ */}
      <TablePagination
        rowsPerPageOptions={rowsPerPageOptions}
        component="div"
        count={ogretmenListesi.length}
        rowsPerPage={rowsPerPage}
        page={page}
        labelRowsPerPage="Sayfa başına kayıt:"
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        sx={{ mb: 2 }}
      />
      <Button variant="outlined" onClick={handleExportClick} sx={{ mb: 2 }}>
        Dışa Aktar
      </Button>
      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleExportClose}>
        <MenuItem onClick={exportToCSV}>CSV Olarak İndir</MenuItem>
        <MenuItem onClick={exportToTXT}>TXT Olarak İndir</MenuItem>
        <MenuItem onClick={exportToXLSX}>Excel (XLSX) Olarak İndir</MenuItem>
      </Menu>

      {/* TABLO */}
      <TableContainer component={Paper}>
        <Table>
          <TableHead sx={{ backgroundColor: 'primary.main' }}>
            <TableRow>
              <TableCell />
              <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>Sıra</TableCell>
              <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>Ekleyen</TableCell>
              <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>Ad</TableCell>
              <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>Soyad</TableCell>
              <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>Email</TableCell>
              <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>Şifre</TableCell>
              <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>Telefon</TableCell>
              <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>Okul</TableCell>
              <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>Durum</TableCell>
              <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>Aktif Eden</TableCell>
              <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>Kayıt Tarihi</TableCell>
              <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>İşlem</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {paginatedData.map((ogretmen, index) => (
              <CollapsibleOgretmenRow
                key={ogretmen._id}
                index={index}
                ogretmen={ogretmen}
                page={page}
                rowsPerPage={rowsPerPage}
                handleStatusChange={handleStatusChange}
                handleEdit={handleEdit}
                handleDeleteOgretmen={handleDeleteOgretmen}
                handleAdjustBalance={handleAdjustBalance}
                handleOpenAtamaDialog={handleOpenAtamaDialog}
                handleOpenRapor={handleOpenRapor} // YENİ: Rapor
                newEndDates={newEndDates}
                setNewEndDates={setNewEndDates}
                handleUpdateEndDate={handleUpdateEndDate}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {/* ALT SAYFALAMA */}
      <TablePagination
        rowsPerPageOptions={rowsPerPageOptions}
        component="div"
        count={ogretmenListesi.length}
        rowsPerPage={rowsPerPage}
        page={page}
        labelRowsPerPage="Sayfa başına kayıt:"
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        sx={{ mt: 2 }}
      />

      {/* İşlem sonucu diyaloğu */}
      <SonucDialogu
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        title={dialogSeverity === 'error' ? 'Hata Oluştu' : 'İşlem Başarılı'}
        message={dialogContent}
        severity={dialogSeverity}
      />

      {/* Atama Dialogu (opsiyonel) */}
      <OgretmenAtamaDialog
        open={atamaDialogOpen}
        onClose={handleCloseAtamaDialog}
        ogretmen={selectedOgretmen}
      />

      {/* Rapor Modal (ÖĞRETMEN RAPORU) */}
      <OgretmenRaporuModal
        open={raporModalOpen}
        onClose={() => setRaporModalOpen(false)}
        teacherId={selectedTeacherId}
        teacherName={selectedTeacherName} // opsiyonel
      />
    </>
  );
};

export default OgretmenListesi;
